import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Table from "../Tables/Table";
import BuyerPostTable from "./buyerPostTable";
// import { brand } from "../common/utils";
import { useFormik } from "formik";
import { SlArrowDown } from "react-icons/sl";
import { buyerAuthorizeAgent, getBuyer, getBuyerAvtivity, getFileByKey, getPrimaryStates, getProfilePicByKey, getPropertyTypes, updateBuyerById } from "../../api/api";
import { MdClose, MdContactMail, MdContactPhone } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { askingPricevalues, brandTypes, dateBefore180Days, formatMobileNumber, mindownpayvalues, numberOfRoomsvalues } from "../common/utils";
import { Badge, Button, Col, Modal, Row } from "react-bootstrap";
import FilePreview from "../common/FilePreview";
import { agentHandler, getAgent, getDateFormatted } from "../common/commonUtils";
import DefaultUserImage from '../../components/assets/default-user.png'
import PdfFormFiller from "../common/PdfFormFiller";
import { Confi_Agreement_Auth_Key } from "../../constants/constants";


const BuyerPostingPage = () => {
  const navigate = useNavigate();
  const [buyersLoading, setBuyersLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [brand, setBrands] = useState();
  const [enableSort, setEnableSort] = useState(true);
  const [activity, setActivity] = useState([]);
  const [buyerInfo, setBuyerInfo] = useState();
  const [filtersApply, setFiltersApply] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState("");
  const [properties, setProperties] = useState();
  const [allproperties, setAllProperties] = useState();
  const [askingPriceslist, setAskingPrices] = useState([])
  const [numberofroomslist, setNumberOfRooms] = useState([])
  const [minDownPaymentlist, setMinDownpaymentlist] = useState([])
  const [pdfBytes, setPdfBytes] = useState();
  const [isDocOpen, setDocOpen] = useState(false);
  const [openDocType, setOpenDocType] = useState();
  const [count, setCount] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [show, setShow] = useState(false);
  const [authFormDetails, setAuthFormDetails] = useState([]);
  const [hbaStaff, setHbaStaff] = useState();
  const [imageBytes, setImageBytes] = useState();
  const [filterValues, setFilterValues] = useState({
    price_min: "",
    price_max: "",
    rooms_min: "",
    rooms_max: "",
    brand: "",
    state: "",
    downpayment_min: "",
    downpayment_max: "",
    askingpriceId: "",
    numberofroomsId: "",
    mindownpayId: "",
  })


  const initValues = {
    price_min: "",
    price_max: "",
    rooms_min: "",
    rooms_max: "",
    brand: "",
    state: "",
    downpayment_min: "",
    downpayment_max: "",
    askingpriceId: "",
    numberofroomsId: "",
    mindownpayId: "",
  };

  useEffect(async () => {

    const st = await getPrimaryStates();
    setStates(st);
   
    getBuyerInfo();

    if (filter == "dealbox") {
      blueFilters("Deal Box")
      setDefaultFilter("Deal Box")
    } else if (filter == "interestsReceived") {
      blueFilters("Deals Received By You")
      setDefaultFilter("Deals Received By You")
    } else if (filter == "shopMore") {
      blueFilters("Shop Hotels For Sale")
      setDefaultFilter("Shop Hotels For Sale")
    } else if (filter == "yourCriteria") {
      blueFilters("Your Buyer Profile")
      setDefaultFilter("Your Buyer Profile")
    } else if (filter == "confis") {
      blueFilters("Confis You Signed")
      setDefaultFilter("Confis You Signed")
    } else {
      blueFilters("Deal Box")
      setDefaultFilter("Deal Box")
    }
    sessionStorage.setItem("currentPostid", id)

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleFilterChange(e) {
    e.preventDefault()
    let name = e.target.name
    let value = e.target.value

    if (name == "brand") {
      if (value) {
        setFilterValues({
          ...filterValues, brand: value
        })
      }

    }

    if (name == "state") {
      askingPriceslist.map(val => {
        if (value) {
          setFilterValues({
            ...filterValues, state: value
          })
        }
      })
    }

    if (name == "askingprice") {
      askingPriceslist.map(val => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues, askingpriceId: value, price_max: val.max, price_min: val.min
          })
        }
      })
    }

    if (name == "numberofrooms") {
      numberofroomslist.map(val => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues, numberofroomsId: value, rooms_max: val.max, rooms_min: val.min
          })
        }
      })
    }

    if (name == "mindownpay") {
      minDownPaymentlist.map(val => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues, mindownpayId: value, downpayment_max: val.max, downpayment_min: val.min
          })
        }
      })
    }

    formik.handleSubmit()

  }

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {
      filterData();
      setFiltersApply((current) => !current);
    },
  });

  const { id, filter } = useParams();


  const closeDoc = () => {
    setDocOpen(false);
  };


  const getBuyerInfo = async () => {
    let res = await getBuyer(id);
    const data = (res ? res.data : res);


    setBrands(brandTypes);
    setAskingPrices(askingPricevalues)
    setNumberOfRooms(numberOfRoomsvalues);
    setMinDownpaymentlist(mindownpayvalues)

    if (data?.hbaStaff) agentHandler(data?.hbaStaff)

    if (res.status == 200) {
      setBuyerInfo(data);
      setProperties(data.viewableProperties);
      setAllProperties(data.viewableProperties);  
    
      {(data?.hbaStaff == null || data?.hbaStaff == "") ?  setHbaStaff(getAgent()) :  setHbaStaff(data?.hbaStaff)}
    }

    {(res?.data?.hbaStaff == null || res?.data?.hbaStaff == "") ?  getImageCards(getAgent()?.profilePic) :  getImageCards(res?.data?.hbaStaff?.profilePic)}
    localStorage.setItem("Approved", data.dealStatus);
    setFiltersApply((current) => !current);
    setBuyersLoading(false);
  };

  const removeFilterHandler = (val, array) => {
    if (array === "brand") {
      let data = formik.values.brand
      formik.values.brand = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setProperties(allproperties);
      } else filterData();
      setFiltersApply((current) => !current)
    } else if (array === 'state') {
      let data = formik.values.state
      formik.values.state = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setProperties(allproperties);
      } else filterData();
      setFiltersApply((current) => !current)
    }
  };

  const clearFilters = (val) => {
    const values = formik.values;
    if (val === 'rooms') {
      formik.values.rooms_min = initValues.rooms_min;
      formik.values.rooms_max = initValues.rooms_max;
      if (
        values.dp_min !== initValues.dp_min ||
        values.dp_max !== initValues.dp_max ||
        values.price_min !== initValues.price_min ||
        values.price_max !== initValues.price_max
      ) {
        filterData();
      } else setProperties(allproperties);
    } else if (val === 'price') {
      formik.values.price_min = initValues.price_min;
      formik.values.price_max = initValues.price_max;
      if (
        values.dp_min !== initValues.dp_min ||
        values.dp_max !== initValues.dp_max ||
        values.rooms_min !== initValues.rooms_min ||
        values.rooms_max !== initValues.rooms_max
      ) {
        filterData();
      } else setProperties(allproperties);
    } else {
      formik.values.dp_min = initValues.dp_min;
      formik.values.dp_max = initValues.dp_max;
      if (
        values.rooms_min !== initValues.rooms_min ||
        values.rooms_max !== initValues.rooms_max ||
        values.price_min !== initValues.price_min ||
        values.price_max !== initValues.price_max
      ) {
        filterData();
      } else setProperties(allproperties);
    }
    setFiltersApply((current) => !current);
  }

  const resetHandler = () => {
    formik.resetForm();
    setFilterValues(initValues)
    setProperties(allproperties);
    setFiltersApply((current) => !current);
  };

  //------------------------------------------------BLUE FILTERS--------------------------------------------------//

  const last180Days = () => {
    const dateLimit = dateBefore180Days();
    const properties = [];
    allproperties?.forEach(pty => {
      let ptyDate = new Date(pty.creationTime);
      if (ptyDate > dateLimit) {
        return properties.push(pty);
      }
    })
    return properties;
  }

  const blueFilters = (flt) => {
    if (flt === "Shop Hotels For Sale") {
      setProperties([]);
      formik.resetForm();
      setFiltersApply((current) => !current);
      setTimeout(() => {
        setProperties(allproperties)
      }, 1000);
    }
    else if (flt === "Deals Selected By You") {
      let interestedProperties = [];
      setProperties([]);
      buyerInfo?.interestSent?.forEach(interest => interestedProperties.push(interest.property))
      setTimeout(() => {
        setProperties(interestedProperties);
      }, 1000);
    }
    else if (flt === "Deals Received By You") {
      let interestedProperties = [];
      setProperties([]);
      buyerInfo?.interestReceived?.forEach(interest => interestedProperties.push(interest.property))
      setTimeout(() => {
        setProperties(interestedProperties);
      }, 1000);
    } else if (flt === "Deals Rejected By You") {
      console.log(buyerInfo?.interestSent)
      let rejectedProperties = [];
      setProperties([]);
      buyerInfo?.interestReceived?.forEach(interest => {
        interest?.removeFromBuyerDealBox ?
          rejectedProperties.push(interest.property) : <></>
      }
      )
      buyerInfo?.interestSent?.forEach(interest => {
        interest?.removeFromBuyerDealBox ?
          rejectedProperties.push(interest.property) : <></>
      }
      )

      buyerInfo?.interestReceived?.forEach(interest => {
        !interest?.buyerAccepted ?
          rejectedProperties.push(interest.property) : <></>
      }
      )

      setTimeout(() => {
        console.log(rejectedProperties)
        setProperties(rejectedProperties);
      }, 1000);
    }
    else if (flt === "Deal Box") {
      let dealBoxProperties = [];
      setProperties([]);
      buyerInfo?.dealBox?.sort((a, b) => a?.buyerRank - b?.buyerRank);   /// sort by rank

      buyerInfo?.dealBox?.forEach(prop => {
        let interestProp = prop.property
        interestProp.comments = prop.buyerComments
        interestProp.rank = prop.buyerRank
        dealBoxProperties.push(interestProp)
      }
      );
      setProperties(dealBoxProperties);
    } else if (flt == "Confis You Signed") {
      let interests = [];
      setProperties([]);
      buyerInfo?.interestSent?.forEach(interest => interests.push(interest))
      setTimeout(() => {
        setProperties(interests);
      }, 1000);
    }
  }

  //-------------------------------------------------------FILTERS LOGIC---------------------------------------------------//

  const filterData = () => {
    let currentFilteredData = allproperties;


    if (filterValues.price_min !== "" || filterValues.price_max !== "") {
      const min = filterValues.price_min !== "" ? filterValues.price_min : 0;
      const max = filterValues.price_max !== "" ? filterValues.price_max : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((p) => p.askingPrice > min && p.askingPrice <= max);
    }

    if (filterValues.rooms_min !== "" || filterValues.rooms_max !== "") {
      const min = filterValues.rooms_min !== "" ? filterValues.rooms_min : 0;
      const max = filterValues.rooms_max !== "" ? filterValues.rooms_max : 300;
      currentFilteredData = currentFilteredData.filter((b) => b.noOfRooms >= min && b.noOfRooms <= max);
    }

    if (filterValues.brand !== "" && filterValues.brand !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => b.propertyType.brandType === filterValues.brand);
    }

    if (filterValues.state !== "" && filterValues.state !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => b.primaryState.id == filterValues.state);
    }

    if (filterValues.downpayment_min !== "" || filterValues.downpayment_max !== "") {
      const min = filterValues.downpayment_min !== "" ? filterValues.downpayment_min : 0;
      const max = filterValues.downpayment_max !== "" ? filterValues.downpayment_max : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((p) => p.minDownPayment >= min && p.minDownPayment <= max);
    }

    setProperties(currentFilteredData);
  }
  //-------------------------------------------------------FILTERS LOGIC---------------------------------------------------//

  const filterTabs = () => {
    return (
      <div className="filter-div" style={{ width: "100%" }}>
        <div
          style={{ marginLeft: "-5px" }}
          className="row "
        >
          <div className="col col-xl col-lg-3 col-md-3 ">
            <label data="Maximum Buying Power"><b>Brand</b></label><br></br>
            <select name="brand" onChange={(e) => handleFilterChange(e)}
              className="filter-div-button-wrapper" value={filterValues.brand}>
              {brand
                ? brand.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val}
                  >
                    {val}
                  </option>
                ))
                : " "}

            </select>

          </div>
          <div className="col col-xl col-lg-3 col-md-3 ">
            <label data="Maximum Buying Power"><b>State</b></label><br></br>
            <select name="state" onChange={(e) => handleFilterChange(e)}
              className="filter-div-button-wrapper" value={filterValues.state}>
              <option className="filter-select"
                name="askingprice"
                value="Any" >Any</option>
              {states
                ? states.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val.id}
                  >
                    {val.name}
                  </option>
                ))
                : " "}

            </select>

          </div>
          <div className="col col-xl col-lg-3 col-md-3">
            <label data="Maximum Buying Power"><b>AskingPrice</b></label><br></br>
            <select name="askingprice" onChange={(e) => handleFilterChange(e)}
              className="filter-div-button-wrapper" value={filterValues.askingpriceId}>
              {askingPriceslist
                ? askingPriceslist.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val.id}
                    max={val.max}
                    min={val.min}
                  >
                    {val.label}
                  </option>
                ))
                : " "}

            </select>

          </div>
          <div className="col col-xl col-lg-3 col-md-3">

            <label data="Maximum Buying Power"><b>Number of Rooms</b></label><br></br>
            <select name="numberofrooms" onChange={(e) => {
              handleFilterChange(e)
            }} className="filter-div-button-wrapper" value={filterValues.numberofroomsId}>
              {numberofroomslist
                ? numberofroomslist.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="numberofrooms"
                    value={val.id}
                  >
                    {val.label}
                  </option>
                ))
                : " "}
            </select>

          </div>
          <div className="col col-xl col-lg-3 col-md-3">

            <label data="Maximum Buying Power"><b>Minimum Downpayment</b></label><br></br>
            <select name="mindownpay" onChange={(e) => {
              handleFilterChange(e)
            }} className="filter-div-button-wrapper" value={filterValues.mindownpayId}>
              {minDownPaymentlist
                ? minDownPaymentlist.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="mindownpay"
                    value={val.id}
                  >
                    {val.label}
                  </option>
                ))
                : " "}
            </select>

          </div>

        </div>
      </div>
    );
  };

  const filteration = useMemo(() => filterTabs, [filtersApply]);

  const resetBtn = () => {
    return (
      <button onClick={() => resetHandler()} className="btn btn-outline-primary-narrow-right">
        Reset
      </button>
    );
  };

  const getData = async () => {
    let res = await getBuyer(id);
    const data = (res ? res.data : res);

    if (res.status == 200) {
      setBuyerInfo(data);
      setProperties(data.viewableProperties);
      setAllProperties(data.viewableProperties);
      blueFilters('Deal Box')
    }
    setFiltersApply(true);
    setBuyersLoading(false);
    return properties;
  }

  const refreshData = async () => {
    let res = await getBuyer(id);
    const data = (res ? res.data : res);

    if (res.status == 200) {
      setBuyerInfo(data);
      setProperties(data.viewableProperties);
      setAllProperties(data.viewableProperties);
      blueFilters('Deal Box')
    }
    setFiltersApply(true);
    setBuyersLoading(false);
  };

  const handleSignAuthorization = async () => {

    setSpinner(true)
    let payload = {
      id: buyerInfo?.id,
      agentAuth: true
    }

    let res = await buyerAuthorizeAgent(payload)

    const data = (res ? res.data : res);
    if (res.status == 200) {
      refreshData()
      setProperties(data?.viewableProperties);
      setAllProperties(data?.viewableProperties);
      blueFilters('Deal Box')
    }
    setShow(false)
    setFiltersApply(true);
    setSpinner(false)
  }

  const handleViewSignAuthorization = async (e, key, type) => {
    setSpinner(true)
    await handleDownload(key, type).then((res => {
      setDocOpen(true)
      setSpinner(false)
    }));
    setSpinner(false)
  }

  const getFileExtention = (key) => {
    console.log(key.split('.').pop().toLowerCase())
    return key.split('.').pop().toLowerCase();
  }

  const handleAuthDoc = async () => {
    setAuthFormDetails([buyerInfo?.user.firstName + " " + buyerInfo?.user?.lastName, buyerInfo?.hbaStaff?.firstName + " " + buyerInfo?.hbaStaff?.lastName, new Date().toLocaleDateString(), " ", buyerInfo?.user.firstName.toUpperCase() + " " + buyerInfo?.user?.lastName.toUpperCase()])
    setSpinner(true)
    await handleDownload(Confi_Agreement_Auth_Key, "pdf").then((res) => {
      setSpinner(false)
      setShow(true)
    })
  }

  const handleDownload = async (key, type) => {
    try {
      await getFileByKey(key).then(res => {
        const bytes = new Uint8Array(res.data);
        setPdfBytes(bytes)
        setOpenDocType(type)
      });
    } catch (error) {
      alert("Error downloading file");
    }
  };

  const getCalculations = (name) => {

    if (name === "totalBuyingPower")
      return ((parseInt(buyerInfo.funds1031) + parseInt(buyerInfo.additionalFunds)) * 5);


  }

  const getBadgeColor = (value) => {
    if (value == "Approved") {
      return "fill-green   px-2 "
    } else if (value == "Unapproved") {
      return "fill-yellow   px-2 "
    } else if (value == "In-contract") {
      return "fill-orange   px-2 "
    } else if (value == "Closed") {
      return "fill-grey   px-2 "
    } else if (value == "Off-market") {
      return "fill-paleBlue   px-2 "
    }
  }

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getColourClass = (colour) => {
    return `currancy-font ${colour}-color`
  }

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  const getImageCards = async (key) => {
    try {
      if(key != null){
        await getProfilePicByKey(key)?.then(res => {
          const bytes = new Uint8Array(res?.data);
          setImageBytes(bytes)
          return bytes
        });
      }
      
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  return (
    <>
      {buyersLoading === false ? (
        <div className="posting-page fill-blue">
          <>
            <div className="row profile-header">
              <div className="row">
                <div className="col py-1 fit-content-max ">
                  <div className="row">
                    <h4><b>{buyerInfo?.buyerName?.toUpperCase()}'s</b> CONFIDENTIAL BUYER ID IS</h4>
                  </div>
                  <div className="row d-flex justify-content-center">
                    <h1 className="fit-content-max  "><label className="font-size-header-id fill-blue px-2"> {buyerInfo?.generatedBuyerId}</label></h1>
                  </div>
                </div>
                <div className="col py-1 d-flex justify-content-center align-items-end">
                  <h4><b>{buyerInfo?.buyerName}'s</b> <b> 1031 Buyer Profile Information</b></h4>
                </div>

                <div className="col py-2 fit-content-max" style={{ marginLeft: "auto", right: "2%" }}>
                  {
                    hbaStaff ?
                      <>
                        <div className="row">
                          <div className="col fit-content-max">
                            <div className="profile-pic">
                              <div className="d-flex justify-content-center">
                                {imageBytes && (
                                  <img
                                    src={`data:image/jpeg;base64,${arrayBufferToBase64(imageBytes)}`}
                                    alt={DefaultUserImage}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col ">
                            <div className="row ">
                              <label><h2 className="font-size-18">Your 1031 Agent is {hbaStaff?.firstName} {hbaStaff?.lastName} </h2></label>
                            </div>
                            <div className="row">
                              <label className="col d-flex px-2" ><MdContactPhone style={{ fontSize: '20px', color: 'grey' }}></MdContactPhone>   <h2 className="font-size-15">    {formatMobileNumber(hbaStaff?.mobileNumber)}</h2></label>
                              <label className="col px-2 d-flex"><MdContactMail style={{ fontSize: '20px', color: 'grey' }}></MdContactMail>   <h2 className="font-size-15">    {hbaStaff?.email}</h2></label>
                            </div>
                            <div className="row">
                              <div className="col">
                                {(JSON.parse(sessionStorage.getItem("user_type")) == "Buyer" && buyerInfo?.hbaStaff != null && buyerInfo?.agentAuthConfi == null) ?
                                  <a className="fill-red highlight-background-on-hover" onClick={handleAuthDoc}>Please sign this Confidential Agreement Authorization form</a>
                                  : ((JSON.parse(sessionStorage.getItem("user_type")) == "Buyer" || JSON.parse(sessionStorage.getItem("user_type")).includes("Agent")) && buyerInfo?.hbaStaff != null && buyerInfo?.agentAuthConfi != null) ?
                                    <a className="fill-paleBlue highlight-background-on-hover" onClick={(e) => handleViewSignAuthorization(e, buyerInfo?.agentAuthConfi, getFileExtention(buyerInfo?.agentAuthConfi))}>View signed Confidential Agreement Authorization</a>
                                    : <></>
                                }
                              </div>
                            </div>

                          </div>
                        </div>
                      </>
                      :
                      <></>
                  }
                </div>



                <></>
              </div>
              <hr className=""></hr>
              <div className="row py-2">
                <div className="col d-flex justify-content-start align-items-start">
                  <div className="border-box w-100  h-100">
                    <div className="row">
                      <div className="col">
                        <label className="d-flex justify-content-center align-items-center bottom-border"><h3 className="font-size-14-black">With 20% Down Payment, Your Maximum  Buying Power is </h3></label><br></br>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col">
                        <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font">$  {buyerInfo?.buyingPower != null ? formatNumberWithCommas(buyerInfo?.buyingPower) : 0}</h1></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col d-flex justify-content-center align-items-center">
                  <div className="border-box">
                    <div className="row">
                      <div className="col ">
                        <label className="d-flex justify-content-center align-items-center bottom-border"><h3 className="font-size-14-black">Days Remaining to Nominate</h3></label><br></br>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col">
                        <label className="d-flex justify-content-center align-items-center"><h1 className={getColourClass(buyerInfo?.deadLineDate45DaysColor)}>
                          {buyerInfo?.deadLineDate45DaysString !== null ? buyerInfo.deadLineDate45DaysString.includes('critical') ? buyerInfo.deadLineDate45DaysString : buyerInfo.deadLineDate45DaysString + " Days" : "-"} <span className="black-color font-span">{getDateFormatted(buyerInfo?.deadLineDate45Days)}</span>
                        </h1> </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col d-flex justify-content-end align-items-end">
                  <div className="border-box">
                    <div className="row">
                      <div className="col">
                        <label className="d-flex justify-content-center align-items-center bottom-border"><h2 className="font-size-14-black">Days Remaining to CLOSE Escrow</h2></label><br></br>
                      </div>
                    </div>
                    <div className="row py-1">
                      <div className="col">
                        <label className="d-flex justify-content-center align-items-center"><h1 className={getColourClass(buyerInfo?.closingDate1031Color)}>
                          {buyerInfo?.closingDate1031String !== null ? buyerInfo.closingDate1031String.includes('critical') ? buyerInfo.closingDate1031String : buyerInfo.closingDate1031String + " Days" : "-"}  <span className="black-color font-span">{getDateFormatted(buyerInfo?.closingDate1031)}</span>
                        </h1> </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <Modal show={isDocOpen} size="xl" onHide={closeDoc}>
              {pdfBytes != null ?
                <FilePreview arrayBuffer={pdfBytes} fileType={openDocType}></FilePreview>
                : <></>
              }
            </Modal>

            <Modal show={show} size="xl" onHide={closeDoc}>
              {pdfBytes != null ?
                <PdfFormFiller arrayBuffer={pdfBytes} fileType={openDocType} formDetails={authFormDetails} handleIAgree={handleSignAuthorization} handleCancel={handleClose}></PdfFormFiller>
                : <></>
              }
            </Modal>



            <BuyerPostTable
              products={properties ? properties : []}
              column={"buyercolumns"}
              filterTabs={filteration}
              reset={resetBtn}
              resetHandler={resetHandler}
              filtersApply={filtersApply}
              defaultFilter={defaultFilter}
              blueFilters={blueFilters}
              enableSort={enableSort}
              setEnableSort={setEnableSort}
              user={buyerInfo}
              // onStateChange={handleChildStateChange} 
              refreshData={refreshData}
              approved={buyerInfo?.dealStatus}
            />
          </>
        </div>

      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "80vh",
            alignItems: "center",
          }}
        >
          <h2>Loading...</h2>
        </div>
      )}
    </>
  );
};

export default BuyerPostingPage;
