import React, { useEffect, useState } from "react";
import {useSearchParams} from "react-router-dom";
import {verifyEmail} from "../../../api/api";
import { toast } from "react-toastify";

const VerifyEmail = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    let token = searchParams.get("token");
    const [data, setData] = React.useState([])
    var loader = document.getElementsByName("loading_icon");

    useEffect(() => {
        loader[0].classList.add("display");
        let result = verifyEmailInfo(token);
        if(result)
        {
            localStorage.setItem("showVerifyEmail","true");
            window.location = '/login';
        }
    }, []);


    const verifyEmailInfo = async (opts) => {
        let res = await verifyEmail(opts);
        return res;
    };

    return (
        <>
            <div className="container-fluid global-div global-div-override">
                    <div className="top-banner">
                        <div className="hero-container">
                            <div id="loading" name="loading_icon" style={{position:"unset"}}/>
                            <span> Verifying Account</span>
                        </div>
                    </div>
            </div>
        </>
    );
};

export default VerifyEmail;
