import React, { useEffect, useRef, useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";

import { FaPlus, FaMinus } from "react-icons/fa";
import { S3_IMAGES } from "../../constants/constants";

const TableComponent = ({
  products,
  columns,
  deals,
  loading,
  filterTabs: Filter,
  filterBtnTabs: FilterButton,
  searchData,
  rowStyle,
  noDataMessage,
  clickEvent,
}) => {
  const { SearchBar } = Search;
  const target = useRef(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [grayedRowsId, setGrayedRowsId] = useState([]);

  const handleRowHover = (row, isHover) => {
    if (isHover) {
      setSelectedRowId(row.id);
      setSelectedRow(row);
    } else {
      setSelectedRowId(null);
      setSelectedRow(null);
    }
  };

  const rowEvents = {
    onClick: (e, row) => clickEvent(row.id),
  };

  useEffect(() => {}, []);

  const setGrayedRows = (rows) => {
    let grayedId = [];
    rows.map((row) => {
      row?.dealStatus.includes("Closed") ? grayedId.push(row?.id) : <></>;
    });
    setGrayedRowsId(grayedId);
    return grayedId;
  };

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "";
      activeStyle.padding = "0 10px";
      activeStyle.fontSize = "10px";
      activeStyle.color = "black";
      activeStyle.marginRight = "6px";
      activeStyle.backgroundColor = "#c3c1c185";
      activeStyle.cursor = "pointer";
      activeStyle.borderRadius = "2px";
      activeStyle.boxShadow =
        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
    } else {
      activeStyle.backgroundColor = "";
      activeStyle.padding = "0 10px";
      activeStyle.fontSize = "10px";
      activeStyle.marginRight = "6px";
      activeStyle.color = "black";
      activeStyle.backgroundColor = "";
      activeStyle.cursor = "pointer";
      activeStyle.borderRadius = "2px";
      activeStyle.boxShadow =
        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
    }
    return (
      <li className="page-item">
        <div onClick={handleClick} style={activeStyle}>
          {" "}
          {page}
        </div>
      </li>
    );
  };

  const options = {
    pageButtonRenderer,
    sizePerPage: 1000,
    sizePerPageList: [],
  };

  const expandRow = {
    renderer: (row) => (
      <div>
        <p>{`This Expand row is belong to rowKey ${row.id}`}</p>
        <p>
          You can render anything here, also you can add additional data on
          every row object
        </p>
        <p>
          expandRow.renderer callback will pass the origin row object to you
        </p>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnPosition: "left",
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        // return <b>-</b>;
      }
      // return <b>+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <FaMinus style={{ cursor: "pointer", fontSize: "10px" }} />
          </b>
        );
      }
      return (
        <b>
          <FaPlus style={{ cursor: "pointer", fontSize: "10px" }} />
        </b>
      );
    },
  };

  const getRowStyle = (row, rowIndex) => {
    
    let grayedRows = [];
    products.map((row) => row?.dealStatus == "Closed" ? grayedRows.push(row.id) : <></>);
    if (grayedRows?.includes(row.id)) {
      return {
        backgroundColor: "#6c757da1" ,
        cursor: "move",
      };
    }

    if (rowStyle == "hotelRowStyle") {
      <div className="row">
        {getImagesArray(row?.imagesPath).map((url) => {
          return {
            backgroundImage: `url(${url})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right",
            backgroundSize: "contain",
            cursor: "pointer",
          };
        })}
      </div>;
    }
    return {};
  };

  const getImagesArray = (urls) => {
    let urlsArray = urls.split(",");
    let imageUrls = [];

    urlsArray.slice(0, 5).map((url) => {
      if (url.includes("files")) {
        const index = url.indexOf("files");
        const substringBeforeKeyword = url.substring(0, index);

        if (substringBeforeKeyword.trim() === "") {
          imageUrls.push(S3_IMAGES + url);
        } else {
          imageUrls.push(url);
        }
      }
    });

    return imageUrls;
  };

  return (
    <>
      <ToolkitProvider
        keyField="id"
        data={products && products.length ? products : []}
        columns={columns}
        apperClasses="boo"
        pagination={paginationFactory(options)}
        search
        ref={target}
      >
        {(props) => (
          <>
            <div className="table-scroll">
              {searchData === true ? (
                <div
                  style={{ marginBottom: "0.5rem" }}
                  className="custom-search-bar-div"
                >
                  <div style={{ fontSize: "13px" }}>Filters : </div>
                  <div>
                    <span>Search:&nbsp;</span>
                    <SearchBar
                      {...props.searchProps}
                      className="custom-search-field"
                      style={{ color: "black" }}
                      placeholder=" "
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {Filter ? <Filter /> : ""}
              {FilterButton ? <FilterButton /> : ""}
              <BootstrapTable
                id="list-table"
                rowStyle={getRowStyle}
                pagination={paginationFactory(options)}
                noDataIndication={
                  loading === true ? "Loading Data ..." : noDataMessage
                }
                {...props.baseProps}
                rowEvents={rowEvents}
                rowClasses={(row) =>
                  selectedRowId === row.id ? "hovered-row" : ""
                }
              />
            </div>
          </>
        )}
      </ToolkitProvider>
    </>
  );
};

export default TableComponent;
