import React, { useEffect } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableComponent from "../../Tables/TableComponent";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import CurrencyFormat from 'react-currency-format';
import { brokerDashboardInterests } from "../../../api/api";


const AgentDashBoardPropertyTable = ({propertiesRows}) => {
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setProperties(propertiesRows)

  }, [])

  


  const propertyInterestColumns = [
    {
      dataField: "generatedPropertyId",
      text: "Hotel Id",
      classes: "broker-buyer-interest-th",

      formatter: (value) => <span><b>{value ? value : "ID"}</b></span>,
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let id = row.id;
          navigate("/propertyPost/" + id + "/interestsReceived");
        },
      },
    },
    {
      dataField: "sellerName",
      text: "Seller Name",
      sort: true,
      classes: "broker-buyer-interest-th",
      formatter: (value) => (
        <span>{value !== null ? `${value}` : "-"}</span>
      ),
    },
    {
      dataField: "address",
      text: "Location",
      sort: true,
      classes: "broker-buyer-interest-th",
      formatter: (value) => <span>{value !== null ? `${value?.state?.name}` : "-"}</span>,
    },
    {
      dataField: "totalInterested",
      text: "Total Interests",
      sort: true,
      classes: "broker-buyer-interest-th",
      formatter: (value) => <span>{value !== null ? `${value}` : "-"}</span>,
    },
    {
      dataField: "askingPrice",
      text: "Asking Price",
      sort: true,
      classes: "broker-buyer-interest-th",
      formatter: (value) => (
        <CurrencyFormat value={value != null ? value : '-'} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      ),
    }
  ];



  return (
    <div className="col-12">
      <div className="card">
        <TableComponent
          columns={propertyInterestColumns}
          products={propertiesRows}
          filterTabs={''}
          filterBtnTabs={''}
          searchData={false}
          noDataMessage={'No New Interests For Hotels'}
        />
      </div>
    </div>
  );
};

export default AgentDashBoardPropertyTable;
