import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDashboard } from "../../api/api";
import DashboardBuyerCard from "./DashboardBuyerCard";
import DashboardPropertyCard from "./DashboardPropertyCard";
import { useNavigate } from "react-router-dom";
import { notifyRegister } from "../common/formUtils";
import { getUserType } from "../common/commonUtils";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Badge } from "react-bootstrap";
import background from "../assets/pngwing.png";
import buyerBg from "../assets/buildingImg.png";

const Dashboard = () => {
  const [buyerData, setBuyerData] = useState([]);
  const [propertyData, setPropertyData] = useState();
  const [userType, setUserType] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    getDashboardInfo();
    { getUserType().includes("Agent") ? setUserType("Agent") : <></> }
    { getUserType().includes("Broker") ? setUserType("Buyer") : <></> }
    { getUserType() === "Buyer" ? setUserType("Buyer") : <></> }
    { getUserType() === "Seller" ? setUserType("Seller") : <></> }

  }, []);

  const getDashboardInfo = async () => {
    let res = await getDashboard();
    setBuyerData(res.data?.buyers);
    setPropertyData(res.data?.properties);
    checkApproved(res.data);
  };

  const checkApproved = (data) => {
    if (data.approvedBuyer === true) {
      sessionStorage.setItem("Appbuyer", 1)
    } else sessionStorage.setItem("Appbuyer", 0)

    if (data.approvedSeller === true) {
      sessionStorage.setItem("Appseller", 1)
    } else sessionStorage.setItem("Appseller", 0)
  }


  if (localStorage.getItem("registered") === "brokerRegistered") {
    notifyRegister("brokerRegistered");
    localStorage.setItem("registered", null);
  }
  if (localStorage.getItem("registered") === "sellerRegistered") {
    notifyRegister("sellerRegistered");
    localStorage.setItem("registered", null);
  }
  if (localStorage.getItem("registered") === "buyerRegistered") {
    notifyRegister("buyerRegistered");
    localStorage.setItem("registered", null);
  }


  return (
    <>
      <Breadcrumbs separator=">" aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          <HomeIcon sx={{ mr: 0.5, mb: 0.5 }} fontSize="inherit" />Home
        </Link>
        <Typography color="text.primary">Dashboard</Typography>
      </Breadcrumbs>
      {false ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "80vh",
            alignItems: "center",
          }}
        >
          <h2>Loading...</h2>
        </div>
      ) : (
        <div className="dashboardPage">
          {getUserType().includes("Broker") ?
            <>
              <div className="row d-flex align-items-center justify-content-center">
                {getUserType().includes("Broker") || getUserType() == "Buyer" ?
                  <button className="col-2 btn btn-dark btn-sm d-flex align-items-center justify-content-center" onClick={() => setUserType("Buyer")}>Buyers</button> : <></>
                }
                {getUserType().includes("Broker") || getUserType() == "Seller" ?
                  <button className="col-2 btn btn-dark btn-sm  d-flex align-items-center justify-content-center" onClick={() => setUserType("Seller")}>Properties</button> : <></>
                }
              </div>
            </> : <></>}

          <br></br>

          {getUserType().includes("Broker") ? (<>
            {userType === "Buyer" ?
              <div id="row-id" className="row-id-broker row">
                {buyerData && buyerData.length
                  ? buyerData?.map((buyer, index) => (
                    <div
                      key={index}
                      className="primary-div card col-4"
                      style={{
                        background: `URL(${background})`,
                        backgroundPosition: "center",
                        height: "100%",
                        minHeight: '375px',
                        backgroundSize: "cover",
                        width:"40%"
                      }}>
                      <DashboardBuyerCard buyer={buyer} />
                    </div>
                  ))
                  : ""}
                <>
                  <div className={buyerData && buyerData.length > 0 ? 'card col-4' : 'card '}>
                      <div id="card-body" className="card-body">
                        <div onClick={() => navigate("/addbuyer")} className="plus-icon-new">
                          <span style={{ opacity: 0.1 }}>
                            <FontAwesomeIcon icon="fa-sharp fa-plus fa-solid" />
                          </span>
                          <span>Add new buyer profile</span>
                        </div>
                      </div>
                    </div>
                </>
              </div> : <></>}
            {userType == "Seller" ?
              <div id="row-id" className="row-id-broker row">
                {propertyData && propertyData.length
                  ? propertyData.map((property, index) => (
                    <div
                      key={index}
                      className="primary-div card col-4"
                      style={{
                        background: `URL(${background})`,
                        backgroundPosition: "center",
                        height: "100%",
                        minHeight: '375px',
                        backgroundSize: "cover",
                        width:"40%"
                      }}>
                      <DashboardPropertyCard property={property} />
                    </div>
                  ))
                  : ""}
                <>

                    <div className={propertyData && propertyData.length > 0 ? 'card col-4' : 'card '}>
                      <div id="card-body" className="card-body" style={{ paddingTop: "50px !important" }}>
                        <div onClick={() => navigate("/addseller")} className="plus-icon-new">
                          <span style={{ opacity: 0.1 }}>
                            <FontAwesomeIcon icon="fa-sharp fa-plus fa-solid" />
                          </span>
                          <span>Add new property profile</span>
                        </div>
                      </div>
                    </div>

                </>
              </div>
              : <></>}

          </>) : null}
          {getUserType() === "Buyer" ? (<>
            <div id="row-id" className="row-id">
              {buyerData && buyerData.length
                ? buyerData.map((buyer, index) => (
                      <div
                          key={index}
                          className="primary-div card col"
                          style={{
                            background: `URL(${background})`,
                            backgroundPosition: "center",
                            height: "100%",
                            minHeight: '375px',
                            backgroundSize: "cover",
                            width:"40%"
                          }}>
                    <DashboardBuyerCard buyer={buyer} />
                      </div>
                ))
                : ""}
              {buyerData && buyerData.length === 0 ? (<>
                <div className="primary-div">
                  <div className="card d-flex" style={{ height: "100%", minHeight: '325px' }}>
                    <div id="card-body " className="card-body" style={{ paddingTop: "10px" }}>
                      <div onClick={() => navigate("/addbuyer")} className="plus-icon-new">
                        <span style={{ opacity: 0.1 }}>
                          <FontAwesomeIcon icon="fa-sharp fa-plus fa-solid" />
                        </span>
                        <span>Add new buyer profile</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>) : null}
            </div>
          </>) : null}
          {getUserType() === "Seller" ? (<>
            <div id="row-id" className="row-id">
              {propertyData && propertyData.length
                ? propertyData.map((property, index) => (
                  <div
                    key={index}
                    className="primary-div card"
                    style={{
                      background: `URL(${background})`,
                      backgroundPosition: "center",
                      height: "100%",
                      minHeight: '375px',
                      backgroundSize: "cover",
                      width:"40%"
                    }}>
                    <DashboardPropertyCard property={property} />
                  </div>
                ))
                : ""}
              {propertyData && propertyData.length === 0 ? (<>
                <div className="primary-div">
                  <div className="card d-flex" style={{ height: "100%", minHeight: '325px'}}>
                    <div id="card-body" className="card-body" style={{ paddingTop: "50px !important" }}>
                      <div onClick={() => navigate("/addseller")} className="plus-icon-new">
                        <span style={{ opacity: 0.1 }}>
                          <FontAwesomeIcon icon="fa-sharp fa-plus fa-solid" />
                        </span>
                        <span>Add new hotel profile</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>) : null}
            </div>
          </>) : null}
        </div>
      )}
    </>
  );
};

export default Dashboard;
