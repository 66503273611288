import React from 'react';
import { useNavigate } from 'react-router-dom';
import TradeClipArt from '../../components/assets/TradeClipArt.png'
import BackgroundStrip from '../../components/assets/BackgroundStrip.jpg'
import { FaCentSign, FaPeopleLine } from 'react-icons/fa6';
import AnimatedDiv from '../common/Animation';
import { TbShoppingCartDollar } from "react-icons/tb";
import { FaHotel, FaPeopleArrows, FaRegPlayCircle } from 'react-icons/fa';
import { MdOutlineApproval } from 'react-icons/md';
import { IoDocumentLockOutline } from "react-icons/io5";
import { RiNewspaperLine, RiSecurePaymentLine } from 'react-icons/ri';
import { GiFlatPlatform } from 'react-icons/gi';
import { getUser } from '../common/commonUtils';

const SellerLanding = () => {

    let navigate = useNavigate();

    return (
        <>
            <>
                <div className=" gradient-background container-landing d-flex justify-content-start flex-column py-4">
                    <div className='row transform-Y'>
                        <h2 className='row px-4 font-size-50'>SELLERS</h2>
                        <h1 className='row px-4'>WELCOME TO 1031 HOTELS</h1>
                        <label className='row fill-white px-4 font-size-32 px-4'>
                            Now you can shop for your own 1031Exchange Buyer
                        </label>
                        <label className='row fill-white px-4 font-size-24 px-4'>
                            1031Hotels.com is the America's first and only marketplace for sellers to sell hotels to 1031 buyers.
                            <br></br>
                            Register your hotel for sale now and close the deal immediately!
                        </label>
                    </div>

                    <div className='row py-5 mt-5  mobile-column-flex'>
                        {getUser() != null ? <></> : <>
                            <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                                <button className='btn btn-white-outline px-4 h-100 transform-Y button-mobile-width-300' onClick={() => navigate("/sellerRegistration")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0'>Register as Seller</span> </button>
                            </div>

                            <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                                <button className='btn btn-white-outline px-4 h-100 transform-Y button-mobile-width-300' onClick={() => navigate("/login")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0'>Login as Seller</span></button>
                            </div>
                        </>}
                        <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                            <button className='btn btn-white-outline px-4    h-100 transform-Y button-mobile-width-300' onClick={() => navigate("/buyerList")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0 '>View 1031 Exchange Buyers</span></button>
                        </div>
                    </div>
                </div>

                <div className='row landing-page-list mobile-zero-padding px-5 pb-5 mobile-column-flex'>
                    <div className='col-8 px-4  mobile-w-100'>
                        <div className="row px-2 py-5">
                            <div className="col d-flex justify-content-center align-item-center mt-2 mb-4 transform-Y">
                                <h2 className='fill-black mobile-text-align-center'>LIST YOUR HOTEL FOR OFF-MARKET SALE</h2>
                            </div>
                        </div>
                        <div className='landing-page-list mobile-w-100 '>

                            <div className='row d-flex justify-content-center align-item-center px-4 py-2 transform-Y'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                            Shop for your 1031 Buyer yourself or let us Help
                                            find your perfect one!
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Post your Confidential Hotel for Sale On/Off
                                                Market, (name & address of hotel not displayed)
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>

                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Your detailed Property information & location is
                                                only identified (with your permission) to bonified
                                                1031 Buyers, and only if there is a match
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                No Listing Agreements
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Non-Exclusive Posting Platform
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                100% Confidential. “It’s just about the Numbers”
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>

                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Once a match is found, our affiliate Brokers will
                                                all manage aspects of your Sale
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Low Brokerage fees upon Close of Sale only
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 px-4 py-4 transform-Y  mobile-w-100'>

                        <div className="tip-area-landing " >
                            <div className="card">
                                <div className='card-body '>
                                    <h3 className='d-flex justify-content-center align-items-center fill-red px-2 py-2 font-weight-400'>How it works</h3>
                                    <div className='row d-flex justify-content-center align-items-center py-0'>
                                        <button className='btn btn-primary fit-content-max d-flex justify-content-center align-items-center' onClick={() => navigate("/sellerTutorial")}><FaRegPlayCircle className='font-size-24 px-1' /> Watch a Tutorial</button>
                                    </div>
                                    <ol className='landing-page-ol' >
                                        <li>
                                            <a href='/sellerRegistration'>Post</a> your hotel for off-market sale.
                                        </li>
                                        <li>
                                            You will receive a HOTEL ID. Your hotel details will remain confidential until a deal is made.
                                        </li>
                                        <li>
                                            Upload hotel financial documents and photos.
                                        </li>
                                        <li>
                                            We will verify and approve your hotel posting.
                                        </li>
                                        <li>
                                            You will be assigned with a 1031 agent.
                                        </li>
                                        <li>
                                            Your agent will be your primary point of contact for everything you need from 1031Hotels.com.
                                        </li>
                                        <li>
                                            Once approved, your profile will be live for buyers to find you confidentially.
                                        </li>
                                        <li>
                                            You can, now, start shopping the 1031 buyers listed for sale.
                                        </li>
                                        <li>
                                            Save the deals you like to your dealbox.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        </>

    );
};

export default SellerLanding;
