import React, { useEffect, useState } from "react";
import "../../../css/main.scss";
import { agentHandler, getToken, setUserSession } from "../../common/commonUtils.js";
import { useCookies } from "react-cookie";
import authapi from "../../../axiosInstances/authInstances";
import axios from "axios";
import { AUTH, getuser, loginuser } from "../../../constants/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



export default function LoginFormCard(props) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [errors, setErrors] = useState([]);
    const [globalError, setGlobalError] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['JWT_1031']);
    const [keepMeSignedIn, setKeepMeSignedin] = useState(false);

    const errorDiv = globalError ? (
        <div className="error">
            <i className="material-icons error-icon">error_outline</i>
            {globalError}
        </div>
    ) : (
        ""
    );
    let handleSubmit;
    const errorArray = [];
    const navigate = useNavigate();

    const loginUser = async (credentials) => {
        const data = new URLSearchParams(credentials).toString();
        var config = {
            method: "post",
            url: loginuser,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Basic " + btoa(AUTH),
            },
            data: data,
        };


        return await axios(config).catch(function (error) {
            toast.error(error?.message,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
        });
    };



    const getUser = async (token) => {
        console.log("getting user");
        try {
            let res = await authapi.get(getuser, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            });
            return res.data;
        } catch (error) {
            console.log(error);
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        setGlobalError(null);
        // User Name validation
        if (username === undefined || username === "") {
            errorArray.push("email");
        }
        // PASSWORD validation
        if (password === undefined || password === "") {
            errorArray.push("password");
        }

        setErrors(errorArray);

        if (errorArray.length > 0) {
            return false;
        }

        const token = await loginUser({
            username,
            password,
        })

        token.status != 200 ?
            toast.error(token?.data?.error,
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                })

            : <></>

        if (token === undefined || (token != null && token.error != null)) {
            setGlobalError("Invalid Credentials");
            console.log("Login Error");
        } else {
            console.log("Login Success");
            if(keepMeSignedIn){
                setToken(token?.data?.access_token);
            }
           

            localStorage.setItem(
                "login_user_token",
                JSON.stringify(token?.data?.access_token)
            );
            let redirectURL = "";
            const getuser = await getUser(token.data.access_token).then((user) => {

                let afterAuthRedirectUrl = sessionStorage.getItem("redirectAfterAuth")
                console.log(afterAuthRedirectUrl)
                sessionStorage.setItem("redirectAfterAuth", "" )
                if(afterAuthRedirectUrl == null){
                    if (user.userTypes.includes('Broker')) {
                        redirectURL = "/brokerDashboard";
                    }
                    if (user.userTypes.includes('Agent')) {
                        redirectURL = "/agentDashboard";
                    }
                    if (user.userTypes.includes('Buyer')) {
                        redirectURL = "/addBuyer";
                        if (user.buyersSize > 0) {
                            redirectURL = "/buyerPost/" + user.buyerId;
                        }
                        sessionStorage.setItem("user_buyers", user?.buyersSize ? user?.buyersSize : 0);
                        sessionStorage.setItem("user_buyer_id", user.buyerId);
                    }
                    if (user.userTypes.includes('Seller')) {
                        redirectURL = "/sellerDashboard";
                        if (user.propertiesSize > 0) {
                            redirectURL = "/propertyPost/" + user.propId;
                        }
                        sessionStorage.setItem("user_sellers", user?.propertiesSize ? user?.propertiesSize : 0);
                        sessionStorage.setItem("user_seller_id", user.propId);
                    }
                    
                }else{
                    if (user.userTypes.includes('Buyer')) {
                     
                        sessionStorage.setItem("user_buyers", user?.buyersSize ? user?.buyersSize : 0);
                        sessionStorage.setItem("user_buyer_id", user.buyerId);
                    }
                    if (user.userTypes.includes('Seller')) {
                      
                        sessionStorage.setItem("user_sellers", user?.propertiesSize ? user?.propertiesSize : 0);
                        sessionStorage.setItem("user_seller_id", user.propId);
                    }
                    redirectURL = afterAuthRedirectUrl;
                    
                }
               
                if(user?.hbaStaff) agentHandler(user?.hbaStaff);
                setUserSession(user.firstName, user.lastName, user.userTypes, user.id, user.email, user.mobileNumber, user.userId);

            });
            window.location = redirectURL;

        }


    };

    function hasError(key) {
        return errors.indexOf(key) !== -1;
    }

    function setToken(access_token) {
        setCookie("JWT_1031", access_token, { path: "/", maxAge: 1800 });
    }

   

  

    return (
        <>
        <h3 className='fill-blue font-size-20 px-2'>Please Login to Make Your Next Deal</h3>
            <div id="login-container" className="container global-div">
                        <div className="text-danger">
                            <h6>{globalError}</h6>
                        </div>
                        <form
                            method="POST"
                            onSubmit={handleSubmit}
                            className="form-control needs-validation "
                            style={{maxWidth:"40%"}}
                        >
                            
                            <div className="form-group">
                                <label htmlFor="email">EMAIL</label>
                                <input
                                    id="email"
                                    type="email"
                                    className={
                                        hasError("email")
                                            ? "form-input is-invalid"
                                            : "form-input"
                                    }
                                    name="email"
                                    tabIndex="1"
                                    required=""
                                    autoFocus=""
                                    onChange={(event) => setUserName(event.target.value)}
                                />
                                <div className="invalid-feedback">
                                    Please fill in your email
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="d-block">
                                    <label htmlFor="password" className="control-label">
                                        PASSWORD
                                    </label>
                                </div>
                                <input
                                    id="password"
                                    type="password"
                                    className={
                                        hasError("password")
                                            ? "form-input is-invalid"
                                            : "form-input"
                                    }
                                    name="password"
                                    tabIndex="2"
                                    required=""
                                    onChange={(event) => setPassword(event.target.value)}
                                />

                                <div className="invalid-feedback">
                                    please fill in your password
                                </div>

                            </div>
                            <div className="form-group">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="agreeterms" id="agreeterms" onClick={(e) => setKeepMeSignedin(true)} checked={keepMeSignedIn} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        Keep me signed-in
                                    </label>
                                </div>
                            </div>

                            <div className="font-size-15" style={{ paddingBottom: "10px" }}>
                                <a href="/forgotPassword">Forgot Password</a>
                            </div>

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className="btn btn-solid btn-lg btn-block button-login font-size-20"
                                    tabIndex="4">
                                    LOGIN
                                </button>
                            </div>

                            <div className="form-group font-size-15" style={{ paddingBottom: "10px" }}>
                                <span className="py-2">Not Registered Yet ?</span>
                                <button
                                    className="btn btn-outline btn-lg btn-block button-login font-size-20"
                                    onClick={()=> navigate("/register")}
                                    tabIndex="4">
                                    REGISTER
                                </button>
                                {/* &nbsp;&nbsp;<a href="/register">Register</a> */}
                            </div>
                        </form>
            </div>
        </>
    );
}
