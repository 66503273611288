import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/CommonPages/home/Home";
import Dashboard from "./components/dashboard/dashboard";
import RegisterAsBuyer from "./components/CommonPages/registration/registerAsBuyer";
import RegisterAsSeller from "./components/CommonPages/registration/registerAsSeller";
import BrokerRegistration from "./components/CommonPages/registration/brokerRegistration";
import Registration from "./components/CommonPages/registration/registration";
import Navbar from "./components/navbar/navigationBar";
import Footer from "./components/CommonPages/footer/footer";
import BuyersList from "./components/buyersList/buyersList";
import BuyerProfile from "./components/MyAccountPages/buyerProfile/buyerProfile";
import PropertyList from "./components/propertyList/propertyList";
import Contact from "./components/CommonPages/contact/Contact";
import { library } from "@fortawesome/fontawesome-svg-core";
import authapi from "./axiosInstances/authInstances";
import PropertyProfile from "./components/MyAccountPages/propertyProfile/propertyProfile";
import BrokerLanding from "./components/CommonPages/registration/brokerLanding";

import {
  faArrowCircleUp,
  faArrowCircleDown,
  faDollarSign,
  faPlus,
  faMinus,
  faChevronLeft,
  faChevronRight,
  faPlusCircle,
  faCartShopping,
  faMoneyBill,
  faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import jQuery from "jquery";
import TokenRoute from "./components/common/tokenRoute";
import { checkUser, GetClientTokenCookie, GetUserTokenCookie, setUserSession } from "./components/common/commonUtils";
import { useCookies } from "react-cookie";
import BuyerPostingPage from "./components/postPages/buyerPostingPage";
import PropertyPostingPage from "./components/postPages/propertyPostPage";
import { ToastContainer } from "react-toastify";
import FAQ from "./components/CommonPages/faqs/faq";
import BuyerRegistration from "./components/CommonPages//registration/buyerRegistration";
import AddBuyer from "./components/dashboard/addBuyer";
import AddProperty from "./components/dashboard/addProperty";
import jwt_decode from "jwt-decode";
import UserProfile from "./components/myAccount/UserProfile";
import Activities from "./components/myAccount/Activities";
import Settings from "./components/myAccount/Settings";
import VerifyEmail from "./components/CommonPages/registration/verifyEmail";
import BrokerDashboard from "./components/MyAccountPages/Dashboard.js/brokerDashboard";
import SellerDashboard from "./components/MyAccountPages/Dashboard.js/sellerDashboard";
import BrokerPropertyList from "./components/MyAccountPages/BrokerBuyersAndSellersList/BrokerPropertyList";
import BrokerBuyersList from "./components/MyAccountPages/BrokerBuyersAndSellersList/BrokerBuyersList";
import ForgotPassword from "./components/CommonPages/forgotPassword/forgotPassword";
import ResetPasswordPage from "./components/CommonPages/resetPassword/resetPassword";
import UserEdit from "./components/myAccount/UserProfileEditor";
import AgentDashboard from "./components/MyAccountPages/Dashboard.js/agentDashboard";
import AgentBuyersList from "./components/MyAccountPages/AgentAssignedBuyerAndSellerList/AgentBuyerList";
import Login from "./components/CommonPages/login/LoginN";
import { getUserInfo } from "./api/api";
import BuyerLanding from "./components/LandingPages/BuyerLanding";
import SellerLanding from "./components/LandingPages/SelllerLanding";
import BrokersLanding from "./components/LandingPages/BrokerLanding";
import SellerTutorial from "./components/CommonPages/TutorialPages/SellerTutorialPage";
import BuyerTutorial from "./components/CommonPages/TutorialPages/BuyerTutorialPage";
import BrokerTutorial from "./components/CommonPages/TutorialPages/BrokerTutorialPage";
 

library.add(
  faArrowCircleUp,
  faArrowCircleDown,
  faDollarSign,
  faPlus,
  faMinus,
  faChevronLeft,
  faChevronRight,
  faHeart,
  faPlusCircle,
  faCartShopping,
  faMoneyBill,
  faBriefcase
);
window.jQuery = jQuery;

export const UserContext = React.createContext();


function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  
  useEffect(async () => {
    const tokenCookie = GetClientTokenCookie();
    var decoded = "";
    if (tokenCookie != null)
      decoded = jwt_decode(tokenCookie, { complete: true })
    
    var dateNow = new Date();
    if (tokenCookie == null || decoded.exp > dateNow.getTime()) {
      await setToken();
    }
    // if(!localStorage.getItem("LoggedIn")){
      
    // }
    // checkKeepSignedIn();

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent default Enter key behavior (submitting a form)
        const formElements = Array.from(document.querySelectorAll('input, button, select, textarea, a[href]'));
        const currentIndex = formElements.indexOf(document.activeElement);
        const nextIndex = (currentIndex + 1) % formElements.length;
        formElements[nextIndex].focus();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };

  }, [cookies]);


  const checkKeepSignedIn = async () => {

    const userCookie = GetUserTokenCookie();
    console.log(userCookie?.toString())
    console.log(localStorage.getItem("LoggedIn"))
    if (userCookie) {
      localStorage.setItem(
        "login_user_token",
        JSON.stringify(userCookie)
      );
      let redirectURL = "";
      const getuser = await getUserInfo(JSON.stringify(userCookie)).then((user) => {

        if (user?.userTypes.includes('Broker')) {
          redirectURL = "/brokerDashboard";
        }
        if (user?.userTypes.includes('Agent')) {
          redirectURL = "/agentDashboard";
        }
        if (user?.userTypes.includes('Buyer')) {
          redirectURL = "/addBuyer";
          if (user?.buyersSize > 0) {
            redirectURL = "/buyerPost/" + user.buyerId;
          }
          sessionStorage.setItem("user_buyers", user.buyersSize);
          sessionStorage.setItem("user_buyer_id", user.buyerId);
        }
        if (user?.userTypes.includes('Seller')) {
          redirectURL = "/sellerDashboard";
          if (user?.propertiesSize > 0) {
            redirectURL = "/propertyPost/" + user.propId;
          }
          sessionStorage.setItem("user_sellers", user.propertiesSize);
          sessionStorage.setItem("user_seller_id", user.propId);
        }
        console.log(user)
        setUserSession(user.firstName, user.lastName, user.userTypes, user.id, user.email, user.mobileNumber, user.userId);
        localStorage.setItem("LoggedIn",true)
      });

      window.location = redirectURL;
    }
  }

  const setToken = async () => {
    console.log("Token Called");
    let refreshTokenInterval;
    try {
      const data = await authapi.post(
        "oauth/token?grant_type=client_credentials"
      );
      const jsonR = data;
      setCookie("AUTH_CODE", jsonR.data.access_token, {
        path: "/",
        maxAge: 1800,
      });
    } catch (err) {
      console.log(err);
    }
    clearInterval(refreshTokenInterval);
    refreshTokenInterval = setInterval(() => setToken(), 25 * 60 * 1000)
  };

  return (
    <Router>
      <div className={"main-wrapper main-wrapper-1"}>
        <Navbar />

        <div className="main-content-loggedOut">
          <div id="loading_spinner" hidden></div>
          <div className={"hboa-section"}>
            <ToastContainer
              position="bottom-center"
              autoClose={4000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />

            <Routes>
              {/* <Route path="/" exact element={<Login />} /> */}
              <Route path="/" exact element={<Home />} />
              <Route path="/home" exact element={<Home />} />
              <Route
                exact
                path="/dashboard"
                element={<TokenRoute component={Dashboard} />}
              // element={<Dashboard />}
              />
              <Route
                exact
                path="/brokerDashboard"
                element={<TokenRoute component={BrokerDashboard} />}
              />

              <Route
                exact
                path="/agentDashboard"
                element={<TokenRoute component={AgentDashboard} />}
              />

              <Route path="/login" exact element={<Login />} />

              <Route path="/contactus" exact element={<Contact />} />

              <Route path="/faqs" exact element={<FAQ />} />

              <Route path="/forgotPassword" element={<ForgotPassword></ForgotPassword>} />

              <Route path="/resetpassword" element={<ResetPasswordPage></ResetPasswordPage>} />

              {/* <Route path="/profile" exact element={<TokenRoute component={UserProfile} />} /> */}

              <Route path="/profile" exact element={<TokenRoute component={UserEdit} />} />
              <Route path="/activities" exact element={<Activities />} />
              <Route path="/settings" exact element={<Settings />} />
              <Route path="/brokerLanding" exact element={<BrokerLanding></BrokerLanding>}></Route>
              <Route path="/brokersLanding" exact element={<BrokersLanding></BrokersLanding>} />
              <Route path="/buyersLanding" exact element={<BuyerLanding></BuyerLanding>} />
              <Route path="/sellersLanding" exact element={<SellerLanding></SellerLanding>} />
              <Route path="/sellerTutorial" exact element={<SellerTutorial></SellerTutorial>} />
              <Route path="/buyerTutorial" exact element={<BuyerTutorial></BuyerTutorial>} />
              <Route path="/brokerTutorial" exact element={<BrokerTutorial></BrokerTutorial>} />
              
              <Route path="/buyerTutorial/:id" exact element={<BuyerTutorial></BuyerTutorial>} />
              <Route path="/sellerTutorial/:id" exact element={<SellerTutorial></SellerTutorial>} />

              <Route path="/verifyEmail" exact element={<VerifyEmail />} />
              <Route
                path="/buyerRegistration"
                exact
                element={<RegisterAsBuyer />}
              />

              <Route
                path="/registerBuyer"
                exact
                element={<BuyerRegistration />}
              />

              <Route
                path="/sellerRegistration"
                exact
                element={<RegisterAsSeller />}
              />
              <Route
                path="/brokerRegistration"
                exact
                element={<BrokerRegistration />}
              />

              <Route
                path="/addSeller"
                exact
                element={<TokenRoute component={AddProperty} />}
              />
              <Route
                path="/addBuyer"
                exact
                element={<TokenRoute component={AddBuyer} />}
              />
              <Route
                path="/sellerDashboard"
                exact
                element={<TokenRoute component={SellerDashboard} />}
              />
              <Route path="/register" exact element={<Registration />} />
              <Route
                exact
                path="/buyerProfile/:id"
                element={<TokenRoute component={BuyerProfile} />}
              />

              <Route
                exact
                path="/propertyProfile/:id"
                element={<TokenRoute component={PropertyProfile} />}
              />
              <Route
                exact
                path="/buyerProfile"
                element={<TokenRoute component={BuyerProfile} />}
              />
              <Route exact path="/buyerList" element={<BuyersList />} />
              <Route exact path="/propertyList" element={<PropertyList />} />
              <Route exact path="/broker/buyerList" element={<BuyersList />} />
              <Route exact path="/broker/propertyList" element={<PropertyList />} />
              <Route exact path="/brokerpropertyList" element={<BrokerPropertyList />} />
              <Route exact path="/agentHotelsList" element={<BrokerPropertyList />} />
              <Route exact path="/brokerbuyerList" element={<BrokerBuyersList />} />
              <Route exact path="/agentBuyersList" element={<BrokerBuyersList />} />
              <Route
                exact
                path="/buyerPost/:id"
                element={<TokenRoute component={BuyerPostingPage} />}
              />
              <Route
                exact
                path="/buyerPost/:id/:filter"
                element={<TokenRoute component={BuyerPostingPage} />}
              />
              <Route
                exact
                path="/propertyPost/:id"
                element={<TokenRoute component={PropertyPostingPage} />}
              />
              <Route
                exact
                path="/propertyPost/:id/:filter"
                element={<TokenRoute component={PropertyPostingPage} />}
              />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </Router>

  );
}

export default App;
