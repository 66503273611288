import Cookies from "js-cookie";
import { GetClientTokenCookie, getToken } from "../components/common/commonUtils";
import {verifyAndSetToken} from "../api/api";

export function authUserHeader() {
    const token = JSON.parse(localStorage.getItem('login_user_token'));
    // const token = getToken();
    if (token) {
        return {'Authorization': 'Bearer ' + token}; 
    } else {
        return {}; 
    } 
}

export function initialAuth() {
    const token = GetClientTokenCookie();
    if (token) {
        return {'Authorization': 'Bearer ' + token}; 
    } else {
        window.location ="/home";
    } 
}
