import axios from "axios";
import { GetClientTokenCookie } from "../components/common/commonUtils";
import { URL } from "../constants/constants";

const tokenInstance = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "Bearer " + GetClientTokenCookie(),
  },
});

tokenInstance.interceptors.request.use(
  function (config) {
    document.getElementById("loading_spinner").hidden = false;
    return config;
  },

  function (error) {
    document.getElementById("loading_spinner").hidden = false;
    return Promise.reject(error);
  }
);

tokenInstance.interceptors.response.use(
  function (response) {
    document.getElementById("loading_spinner").hidden = true;
    return response;
  },

  function (error) {
    document.getElementById("loading_spinner").hidden = true;
    return Promise.reject(error);
  }
);

export default tokenInstance;
