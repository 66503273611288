import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken, isUserLoggedIn } from './commonUtils.js';

// handle the private routes
function TokenRoute({ component: Component, ...rest }) {
    const auth = isUserLoggedIn();
    sessionStorage.setItem("redirectAfterAuth", window.location.href )
    if(Component.name === 'Login' && auth != null)
    {
        window.location = "/dashboard";
    }
    return auth ? <Component/> : <Navigate to="/login" />;
}
export default TokenRoute;
