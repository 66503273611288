
const BrokerLanding = () => {
    return (
        <>
            <div className="container-fluid broker-landing-page">
                <div className="row">
                    <div className="col-md-12">
                        <div className="hero-content">
                            <div className="hero-cta">
                                <a href="/login" className="btn btn-default btn-lg"
                                   style={{backgroundColor: "rgb(253, 80, 43)"}}>BROKER LOGIN <br/>
                                    <span className="font-size-15"> (Already Registered? Click here)</span>
                                </a>
                                <a href="/brokerRegistration" className="btn btn-default btn-lg"
                                   style={{backgroundColor: "rgb(3, 137, 153)", marginLeft: "10px"}}>BROKER
                                    REGISTRATION <br/> <span className="font-size-15"> (New user Registration)</span>
                                </a>
                                <a href="/faqs" className="btn btn-default btn-lg"
                                   style={{backgroundColor: "rgb(46, 72, 167)", marginLeft: "10px"}}>BROKER FAQS <br/>
                                    <span className="font-size-15"> (Answers to your questions)</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BrokerLanding;
