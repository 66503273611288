import React, { useEffect, useRef } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableComponent from "../../Tables/TableComponent";
import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { BiFilter, BiHeart } from "react-icons/bi";
import { ListGroup } from "react-bootstrap";
import { AiFillHeart } from "react-icons/ai";
import {
  getPrimaryStates,
  getProperties,
  getPropertyTypes,
  getUserInfo,
} from "../../../api/api";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
import { useMemo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SlArrowDown } from "react-icons/sl";
import { MdClose } from "react-icons/md";
import CurrencyFormat from "react-currency-format";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  askingPricevalues,
  brandTypes,
  mindownpayvalues,
  numberOfRoomsvalues,
} from "../../common/utils";
import { getDashboard } from "../../../api/api";
import Swal from "sweetalert2";
import { S3_IMAGES } from "../../../constants/constants";
import { agentHandler } from "../../common/commonUtils";

const BrokerPropertyList = () => {
  const [filterList, setFilterList] = useState(true);
  const [states, setStates] = useState([]);
  const [brand, setBrands] = useState([]);

  const [sortIcon, setSortIcon] = useState({
    exchange_funds: "asc",
    max_buying_power: "asc",
    deadline: "asc",
    closing: "asc",
    no_of_rooms: "asc",
  });

  const [updateFilter, setUpdateFilter] = useState(false);

  const [allproperties, setAllProperties] = useState([]);
  const [unfilteredHotels, setUnfilteredHotels] = useState([]);

  const [properties, setProperties] = useState([]);

  const [showImages, setShowImages] = useState(false);
  const [showImagerow, setShowImagerow] = useState();
  const [askingPriceslist, setAskingPrices] = useState([]);
  const [numberofroomslist, setNumberOfRooms] = useState([]);
  const [minDownPaymentlist, setMinDownpaymentlist] = useState([]);
  const [filterValues, setFilterValues] = useState({
    price_min: "",
    price_max: "",
    rooms_min: "",
    rooms_max: "",
    brand: "",
    state: "",
    downpayment_min: "",
    downpayment_max: "",
    askingpriceId: "",
    numberofroomsId: "",
    mindownpayId: "",
  });
  const [large, setLarge] = useState(true);

  const initValues = {
    price_min: "",
    price_max: "",
    rooms_min: "",
    rooms_max: "",
    brand: [],
    state: [],
    downpayment_min: "",
    downpayment_max: "",
    askingpriceId: "",
    numberofroomsId: "",
    mindownpayId: "",
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleClosedPosts = (e) => {
    if (e.target.checked) {
      setProperties(unfilteredHotels);
    } else {
      setProperties(allproperties);
    }
    setIsChecked(!isChecked);
  };

  function handleFilterChange(e) {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "askingprice") {
      askingPriceslist.map((val) => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues,
            askingpriceId: value,
            price_max: val.max,
            price_min: val.min,
          });
        }
      });
    }

    if (name == "numberofrooms") {
      numberofroomslist.map((val) => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues,
            numberofroomsId: value,
            rooms_max: val.max,
            rooms_min: val.min,
          });
        }
      });
    }

    if (name == "mindownpay") {
      minDownPaymentlist.map((val) => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues,
            mindownpayId: value,
            downpayment_max: val.max,
            downpayment_min: val.min,
          });
        }
      });
    }

    formik.handleSubmit();
  }

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {
      filterData();
      console.log(values);

      setUpdateFilter((current) => !current);
    },
  });

  const navigate = useNavigate();

  const loggedInUser = sessionStorage.getItem("user");

  useEffect(() => {
    getPropertyList();
    getUserInfo().then((user) => agentHandler(user?.hbaStaff));
    sessionStorage.removeItem("currentPostid");
  }, []);

  const getPropertyList = async () => {
    let res = await getDashboard();
    const data = res.data ? res.data : res;
    setUnfilteredHotels(data?.properties);
    let filteredHotels = [];
    data.properties?.map((hotel) => {
      hotel?.dealStatus != "Closed" ? filteredHotels.push(hotel) : <></>;
    });
    setProperties(filteredHotels);
    setAllProperties(filteredHotels);
    let val = await getPrimaryStates();
    setStates(val);
    let bt = await getPropertyTypes();
    setBrands(brandTypes);
    setAskingPrices(askingPricevalues);
    setNumberOfRooms(numberOfRoomsvalues);
    setMinDownpaymentlist(mindownpayvalues);
    setUpdateFilter((current) => !current);
  };

  const resetHandler = () => {
    formik.resetForm();
    setFilterValues(initValues);
    setProperties(allproperties);
    setUpdateFilter((current) => !current);
  };

  //---------------------------------------------FILTER LOGIC----------------------------------------------------------//

  const filterData = () => {
    let currentFilteredData = allproperties;

    if (filterValues.price_min !== "" || filterValues.price_max !== "") {
      const min = filterValues.price_min !== "" ? filterValues.price_min : 0;
      const max =
        filterValues.price_max !== ""
          ? filterValues.price_max
          : 5000000000000000;
      currentFilteredData = currentFilteredData.filter(
        (p) => p.askingPrice > min && p.askingPrice <= max
      );
    }

    if (filterValues.rooms_min !== "" || filterValues.rooms_max !== "") {
      const min = filterValues.rooms_min !== "" ? filterValues.rooms_min : 0;
      const max = filterValues.rooms_max !== "" ? filterValues.rooms_max : 300;
      currentFilteredData = currentFilteredData.filter(
        (b) => b.noOfRooms >= min && b.noOfRooms <= max
      );
    }

    if (filterValues.brand !== "" && filterValues.brand !== "Any") {
      currentFilteredData = currentFilteredData.filter(
        (b) => b.propertyType.brandType === filterValues.brand
      );
    }

    if (filterValues.state !== "" && filterValues.state !== "Any") {
      currentFilteredData = currentFilteredData.filter(
        (b) => b.primaryState.id == filterValues.state
      );
    }

    if (
      filterValues.downpayment_min !== "" ||
      filterValues.downpayment_max !== ""
    ) {
      const min =
        filterValues.downpayment_min !== "" ? filterValues.downpayment_min : 0;
      const max =
        filterValues.downpayment_max !== ""
          ? filterValues.downpayment_max
          : 5000000000000000;
      currentFilteredData = currentFilteredData.filter(
        (p) => p.minDownPayment >= min && p.minDownPayment <= max
      );
    }

    setProperties(currentFilteredData);
  };
  //---------------------------------------------FILTER LOGIC----------------------------------------------------------//

  const iconHandler = (order) => {
    if (!order)
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp style={{ color: "#d7d7d7" }} />
          </span>
          <span>
            <CgArrowLongDown style={{ color: "#d7d7d7" }} />
          </span>
        </div>
      );
    else if (order === "asc")
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp />
          </span>
          <span>
            <CgArrowLongDown style={{ color: "#d7d7d7" }} />
          </span>
        </div>
      );
    else if (order === "desc")
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp style={{ color: "#d7d7d7" }} />
          </span>
          <span>
            <CgArrowLongDown />
          </span>
        </div>
      );
    return null;
  };

  const filterHandler = () => {
    setFilterList((current) => !current);
  };

  const removeFilterHandler = (val, array) => {
    if (array === "brand") {
      let data = formik.values.brand;
      formik.values.brand = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setProperties(allproperties);
      } else filterData();
    } else if (array === "state") {
      let data = formik.values.state;
      formik.values.state = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setProperties(allproperties);
      } else filterData();
    }
    setUpdateFilter((current) => !current);
  };

  const clearFilters = (val) => {
    const values = formik.values;
    if (val === "rooms") {
      formik.values.rooms_min = initValues.rooms_min;
      formik.values.rooms_max = initValues.rooms_max;
      if (
        values.downpayment_min > initValues.downpayment_min ||
        values.downpayment_max < initValues.downpayment_max ||
        values.price_min > initValues.price_min ||
        values.price_max < initValues.price_max
      ) {
        filterData();
      } else setProperties(allproperties);
    } else if (val === "price") {
      formik.values.price_min = initValues.price_min;
      formik.values.price_max = initValues.price_max;
      if (
        values.downpayment_min > initValues.downpayment_min ||
        values.downpayment_max < initValues.downpayment_max ||
        values.rooms_min > initValues.rooms_min ||
        values.rooms_max < initValues.rooms_max
      ) {
        filterData();
      } else setProperties(allproperties);
    } else {
      formik.values.downpayment_min = initValues.downpayment_min;
      formik.values.downpayment_max = initValues.downpayment_max;
      if (
        values.rooms_min > initValues.rooms_min ||
        values.rooms_max < initValues.rooms_max ||
        values.price_min > initValues.price_min ||
        values.price_max < initValues.price_max
      ) {
        filterData();
      } else setProperties(allproperties);
    }
    setUpdateFilter((current) => !current);
  };

  const filterTabs = () => {
    return (
      <div className="filter-div">
        <div
          style={{ marginLeft: "-5px" }}
          className="row justify-content-start gy-3"
        >
          <div className="col ">
            <label data="Maximum Buying Power">
              <b>Brand</b>
            </label>
            <br></br>
            <div className="dropdown">
              <div
                className={
                  formik.values.brand.length
                    ? "filter-div-button-wrapper-active"
                    : "filter-div-button-wrapper"
                }
              >
                {/* <span data="Brand" ></span> */}
                <button
                  className="scroll filter-btn-icon"
                  type="button"
                  id="filterDrops"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <div className="scroll">
                    <div className="filter-select-wrapper">
                      {formik.values.brand.length ? (
                        formik.values.brand.map((val, index) => (
                          <div className="filter-select" key={index}>
                            {val}
                            <button
                              onClick={() => removeFilterHandler(val, "brand")}
                              className="remove-filter"
                            >
                              <MdClose />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <span style={{ paddingLeft: "5px" }}>Brand </span>
                        </>
                      )}
                    </div>
                  </div>
                </button>
                <div className="filter-btn-icon">
                  <SlArrowDown className="filter-btn-icon" />
                </div>
                <ul
                  id="brand-list"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                >
                  <form onSubmit={formik.handleSubmit}>
                    {/* <li>
                      <input id="brand-any" type="checkbox" />
                      <label className="label-class" htmlFor="brand-any">
                        Any
                      </label>
                    </li> */}
                    <div className="brands">
                      {brand
                        ? brand.map((val, index) => (
                            <li key={index}>
                              <input
                                name="brand"
                                value={val}
                                defaultChecked={
                                  formik.values.brand.length
                                    ? formik.values.brand.includes(val)
                                    : false
                                }
                                onChange={formik.handleChange}
                                id="brand"
                                type="checkbox"
                              />
                              <label className="label-class" htmlFor="brand">
                                {val}
                              </label>
                            </li>
                          ))
                        : " "}
                    </div>
                    <button
                      style={{ marginTop: "2px" }}
                      type="submit"
                      className="apply_filter"
                      onClick={formik.handleSubmit}
                    >
                      Apply
                    </button>
                  </form>
                </ul>
              </div>
            </div>
          </div>
          <div className="col ">
            <label data="Maximum Buying Power">
              <b>State</b>
            </label>
            <br></br>
            <div className="dropdown">
              <div
                className={
                  formik.values.state.length
                    ? "filter-div-button-wrapper-active"
                    : "filter-div-button-wrapper"
                }
              >
                {/* <span data="State" ></span> */}
                <button
                  className="scroll filter-btn-icon"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <div className="scroll">
                    <div className="filter-select-wrapper">
                      {formik.values.state.length ? (
                        formik.values.state.map((val, index) => (
                          <div className="filter-select" key={index}>
                            {JSON.parse(val).name}
                            <button
                              onClick={() => removeFilterHandler(val, "state")}
                              className="remove-filter"
                            >
                              <MdClose />
                            </button>
                          </div>
                        ))
                      ) : (
                        <span style={{ paddingLeft: "5px" }}>State</span>
                      )}
                    </div>
                  </div>
                </button>
                <div className="filter-btn-icon">
                  <SlArrowDown />
                </div>
                <ul
                  id="state_div"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                >
                  <form onSubmit={formik.handleSubmit}>
                    <li>
                      <input id="state" type="checkbox" />
                      <label className="label-class" htmlFor="state">
                        Any
                      </label>
                    </li>
                    <div className="states">
                      {states
                        ? states.map((state, index) => (
                            <li key={index}>
                              <input
                                name="state"
                                value={JSON.stringify(state)}
                                defaultChecked={
                                  formik.values.state.length > 0
                                    ? formik.values.state.includes(
                                        JSON.stringify(state)
                                      )
                                    : false
                                }
                                onChange={formik.handleChange}
                                id="state"
                                type="checkbox"
                              />
                              <label
                                style={{ textTransform: "capitalize" }}
                                className="label-class"
                                htmlFor="buying_check"
                              >
                                {state.name}
                              </label>
                            </li>
                          ))
                        : ""}
                    </div>
                    <button
                      type="submit"
                      style={{ marginTop: "2px" }}
                      className="apply_filter"
                    >
                      Apply
                    </button>
                  </form>
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <label data="Maximum Buying Power">
              <b>AskingPrice</b>
            </label>
            <br></br>
            <select
              name="askingprice"
              onChange={(e) => handleFilterChange(e)}
              className="filter-div-button-wrapper"
              value={filterValues.askingpriceId}
            >
              {askingPriceslist
                ? askingPriceslist.map((val, index) => (
                    <option
                      key={index}
                      className="filter-select"
                      name="askingprice"
                      value={val.id}
                      max={val.max}
                      min={val.min}
                    >
                      {val.label}
                    </option>
                  ))
                : " "}
            </select>
          </div>
          <div className="col">
            <label data="Maximum Buying Power">
              <b>Number of Rooms</b>
            </label>
            <br></br>
            <select
              name="numberofrooms"
              onChange={(e) => {
                handleFilterChange(e);
              }}
              className="filter-div-button-wrapper"
              value={filterValues.numberofroomsId}
            >
              {numberofroomslist
                ? numberofroomslist.map((val, index) => (
                    <option
                      key={index}
                      className="filter-select"
                      name="numberofrooms"
                      value={val.id}
                    >
                      {val.label}
                    </option>
                  ))
                : " "}
            </select>
          </div>
          <div className="col">
            <label data="Maximum Buying Power">
              <b>Minimum Downpayment</b>
            </label>
            <br></br>
            <select
              name="mindownpay"
              onChange={(e) => {
                handleFilterChange(e);
              }}
              className="filter-div-button-wrapper"
              value={filterValues.mindownpayId}
            >
              {minDownPaymentlist
                ? minDownPaymentlist.map((val, index) => (
                    <option
                      key={index}
                      className="filter-select"
                      name="mindownpay"
                      value={val.id}
                    >
                      {val.label}
                    </option>
                  ))
                : " "}
            </select>
          </div>

          <div className="col">
            <button onClick={resetHandler} type="button" className="reset-btn">
              Reset
            </button>
          </div>
          <div className="col d-flex justify-content-end align-items-end">
            <span className="font-14">
              <sup>*</sup>Hover over the Hotel ID to view images
            </span>
          </div>
        </div>
      </div>
    );
  };
  const filteration = useMemo(() => filterTabs, [updateFilter]);

  const filterBtnTabs = () => {
    return (
      <div className="filter-list">
        <button className="filter-list-btn" onClick={filterHandler}>
          Filters <BiFilter />
        </button>
        <div className={filterList === false ? "hide-tru" : "show-tru"}>
          <ListGroup>
            <ListGroup.Item>
              <div className="dropdown">
                <label data="Maximum Buying Power">
                  <b>AskingPrice</b>
                </label>
                <br></br>
                <select
                  name="askingprice"
                  onChange={(e) => handleFilterChange(e)}
                  className="filter-div-button-wrapper"
                  value={filterValues.askingpriceId}
                >
                  {askingPriceslist
                    ? askingPriceslist.map((val, index) => (
                        <option
                          key={index}
                          className="filter-select"
                          name="askingprice"
                          value={val.id}
                          max={val.max}
                          min={val.min}
                        >
                          {val.label}
                        </option>
                      ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <label data="Maximum Buying Power">
                  <b>Number of Rooms</b>
                </label>
                <br></br>
                <select
                  name="numberofrooms"
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                  className="filter-div-button-wrapper"
                  value={filterValues.numberofroomsId}
                >
                  {numberofroomslist
                    ? numberofroomslist.map((val, index) => (
                        <option
                          key={index}
                          className="filter-select"
                          name="numberofrooms"
                          value={val.id}
                        >
                          {val.label}
                        </option>
                      ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Brand Category
                </button>
                <ul
                  id="brand-list"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                >
                  <li>
                    <input id="buying_check" type="checkbox" />
                    <label className="label-class" htmlFor="buying_check">
                      All
                    </label>
                  </li>
                  <div className="brands">
                    {brand
                      ? brand.map((val, index) => (
                          <li key={index}>
                            <input
                              name="brand"
                              value={val}
                              onChange={formik.handleChange}
                              id="buying_check"
                              type="checkbox"
                            />
                            <label
                              className="label-class"
                              htmlFor="buying_check"
                            >
                              {val}
                            </label>
                          </li>
                        ))
                      : " "}
                  </div>
                  <button style={{ marginTop: "2px" }} className="apply_filter">
                    Apply
                  </button>
                </ul>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  State
                </button>
                <ul
                  id="state_div"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                >
                  <li>
                    <input id="buying_check" type="checkbox" />
                    <label className="label-class" htmlFor="buying_check">
                      All
                    </label>
                  </li>
                  <div className="states">
                    {states
                      ? states.map((state, index) => (
                          <li key={index}>
                            <input
                              name="state"
                              value={state.id}
                              onChange={formik.handleChange}
                              id="buying_check"
                              type="checkbox"
                            />
                            <label
                              className="label-class"
                              htmlFor="buying_check"
                            >
                              {state.name}
                            </label>
                          </li>
                        ))
                      : " "}
                  </div>
                  <button style={{ marginTop: "2px" }} className="apply_filter">
                    Apply
                  </button>
                </ul>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <label data="Maximum Buying Power">
                  <b>Minimum Downpayment</b>
                </label>
                <br></br>
                <select
                  name="mindownpay"
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                  className="filter-div-button-wrapper"
                  value={filterValues.mindownpayId}
                >
                  {minDownPaymentlist
                    ? minDownPaymentlist.map((val, index) => (
                        <option
                          key={index}
                          className="filter-select"
                          name="mindownpay"
                          value={val.id}
                        >
                          {val.label}
                        </option>
                      ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </div>
    );
  };

  const Login = () => (
    <div>
      <span>Please Login</span>
      <button className="login-toast" onClick={() => navigate("/register")}>
        Register
      </button>
      <button className="login-toast" onClick={() => navigate("/login")}>
        Login
      </button>
    </div>
  );

  const notify = () => {
    toast.error(Login, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const notifyGetApproved = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: "my-swal",
        popup: "my-swal-popup-notify",

        content: "my-swal-content",
      },
    });

    swalWithBootstrapButtons.fire({
      title: "Your profile is being reviewed",
      html: `<div>
              <h4>Please wait until your profile is approved to access the hotels</h4>
              </br>    
              <h4> You can contact us on </h4>    
              <h4><b>info@1031hotels.com</b></h4>
              <h4><b>661-808-4998</b></h4>
            </div>
        `,
    });
  };

  const toastLogin = (id) => {
    const user = sessionStorage.getItem("user");
    const approvedBuyer = sessionStorage.getItem("Appbuyer");

    if (user) {
      if (true) {
        navigate("/propertyPost/" + id);
      } else {
        notifyGetApproved();
      }
    } else {
      notify();
    }
  };

  const getImagesArray = (urls) => {
    let urlsArray = urls.split(",");
    let imageUrls = [];

    urlsArray.slice(0, 5).map((url) => {
      if (url.includes("files")) {
        const index = url.indexOf("files");
        const substringBeforeKeyword = url.substring(0, index);

        if (substringBeforeKeyword.trim() === "") {
          imageUrls.push(S3_IMAGES + url);
        } else {
          imageUrls.push(url);
        }
      }
    });

    // let imagesArray = []
    // urlsArray.forEach(element => {
    //   let imagesObj = {
    //     original: element,
    //     thumbnail: element,
    //   }
    //   imagesArray.push(imagesObj)
    // });
    return imageUrls;
  };

  const columns = [
    {
      dataField: "generatedPropertyId",
      text: "Hotel ID",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      classes: large
        ? "data-styleproperty-id-lg hand-cursor"
        : "data-style-id hand-cursor",
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <OverlayTrigger
            placement="auto-end"
            overlay={
              <div className="row carousel-overlay">
                {getImagesArray(row?.imagesPath).map((url, index) => (
                  <img
                    key={index}
                    className="col-4"
                    src={url}
                    style={{ width: "270px", height: "216px" }}
                    alt={`Image ${index + 1}`}
                  />
                ))}
              </div>
            }
          >
            {({ ref, ...triggerHandler }) => (
              <div
                variant="light"
                {...triggerHandler}
                className="d-inline-flex align-items-center"
              >
                <label ref={ref} className="ms-1 hand-cursor">
                  {" "}
                  <label>{value ? value : "Hotel ID"}</label>
                </label>
              </div>
            )}
          </OverlayTrigger>
        </>
      ),

      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let id = row.id;
          toastLogin(id);
        },
      },
      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>Hotel ID</Tooltip>}>
          <label>Hotel ID</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "sellerName",
      text: "Seller Name",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,

      formatter: (value, row) => (
        <>
          <label>{value !== null ? `${value}` : "-"}</label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Seller Name</Tooltip>}
        >
          <label>Seller Name</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "propertyType",
      text: "Brand",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,

      formatter: (value, row) => (
        <>
          {row?.displayBrandOn1031 ? (
            <label>{value !== null ? `${value?.brandName}` : "-"}</label>
          ) : (
            <label>{value !== null ? `${value?.brandType}` : "-"}</label>
          )}
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>Brand</Tooltip>}>
          <label>Brand</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "primaryState.name",
      text: "State",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,

      formatter: (value, row) => (
        <>
          <label>{value !== null ? `${value}` : "-"}</label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>State</Tooltip>}>
          <label>State</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "nearestMSA",
      text: "Nearest MSA",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,

      formatter: (value, row) => (
        <>
          <label>{value !== null ? `${value}` : "-"}</label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Nearest MSA</Tooltip>}
        >
          <label>Nearest MSA</label>
        </OverlayTrigger>
      ),
    },
    // {
    //   dataField: "dealBox",
    //   text: "# Active Deals",
    //   headerStyle: { fontSize: "10px", textAlign: "center" },
    //   style: { fontSize: "10px", textAlign: "center" },
    //   sort: true,

    //   formatter: (value, row) => (
    //     <>
    //       <label>{value !== null ? `${value?.length}` : 0}</label>
    //     </>
    //   ),
    //   classes: large ? "data-style-lg" : "data-style",
    //   headerFormatter: (column) => (
    //     <OverlayTrigger
    //       placement="top"
    //       overlay={<Tooltip># Active Deals</Tooltip>}
    //     >
    //       <label># Active Deals</label>
    //     </OverlayTrigger>
    //   ),
    // },
    {
      dataField: "askingPrice",
      text: "Asking Price",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      sort: true,

      formatter: (value, row) => (
        <>
          <CurrencyFormat
            value={value != null ? value : 0}
            decimalScale={0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      style: { fontSize: "10px", textAlign: "center" },

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Asking Price</Tooltip>}
        >
          <label>Asking Price</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "pipEstimate",
      text: "Estimated PIP",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <CurrencyFormat
            value={value != null ? value : 0}
            decimalScale={0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Estimated PIP</Tooltip>}
        >
          <label>Estimated PIP</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "noOfRooms",
      text: "Room Count",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <label>{value !== null ? `${value}` : "-"}</label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>Room Count</Tooltip>}>
          <label>Room Count</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "minDownPayment",
      text: "Minimum Downpayment",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <CurrencyFormat
            value={value != null ? value : 0}
            decimalScale={0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Minimum Downpayment</Tooltip>}
        >
          <label>Minimum Downpayment</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "totalRevenue",
      text: "Revenue",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <CurrencyFormat
            value={value != null ? value : 0}
            decimalScale={0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>Revenue</Tooltip>}>
          <label>Revenue</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "netIncome",
      text: "Net Income",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <CurrencyFormat
            value={value != null ? value : 0}
            decimalScale={0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>Net Income</Tooltip>}>
          <label>Net Income</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "capRate",
      text: "Cap Rate %",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <label>{value !== null ? `${value.toFixed(2)}` : "-"}</label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>Cap Rate %</Tooltip>}>
          <label>Cap Rate %</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "roomRevenueMultiplier",
      text: "Revenue Multiplier",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <label>{value != null ? value.toFixed(2) : 0} </label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Revenue Multiplier</Tooltip>}
        >
          <label>Revenue Multiplier</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "pricePerKey",
      text: "Price Per Room",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <CurrencyFormat
            value={value != null ? value : 0}
            decimalScale={0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Price Per Room</Tooltip>}
        >
          <label>Price Per Room</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "cashOnCashRet",
      text: "Cash On Cash Return %",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) => (
        <>
          <label>{value != null ? value.toFixed(2) : 0} </label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Cash On Cash Return %</Tooltip>}
        >
          <label>Cash On Cash Return %</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "yearBuilt",
      text: "Year Built",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,

      formatter: (value, row) => (
        <>
          <label>{value !== null ? `${value}` : "-"}</label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>Year Built</Tooltip>}>
          <label>Year Built</label>
        </OverlayTrigger>
      ),
    },
  ];

  return (
    <div className="padding-wrapper-custom">
      <div className="buyer-profile-page property-list-page">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <h5 className="boldBlue-color-filler" style={{position:"absolute"}}>
                  SELECT YOUR HOTEL FOR WHICH YOU ARE SHOPPING FOR
                </h5>
              </div>
              <div className="d-flex justify-content-end" >
                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleClosedPosts}
                    />
                    <b className="px-1">Show Closed Hotels</b>
                  </label>
                </div>
              </div>
              <p className="d-flex justify-content-end font-14 mb-0">Hover over the Hotel ID to view images</p>

              <div className="wrapper bd-property-interests table-hover">
                <TableComponent
                  columns={columns}
                  products={properties}
                  filterTabs={""}
                  filterBtnTabs={""}
                  searchData={false}
                  showImages={showImages}
                  rowStyle={"hotelRowStyle"}
                  noDataMessage={
                    <>
                      <br></br>
                      <br></br>
                      <span>You have no hotel posted</span>
                      <br></br>
                      <br></br>
                      <button
                        className="btn btn-primary"
                        onClick={() => navigate("/addSeller")}
                      >
                        Post a Hotel
                      </button>
                    </>
                  }
                  clickEvent={(id) => navigate("/propertyPost/" + id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerPropertyList;
