// import React from "react";
// import { useEffect } from "react";
// import { useState } from "react";
// import { getFaqs } from "../../../api/api";
// import $ from "jquery";
// import { Breadcrumbs, Link, Typography } from "@mui/material";
// import HomeIcon from '@mui/icons-material/Home';
// import { Accordion, Button, Card } from "react-bootstrap";

// const FAQ = () => {
//     const [questions, setQuestions] = useState("");
//     const [buyers, setBuyersQuestions] = useState([]);
//     const [brokers, setBrokersQuestions] = useState([]);
//     const [exchange, setExchangeQuestions] = useState([]);
//     const [hotels, setHotelsQuestions] = useState([]);
//     const [sellers, setSellersQuestions] = useState([]);
//     const [loading, setLoading] = useState(true);

//     function faqHeadingClick(e, param) {
//         $('.btn-primary').removeClass('btn-primary').addClass('btn-outline-primary');
//         let element = e.target;
//         element.classList.remove('btn-outline-primary')
//         element.classList.add('btn-primary');
//         $('.faq-questions').addClass('hidden');
//         $('#' + param).removeClass('hidden');
//     }

//     useEffect(() => {
//         getAllFaqs();
//     }, [])

//     const getAllFaqs = async () => {
//         let res = await getFaqs();
//         res.data?.faqs.map(faq => {
//             if (faq.faqtype === 'Brokers') {
//                 brokers.push(faq);
//             }
//             if (faq.faqtype === 'Hotels') {
//                 hotels.push(faq);
//             }
//             if (faq.faqtype === '1031 Exchange') {
//                 exchange.push(faq);
//             }
//             if (faq.faqtype === 'Buyers') {
//                 buyers.push(faq);
//             }
//             if (faq.faqtype === 'Sellers') {
//                 sellers.push(faq);
//             }
//         });
//         setQuestions(res.data?.faqs);
//         setLoading(false);
//     }

//     function Question({ question, answer }) {
//         return (
//             <Accordion>
//                 <Card>
//                     <Accordion.Toggle as={Card.Header} eventKey="0">
//                         {question}
//                     </Accordion.Toggle>
//                     <Accordion.Collapse eventKey="0">
//                         <Card.Body dangerouslySetInnerHTML={{ __html: answer }} />
//                     </Accordion.Collapse>
//                 </Card>
//             </Accordion>
//         );
//         }
//         return (
//             <div className="global-div" style={{ height: 'auto', marginTop: '0px' }}>
//                 <div className="faq-main-wrapper" style={{ height: "" }}>
//                     <div className="card">
//                         <div className="faq-card-wrapper">
//                             <h1>FAQ's</h1>
//                             <div className="">
//                                 <input type="button" className="btn btn-primary" value="1031 Exchange" onClick={(e) => faqHeadingClick(e, 'exchange')} />
//                                 <input type="button" className="btn btn-outline-primary" value="1031 Hotels" onClick={(e) => faqHeadingClick(e, 'hotels')} />
//                                 <input type="button" className="btn btn-outline-primary" value="1031 Buyers" onClick={(e) => faqHeadingClick(e, 'buyers')} />
//                                 <input type="button" className="btn btn-outline-primary" value="1031 Sellers" onClick={(e) => faqHeadingClick(e, 'sellers')} />
//                                 <input type="button" className="btn btn-outline-primary" value="1031 Brokers" onClick={(e) => faqHeadingClick(e, 'brokers')} />
//                             </div>
//                             {
//                                 !loading ?
//                                     <div className="faq-questions" id="exchange">

//                                         {
//                                             exchange?.map(q => (
//                                                 <>
//                                                     <>
//                                                         <div className="question" dangerouslySetInnerHTML={{ __html: q.question }}>
//                                                         </div>
//                                                         <div className="answer" dangerouslySetInnerHTML={{ __html: q.answer }}>
//                                                         </div>
//                                                     </>
//                                                 </>
//                                             ))
//                                         }

//                                     </div> : <span>Loading ...</span>
//                             }
//                             {
//                                 !loading ?
//                                     <div className="faq-questions hidden" id="hotels">
//                                         {
//                                             hotels?.map(q => (
//                                                 <>
//                                                     <div className="question" dangerouslySetInnerHTML={{ __html: q.question }}>
//                                                     </div>
//                                                     <div className="answer" dangerouslySetInnerHTML={{ __html: q.answer }}>
//                                                     </div>
//                                                 </>
//                                             ))
//                                         }
//                                     </div> : null
//                             }
//                             {
//                                 !loading ?
//                                     <div className="faq-questions hidden" id="buyers">
//                                         {
//                                             buyers?.map(q => (
//                                                 <>
//                                                     <div className="question" dangerouslySetInnerHTML={{ __html: q.question }}>
//                                                     </div>
//                                                     <div className="answer" dangerouslySetInnerHTML={{ __html: q.answer }}>
//                                                     </div>
//                                                 </>
//                                             ))
//                                         }
//                                     </div> : null
//                             }
//                             {
//                                 !loading ?
//                                     <div className="faq-questions hidden" id="sellers">
//                                         {
//                                             sellers?.map(q => (
//                                                 <>
//                                                     <div className="question" dangerouslySetInnerHTML={{ __html: q.question }}>
//                                                     </div>
//                                                     <div className="answer" dangerouslySetInnerHTML={{ __html: q.answer }}>
//                                                     </div>
//                                                 </>
//                                             ))
//                                         }
//                                     </div> : null
//                             }
//                             {
//                                 !loading ?
//                                     <div className="faq-questions hidden" id="brokers">
//                                         {
//                                             brokers?.map(q => (
//                                                 <>
//                                                     <div className="question" dangerouslySetInnerHTML={{ __html: q.question }}>
//                                                     </div>
//                                                     <div className="answer" dangerouslySetInnerHTML={{ __html: q.answer }}>
//                                                     </div>
//                                                 </>
//                                             ))
//                                         }
//                                     </div> : null
//                             }
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     };

//     export default FAQ;
import React, { useEffect, useState } from 'react';
import AccordionList from './faqAccordian';
import { getFaqs } from '../../../api/api';

function MyApp() {

    const [questions, setQuestions] = useState([]);
    const [buyers, setBuyersQuestions] = useState([]);
    const [brokers, setBrokersQuestions] = useState([]);
    const [exchange, setExchangeQuestions] = useState([]);
    const [hotels, setHotelsQuestions] = useState([]);
    const [sellers, setSellersQuestions] = useState([]);
    const [allFaqs, setAllFaqs] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('all');



    useEffect(() => {
        getAllFaqs();
    }, [])

    const getAllFaqs = async () => {
        let res = await getFaqs();
        let buyerFaqs = [];
        let brokerFaqs = [];
        let exchangeFaqs = [];
        let hotelFaqs = [];
        let sellerFaqs = [];
        let allFaqs = [];
        res.data?.faqs.map(faq => {
            if (faq.faqtype === 'Brokers') {
                brokerFaqs.push(faq);
                allFaqs.push(faq)
            }
            if (faq.faqtype === 'Hotels') {
                hotelFaqs.push(faq);
                allFaqs.push(faq)
            }
            if (faq.faqtype === '1031 Exchange') {
                exchangeFaqs.push(faq);
                allFaqs.push(faq)
            }
            if (faq.faqtype === 'Buyers') {
                buyerFaqs.push(faq);
                allFaqs.push(faq)
            }
            if (faq.faqtype === 'Sellers') {
                sellerFaqs.push(faq);
                allFaqs.push(faq)
            }
        });
        setAllFaqs(allFaqs);
        setBrokersQuestions(brokerFaqs);
        setHotelsQuestions(hotelFaqs);
        setExchangeQuestions(exchangeFaqs);
        setBuyersQuestions(buyerFaqs);
        setSellersQuestions(sellerFaqs)
        setQuestions(allFaqs);
    }

    const faqHeadingClick = (e, i) => {

        if (i == "exchange") setQuestions(exchange)
        if (i == "hotels") setQuestions(hotels)
        if (i == "sellers") setQuestions(sellers)
        if (i == "brokers") setQuestions(brokers)
        if (i == "all") setQuestions(allFaqs)
        setSelectedFilter(i);
    }



    return (
        <div className=''>
            <div className="" >
                <div className="container faq-container" >
                    <div className="card">
                        <div className="card-header ">
                            <h1>FAQ's </h1>
                            
                        </div>
                        <div className='card-body'>
                        <br></br>
                            <div className="row px-4">
                                <input type="button" className={selectedFilter == 'all'  ? "col btn btn-selected w-100" :  "col btn btn-outline-primary-faq w-100"} value="All" onClick={(e) => faqHeadingClick(e, 'all')} />
                                <input type="button" className={selectedFilter == 'exchange'  ? "col btn btn-selected w-100" :  "col btn btn-outline-primary-faq w-100"} value="1031 Exchange Questions" onClick={(e) => faqHeadingClick(e, 'exchange')} />
                                <input type="button" className={selectedFilter == 'hotels'  ? "col btn btn-selected w-100" :  "col btn btn-outline-primary-faq w-100"} value="1031 Buyers Questions" onClick={(e) => faqHeadingClick(e, 'hotels')} />
                                <input type="button" className={selectedFilter == 'sellers'  ? "col btn btn-selected w-100" :  "col btn btn-outline-primary-faq w-100"} value="1031 Sellers Questions" onClick={(e) => faqHeadingClick(e, 'sellers')} />
                                <input type="button" className={selectedFilter == 'brokers'  ? "col btn btn-selected w-100" :  "col btn btn-outline-primary-faq w-100"} value="1031 Brokers Questions" onClick={(e) => faqHeadingClick(e, 'brokers')} />
                            </div>
                            <br></br>
                            <div className='row px-2'>
                                <AccordionList items={questions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default MyApp;
