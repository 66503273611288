import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import DashBoardBuyersTable from "./brokerDashboardBuyersInterestTable.jsx";
import DashBoardPropertyTable from "./brokerDashboardPropertiesInterestTable";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { brokerDashboardInterests } from "../../../api/api.js";

const BrokerDashboard = () => {
  const [buyers, setBuyers] = useState([]);
  const [properties, setProperties] = useState([]);

  useEffect(() => {

    sessionStorage.removeItem("currentPostid")
    getBrokerDashboardInterests();
  }, []);

  const navigate = useNavigate();

  const getBrokerDashboardInterests = async () => {
    let res = await brokerDashboardInterests();
    setBuyers(res.data.buyerInterests);
    setProperties(res.data.propertyInterests);
  };

  return (
    <>
      <Container >
        <br></br>
        <Row fluid="xxl" xs={2}>
          <Col fluid="md" xs={2}>
            <Form >
              <div className="d-grid  margin-dashboard-cards">
                <h4 className="d-flex justify-content-center mb-4">BUYER FUNCTIONS</h4>
                <Form.Group>
                  <Card className="card-red">
                    <Button size="md" variant="outline-light" className="dashboardBtn" onClick={() => navigate('/addBuyer')}>

                      <Card.Body><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#444445" class="bi bi-person-add" viewBox="0 0 16 16">
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                        <path d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Z" />
                      </svg></Card.Body>
                      <Card.Subtitle className="text-muted"><h6>Register a New</h6> </Card.Subtitle>
                      <Card.Title ><h5>1031 Buyer</h5></Card.Title>
                    </Button>
                  </Card>
                </Form.Group>
                <Form.Group>
                  <Card className="card-red">
                    <Button size="md" variant="outline-light" className="dashboardBtn" onClick={() => navigate("/brokerbuyerList")}>

                      <Card.Body><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#444445" class="bi bi-people" viewBox="0 0 16 16">
                        <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                      </svg></Card.Body>
                      <Card.Subtitle className="text-muted"><h6>Work On Your</h6> </Card.Subtitle>
                      <Card.Title ><h5>1031 Buyers</h5></Card.Title>
                    </Button>
                  </Card>
                </Form.Group>
                <Form.Group>
                  <Card className="card-red">
                    <Button size="md" variant="outline-light" className="dashboardBtn" onClick={() => navigate('/propertyList')}>
                      {/* <Card.Img src={ShopForSeller} alt="Card image" /> */}
                      <Card.Body ><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#444445" class="bi bi-building-gear" viewBox="0 0 16 16">
                        <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1V1Z" />
                        <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm4.386 1.46c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                      </svg></Card.Body>
                      <Card.Subtitle className="text-muted"><h6>Shop for a</h6> </Card.Subtitle>
                      <Card.Title ><h5>Hotel</h5></Card.Title>
                    </Button>
                  </Card>
                </Form.Group>
              </div>
            </Form>
          </Col>
          <Col xs={8}>

            <Tabs defaultActiveKey="buyersToReview" className="mb-3" fill justify  >
              <Tab className="header" eventKey="buyersToReview" title={<><span><b>SELLERS INTERESTED IN YOUR BUYERS </b> </span><span className="btn btn-sm btn-round-badge ">{buyers?.length ? buyers?.length : 0}</span></>}>
                <div className="d-grid bd-buyer-interests table-hover">
                  <DashBoardBuyersTable buyersRows={buyers} />
                </div>
              </Tab>
              <Tab  eventKey="hotelsToReview" title={<><span><b>BUYERS REQUESTING HOTELS YOU HAVE FOR SALE </b></span><span className="btn btn-sm btn-round-badge " >{properties?.length ? properties?.length : 0}</span></>}>
                <div className="d-grid bd-property-interests table-hover">
                  <DashBoardPropertyTable propertiesRows={properties} />
                </div>
              </Tab>
            </Tabs>
          </Col>
          <Col fluid="md" xs={2}>
            <Form>
              <div className="d-grid margin-dashboard-cards">
                <h4 className="d-flex justify-content-center mb-4">SELLER FUNCTIONS</h4>
                <Form.Group>
                  <Card className="card-green">
                    <Button size="md" variant="outline-light" className="dashboardBtn" onClick={() => navigate('/addSeller')}>

                      <Card.Body><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#444445" class="bi bi-building-add" viewBox="0 0 16 16">
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Z" />
                        <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v6.5a.5.5 0 0 1-1 0V1H3v14h3v-2.5a.5.5 0 0 1 .5-.5H8v4H3a1 1 0 0 1-1-1V1Z" />
                        <path d="M4.5 2a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm-6 3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm3 0a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                      </svg></Card.Body>
                      <Card.Subtitle className="text-muted"><h6>Post a New</h6> </Card.Subtitle>
                      <Card.Title ><h5>Hotel For Sale</h5></Card.Title>
                    </Button>
                  </Card>
                </Form.Group>
                <Form.Group>
                  <Card className="card-green">
                    <Button size="md" variant="outline-light" className="dashboardBtn" onClick={() => navigate("/brokerpropertyList")}>

                      <Card.Body><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#444445" class="bi bi-buildings" viewBox="0 0 16 16">
                        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                        <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                      </svg></Card.Body>
                      <Card.Subtitle className="text-muted"><h6>Work On Your</h6> </Card.Subtitle>
                      <Card.Title ><h5>Hotels For Sale</h5></Card.Title>
                    </Button>
                  </Card>
                </Form.Group>
                <Form.Group>
                  <Card className="card-green">
                    <Button size="md" variant="outline-light" className="dashboardBtn" onClick={() => navigate('/buyerList')}>
                      <Card.Body><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#444445" class="bi bi-person-fill-gear" viewBox="0 0 16 16">
                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                      </svg></Card.Body>
                      <Card.Subtitle className="text-muted"><h6>Shop for a</h6> </Card.Subtitle>
                      <Card.Title ><h5>1031 Buyer</h5></Card.Title>
                    </Button>
                  </Card>
                </Form.Group>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BrokerDashboard;
