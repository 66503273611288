import React from "react";
import { useNavigate } from "react-router-dom";
const Registration = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="container-fluid screen-view-height  gradient-background flex-column px-0 ">

        <div className="row vh-100 ">
          <div className="col-12  d-flex justify-content-center align-items-center flex-column">
            <div className="row px-4 py-4 h-100 justify-content-center align-items-center mobile-align-header">
              <div className='d-flex justify-content-center align-items-center'>
                <h2 className='fill-white font-size-60 font-weight-300 '>WELCOME TO 1031 HOTELS</h2>
              </div>
              <h3 className='row fill-white px-4  font-weight-300 px-4 justify-content-center align-items-center'>
                America’s Only 1031 Exchange Marketplace
              </h3>
            </div>
            <div className="row mobile-column-flex">
              <div className='col'>
                <button className='btn btn-white-outline px-2 button-mobile-width-300 mobile-margin-10  w-100' onClick={() => navigate("/buyerRegistration")}>Register as Buyer</button>
              </div>
              <div className='col'>
                <button className='btn btn-white-outline px-2  button-mobile-width-300 mobile-margin-10  w-100' onClick={() => navigate("/sellerRegistration")}>Register as a Seller</button>
              </div>
              <div className='col'>
                <button className='btn btn-white-outline px-2  button-mobile-width-300 mobile-margin-10  w-100' onClick={() => navigate("/brokerRegistration")}>Register as a Broker</button>
              </div>
            </div>
            <div className="row h-100 mobile-column-flex mt-2">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <span className="fill-white">&nbsp;&nbsp;Already Registered?&nbsp;<u><a href="/login" className="fill-white">Login</a></u>&nbsp;&nbsp;</span>
              </div>
            </div>

          </div>
        </div>
        <br />


        <div className="row w-100 h-100 px-0 registration-background">
        </div>
      </div>

    </>
  );
};

export default Registration;
