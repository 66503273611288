import React from 'react';
import { useNavigate } from 'react-router-dom';
import TradeClipArt from '../../components/assets/TradeClipArt.png'
import BackgroundStrip from '../../components/assets/BackgroundStrip.jpg'
import AnimatedDiv from '../common/Animation';
import { FaRegPlayCircle } from 'react-icons/fa';


const BrokersLanding = () => {

    let navigate = useNavigate();

    return (
        <>
            <>
                <div className=" gradient-background container-landing d-flex justify-content-start flex-column py-4">
                    <div className='row transform-Y'>
                        <h2 className='row px-4 font-size-50'>BROKERS</h2>
                        <h1 className='row px-4'>WELCOME TO 1031 HOTELS</h1>
                        <label className='row fill-white px-4 font-size-24 px-4'>
                            1031Hotels.com is the America's first and only marketplace to sell hotels to 1031 buyers.
                            <br></br>
                            Register your broker account to start making deals!
                        </label>
                    </div>

                    <div className='row py-5 mt-5 mobile-column-flex'>
                        <div className='col px-4 py-2 d-flex justify-content-center align-items-center'>
                            <div className='btn-primary-wrapper  button-mobile-width-500'>
                                <button className='btn btn-white-outline h-100 px-4 transform-Y  button-mobile-width-500' onClick={() => navigate("/brokerRegistration")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0'>Register as Broker</span> </button>
                            </div>
                        </div>
                        <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                            <button className='btn btn-white-outline px-4 h-100 transform-Y  button-mobile-width-500' onClick={() => navigate("/login")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0'>Login as Broker</span></button>
                        </div>
                        <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                            <button className='btn btn-white-outline px-4    h-100 transform-Y  button-mobile-width-500' onClick={() => navigate("/broker/buyerList")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0 '>View 1031 Exchange Buyers</span></button>
                        </div>
                        <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                            <button className='btn btn-white-outline px-4    h-100 transform-Y  button-mobile-width-500' onClick={() => navigate("/broker/propertyList")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0 '>View Hotels for sale</span></button>
                        </div>
                    </div>


                </div>

                <div className='row landing-page-list  mobile-zero-padding px-5 pb-5 mobile-column-flex'>
                    <div className='col-8 px-4 mobile-w-100'>
                        <div className="row px-2 py-5">
                            <div className="col d-flex justify-content-center align-item-center mt-2 mb-4 transform-Y">
                                <h2 className='fill-black mobile-text-align-center'>FIND BUYERS AND HOTELS ON A SINGLE PLATFORM</h2>
                            </div>
                        </div>
                        <div className='landing-page-list mobile-w-100'>

                            <div className='row d-flex justify-content-center align-item-center px-4 py-2 transform-Y'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                            1031Hotels is the Perfect confidential Marketing Platform designed with Brokers in mind, and designed to make
                                            deals happen faster between Brokers, 1031 Buyers and Sellers
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Show your 1031 Buyer’s “Confidentially” to “Off-Market Sellers” Nationwide who are ready and willing to Sell
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>

                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Show all your listing confidentially to qualified 1031 Exchange Buyers
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Brokers are protected by a non-circumvention agreement protecting your 1031 Buyers
                                                and Listed, or Off-Market hotel Listings
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                            <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                                <div className='listing-index-card col '>
                                </div>
                                <div className='col px-0'>
                                    <div className='listing-note-card card card-body'>
                                        <AnimatedDiv>
                                            <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                                Upon a Sale facilitated by 1031Hotels, you keep more of your commissions - we are here to help!
                                            </h4>
                                        </AnimatedDiv>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-4 px-4 py-4 transform-Y  mobile-w-100'>
                        <div className="tip-area-landing " >
                            <div className="card">

                                <div className='card-body '>
                                    <h3 className='d-flex justify-content-center align-items-center fill-red px-2 py-2 font-weight-400'>How it works</h3>
                                    <div className='row d-flex justify-content-center align-items-center py-0'>
                                        <button className='btn btn-primary fit-content-max d-flex justify-content-center align-items-center' onClick={() => navigate("/brokerTutorial")}><FaRegPlayCircle className='font-size-24 px-1' /> Watch a Tutorial</button>
                                    </div>
                                    <ol className='landing-page-ol' >
                                        <li>
                                            <a href='/brokerRegistration'>Register</a> yourself as a Broker.
                                        </li>
                                        <li>
                                            Register all your 1031 buyers and Post all your hotels for sale. Your posting details will remain confidential until a deal is made.
                                        </li>
                                        <li>
                                            Fill in details and Upload the necessary documents.
                                        </li>
                                        <li>
                                            We will verify and approve the profiles you posted. Once approved, your profiles will be live for sellers and buyers to find you confidentially.
                                        </li>
                                        <li>
                                            Manage all your buyer and hotel postings on a single platform.
                                        </li>
                                        <li>
                                            You will be assigned with a 1031 admin.
                                        </li>
                                        <li>
                                            Your admin will be your primary point of contact for everything you need from 1031Hotels.com.
                                        </li>
                                        <li>
                                            Save the deals you like to your dealbox.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>





        </>
    );
};

export default BrokersLanding;
