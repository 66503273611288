import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const BuyerTutorial = () => {

    const { id } = useParams();
    let navigate = useNavigate();


    return (
        <div className='d-flex justify-content-center align-items-center flex-column'>
            <div id="iframeContainer" >


                <iframe src="https://player.vimeo.com/video/930136991?h=4b8ee29d75" width="640" height="564" frameborder="0" allow="autoplay"  mozallowfullscreen="true" webkitallowfullscreen="true" name='buyerTutorial'></iframe>
            </div>
            <div  className='w-80 d-flex justify-content-end py-2 px-0'>
                <button className='btn btn-outline-primary font-size-16 button-width-300 border-radius-10 min-button-height' onClick={() =>  { id ?  navigate("/buyerPost/" + JSON.parse(sessionStorage.getItem("user_buyer_id"))) : navigate(-1)}}>Skip</button>
            </div>
        </div>
    );
};

export default BuyerTutorial;
