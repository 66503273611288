
export const marketTypes = [
  "Any",
  "Primary",
  "Primary Or Secondary",
  "Secondary Market"
]

export const propertyCategories = [
  "Any",
  "Limited Service",
  "Full Service",
  "Select Service",
  "Extended Stay"
]

export const propertyRoomCount = [
  "Any",
  "1-10",
  "11-40",
  "41-60",
  "61-80",
  "81-100",
  "101-150",
  "151-300",
  "300-1000"
]


export const brand = [
  "Economy",
  "Life Style",
  "Mid-Scale",
  "Upper Mid-Scale",
  "Upscale",
  "Resort",
  "Luxury",
]

export const brandTypes = [
  "Any",
  "Upper Upscale",
  "Upscale",
  "Upper Midscale",
  "Midscale",
  "Upper Economy",
  "Economy",
  "Lower Economy",
  "Select Service",
  "Other"
]

export const prefferedBrands = [
  "Any",
  "Hilton",
  "Marriott",
  "Wyndham",
  "Choice",
  "Hyatt",
  "Sonesta",
  "IHG"
]

export const opportunityTypes = [
  "Any",
  "Existing Cash Flow",
  "Small PIP",
  "Major PIP",
  "Rebranding",
  "Apartment Conversion",
  "REO/Bank Owned",
  "Distressed",
  "Value Add"
]

export const geographicRegion = [
  "Any",
  "North West",
  "West",
  "South West",
  "Mid West",
  "South East",
  "Mid Atlantic",
  "North East"
]

export const AdminOptions = [
  { value: "Unapproved", label: "Unapproved" },
  { value: "Approved", label: "Approved" },
  { value: "In-contract", label: "In-contract" },
  { value: "Off-market", label: "Off-market" },
  { value: "Closed", label: "Closed" },
];

export const buyingPowers = [
  { id: 1, label: "Any", min: 0, max: 5000000000000000 },
  { id: 2, label: "$1 to $2M", min: 1, max: 2000000 },
  { id: 3, label: "$2M to $4M", min: 2000000, max: 4000000 },
  { id: 4, label: "$4M to $6M", min: 4000000, max: 6000000 },
  { id: 5, label: "$6M to $8M", min: 6000000, max: 8000000 },
  { id: 6, label: "$8M to $10M", min: 8000000, max: 10000000 },
  { id: 7, label: "$10M to $12M", min: 10000000, max: 12000000 },
  { id: 8, label: "$12M to $15M", min: 12000000, max: 15000000 },
  { id: 9, label: "$15M to $20M", min: 15000000, max: 20000000 },
  { id: 10, label: "$20M to $30M", min: 20000000, max: 30000000 },
  { id: 11, label: "$30M to $50M", min: 30000000, max: 50000000 },
  { id: 12, label: "$50M+", min: 50000001, max: 5000000000000000 },

]


export const days45deadlines = [
  { id: 1, label: "Any", min: 0, max: 5000000000000000 },
  { id: 2, label: "less than 14", min: 1, max: 14 },
  { id: 3, label: "15 to 29", min: 15, max: 29 },
  { id: 4, label: "30 to 45", min: 30, max: 45 },
  { id: 5, label: "45+", min: 46, max: 5000000000000000 },
]

export const numberOfRoomsvalues = [
  { id: 1, label: "Any", min: 0, max: 5000000000000000 },
  { id: 2, label: "0 to 40", min: 0, max: 40 },
  { id: 3, label: "41 to 60", min: 41, max: 60 },
  { id: 4, label: "61 to 80", min: 61, max: 80 },
  { id: 5, label: "81 to 100", min: 81, max: 100 },
  { id: 6, label: "101 to 150", min: 101, max: 150 },
  { id: 7, label: "151 to 300", min: 151, max: 300 },
  { id: 8, label: "300 to 1000", min: 301, max: 1000 },
]

export const askingPricevalues = [
  { id: 1, label: "Any", min: 0, max: 5000000000000000 },
  { id: 2, label: "$1K to $2M", min: 1, max: 2000000 },
  { id: 3, label: "$2M to $4M", min: 2000000, max: 4000000 },
  { id: 4, label: "$4M to $6M", min: 4000000, max: 6000000 },
  { id: 5, label: "$6M to $8M", min: 6000000, max: 8000000 },
  { id: 6, label: "$8M to $10M", min: 8000000, max: 10000000 },
  { id: 7, label: "$10M to $12M", min: 10000000, max: 12000000 },
  { id: 8, label: "$12M to $15M", min: 12000000, max: 15000000 },
  { id: 9, label: "$15M to $20M", min: 15000000, max: 20000000 },
  { id: 10, label: "$20M+", min: 20000001, max: 5000000000000000 },
]

export const mindownpayvalues = [
  { id: 1, label: "Any", min: 0, max: 5000000000000000 },
  { id: 2, label: "$1K to $500K", min: 1, max: 500000 },
  { id: 3, label: "$500K to $1M", min: 5000000, max: 1000000 },
  { id: 4, label: "$1M to $1.5M", min: 1000000, max: 1500000 },
  { id: 5, label: "$1.5M to $2M", min: 1500000, max: 2000000 },
  { id: 6, label: "$2M to $2.5M", min: 2000000, max: 1500000 },
  { id: 7, label: "$2.5M to $3M", min: 2500000, max: 3000000 },
  { id: 8, label: "$3M to $5M", min: 3000000, max: 5000000 },
  { id: 9, label: "$5M to $10M", min: 5000000, max: 10000000 },
  { id: 10, label: "$10M+", min: 10000001, max: 5000000000000000 },
]

export const contactUsSubjects = [
  { value: 'Post my hotel for sale', label: 'Post my hotel for sale' },
  { value: 'I want to post a 1031 buyer', label: 'I want to post a 1031 buyer' },
  { value: 'I am in a 1031 exchange. Please call me', label: 'I am in a 1031 exchange. Please call me' },
  { value: 'I want to learn more about your site', label: 'I want to learn more about your site' },
  { value: 'I want a broker to contact me', label: 'I want a broker to contact me' },
  { value: 'I want to report a problem', label: 'I want to report a problem' },
  { value: 'I want more information about a hotel', label: 'I want more information about a hotel' },
  { value: 'I want more information about your 1031 buyers', label: 'I want more information about your 1031 buyers' },
  { value: 'Other', label: 'Other' },
];

export const brokerTypes = [
  "Residential",
  "Hotel Specialist",
  "Commercial General",
  "Loan Broker"
]

export const hotelsSoldRanges = [
  "0", 
  "1-5", 
  "5-10", 
  "10-20", 
  "20+"
]

export const brokerDescription = [
  "Principal Broker", 
  "Associate Broker",
  "Licensed Agent"
]

export const brokerLookingFor = [
  "1031 Buyers", 
  "Hotels for Sale", 
  "Both 1031 Buyers and Hotels for Sale"
]

export const USAstates = [
    {
      "id": 1,
      "code": "AK",
      "name": "Alaska"
    },
    {
      "id": 2,
      "code": "AL",
      "name": "Alabama"
    },
    {
      "id": 3,
      "code": "AZ",
      "name": "Arizona"
    },
    {
      "id": 4,
      "code": "AR",
      "name": "Arkansas"
    },
    {
      "id": 5,
      "code": "CA",
      "name": "California"
    },
    {
      "id": 6,
      "code": "CO",
      "name": "Colorado"
    },
    {
      "id": 7,
      "code": "CT",
      "name": "Connecticut"
    },
    {
      "id": 8,
      "code": "DE",
      "name": "Delaware"
    },
    {
      "id": 9,
      "code": "DC",
      "name": "District of Columbia"
    },
    {
      "id": 10,
      "code": "FL",
      "name": "Florida"
    },
    {
      "id": 11,
      "code": "GA",
      "name": "Georgia"
    },
    {
      "id": 12,
      "code": "HI",
      "name": "Hawaii"
    },
    {
      "id": 13,
      "code": "ID",
      "name": "Idaho"
    },
    {
      "id": 14,
      "code": "IL",
      "name": "Illinois"
    },
    {
      "id": 15,
      "code": "IN",
      "name": "Indiana"
    },
    {
      "id": 16,
      "code": "IA",
      "name": "Iowa"
    },
    {
      "id": 17,
      "code": "KS",
      "name": "Kansas"
    },
    {
      "id": 18,
      "code": "KY",
      "name": "Kentucky"
    },
    {
      "id": 19,
      "code": "LA",
      "name": "Louisiana"
    },
    {
      "id": 20,
      "code": "ME",
      "name": "Maine"
    },
    {
      "id": 21,
      "code": "MD",
      "name": "Maryland"
    },
    {
      "id": 22,
      "code": "MA",
      "name": "Massachusetts"
    },
    {
      "id": 23,
      "code": "MI",
      "name": "Michigan"
    },
    {
      "id": 24,
      "code": "MN",
      "name": "Minnesota"
    },
    {
      "id": 25,
      "code": "MS",
      "name": "Mississippi"
    },
    {
      "id": 26,
      "code": "MO",
      "name": "Missouri"
    },
    {
      "id": 27,
      "code": "MT",
      "name": "Montana"
    },
    {
      "id": 28,
      "code": "NE",
      "name": "Nebraska"
    },
    {
      "id": 29,
      "code": "NV",
      "name": "Nevada"
    },
    {
      "id": 30,
      "code": "NH",
      "name": "New Hampshire"
    },
    {
      "id": 31,
      "code": "NJ",
      "name": "New Jersey"
    },
    {
      "id": 32,
      "code": "NM",
      "name": "New Mexico"
    },
    {
      "id": 33,
      "code": "NY",
      "name": "New York"
    },
    {
      "id": 34,
      "code": "NC",
      "name": "North Carolina"
    },
    {
      "id": 35,
      "code": "ND",
      "name": "North Dakota"
    },
    {
      "id": 36,
      "code": "OH",
      "name": "Ohio"
    },
    {
      "id": 37,
      "code": "OK",
      "name": "Oklahoma"
    },
    {
      "id": 38,
      "code": "OR",
      "name": "Oregon"
    },
    {
      "id": 39,
      "code": "PA",
      "name": "Pennsylvania"
    },
    {
      "id": 40,
      "code": "PR",
      "name": "Puerto Rico"
    },
    {
      "id": 41,
      "code": "RI",
      "name": "Rhode Island"
    },
    {
      "id": 42,
      "code": "SC",
      "name": "South Carolina"
    },
    {
      "id": 43,
      "code": "SD",
      "name": "South Dakota"
    },
    {
      "id": 44,
      "code": "TN",
      "name": "Tennessee"
    },
    {
      "id": 45,
      "code": "TX",
      "name": "Texas"
    },
    {
      "id": 46,
      "code": "UT",
      "name": "Utah"
    },
    {
      "id": 47,
      "code": "VT",
      "name": "Vermont"
    },
    {
      "id": 48,
      "code": "VA",
      "name": "Virginia"
    },
    {
      "id": 49,
      "code": "WA",
      "name": "Washington"
    },
    {
      "id": 50,
      "code": "WV",
      "name": "West Virginia"
    },
    {
      "id": 51,
      "code": "WI",
      "name": "Wisconsin"
    },
    {
      "id": 52,
      "code": "WY",
      "name": "Wyoming"
    }
  ]

export const dateBefore180Days = () => {
  let year, month, day;
  const date = new Date();

  const currentDate = {
    year: date.getFullYear(),
    month: (date.getMonth() + 1),
    day: date.getDate()
  }

  if (currentDate.month >= 7) {
    year = currentDate.year;
    month = currentDate.month - 6;
    day = currentDate.day;
  } else if (currentDate.month < 7) {
    let initMonth = currentDate.month - 6;
    year = currentDate.year - 1;
    month = 12 + initMonth;
    day = currentDate.day;
  }

  return (new Date(`${year}-${month}-${day}`));
}

export const formatMobileNumber = (moduleNumber) => {
  const moduleStr = moduleNumber?.toString();

  if (moduleStr?.length === 10) {
    const formattedNumber = `(${moduleStr?.slice(0, 3)}) ${moduleStr?.slice(3, 6)} ${moduleStr?.slice(6)}`;
    return formattedNumber;
  } else {
    // If the length is not 10, return an error message or handle it as needed
    return "Invalid module number length";
  }
};

