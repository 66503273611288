// import axios from 'axios';
import api from "../axiosInstances/instances";
import {authUserHeader, initialAuth} from "../services/authHeader";
import $ from "jquery"
import {
    buyer,
    buyers,
    properties,
    property,
    interest,
    primaryStates,
    dashboard,
    buyerActivity,
    propertyActivity,
    faqs,
    propertyCategories,
    PropertyMarketType,
    PropertyRoomCount,
    PropertyLoanType,
    interestForSeller,
    interestForBuyer,
    PropertyBrands,
    uploadFile,
    getuser, verifyAccount,
    stateMsa,
    buyerR,
    contactus,
    emailValidity,
    forgotPassword,
    passwordReset,
    entityValidity,
    dealBox,
} from "../constants/constants";
import interestAPI from "../axiosInstances/interestInstance";
import tokenInstance from "../axiosInstances/tokenInstance";
import Swal from "sweetalert2";
import {GetClientTokenCookie, isUserLoggedIn, Logout, setToken} from "../components/common/commonUtils";
import jwt_decode from "jwt-decode";
import { USAstates } from "../components/common/utils";
import { toast } from "react-toastify";

const checkUnauthorized = (error) => {
    if (error?.response?.status == 401) {
        Logout();
    }
}

const getTokenForApi = () => {
    return isUserLoggedIn() ? authUserHeader() : initialAuth() ;
}
export const registerUser = async (payload) => {
    try{
        let res = await api.post(getuser,payload,{
            headers: initialAuth(),
        })
    
        return res
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
    }
}

export const validateEmail = async (email) => {
    try{
        let res = await api.post(emailValidity + email,{},{
            headers: initialAuth(),
        })
    
        return res
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
    }
}

export const validateEntity = async (entity) => {
    try{
        let res = await api.post(entityValidity + entity,{},{
            headers: initialAuth(),
        })
    
        return res
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
    }
}

export const forgotPasswordRequest = async (email) => {
    try {
        let res = await api.get(forgotPassword + email,{
            headers: initialAuth(),
        })

        return res;
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
    }
}

export const resetPassword = async (payload) => {
    try {
        let res = await api.post(passwordReset,payload,{
            headers: initialAuth(),
        })
        
        return res;
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        return error.response.data
    }
}

export const getUserInfo = async () => {
    
    try {
        let res = await api.get(getuser, {
            headers: authUserHeader(),
        });
        return res.data;
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        
    }
};

export const contactUsApi = async (payload) => {
    
    try {
        let res = await api.post(contactus, payload, {
            headers: initialAuth(),
        });
        return res.data;
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        
    }
};
//-------------------------------------------BUYER--------------------------------------------//

export const getBuyers = async () => {
    try {
        let res = await api.get(buyers, {
            headers: getTokenForApi(),
        });
        return res.data;
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        checkUnauthorized();
    }
};

export const getBuyer = async (id) => {
    
    try {
        let res = await api.get(buyer + id, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
};

export const getBuyerAvtivity = async (id) => {
    try {
        let res = await api.get(buyerActivity + id, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();

    }
};

//-------------------------------------------BUYER--------------------------------------------//

//-------------------------------------------PROPERTY--------------------------------------------//
export const getProperties = async () => {

    try {
        let res = await api.get(properties, {
            headers: getTokenForApi(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        console.log(error.message);
    }
};

export const getProperty = async (id) => {
    try {
        let res = await api.get(property + id, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
    
        return error.response.data;
    }
};

export const getPropertyActivity = async (id) => {
    try {
        let res = await api.get(propertyActivity + id, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();

    }
};

//-------------------------------------------PROPERTY--------------------------------------------//

export const getDashboard = async () => {
    try {
        let res = await api.get(dashboard, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
};

export const getDealBox = async () => {
    try {
        let res = await api.get(dealBox, {
            headers: authUserHeader(),
            skipSpinner:true
        });
        return res;
    } catch (error) {
        checkUnauthorized();
    }
};


export const updateInterestForBuyer = async (payload) => {
    try {
        let res = await interestAPI.post(interestForSeller, payload, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
       
        checkUnauthorized();
    }
};

export const acceptInterestFromBuyer = async (payload) => {
    try {
        let res = await interestAPI.put(interestForSeller, payload, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
};

export const interestUpdateOfBuyer = async (payload) => {
    try {
        let res = await interestAPI.put(interestForBuyer, payload, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
};

export const updateInterestForProperty = async (payload) => {
    try {
        let res = await interestAPI.post(interestForBuyer, payload, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
};

export const  acceptInterestFromHotel = async (payload) => {
    try {
        let res = await interestAPI.put(interestForBuyer, payload, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
};

export const updateRankByBuyer = async (payload) => {
    try {
        let res = await interestAPI.put(interestForSeller, payload, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
}

export const updateRankBySeller = async (payload) => {
    try {
        let res = await interestAPI.put(interestForBuyer, payload, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
}

export const  interestUpdateOfHotel = async (payload) => {
    try {
        let res = await interestAPI.put(interestForSeller, payload, {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        checkUnauthorized();
        
    }
};

export const uploadFiles = (upload_File, name) => {
    try {
        const modifiedFileName = upload_File.name
        const modifiedFile = new File([upload_File], modifiedFileName, { type: upload_File.type });
        const formData = new FormData();
        formData.append("file", modifiedFile);
        if (name === "propertyImages") formData.append("isFile", false)
        if (name != "propertyImages") formData.append("isFile", true)

        let res = api.post(uploadFile, formData, {
            headers: initialAuth(),
        });
        return res;
    } catch (error) {
        console.log(error)
        return error;
    }
};

export const getImageByKey = async (key) => {
    let res = await api.get(`/hba/commons/downloadfile?key=${key}&isFile=false`,{
        headers: authUserHeader(),
        responseType: 'arraybuffer',
    })
    return res
}

export const getImageByKeyForList = async (key) => {
    let res = await api.get(`/hba/commons/downloadfile?key=${key}&isFile=false`,{
        headers: initialAuth(),
        responseType: 'arraybuffer',
    })
    return res
}

export const getPrimaryStates = async () => {

    localStorage.setItem("primaryStates", JSON.stringify(USAstates));

    return USAstates
};

export const getStateMsa = async () => {
    let msaStored = localStorage.getItem("stateMsa");
    try {
        let res = await tokenInstance.get(stateMsa, {
            headers: initialAuth(),
        });
        localStorage.setItem("stateMsa", JSON.stringify(res.data.stateMsaMap));
        return res.data;
    } catch (error) {
        await verifyAndSetToken();
        window.location.reload()
        
    }
};

export const getFaqs = async () => {
    try {
        let res = api.get(faqs, {
            headers: initialAuth(),
        });
        return res;
    } catch (error) {
        window.location.reload();
        
    }
};

export const getPropertyTypes = async () => {
    let propTypes = localStorage.getItem("propertyTypes");
    if (propTypes === null || propTypes === "" || propTypes == "undefined") {
        console.log(" No Property Types in local storage, calling states API");
        await verifyAndSetToken();
        try {
            let res = await tokenInstance.get(PropertyBrands, {
                headers: initialAuth(),
            });
            localStorage.setItem("propertyTypes", JSON.stringify(res.data.propertyBrands));
            return res.data;
        } catch (error) {
            // Swal.fire(error.response.data.error, error.response.data.error_description, "error");
            window.location.reload();
            
        }
    } else {
        return propTypes;
    }
};

export const getPropertyCategories = async () => {
    let propCat = localStorage.getItem("propertycategories");
    if (propCat === null || propCat === "" || propCat == "undefined") {
        console.log(" No Property Categories in local storage, calling states API");
        await verifyAndSetToken();
        try {
            let res = await tokenInstance.get(propertyCategories, {
                headers: initialAuth(),
            });
            localStorage.setItem(
                "propertycategories",
                JSON.stringify(res.data.propertyCategories)
            );
            return res.data;
        } catch (error) {
            // Swal.fire(
            //     error.response.data.error,
            //     error.response.data.error_description,
            //     "error"
            // );
            window.location.reload();
            
        }
    } else {
        return propCat;
    }
};

export const getPropertyMarketTypes = async () => {
    let propMarket = localStorage.getItem("propertymarkettype");
    if (propMarket === null || propMarket === "" || propMarket == "undefined") {
        console.log(" No Property Market Types in local storage, calling states API");
        await verifyAndSetToken();
        try {
            let res = await tokenInstance.get(PropertyMarketType, {
                headers: initialAuth(),
            });
            localStorage.setItem(
                "propertymarkettype",
                JSON.stringify(res.data.propertyMarketTypes)
            );
            return res.data;
        } catch (error) {
            // Swal.fire(
            //     error.response.data.error,
            //     error.response.data.error_description,
            //     "error"
            // );
            window.location.reload();
            
        }
    } else {
        return propMarket;
    }
};

export const getPropertyRoomCount = async () => {
    let roomCount = localStorage.getItem("propertyroomcount");
    if (roomCount === null || roomCount === "" || roomCount == "undefined") {
        console.log(" No Property roomCount Types in local storage, calling states API");
        await verifyAndSetToken();
        try {
            let res = await tokenInstance.get(PropertyRoomCount, {
                headers: initialAuth(),
            });
            localStorage.setItem(
                "propertyroomcount",
                JSON.stringify(res.data.propertyRoomCounts)
            );
            return res.data;
        } catch (error) {
            window.location.reload()
            
        }
    } else {
        return roomCount;
    }
};

export const getPropertyLoanTypes = async () => {
    try {
        let res = await tokenInstance.get(PropertyLoanType, {
            headers: initialAuth(),
        });
        localStorage.setItem("loantype", JSON.stringify(res.data.loanTypes));
        return res.data;
    } catch (error) {
        window.location.reload();
    }
};


export const getPropertyBrands = async () => {
    let propBrands = localStorage.getItem("propertyBrands");
    try {
        let res = await tokenInstance.get(PropertyBrands, {
            headers: initialAuth(),
        });
        localStorage.setItem(
            "propertyBrands",
            JSON.stringify(res.data.propertyTypes)
        );
        console.log(res.data)
        return res.data;
    } catch (error) {
        window.location.reload();
    }
    return propBrands;

};

export const verifyAndSetToken = async () => {
    const tokenCookie = GetClientTokenCookie();
    var decoded = "";
    if (tokenCookie != null)
        decoded = jwt_decode(tokenCookie, {complete: true})
    var dateNow = new Date();
    if (tokenCookie == null || decoded.exp > dateNow.getTime()) {
        console.log("Token is not present ..  setting token")
        await setToken();
        return true;
    }
}

export const getUserInformation = async () => {
    try {
        let res = await api.get(getuser, {
            headers: authUserHeader()
        })
        console.log(res)
        return res;
    } catch (error) {
        
        return error;
    }
}

//----------------------------------------------UPDATE USER INFO----------------------------------------------------//

export const updateUser = async (data) => {
    try {
        let res = await api.put(getuser, data, {
            headers: authUserHeader()
        })
        return res;
    } catch (error) {
        checkUnauthorized();
        
        return error;
    }
}

export const registerPropertyforUser = async (data) => {
    try {
        let res = await api.post(property, data, {
            headers: authUserHeader()
        })
        return res;
    } catch (error) {
        checkUnauthorized();
        
        return error;
    }
}

export const registerBuyerforUser = async (data) => {
    try {
        let res = await api.post(buyerR, data, {
            headers: authUserHeader()
        })
        return res;
    } catch (error) {
        checkUnauthorized();
        
        return error;
    }
}

export const updateBuyerById = async (payload) => {
    try {
        let res = await api.put("/hba/buyer", payload, {
            headers: authUserHeader(),
        })
        return res;

    } catch (error) {
        checkUnauthorized();
        console.log(error)
    }
}

export const updatePropertyById = async (payload) => {
    try {
      let res = await api.put("/hba/property", payload, {
        headers: authUserHeader(),
      })
      return res;
  
    } catch (error) {
      checkUnauthorized();
      console.log(error)
    }
  }

export const updateStatusById = async (payload) => {
    try {
      let res = await api.post("/hba/update/dealStatus", payload, {
        headers: authUserHeader(),
      })
      return res;
  
    } catch (error) {
      checkUnauthorized();
      console.log(error)
    }
  }
  

export const buyerAuthorizeAgent = async (payload) => {
    try {
        let res = await api.put("/hba/buyer/authorizeagent", payload, {
            headers: authUserHeader(),
        })
        return res;

    } catch (error) {
        checkUnauthorized();
        console.log(error)
    }
}

export const verifyEmail = async (opts) => {
    let res = await api
        .get(verifyAccount + "?token=" + opts, {
            headers: initialAuth(),
        });
    return res.data;
};


//----------------------------------------------BROKER----------------------------------------------------//


export const brokerDashboardInterests = async () => {
    try {
        let res = await api.get("/hba/interests/foruser", {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        // checkUnauthorized();
    }
}

//----------------------------------------------AGENT----------------------------------------------------//

export const agentDashboardInterests = async () => {
    try {
        let res = await api.get("/hba/interests/agent?noOfDays=7", {
            headers: authUserHeader(),
        });
        return res;
    } catch (error) {
        toast.error(error?.message,
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        // checkUnauthorized();
        console.log(error)
    }
}

//----------------------------------------------FILES----------------------------------------------------//

export const getFileByKey = async (key) => {
    let payload ={}
    let res = await api.get(`/hba/commons/downloadfile?key=${key}&isFile=true`,{
        headers: authUserHeader(),
        responseType: 'arraybuffer',
    })
    return res
  }

  export const getAppFileByKey = async (key) => {
    let res = await api.get(`/hba/commons/downloadfile?key=${key}&isFile=true`,{
        headers: authUserHeader(),
        responseType: 'arraybuffer',
    })
    return res
  }

  export const getProfilePicByKey = async (key) => {
    let res = await api.get(`/hba/commons/downloadfile?key=${key}&isFile=true`,{
        headers: authUserHeader(),
        responseType: 'arraybuffer',
    })
    return res
  }