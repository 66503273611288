import React, { useEffect, useState } from 'react';
import $ from "jquery";
import { FaCheckCircle, FaTrash, FaWindowClose } from "react-icons/fa";
import { getAppFileByKey, getFileByKey, getImageByKey, getPrimaryStates, getPropertyBrands, getPropertyCategories, getPropertyLoanTypes, getPropertyMarketTypes, getStateMsa, getUserInfo, getUserInformation, registerPropertyforUser, uploadFiles } from '../../../api/api';
// import "file-upload-with-preview/dist/file-upload-with-preview.min.css";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import CurrencyInput from 'react-currency-input-field';
import { Badge } from 'reactstrap';
import { prefferedBrands, opportunityTypes, marketTypes } from '../../common/utils';
import { Confi_Agreement_Key, Seller_Posting_Fee_Agreement, S3_IMAGES, Terms_And_Conditions, Broker_Posting_Fee_Agreement } from '../../../constants/constants';
import { toast } from 'react-toastify';
import PdfFormFiller from '../../common/PdfFormFiller';
import { PiCurrencyDollarSimpleBold } from 'react-icons/pi';
import TermsAndConditions from './TermsAndConditions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IoClose } from 'react-icons/io5';
import { MdOutlinePercent } from 'react-icons/md';
import ImageGalleryEditor from '../../common/ImageGalleryEditor';



const SellerProperty = ({ changePage }) => {
    const fileTypes = ["JPEG", "PNG", "JPG"];
    const [states, setStates] = useState();
    const [addDocasFiles, setAddDocsFiles] = useState([]);
    const [monthProfitFiles, setMonthProfitFiles] = useState([]);
    const [lastMonthFiles, setLastMonthFiles] = useState([]);
    const [plpReportFiles, setPlpReportFiles] = useState([]);
    const [fileList, setFileList] = useState(null);
    const [uploadButtonFlag, setUploadButtonFlag] = useState(false);
    const [imageBytes, setImageBytes] = useState([]);
    const [pdfBytes, setPdfBytes] = useState();
    const [isDocOpen, setDocOpen] = useState(false);
    const [openDocType, setOpenDocType] = useState();
    const [userFormDetails, setUserFormDetails] = useState([]);
    const [formState, setFormState] = useState({
        sellerid: "",
        sellerName: "",
        brandName: "",
        franchiseName: "",
        lastRenovatedYear: "",
        yearBuilt: "",
        loanType: "",
        marketType: "",
        opportunityType: "",
        primaryState: {
            id: "",
            name: "",
            code: "",
        },
        nearestMSA: "",
        hotelCategory: "",
        pipEstimate: "",
        askingPrice: "",
        totalPrice: "",
        displayBrandOn1031: "",
        netOperatingRevenue: "",
        cashOnCashRet: "",
        propertyType: {
            id: "",
            brandName: "",
            brandType: "",
            franchiseName: "",
            franchiseType: ""
        },
        address: {
            line1: "",
            city: "",
            state: {
                id: "",
                name: "",
                code: ""
            },
            zipCode: "",
        },
        imagesPath: "",
        additionalDocs: "",
        lastMonthReport: "",
        last12MonthProfitReport: "",
        plpReport: "",
        website: "",
        noOfRooms: "",
        yearlyADR: "",
        yearlyOccupancy: "",
        roomRevenue: "",
        yearlyFNBRevenue: "",
        otherRevenue: "",
        netIncome: "",
        listingExpiry: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
    })
    const [formMessage, setFormMessage] = useState({
        brandName: "",
        franchiseName: "",
        lastRenovatedYear: "",
        yearBuilt: "",
        loanType: "",
        marketType: "",
        nearestMSA: "",
        hotelCategory: "",
        pipEstimate: "",
        askingPrice: "",
        totalPrice: "",
        netOperatingRevenue: "",
        cashOnCashRet: "",
        propertyType: {
            id: "",
            brandName: "",
            brandType: "",
            franchiseName: "",
            franchiseType: ""
        },
        address: {
            line1: "",
            city: "",
            state: {
                id: "",
                name: "",
                code: ""
            },
            zipCode: "",
        },
        noOfRooms: "",
        yearlyADR: "",
        roomRevenue: "",
        yearlyFNBRevenue: "",
        otherRevenue: "",
        netIncome: "",

    });
    const [formValid, setFormValid] = useState({
        brandName: null,
        franchiseName: null,
        lastRenovatedYear: null,
        yearBuilt: null,
        loanType: null,
        marketType: null,
        opportunityType: null,
        nearestMSA: null,
        hotelCategory: null,
        pipEstimate: null,
        askingPrice: null,
        totalPrice: null,
        netOperatingRevenue: null,
        cashOnCashRet: null,
        propertyType: {
            id: null,
            brandName: null,
            brandType: null,
            franchiseName: null,
            franchiseType: null
        },
        address: {
            line1: null,
            city: null,
            state: {
                id: null,
                name: null,
                code: null
            },
            zipCode: null,
        },
        website: null,
        noOfRooms: null,
        yearlyADR: null,
        yearlyOccupancy: null,
        roomRevenue: null,
        yearlyFNBRevenue: null,
        otherRevenue: null,
        netIncome: null,
    });
    const [formValidForSbmission, setFormValidForSumisson] = useState(false);
    const [file, setFile] = useState([]);
    const [msaArray, setMsaArray] = useState([]);
    const [className, setClassName] = useState('');
    const [showAggr, setShowAggrement] = useState(false);
    const [user, setUser] = useState();
    const [page, setPage] = useState(1);
    const [redirect_url, setRedirectUrl] = useState();
    const [TermsAndConditionsPdf, setTermsAndConditionsPdf] = useState();
    const [tcFormDetails, setTcFormDetail] = useState({ "Text-iTvuSuGI6-": "A Unique Fingerprint will be generated once you sign this agreement" });
    const [uploadBtnState, setUploadBtnState] = useState({
        last12MonthProfitReport: false,
        lastMonthReport: false,
        plpReport: false,
        additionalDocs: false
    })

    const [images, setImages] = useState([]);

    let user_type = sessionStorage.getItem("user_type");


    useEffect(async () => {
        if (!user)
            document.body.classList.add("no-navbars");
        await getPropertyCategories();
        await getPropertyMarketTypes();
        await getPropertyLoanTypes()
        await getPropertyBrands();
        setPropertyDropdowns();
        await getStateMsa();
        getUserInformation().then(user => {
            setUser(user.data);
            { (user.data.userTypes.includes("Broker") || user.data.userTypes.includes("Agent")) ? <></> : handleSellerName(user?.data) }
        }
        );
        let redirect_url = sessionStorage.getItem("user_type").includes('Broker') ? "/brokerDashboard" : "/";
        setRedirectUrl(redirect_url)
        handleTCDownload(Terms_And_Conditions, "pdf")
    }, []);

    useEffect(() => {
        if (formState?.imagesPath) {
            const initialImages = formState.imagesPath.split(',').map(imageUrl => ({
                src: S3_IMAGES + imageUrl,
                width: 260,
                height: 174,
            }));
            setImages(initialImages);
        }
    }, [formState?.imagesPath]);


    const closeDoc = () => {
        setDocOpen(false);
    };

    const setPropertyDropdowns = () => {
        $("#hotelbrand").append(`<option value="" selected="selected" disabled hidden>Please Select</option>`)
        {
            $("#hotelbrand").val() == null ?
                $.each(JSON.parse(localStorage.getItem("propertyBrands") || "[]"), function (key, val) {
                    $("#hotelbrand").append(`<option value="${val.id}">${val.brandName} </option>`)
                    $("#hotelfranchise").val(val.franchiseName);
                    $("#hotelbrandtype").val(val.brandType);
                    $("#hotelfranchisetype").val(val.franchiseType);
                })
                : <></>
        }
        {
            $("#hotelbrand").val() == null ?
                $("#hotelbrand").append($("#hotelbrand option")
                    .remove().sort(function (a, b) {
                        var at = $(a).text(),
                            bt = $(b).text();
                        return (at > bt) ? 1 : ((at < bt) ? -1 : 0);
                    })) : <></>
        }

        $("#marketType").append(`<option value="" selected="selected" disabled hidden>Please Select</option>`)
        $.each(marketTypes, function (key, val) {
            $("#marketType").append(`<option value="${val}">${val} </option>`)
        })

        $("#opportunityType").append(`<option value="" selected="selected" disabled hidden>Please Select</option>`)
        $.each(opportunityTypes, function (key, val) {
            $("#opportunityType").append(`<option value="${val}">${val} </option>`)
        })

        $("#hotelcategory").append(`<option value="" selected="selected" disabled hidden>Please Select</option>`)
        $.each(JSON.parse(localStorage.getItem("propertycategories") || "[]"), function (key, val) {
            $("#hotelcategory").append(`<option value="${val.name}">${val.name} </option>`)
        })

        $("#loantype").append(`<option value="" selected="selected" disabled hidden>Please Select</option>`)
        $.each(JSON.parse(localStorage.getItem("loantype") || "[]"), function (key, val) {
            $("#loantype").append(`<option value="${val.name}">${val.name} </option>`)
        })

        setStates([]);

        getPrimaryStates().then((res) => setStates(res));
        $("#sellerStates").append(`<option value="" selected="selected" disabled hidden>Please Select</option>`)
        $.each(JSON.parse(localStorage.getItem("primaryStates") || "[]"), function (key, val) {
            $("#sellerStates").append(`<option value="${val.id}">${val.name} - ${val.code}</option>`)
        })
    }
    function handleCurrencyChange(value, name) {
        setFormState({
            ...formState,
            [name]: value
        });

        if (!value) {
            setClassName('');
            return;
        }

        if (Number.isNaN(Number(value))) {
            setClassName('invalid-feedback-span');
            return;
        }


    }

    function formatDateToMMDDYY(date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear().toString()

        return `${month}-${day}-${year}`;
    }


    const handleNext = (e) => {
        e.preventDefault();

        // if (page == 3 ){
        //     openDoc(Terms_And_Conditions, "pdf")

        // }

        if (page == 3 && validateForm("check", 0)) {
            setShowAggrement(true)
            // setPage(page + 1)
            // setDocOpen(true)

            let isValid = validateForm("check", 0)

            const currentDate = new Date();

            const oneEightyDaysFromNow = new Date(currentDate);
            oneEightyDaysFromNow.setDate(currentDate.getDate() + 180);

            const formattedCurrentDate = formatDateToMMDDYY(currentDate);
            const formattedoneEightyDaysFromNow = formatDateToMMDDYY(oneEightyDaysFromNow);
            let userIsBroker = JSON.parse(sessionStorage.getItem("user_type")).includes("Broker")

            if (isValid) {
                // setUserFormDetails([user?.firstName + " " + user?.lastName, user?.companyName, formState?.propertyType?.brandName, formState?.propertyType?.franchiseName, formState?.address?.line1, formState?.askingPrice, formattedCurrentDate, formattedNinetyDaysFromNow, user?.firstName.toUpperCase() + " " + user?.lastName.toUpperCase()])
                if (userIsBroker) {


                    setUserFormDetails({
                        "Text-Hotel-Address": formState?.address?.line1 + ", " + formState?.address?.city + ", " + formState?.address?.state?.code + ", " + formState?.address?.zipCode,
                        "Text-Commission": formState?.totalCommission,
                        "Text-Start-Date": formattedCurrentDate,
                        "Text-End-Date": formattedoneEightyDaysFromNow,
                        "Text-Seller-Company": user?.companyName,
                        "Text-Date": formattedCurrentDate,
                        "Text-Seller-Name": user?.firstName + " " + user?.lastName,
                        "Text-Broker-Name": user?.firstName + " " + user?.lastName,
                        "Text-FingerPrint": "A Unique Fingerprint will be generated once you sign this agreement",
                        "Text-Hotel-Name": formState?.propertyType?.brandName + " " + formState?.propertyType?.franchiseName + ", " + formState?.address?.city + " " + formState?.address?.state?.code,

                    })
                    openDoc(Broker_Posting_Fee_Agreement, "pdf")
                } else {
                    setUserFormDetails({
                        "Text-Hotel-Name": formState?.propertyType?.brandName + " " + formState?.propertyType?.franchiseName + ", " + formState?.address?.city + " " + formState?.address?.state?.code,
                        "Text-Start-Date": formattedCurrentDate,
                        "Text-End-Date": formattedoneEightyDaysFromNow,
                        "Text-Seller-Company": user?.companyName,
                        "Text-Seller-Name": formState?.sellerName,
                        "Text-Broker-Name": "Hotel Brokers of America",
                        "Text-Local-Broker": "Bang Realty Inc",
                        "Text-Date": formattedCurrentDate,
                        "Text-FingerPrint": "A Unique Fingerprint will be generated once you sign this agreement",
                    })

                    openDoc(Seller_Posting_Fee_Agreement, "pdf")
                }
            }
        }
        if (validateForm("check", 0)) {
            setPage(page + 1)
            changePage(page + 1)
        }
    }

    const handlePrevious = (e) => {
        e.preventDefault();

        if (validateForm("check", 0)) {
            setPage(page - 1)
            changePage(page - 1)
        }
    }

    function handleChange(evt) {
        const value = evt.target.value;
        if (evt.target.name == "state") {
            JSON.parse(localStorage.getItem("primaryStates") || "[]")?.map((statenew) => {

                if (statenew.id == value) {
                    let address = {
                        line1: formState.address.line1,
                        city: formState.address.city,
                        state: statenew,
                        zipCode: formState.address.zipCode
                    }
                    setFormState({
                        ...formState,
                        primaryState: statenew,
                        address: address,
                    });
                    $.each(JSON.parse(localStorage.getItem("stateMsa") || "[]"), function (key, val) {
                        if (key == statenew.code) {
                            // $.each(val || "[]"), function(key,val){
                            // }
                            setMsaArray(val.sort());

                        }
                    })
                }
            })
        } else if (evt.target.name == "brandName") {

            $.each(JSON.parse(localStorage.getItem("propertyBrands") || "[]"), function (key, val) {
                // $("#hotelbrand").append(`<option value="${val.id}">${val.brandName} </option>`)
                if (val.id == value) {
                    // $("#hotelbrand").val(val.brandName);
                    // $("#hotelfranchise").val(val.franchiseName);
                    // $("#hotelbrandtype").val(val.brandType);
                    // $("#hotelfranchisetype").val(val.franchiseType);

                    setFormState({
                        ...formState,
                        propertyType: val,
                        brandName: val.brandName,
                        franchiseName: val.franchiseName
                    })
                }

            })
        } else if (evt.target.name == "line1") {
            console.log(value)
            let addressnew = {
                line1: value,
                city: formState.address.city,
                state: formState.address.state,
                zipCode: formState.address.zipCode
            }
            setFormState({
                ...formState,
                address: addressnew
            })
            console.log(formState)
        } else if (evt.target.name == "city") {
            console.log(value)
            let addressnew = {
                line1: formState.address.line1,
                city: value,
                state: formState.address.state,
                zipCode: formState.address.zipCode
            }
            setFormState({
                ...formState,
                address: addressnew
            })
            console.log(formState)
        } else if (evt.target.name == "zipCode") {
            console.log(value)
            let addressnew = {
                line1: formState.address.line1,
                city: formState.address.city,
                state: formState.address.state,
                zipCode: value
            }
            setFormState({
                ...formState,
                address: addressnew
            })
            console.log(formState)
        } else if (evt.target.name == "displayBrandOn1031") {

            if ($("#displayBrandCheck").is(":checked")) {
                // $("#displayBrandCheck").addClass("is-invalid"); // unchecked
                setFormState({
                    ...formState,
                    displayBrandOn1031: true
                })

            } else {
                $("#defaultCheck1").removeClass("is-invalid");
                setFormState({
                    ...formState,
                    displayBrandOn1031: false
                })
            }

        } else if (evt.target.name == "totalCommission" && parseFloat(value) <= 100) {
            if (parseFloat(value) <= 100) {
                // Format the input value to have at least two decimal places
                // const formattedValue = parseFloat(value);
                setFormState({
                    ...formState,
                    [evt.target.name]: value
                });

            }
        }
        else {
            setFormState({
                ...formState,
                [evt.target.name]: value
            });
        }
        console.log(formState)
        // validateForm(evt.target.name, evt.target.value);
    }

    const validateForm = (name, value) => {
        name = 'check'
        let fieldName = ""
        // var validationerrorMessage = this.state.formerrorMessage;
        // var formvalid = this.state.formvalid;
        var validity = formValid
        var messages = formMessage


        if (fieldName == "brandName" || name == "check") {
            if (formState.brandName === "") {
                messages.brandName = "Enter the field";
                validity.brandName = false;
                messages.franchiseName = "Enter the field";
                validity.franchiseName = false;
                let message = {
                    brandType: "Enter the field",
                    brandName: "Enter the field",
                    franchiseType: "Enter the field",
                    franchiseName: "Enter the field"
                }
                let valid = {
                    brandType: false,
                    brandName: false,
                    franchiseType: false,
                    franchiseName: false
                }
                message.propertyType = message
                validity.propertyType = valid
                $(`[name=${"brandName"}]`).addClass("is-invalid");
            }
            else {
                messages.brandName = "";
                validity.brandName = true;
                messages.franchiseName = "";
                validity.franchiseName = true;
                let message = {
                    brandType: "",
                    brandName: "",
                    franchiseType: "",
                    franchiseName: ""
                }
                let valid = {
                    brandType: true,
                    brandName: true,
                    franchiseType: true,
                    franchiseName: true
                }
                message.propertyType = message
                validity.propertyType = valid
                $(`[name=${"brandName"}]`).removeClass("is-invalid");
            }
        }
        if (fieldName == "line1" || name == "check") {
            if (formState.address.line1 === "") {
                messages.address.line1 = "Enter the field";
                validity.address.line1 = false;
                $(`[name=${"line1"}]`).addClass("is-invalid");
            }
            else {
                messages.address.line1 = "";
                validity.address.line1 = true;
                $(`[name=${"line1"}]`).removeClass("is-invalid");
            }
        }
        if (fieldName == "city" || name == "check") {
            if (formState.address.city === "") {
                messages.address.city = "Enter the field";
                validity.address.city = false;
                $(`[name=${"city"}]`).addClass("is-invalid");
            }
            else {
                messages.address.city = "";
                validity.address.city = true;
                $(`[name=${"city"}]`).removeClass("is-invalid");
            }
        }
        if (fieldName == "zipCode" || name == "check") {
            if (formState.address.zipCode === "") {
                messages.address.zipCode = "Enter the field";
                validity.address.zipCode = false;
                $(`[name=${"zipCode"}]`).addClass("is-invalid");
            }
            else {
                messages.address.zipCode = "";
                validity.address.zipCode = true;
                $(`[name=${"zipCode"}]`).removeClass("is-invalid");
            }
        }
        if (fieldName == "state" || name == "check") {
            if (formState.address.state === "") {
                messages.address.state = "Enter the field";
                validity.address.state = false;
                $(`[name=${"state"}]`).addClass("is-invalid");
            }
            else {
                messages.address.state = "";
                validity.address.state = true;
                $(`[name=${"state"}]`).removeClass("is-invalid");
            }
        }

        if (fieldName == "yearBuilt" || name == "check") {
            if (formState.yearBuilt === "") {
                messages.yearBuilt = "Enter the field";
                validity.yearBuilt = false;
                $(`[name=${"yearBuilt"}]`).addClass("is-invalid");
            }
            else {
                messages.yearBuilt = "";
                validity.yearBuilt = true;
                $(`[name=${"yearBuilt"}]`).removeClass("is-invalid");
            }
        }

        if (fieldName == "askingPrice" || name == "check") {
            if (formState.askingPrice === "") {
                messages.askingPrice = "Enter the field";
                validity.askingPrice = false;
                $(`[name=${"asking-price-feedback"}]`).removeClass("display");

            }
            else {
                messages.askingPrice = "";
                validity.askingPrice = true;
                $(`[name=${"asking-price-feedback"}]`).addClass("display");
            }
        }
        if (fieldName == "noOfRooms" || name == "check") {
            if (formState.noOfRooms === "") {
                messages.noOfRooms = "Enter the field";
                validity.noOfRooms = false;
                $(`[name=${"noOfRooms"}]`).addClass("is-invalid");
            }
            else {
                messages.noOfRooms = "";
                validity.noOfRooms = true;
                $(`[name=${"noOfRooms"}]`).removeClass("is-invalid");
            }
        }


        console.log(validity)
        setFormMessage(messages);
        setFormValid(validity)
        let formready = validity.brandName && validity.franchiseName && validity.yearBuilt && validity.askingPrice && validity.propertyType.brandName && validity.propertyType.brandType && validity.propertyType.franchiseName && validity.propertyType.franchiseType && validity.address.line1 && validity.address.city && validity.address.state && validity.address.zipCode;
        setFormValidForSumisson(formready)
        return formready
    }

    const setFileUrls = () => {
        setFormState({
            ...formState,
            plpReport: localStorage.getItem("plpReportUrls"),
            additionalDocs: localStorage.getItem("additionFilesUrls"),
            lastMonthReport: localStorage.getItem("monthlyReportUrls"),
            last12MonthProfitReport: localStorage.getItem("profitReportUrls")
        })
    }

    const convertArrayBufferToString = (arrayBuffer) => {
        const textDecoder = new TextDecoder();
        const decodedText = textDecoder.decode(arrayBuffer);
        return decodedText;
    };

    const handleDownload = async (key, type) => {
        try {
            await getAppFileByKey(key).then(res => {
                const bytes = new Uint8Array(res.data);
                setPdfBytes(bytes)
                setOpenDocType(type)
            });
        } catch (error) {
            console.error("Error downloading file:", error);
            alert("Error downloading file");
        }
    };

    const handleTCDownload = async (key, type) => {
        try {
            await getAppFileByKey(key).then(res => {
                const bytes = new Uint8Array(res.data);
                setTermsAndConditionsPdf(bytes)

            });
        } catch (error) {
            console.error("Error downloading file:", error);
            alert("Error downloading file");
        }
    };

    const openDoc = async (key, type) => {

        await handleDownload(key, type).then((res => {

            setDocOpen(true)
        }));

    };



    const handleListingAggrement = (e) => {
        e.preventDefault()
        handleNext()
        setDocOpen(true)

        let isValid = validateForm("check", 0)

        const currentDate = new Date();

        const oneYearFromNow = new Date(currentDate);
        oneYearFromNow.setFullYear(currentDate.getFullYear() + 1);

        const formattedCurrentDate = currentDate.toISOString().slice(0, 10);
        const formattedOneYearDate = oneYearFromNow.toISOString().slice(0, 10);

        console.log(oneYearFromNow)

        if (isValid) {
            setUserFormDetails([user?.firstName + " " + user?.lastName, user?.companyName, formState?.propertyType?.brandName, formState?.propertyType?.franchiseName, formState?.address?.line1, formState?.askingPrice, formattedCurrentDate, formattedOneYearDate, user?.firstName?.toUpperCase() + " " + user?.lastName?.toUpperCase()])
            openDoc(Seller_Posting_Fee_Agreement, "pdf")
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();


        setFileUrls();

        if (validateForm("check", 0)) {

            let res = await registerPropertyforUser(formState)
            {
                sessionStorage.setItem("user_seller_id", res.data.id);
                sessionStorage.setItem("user_sellers", 1)
                res.status == 200 ?
                    Swal.fire({
                        title: 'We have received your information.',
                        icon: 'success',
                        html: `<div>
                        <h3 className="font-size-primary-24">An agent will contact you to finalize your posting</h3>
                        </br>    
                        <h4> You can contact us on </h4>    
                        <h4><b>info@1031hotels.com</b></h4>
                        <h4><b>661-808-4998</b></h4>
                    </div>
            `,
                        showCloseButton: false,
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        let userType = sessionStorage.getItem("user_type");
                        if (userType.includes("Broker")) {
                            window.location.href = "/propertyPost/" + res.data.id
                        } else {
                            window.location.href = `/sellerTutorial/` + res.data.id
                        }
                    })

                    :

                    toast.error(res.data?.message,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        }).then(() => {

                            window.location.href = "/addSeller"
                        });

            }

        } else {
            validateForm("check", 0)
        }

    }

    const getCalculations = (name) => {
        let askingPrice = formState.askingPrice ? parseInt(formState?.askingPrice) : 0;
        let pipEstimate = formState.pipEstimate ? parseInt(formState?.pipEstimate) : 0;
        let otherRevenue = formState.otherRevenue ? parseInt(formState?.otherRevenue) : 0;
        let fandbrevenue = formState.yearlyFNBRevenue ? parseInt(formState?.yearlyFNBRevenue) : 0;
        let roomRevenue = formState.roomRevenue ? parseInt(formState?.roomRevenue) : 0;
        let netOperatingRevenue = formState.netOperatingRevenue ? parseInt(formState?.netOperatingRevenue) : 0;
        let noOfRooms = formState.noOfRooms ? parseInt(formState?.noOfRooms) : 0;
        // console.log(fandbrevenue, roomRevenue, netOperatingRevenue, otherRevenue)
        if (name === "downPayment")
            return ((askingPrice) + (pipEstimate)) * 0.2;

        if (name === "totalPrice")
            return ((askingPrice) + (pipEstimate));

        if (name === "totalRevenue")
            return (otherRevenue) + (fandbrevenue) + (roomRevenue);

        if (name === "capRate")
            return netOperatingRevenue / askingPrice;

        if (name === "pricePerRoom")
            return (askingPrice + pipEstimate) / noOfRooms;

        // if (name === "revMultiplier")
        //     return askingPrice + pipEstimate / ((otherRevenue) + (fandbrevenue) + (roomRevenue))
    }

    const listItemStyle = {
        listStyleType: 'none',
        marginBottom: '10px', // Add additional styling as needed
    };


    const getFileName = (key) => {
        const parts = key.split('/');
        let filename = parts[parts.length - 1];
    
        filename = filename.split('.')[0]; // Truncate after first dot
        filename = filename.replace(/-/g, ' ')
            .replace(/%20/g, ' ')
            .replace(/,/g, ' ')
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/&/g, ' '); // Replace ampersand with underscore
    
        return filename;
    }
    //------------------------------------------------------------------------------------------------------------//
    const fileUrlarray = [];
    const handleImageFileChange = async (input) => {

        try {
            let files = input;

            for (var file of files) {

                const res = await uploadFiles(file, "propertyImages")

                fileUrlarray.push(res.data.key)
            }


            if (fileUrlarray.length <= 25) {
                if (formState.imagesPath == '') {
                    setFormState({
                        ...formState,
                        imagesPath: fileUrlarray.toString()
                    })
                } else {
                    setFormState({
                        ...formState,
                        imagesPath: formState.imagesPath + ',' + fileUrlarray.toString()
                    })
                }

                setFile(fileUrlarray);
            } else {
                Swal.fire(
                    "Images Limit Reached!!",
                    "Maximum of 25 images can be uploaded",
                    "warning"
                );
            }

        } catch (error) {
            console.log(error);
            
        }

    };


    const validateSelectedFile = (files) => {
        const MIN_FILE_SIZE = 1024 // 1MB
        const MAX_FILE_SIZE = 30720 // 30MB
        let valid = true
        files.map(selectedFile => {

            const fileSizeKiloBytes = selectedFile.size / 1024

            console.log(fileSizeKiloBytes)
            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File is too large!',
                    footer: '<span>File size should be less than 30MB</span>'
                })
                valid = false

            }

        })

        return valid
    };

    const getImages = () => {
        let images = []
        {
            file &&
                [...file].map(fileurl => {
                    images.push({
                        src: fileurl,
                        width: 260,
                        height: 174,
                        isSelected: true,
                    })

                })
            return images
        }

    }


    const getImage = async (key) => {
        let res = await getImageByKey(key);
        const base64String = btoa(
            new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
            )
        );
        return base64String
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(images);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setImages(items);

        // Update imagesPath in formState
        const newImagesPath = items.map(img => img.src.replace(S3_IMAGES, '')).join(',');

        // Call a function passed in as a prop to update formState
        // handleFormStateUpdate('imagesPath', newImagesPath);

        console.log(newImagesPath)
    };


    const getImageCards = (keys) => {
        let images = []
        {
            keys?.split(',').map(imageUrl => {
                if (imageUrl != "") {
                    // let imagedata = await getImage(filekey)
                    images.push({
                        src: S3_IMAGES + imageUrl,
                        width: 260,
                        height: 174,
                        // isSelected: true,
                        customOverlay: <button type="button" className="btn" ><FaWindowClose className="btn-light" /></button>
                    })
                }
            })
            // setImageBytes(images)
            console.log(images)
            return images
        }

    }


    const getMaxYearLimit = () => {
        let now = new Date().getFullYear()
        return now + 1
    }

    const handleFileChange = async (e) => {

        let name = e.target.name
        let fileList = e.target.files;
        let files = fileList ? [...fileList] : [];
        console.log(fileList)
        if (validateSelectedFile(files)) {
            if (name == "last12MonthProfitReport") {

                // setMonthProfitFiles(files)
                // console.log(e.target.files)
                // setFileList(e.target.files);
                handleUploadClick(name, files);
                $(`[name=${name}]`).removeClass("is-invalid");
            }

            if (name == "lastMonthReport") {
                // const files = fileList ? [...fileList] : [];
                // files.forEach((file, i) => {
                //     data.append(`file-${i}`, file, file.name);
                // });
                // setLastMonthFiles(files)
                // setFileList(e.target.files);
                handleUploadClick(name, files);
                $(`[name=${name}]`).removeClass("is-invalid");
            }

            if (name == "plpReport") {
                // const files = fileList ? [...fileList] : [];
                // files.forEach((file, i) => {
                //     data.append(`file-${i}`, file, file.name);
                // });
                // setPlpReportFiles(files)
                // setFileList(e.target.files);
                handleUploadClick(name, files);
                $(`[name=${name}]`).removeClass("is-invalid");
            }

            if (name == "additionalDocs") {
                // const files = fileList ? [...fileList] : [];
                // files.forEach((file, i) => {
                //     data.append(`file-${i}`, file, file.name);
                // });

                // setFileList(e.target.files);
                handleUploadClick(name, files);
                $(`[name=${name}]`).removeClass("is-invalid");
            }

        } else {
            $(`[name=${name}]`).addClass("is-invalid");
        }

    };

    // const handleUploadClick = async (name, files) => {
    //     setUploadButtonFlag(true)



    //     if (name == "last12MonthProfitReport") {
    //         setUploadBtnState({ ...uploadBtnState, last12MonthProfitReport: true })
    //         // let files = monthProfitFiles ? [...monthProfitFiles] : [];
    //         for (var file of files) {
    //             let fileObj = {}
    //             try {
    //                 setUploadButtonFlag(true)
    //                 const res = await uploadFiles(file, "propertyDocs")
    //                 if (res) $('#last12MonthProfitReport').addClass('is-valid')
    //                 { res.status == 200 ? setUploadButtonFlag(false) : <></> }
    //                 { res.status == 200 ? setUploadBtnState({ ...uploadBtnState, last12MonthProfitReport: false }) : <></> }

    //                 fileObj = {
    //                     id: res?.data.fileName,
    //                     key: res.data.key
    //                 }
    //                 let prevDocs = monthProfitFiles
    //                 prevDocs.push(fileObj)

    //                 { res.status == 200 ? setMonthProfitFiles(prevDocs) : <></> }
    //                 setUploadButtonFlag(false)

    //                 setTimeout(() => {
    //                     if (res) $('#last12MonthProfitReport').removeClass('is-valid')
    //                     document.getElementById("last12MonthProfitReport").value = "";
    //                     setUploadButtonFlag(false)
    //                 }, 2000)

    //                 $('#last12MonthProfitReport').removeClass('is-invalid')
    //             } catch (error) {
    //                 $('#last12MonthProfitReport').addClass('is-invalid')
    //                 $('#last12MonthProfitReport').removeClass('is-valid')
    //                 setUploadButtonFlag(false)
    //                 setUploadBtnState({ ...uploadBtnState, last12MonthProfitReport: false })
    //             }
    //         }

    //         if (monthProfitFiles.length <= 25) {
    //             {
    //                 formState.last12MonthProfitReport != '' ?
    //                     setFormState({
    //                         ...formState,
    //                         last12MonthProfitReport: formState.last12MonthProfitReport + ',' + fileObj.key.toString()
    //                     })
    //                     :
    //                     setFormState({
    //                         ...formState,
    //                         last12MonthProfitReport: fileObj.key.toString()
    //                     })

    //             }

    //         } else {
    //             Swal.fire(
    //                 "Images Limit Reached!!",
    //                 "Maximum of 25 images can be uploaded",
    //                 "warning"
    //             );
    //         }
    //         console.log(formState)
    //     }

    //     if (name == "lastMonthReport") {

    //         setUploadBtnState({ ...uploadBtnState, lastMonthReport: false })
    //         for (var file of files) {
    //             let fileObj = {}
    //             try {
    //                 setUploadButtonFlag(true)
    //                 const res = await uploadFiles(file, "propertyDocs")
    //                 if (res) $('#lastMonthReport').addClass('is-valid')
    //                 { res.status == 200 ? setUploadButtonFlag(false) : <></> }
    //                 { res.status == 200 ? setUploadBtnState({ ...uploadBtnState, lastMonthReport: false }) : <></> }
    //                 fileObj = {
    //                     id: res?.data.fileName,
    //                     key: res.data.key
    //                 }
    //                 let prevDocs = lastMonthFiles
    //                 prevDocs.push(fileObj)

    //                 { res.status == 200 ? setLastMonthFiles(prevDocs) : <></> }

    //                 setTimeout(() => {
    //                     if (res) $('#lastMonthReport').removeClass('is-valid')
    //                     document.getElementById("lastMonthReport").value = "";
    //                     setUploadButtonFlag(false)
    //                 }, 2000)

    //                 $('#lastMonthReport').removeClass('is-invalid')
    //             } catch (error) {
    //                 $('#lastMonthReport').addClass('is-invalid')
    //                 $('#lastMonthReport').removeClass('is-valid')
    //                 setUploadButtonFlag(false)
    //                 setUploadBtnState({ ...uploadBtnState, lastMonthReport: false })
    //             }

    //         }

    //         if (lastMonthFiles.length <= 25) {
    //             {
    //                 formState.lastMonthReport != '' ?
    //                     setFormState({
    //                         ...formState,
    //                         lastMonthReport: formState.lastMonthReport + ',' + fileObj?.key.toString()
    //                     })
    //                     :
    //                     setFormState({
    //                         ...formState,
    //                         lastMonthReport: fileObj?.key?.toString()
    //                     })
    //             }

    //         } else {
    //             Swal.fire(
    //                 "Images Limit Reached!!",
    //                 "Maximum of 25 images can be uploaded",
    //                 "warning"
    //             );
    //         }
    //     }

    //     if (name == "plpReport") {

    //         setUploadBtnState({ ...uploadBtnState, plpReport: true })
    //         for (var file of files) {
    //             let fileObj = {}
    //             try {
    //                 setUploadButtonFlag(true)
    //                 const res = await uploadFiles(file, "propertyDocs")
    //                 if (res) $('#plpReport').addClass('is-valid')
    //                 { res.status == 200 ? setUploadButtonFlag(false) : <></> }
    //                 { res.status == 200 ? setUploadBtnState({ ...uploadBtnState, plpReport: false }) : <></> }
    //                 fileObj = {
    //                     id: res?.data.fileName,
    //                     key: res.data.key
    //                 }

    //                 let prevDocs = plpReportFiles
    //                 prevDocs.push(fileObj)

    //                 { res.status == 200 ? setPlpReportFiles(prevDocs) : <></> }

    //                 setTimeout(() => {
    //                     if (res) $('#plpReport').removeClass('is-valid')
    //                     document.getElementById("plpReport").value = "";
    //                     setUploadButtonFlag(false)
    //                 }, 2000)

    //                 $('#plpReport').removeClass('is-invalid')
    //             } catch (error) {
    //                 $('#plpReport').addClass('is-invalid')
    //                 $('#plpReport').removeClass('is-valid')
    //                 setUploadButtonFlag(false)
    //                 setUploadBtnState({ ...uploadBtnState, plpReport: false })
    //             }

    //         }

    //         if (plpReportFiles.length <= 25) {
    //             {
    //                 formState.plpReport != 0 ?
    //                     setFormState({
    //                         ...formState,
    //                         plpReport: formState.plpReport + ',' + fileObj.key.toString()
    //                     })
    //                     :
    //                     setFormState({
    //                         ...formState,
    //                         plpReport: fileObj?.key?.toString()
    //                     })
    //             }


    //         } else {
    //             Swal.fire(
    //                 "Images Limit Reached!!",
    //                 "Maximum of 25 images can be uploaded",
    //                 "warning"
    //             );
    //         }
    //     }

    //     if (name == "additionalDocs") {


    //         setUploadBtnState({ ...uploadBtnState, additionalDocs: true })
    //         for (var file of files) {
    //             let fileObj = {}
    //             try {

    //                 setUploadButtonFlag(true)
    //                 const res = await uploadFiles(file, "propertyDocs")
    //                 if (res) $('#additionalDocs').addClass('is-valid')
    //                 { res.status == 200 ? setUploadButtonFlag(false) : <></> }
    //                 { res.status == 200 ? setUploadBtnState({ ...uploadBtnState, additionalDocs: false }) : <></> }
    //                 fileObj = {
    //                     id: res?.data.fileName,
    //                     key: res.data.key
    //                 }
    //                 let prevDocs = addDocasFiles
    //                 prevDocs.push(fileObj)

    //                 { res.status == 200 ? setAddDocsFiles(prevDocs) : <></> }
    //                 setUploadButtonFlag(false)

    //                 setTimeout(() => {
    //                     if (res) $('#additionalDocs').removeClass('is-valid')
    //                     document.getElementById("additionalDocs").value = "";
    //                     setUploadButtonFlag(false)
    //                 }, 2000)

    //                 $('#additionalDocs').removeClass('is-invalid')

    //             } catch (error) {
    //                 $('#additionalDocs').addClass('is-invalid')
    //                 $('#additionalDocs').removeClass('is-valid')
    //                 setUploadButtonFlag(false)
    //                 setUploadBtnState({ ...uploadBtnState, additionalDocs: false })
    //             }

    //         }

    //         if (addDocasFiles.length <= 25) {
    //             {

    //                 formState.additionalDocs != '' ?
    //                     setFormState({
    //                         ...formState,
    //                         additionalDocs: formState.additionalDocs + ',' + fileObj.key.toString()
    //                     })
    //                     :
    //                     setFormState({
    //                         ...formState,
    //                         additionalDocs: fileObj?.key?.toString()
    //                     })
    //             }


    //         } else {
    //             Swal.fire(
    //                 "Images Limit Reached!!",
    //                 "Maximum of 25 images can be uploaded",
    //                 "warning"
    //             );
    //         }
    //     }

    //     console.log(formState)
    // };

    const handleUploadClick = async (name, files) => {
        setUploadButtonFlag(true);

        let fileObjs = [];
        let prevDocs = [];

        for (var file of files) {
            try {
                setUploadButtonFlag(true);
                const res = await uploadFiles(file, "propertyDocs");

                if (res) {
                    $('#' + name).addClass('is-valid');

                    if (res.status === 200) {
                        setUploadButtonFlag(false);
                        setUploadBtnState({ ...uploadBtnState, [name]: false });

                        fileObjs.push({
                            fileName: res?.data?.fileName,
                            key: res?.data?.key,
                            user:{
                                id: user?.id
                            }
                        });

                
                        

                        // Update the array based on the document type
                        switch (name) {
                            case "last12MonthProfitReport":
                                prevDocs = monthProfitFiles;
                                setMonthProfitFiles([...prevDocs, ...fileObjs]);
                                break;
                            case "lastMonthReport":
                                prevDocs = lastMonthFiles;
                                setLastMonthFiles([...prevDocs, ...fileObjs]);
                                break;
                            case "plpReport":
                                prevDocs = plpReportFiles;
                                setPlpReportFiles([...prevDocs, ...fileObjs]);
                                break;
                            case "additionalDocs":
                                prevDocs = addDocasFiles;
                                setAddDocsFiles([...prevDocs, ...fileObjs]);
                                break;
                            
                            default:
                                break;
                        }

                        setTimeout(() => {
                            $('#' + name).removeClass('is-valid');
                            document.getElementById(name).value = "";
                            setUploadButtonFlag(false);
                        }, 2000);

                        $('#' + name).removeClass('is-invalid');
                    }
                }
            } catch (error) {
                $('#' + name).addClass('is-invalid');
                $('#' + name).removeClass('is-valid');
                setUploadButtonFlag(false);
                setUploadBtnState({ ...uploadBtnState, [name]: false });
            }
        }

        // Update the form state based on the document type
        if (fileObjs.length <= 25) {
            // const keys = fileObjs.map((fileObj) => fileObj.key.toString()).join(',');

            switch (name) {
                case "last12MonthProfitReport":
                    setFormState({
                        ...formState,
                        last12MonthProfitReports: [...prevDocs, ...fileObjs]
                    });
                    break;
                case "lastMonthReport":
                    setFormState({
                        ...formState,
                        lastMonthReports: [...prevDocs, ...fileObjs]
                    });
                    break;
                case "plpReport":
                    setFormState({
                        ...formState,
                        plpReports: [...prevDocs, ...fileObjs]
                    });
                    break;
                case "additionalDocs":
                    setFormState({
                        ...formState,
                        additionalDocsList: [...prevDocs, ...fileObjs]
                    });
                    break;
                
                default:
                    break;
            }
        } else {
            Swal.fire(
                "Images Limit Reached!!",
                "Maximum of 25 images can be uploaded",
                "warning"
            );
        }

        console.log(formState);
    };


    const handleRemoveFile = (e, fileName, file) => {
        e.preventDefault()
        console.log(fileName, file)
        let filesArray = formState[fileName]
        // console.log(filesArray)
        let fileIndex = filesArray?.indexOf(file)
        if (fileIndex >= 0) {
            filesArray.splice(fileIndex, 1);
        }
        // console.log(filesArray)
        setFormState({
            ...formState,
            [fileName]: filesArray
        })

        if (fileName == "imagesPath") {

            const indexOfFiles = file.indexOf('files');
            let trimmedUrl = ''
            if (indexOfFiles !== -1) {
                trimmedUrl = file.substring(indexOfFiles);
                console.log(trimmedUrl);
            } else {
                console.log('"/files" not found in the URL.');
                trimmedUrl = file;
            }

            let filesArray = formState[fileName]?.split(',')
            let fileIndex = filesArray?.indexOf(trimmedUrl)
            if (fileIndex >= 0) {
                filesArray.splice(fileIndex, 1);
            }
            setFormState({
                ...formState,
                [fileName]: filesArray?.toString()
            })
        }

        if (fileName == "last12MonthProfitReport") {
            let fileIndex = monthProfitFiles.findIndex(removefile => removefile.key == file?.key);
            if (fileIndex >= 0) {
                monthProfitFiles.splice(fileIndex, 1);
            }

        }

        if (fileName == "lastMonthReport") {
            let fileIndex = lastMonthFiles.findIndex(removefile => removefile.key == file?.key);
            if (fileIndex >= 0) {
                lastMonthFiles.splice(fileIndex, 1);
            }
        }

        if (fileName == "plpReport") {
            let fileIndex = plpReportFiles.findIndex(removefile => removefile.key == file?.key);
            if (fileIndex >= 0) {
                plpReportFiles.splice(fileIndex, 1);
            }
        }

        if (fileName == "additionalDocs") {
            let fileIndex = addDocasFiles.findIndex(removefile => removefile.key == file?.key);
            if (fileIndex >= 0) {
                addDocasFiles.splice(fileIndex, 1);
            }
        }

        console.log(formState)
    }

    const handleRemoveImage = (e, fileName, key) => {
        e.preventDefault()
        console.log(fileName, key)
        let filesArray = formState[fileName]?.split(',')
        // console.log(filesArray)
        let fileIndex = filesArray?.indexOf(key)
        if (fileIndex >= 0) {
            filesArray.splice(fileIndex, 1);
        }
        // console.log(filesArray)
        setFormState({
            ...formState,
            [fileName]: filesArray?.toString()
        })

        if (fileName == "imagesPath") {

            const indexOfFiles = key.indexOf('files');
            let trimmedUrl = ''
            if (indexOfFiles !== -1) {
                trimmedUrl = key.substring(indexOfFiles);
                console.log(trimmedUrl);
            } else {
                console.log('"/files" not found in the URL.');
                trimmedUrl = key;
            }

            let filesArray = formState[fileName]?.split(',')
            let fileIndex = filesArray?.indexOf(trimmedUrl)
            if (fileIndex >= 0) {
                filesArray.splice(fileIndex, 1);
            }
            setFormState({
                ...formState,
                [fileName]: filesArray?.toString()
            })
        }

    }


    const handleSelect = (index, item, event) => {
        console.log(index)
        const nextImages = getImages().map((image, i) =>
            i === index ? handleRemoveFile("imagesPath", image.src) : <></>
        );
        // setImages(nextImages);
    };

    const handleSellerName = (user) => {
        console.log(user)
        setFormState({
            ...formState,
            sellerName: user?.firstName + ' ' + user?.lastName, primaryState: user?.stateLicensed,
            sellerMobileNumber: user?.mobileNumber,
            sellerEmail: user?.email,
            sellerCompanyName: user?.companyName
        })


    }

    return (
        <>
            {page === 1 && (
                <div className='card-body'>
                    <div className="form-body">
                        <div className='borderWithText'>
                            <div className={"row"}>
                                <div className="form-group col-6">
                                    <label>SELLER NAME</label>
                                    <input id="sellerName" className="form-input" name='sellerName' disabled={(user?.userTypes.includes("Broker") || user?.userTypes.includes("Agent")) ? false : true} value={formState.sellerName} onChange={handleChange} required="" maxLength={128}>
                                    </input>
                                    <div className="invalid-feedback">
                                        Select Franchise
                                    </div>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className="form-group col-6">
                                    <label>HOTEL FRANCHISE<sup>**</sup></label>
                                    <select id="hotelbrand" className={formValid.propertyType?.brandName == null || formValid.propertyType?.brandName ? "form-input form-select " : "form-input form-select is-invalid"} name='brandName' onChange={handleChange} value={formState.propertyType.id} required="" >
                                    </select>
                                    <div className="invalid-feedback">
                                        {!formValid.propertyType.brandName ? (
                                            <>Please Enter the Field</>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                    <label>FRANCHISE PARENT COMPANY<sup>**</sup></label>
                                    <input id="hotelfranchise" className="form-input" name='franchiseName' onChange={handleChange} value={formState.propertyType.franchiseName} required="" disabled maxLength={128}>
                                    </input>
                                    <div className="invalid-feedback">
                                        Select Franchise
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="">
                                    <label className='font-size-primary-14 py-1'>
                                        <sup>**</sup>(HOTEL BRAND WOULD REMAIN CONFIDENTIAL UNLESS YOU AUTHORIZE US TO DISPLAY THE FRANCHISE NAME)
                                    </label>
                                </div>

                                <div className="form-group mb-2">
                                    <div className="form-check">
                                        <input className="form-check-input" name='displayBrandOn1031' type="checkbox" id="displayBrandCheck" onChange={handleChange} value={formState.displayBrandOn1031} />
                                        <label className="form-check-label" htmlFor="displayBrandCheck">
                                            Display Brand Name publically on 1031 Exchange website
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group col-12 col-lg-6 ">
                                    <label>MARKET TYPE</label>
                                    <select id="marketType" className={formValid.marketType == null || formValid?.marketType ? "form-input form-select" : "form-input form-select is-invalid"} name='marketType' onChange={handleChange} value={formState?.marketType} required="">
                                    </select>
                                    <div className="invalid-feedback">
                                        {!formValid.marketType ? (
                                            <>Please Enter the Field</>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="form-group col-12 col-lg-6">
                                    <label>INVESTMENT TYPE</label>
                                    <select id="opportunityType" className={formValid.opportunityType == null || formValid?.opportunityType ? "form-input form-select" : "form-input form-select is-invalid"} name='opportunityType' onChange={handleChange} value={formState.opportunityType} required="">
                                    </select>
                                    <div className="invalid-feedback">
                                        {!formValid.opportunityType ? (
                                            <>Please Enter the Field</>
                                        ) : null}
                                    </div>
                                </div>


                                <div className="form-group">
                                    <label>ADDRESS</label>
                                    <input type="text" className={formValid.address.line1 == null || formValid.address?.line1 ? "form-input" : "form-input is-invalid"} name='line1' required="" onChange={handleChange} value={formState.address.line1} maxLength={256} />
                                    <div className="invalid-feedback">
                                        {!formValid.address.line1 ? (
                                            <>Please Enter the Field</>
                                        ) : null}
                                    </div>
                                </div>


                            </div>
                            <div className="row">
                                <div className="form-group col-12 col-lg-3">
                                    <label>CITY</label>
                                    <input type="text" className={formValid.address.city == null || formValid.address?.city ? "form-input" : "form-input is-invalid"} name='city' onChange={handleChange} value={formState.address.city} required="" maxLength={128} />
                                    <div className="invalid-feedback">
                                        {!formValid.address.city ? (
                                            <>Please Enter the Field</>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group col-12 col-lg-3">
                                    <label>ZIP CODE</label>
                                    <input type="text" className={formValid.address.zipCode == null || formValid.address?.zipCode ? "form-input" : "form-input is-invalid"} name='zipCode' onChange={handleChange} value={formState.address.zipCode} required="" maxLength={5} />
                                    <div className="invalid-feedback">
                                        {!formValid.address.zipCode ? (
                                            <>Please Enter the Field</>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group col-12 col-lg-6">
                                    <label>HOTEL DETAILS WEB LINK</label>
                                    <input type="text" className={formValid.website == null || formValid.website ? "form-input" : "form-input is-invalid"} name='website' onChange={handleChange} value={formState.website} required="" maxLength={128} />
                                    <div className="invalid-feedback">
                                        {!formValid.website ? (
                                            <>Please Enter the Field</>
                                        ) : null}
                                    </div>

                                </div>

                            </div>
                        </div>


                        <br></br>

                        <div className={"row"}>
                            <div className="form-group col-12 col-lg-3">
                                <label>STATE</label>
                                <select id="sellerStates" className={formValid.address.state == null || formValid.address?.state ? "form-input form-select" : "form-input form-select is-invalid"} name='state' onChange={handleChange} value={formState.address.state.id} required="">
                                </select>
                                <div className="invalid-feedback">
                                    {!formValid.address.state ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group col-12 col-lg-3">
                                <label>NEAREST MSA</label>
                                {msaArray.length == 0 ? <input type="text" className="form-input" required="" placeholder='Choose State' /> :
                                    <select id="nearestMSA" className={formValid.nearestMSA == null || formValid?.nearestMSA ? "form-input form-select" : "form-input form-select is-invalid"} name='nearestMSA' onChange={handleChange} value={formState.nearestMSA} disabled={msaArray.length == 0} required="">
                                        <option value="" selected="selected" disabled hidden>Please Select</option>
                                        {msaArray.map((msa, key) => {
                                            return <option key={key} value={msa}>{msa}</option>
                                        })}
                                    </select>
                                }
                                <div className="invalid-feedback">
                                    {!formValid.nearestMSA ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group col-12 col-lg-6">
                                <label>LOAN TYPE</label>
                                <select id="loantype" className={formValid.loanType == null || formValid?.loanType ? "form-input form-select" : "form-input form-select is-invalid"} name='loanType' onChange={handleChange} value={formState.loanType} required="">
                                </select>
                                <div className="invalid-feedback">
                                    {!formValid.loanType ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>

                        </div>
                        <div className={"row"}>
                            <div className="form-group col-6">
                                <label>HOTEL BRAND SCALE</label>
                                <input id="hotelbrandtype" className="form-input" name='hotelbrandtype' onChange={handleChange} value={formState.propertyType.brandType} required="" disabled maxLength={128}>
                                </input>
                                <div className="invalid-feedback">
                                    Select Brand
                                </div>
                            </div>
                            <div className="form-group col-6">
                                <label>MARKET SEGMENT</label>
                                <input id="hotelfranchisetype" className="form-input" name='franchisetype' onChange={handleChange} value={formState.propertyType.franchiseType} required="" disabled maxLength={128}>
                                </input>
                                <div className="invalid-feedback">
                                    Select Franchise
                                </div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className="form-group col-12 col-lg-4">
                                <label>YEAR BUILT</label>
                                <input id="yearbuilt" type="number" min="1400" max={getMaxYearLimit} className={formValid.yearBuilt == null || formValid?.yearBuilt ? "form-input" : "form-input is-invalid"} name='yearBuilt' onChange={handleChange} value={formState.yearBuilt} required="">
                                </input>
                                <div className="invalid-feedback">
                                    {!formValid.yearBuilt ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group col-12 col-lg-4">
                                <label>YEAR RENOVATED</label>
                                <input id="yearrenovated" type="number" min="1400" max={getMaxYearLimit} className={formValid.lastRenovatedYear == null || formValid?.lastRenovatedYear ? "form-input" : "form-input is-invalid"} name='lastRenovatedYear' onChange={handleChange} value={formState.lastRenovatedYear} required="">
                                </input>
                                <div className="invalid-feedback">
                                    {!formValid.lastRenovatedYear ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group col-12 col-lg-4">
                                <label>ROOM COUNT</label>
                                <input type="number" className={formValid.noOfRooms == null || formValid.noOfRooms ? "form-input" : "form-input is-invalid"} name='noOfRooms' onChange={handleChange} value={formState.noOfRooms} required="" maxLength={128} />
                                <div className="invalid-feedback">
                                    {!formValid.noOfRooms ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        {/* <div class="custom-file-container" data-upload-id="myFirstImage"></div> */}
                        <div className={"row"}>
                            <div className="form-group col-12 col-lg-4">
                                <label>ASKING PRICE</label>
                                <div className="input-container d-flex align-item-center">
                                    <span class="currency-symbol"><PiCurrencyDollarSimpleBold></PiCurrencyDollarSimpleBold></span>
                                    <CurrencyInput className={formValid.askingPrice == null || formValid?.askingPrice ? `currency-input form-input remove-left-border-radius ` : `currency-input form-input remove-left-border-radius is-invalid`} name='askingPrice' onValueChange={handleCurrencyChange} value={formState.askingPrice} required="" maxLength={128} />
                                </div>
                                {/* <div for="askingPrice" className="invalid-feedback">
                                    { formValid.askingPrice != null && !formValid?.askingPrice ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div> */}

                                <div className="invalid-feedback-span">
                                    <div id="asking-price-feedback" name="asking-price-feedback" className='display'>
                                        <>Please Enter the Field</>
                                    </div>
                                </div>

                            </div>
                            <div className="form-group col-12 col-lg-4">
                                <label>PIP ESTIMATE</label>
                                <div className="input-container d-flex align-item-center">
                                    <span class="currency-symbol"><PiCurrencyDollarSimpleBold></PiCurrencyDollarSimpleBold></span>
                                    <CurrencyInput className={formValid.pipEstimate == null || formValid?.pipEstimate ? "currency-input form-input remove-left-border-radius " : "currency-input form-input remove-left-border-radius is-invalid"} name='pipEstimate' onValueChange={handleCurrencyChange} value={formState.pipEstimate} required="" maxLength={128} />
                                </div>
                                <div className="invalid-feedback">
                                    {!formValid.pipEstimate ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group col-12 col-lg-4">
                                <label>TOTAL PRICE</label>
                                <div className="input-container d-flex align-item-center">
                                    <span class="currency-symbol"><PiCurrencyDollarSimpleBold></PiCurrencyDollarSimpleBold></span>
                                    <CurrencyInput className={"currency-input form-input remove-left-border-radius"} name='totalPrice' disabled value={getCalculations("totalPrice")} required="" maxLength={128} />
                                </div>
                                <div className="invalid-feedback">
                                    {!formValid.pipEstimate ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>


                            {/* <div className="form-group col-12 col-lg-4">
                            <label>Occupancy t-12 %</label>
                            <input type="text" className={formValid.yearlyOccupancy == null || formValid?.yearlyOccupancy ? "form-input " : "form-input is-invalid"} name='yearlyOccupancy' min={0} max={100} onChange={handleChange} placeholder={"%"} value={formState.yearlyOccupancy} required="" maxLength={128} ></input>
                            <div className="invalid-feedback">
                                {!formValid.yearlyOccupancy ? (
                                    <>Please Enter the Field</>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group col-12 col-lg-4">
                            <label>ADR t-12</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <CurrencyInput className={formValid.yearlyADR == null || formValid.yearlyADR ? "form-input" : "form-input is-invalid"} name='yearlyADR' value={formState.yearlyADR} onValueChange={handleCurrencyChange} required="" maxLength={128} />
                            </div>
                            <div className="invalid-feedback">
                                {!formValid.yearlyADR ? (
                                    <>Please Enter the Field</>
                                ) : null}
                            </div>

                        </div>
                        <div className="form-group col-12 col-lg-4">
                            <label>Room Revenue t-12</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <CurrencyInput id='roomRevenue' className={formValid.roomRevenue == null || formValid?.roomRevenue ? "form-input " : "form-input is-invalid"} name='roomRevenue' onValueChange={handleCurrencyChange} value={formState.roomRevenue} required="">
                                </CurrencyInput>
                            </div>
                            <div className="invalid-feedback">
                                {!formValid.roomRevenue ? (
                                    <>Please Enter the Field</>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group col-12 col-lg-4">
                            <label>Food and Beverage Revenue</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <CurrencyInput id='yearlyFNBRevenue' className={formValid.yearlyFNBRevenue == null || formValid?.yearlyFNBRevenue ? "form-input " : "form-input is-invalid"} name='yearlyFNBRevenue' onValueChange={handleCurrencyChange} value={formState.yearlyFNBRevenue} required="">
                                </CurrencyInput>
                            </div>
                            <div className="invalid-feedback">
                                {!formValid.netOperatingRevenue ? (
                                    <>Please Enter the Field</>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group col-12 col-lg-4">
                            <label>Other Revenue</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <CurrencyInput id='otherRevenue' className={formValid.otherRevenue == null || formValid?.otherRevenue ? "form-input " : "form-input is-invalid"} name='otherRevenue' onValueChange={handleCurrencyChange} value={formState.otherRevenue} required="">
                                </CurrencyInput>
                            </div>
                            <div className="invalid-feedback">
                                {!formValid.netOperatingRevenue ? (
                                    <>Please Enter the Field</>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-group col-12 col-lg-4">
                            <label>Total Revenue</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <CurrencyInput id='otherRevenue' className={formValid.otherRevenue == null || formValid?.otherRevenue ? "form-input " : "form-input is-invalid"} name='totalRevenue' value={getCalculations("totalRevenue")} disabled required="">
                                </CurrencyInput>
                            </div>
                            <div className="invalid-feedback">
                                {!formValid.netOperatingRevenue ? (
                                    <>Please Enter the Field</>
                                ) : null}
                            </div>
                        </div> 
                        <div className="form-group col-12 col-lg-4">
                            <label>Net Operating Income</label>
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">$</span>
                                </div>
                                <CurrencyInput id='netIncome' className={formValid.netIncome == null || formValid?.netIncome ? "form-input " : "form-input is-invalid"} name='netIncome' onValueChange={handleCurrencyChange} value={formState.netIncome} required="">
                                </CurrencyInput>
                            </div>
                            <div className="invalid-feedback">
                                {!formValid.netOperatingRevenue ? (
                                    <>Please Enter the Field</>
                                ) : null}
                            </div>
                        </div>*/}


                        </div>
                    </div>
                    {JSON.parse(sessionStorage.getItem("user_type")).includes("Broker") ?
                        <div className={"row"}>
                            <div className="form-group col-12 col-lg-4">
                                <label>TOTAL COMMISSON CHARGING THE SELLER</label>
                                <div className="input-container d-flex align-item-center">
                                    <span class="currency-symbol"><MdOutlinePercent /></span>
                                    <input type="text" id='totalCommission' className={formValid.totalCommission == null || formValid?.totalCommission ? `currency-input form-input remove-left-border-radius ` : `currency-input form-input remove-left-border-radius is-invalid`} name='totalCommission' pattern="^\d{1,2}(\.\d{1,2})?%" min={0} max={100} placeholder={"%"}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            setFormState({
                                                ...formState,
                                                [e.target.name]: parseFloat(e.target.value).toFixed(2)
                                            });
                                        }}
                                        value={formState?.totalCommission} required=""></input>
                                </div>


                                <div className="invalid-feedback-span">
                                    <div id="totalCommission-feedback" name="totalCommission-feedback" className='display'>
                                        <>Please Enter the Field</>
                                    </div>
                                </div>

                            </div>
                        </div>
                        : <></>}



                    <div className="form-group">
                        <label>ADDITIONAL INFORMATION ABOUT YOUR HOTEL</label>
                        <textarea
                            className="form-input"
                            required=""
                            id="description"
                            name="description"
                            maxLength={256}
                            onChange={handleChange}
                        ></textarea>
                        <div className="invalid-feedback">What do you wanna say?</div>
                    </div>

                    <div className='row' style={{ width: "25%", float: 'right', textAlign: "right" }} >
                        <div className='form-group  col'>
                            <button className="btn btn-outline-cancel" ariaDisabled="true">
                                <a href={redirect_url} className='fill-label-red'>CANCEL</a>
                            </button>
                        </div>

                        <div className='form-group col'>
                            <button className='btn btn-solid' style={{ backgroundColor: 'black', float: 'right', padding: "13px" }} onClick={handleNext} ariaHidden="false">
                                <span style={{ color: "white", textDecoration: "none" }}>NEXT</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {page === 2 && (
                <>
                    <div className="form-group col-12 py-4">
                        <label>ANY NEW HOTEL PHOTOS YOU HAVE</label>
                        <FileUploader
                            multiple={true}
                            handleChange={handleImageFileChange}
                            name="file"
                            label="Upload Hotel Images Here"
                            types={fileTypes}
                            maxSize={30}
                            minSize={0}
                            onSizeError={() => {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'File is too large!',
                                    footer: '<span>File size should be less than 30MB</span>'
                                }
                                );
                            }}

                        />

                        {/* {formState?.imagesPath != '' ? <><Gallery images={setImageCards(formState?.imagesPath)} enableImageSelection={true} onSelect={handleSelect} /></> : <></>} */}
                        <br></br>

                        {formState?.imagesPath != '' ?
                            <div>
                                <ImageGalleryEditor initialImageSrcs={formState?.imagesPath} formSubmissable={(newImages) => {

                                    setFormState({
                                        ...formState,
                                        imagesPath: newImages
                                    })

                                    setFormValidForSumisson(true)
                                }}></ImageGalleryEditor>

                            </div>
                            : <></>}
                        <br></br>

                        {file &&
                            [...file].map(file => {
                                <p>{file ? `File name: ${file.name}` : "no files uploaded yet"}</p>
                            })}
                    </div>
                    <div className='row' style={{ width: "50%", float: 'right', textAlign: "right" }} >
                        <div className='form-group  col'>
                            <button className="btn btn-outline-cancel" ariaDisabled="true">
                                <a href={redirect_url} className='fill-label-red'>CANCEL</a>
                            </button>
                        </div>

                        <div className='form-group  col'>
                            <button className='btn btn-outline' onClick={handlePrevious} ariaDisabled="true">
                                <a className="fill-label-blue font-bold">BACK</a>
                            </button>
                        </div>

                        <div className='form-group col'>
                            <button className='btn btn-solid' style={{ backgroundColor: 'black', float: 'right', padding: "13px" }} onClick={handleNext} ariaHidden="false">
                                <span style={{ color: "white", textDecoration: "none" }}>NEXT</span>
                            </button>
                        </div>
                    </div>
                </>

            )}

            {page === 3 && (
                <div className='card-body' >
                    <div className='form-body'>
                        {/* <FileUploadWithPreview></FileUploadWithPreview> */}
                        {/* <div class="custom-file-container" data-upload-id="myFirstImage"></div> */}
                        <div className={"row form-group"}>

                            <div className="py-2">
                                <span className='font-size-primary-14'>(Please provide us the following reports so that we can properly value your hotel and post on our confidential platform)</span>
                            </div>
                            <label className='fill-blue'>DECEMBER MONTHLY STR REPORT FOR PREVIOUS 3 YEARS AND CURRENT MONTH : </label>

                            <div className="row">
                                <div className="form-group col-6 ">
                                    <div className="upload-wrapper mb-4">
                                        <input type="file" multiple="multiple" accept='.pdf, .xlsx' id='lastMonthReport' name='lastMonthReport' className="form-input form-control" onChange={handleFileChange} />
                                        {uploadButtonFlag && uploadBtnState.lastMonthReport ? <div className="display" id="loading" name="loading_icon"></div> : <></>}
                                        <div id="monthlyReport_file_list" name="file_list">
                                            {formState?.lastMonthReports?.map((file) => {
                                                return <>
                                                    <ul style={listItemStyle}>
                                                        <li className=" px-1 " >
                                                            {file?.fileName}
                                                            <button type="button" className="px-0 cross-button" onClick={(e) => handleRemoveFile(e, "lastMonthReports", file)}><IoClose className="font-size-18 fill-red" /></button>
                                                        </li>
                                                    </ul>
                                                </>
                                            })}

                                        </div>

                                    </div>
                                </div>

                            </div>

                            <label>END OF YEAR P&LS FOR PREVIOUS 3 YEARS AND CURRENT YEAR INTERIM :</label>

                            <div className="row">
                                <div className="form-group col-6 ">
                                    <div className="upload-wrapper mb-4">
                                        <input type="file" multiple="multiple" accept='.pdf, .xlsx' id='last12MonthProfitReport' name='last12MonthProfitReport' className="form-input" onChange={handleFileChange} />
                                        {uploadButtonFlag && uploadBtnState.last12MonthProfitReport ? <div className="display" id="loading" name="loading_icon"></div> : <></>}
                                        <div id="profitReport_file_list" name="file_list">
                                            <>
                                                {formState?.last12MonthProfitReports?.map((file) => {

                                                    return <>
                                                        <ul style={listItemStyle}>
                                                            <li className=" px-1 " >
                                                                {/* {getFileName(file.key)} */}
                                                                {file?.fileName}
                                                                <button type="button" className="px-0 cross-button" onClick={(e) => handleRemoveFile(e, "last12MonthProfitReports", file)}><IoClose className="font-size-18 fill-red" /></button>
                                                            </li>
                                                        </ul>
                                                    </>
                                                })}
                                            </>
                                        </div>
                                    </div>

                                </div>

                            </div>



                            <br></br>
                            <label>PIP REPORT / PIP COST ESTIMATE : </label>

                            <div className="row">
                                <div className="form-group col-6">
                                    <div className='upload-wrapper mb-4' >
                                        <input type="file" multiple="multiple" accept='.pdf, .xlsx' id='plpReport' name='plpReport' className="form-input" onChange={handleFileChange} />
                                        {uploadButtonFlag && uploadBtnState.plpReport ? <div className="display" id="loading" name="loading_icon"></div> : <></>}
                                        <div id="plpReport_file_list" name="file_list">
                                            {formState?.plpReports?.map((file) => {
                                                return <>
                                                    <ul style={listItemStyle}>
                                                        <li className=" px-1 " >
                                                            {file?.fileName}
                                                            <button type="button" className="px-0 cross-button" onClick={(e) => handleRemoveFile(e, "plpReports", file)}><IoClose className="font-size-18 fill-red" /></button>
                                                        </li>
                                                    </ul>
                                                </>
                                            })}
                                        </div>
                                    </div>

                                </div>


                            </div>

                            <br></br>
                            <label>ANY ADDITIONAL DOCUMENTS (SUCH AS QA SCORES, PAST APPRISALS, etc.) : </label>

                            <div className="row">
                                <div className="form-group col-6">
                                    <div className='upload-wrapper mb-4' >
                                        <input type="file" multiple="multiple" accept='.pdf, .xlsx' id='additionalDocs' name='additionalDocs' className="form-input" onChange={handleFileChange} />
                                        {uploadButtonFlag && uploadBtnState?.additionalDocs ? <div className="display" id="loading" name="loading_icon"></div> : <></>}
                                        <div id="additional_file_list" name="file_list">

                                            {formState?.additionalDocsList?.map((file) => {

                                                return <>
                                                    <ul style={listItemStyle}>
                                                        <li className=" px-1 " >
                                                            {file?.fileName}
                                                            <button type="button" className="px-0 cross-button" onClick={(e) => handleRemoveFile(e, "additionalDocsList", file)}><IoClose className="font-size-18 fill-red" /></button>
                                                        </li>
                                                    </ul>

                                                </>
                                            })}

                                        </div>
                                    </div>
                                </div>


                            </div>



                        </div>
                    </div>
                    <div className='row' style={{ width: "50%", float: 'right', textAlign: "right" }} >
                        <div className='form-group  col'>
                            <button className="btn-outline-cancel" ariaDisabled="true">
                                <a href={redirect_url} className='fill-label-red'>CANCEL</a>
                            </button>
                        </div>
                        <div className='form-group  col'>
                            <button className='btn btn-outline' onClick={handlePrevious} ariaDisabled="true">
                                BACK
                            </button>
                        </div>

                        <div className='form-group col'>
                            <button className='btn btn-solid' onClick={handleNext} ariaHidden="false">
                                <span style={{ color: "white", textDecoration: "none" }}>NEXT</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {page === 4 && (
                <>
                    {/* <h3 className='d-flex justify-content-center' >Terms And Conditions</h3> */}

                    <PdfFormFiller arrayBuffer={TermsAndConditionsPdf} fileType={"pdf"} formDetails={tcFormDetails} handleIAgree={""} handleCancel={""}></PdfFormFiller>


                    <div className='row' style={{ width: "50%", float: 'right', textAlign: "right" }} >
                        <div className='form-group  col'>
                            <button className="btn-outline-cancel" ariaDisabled="true">
                                <a href={redirect_url} className='fill-label-red'>CANCEL</a>
                            </button>
                        </div>
                        <div className='form-group  col'>
                            <button className='btn btn-outline' onClick={handlePrevious} ariaDisabled="true">
                                <a className="fill-label-blue font-bold">BACK</a>
                            </button>
                        </div>

                        <div className='form-group col'>
                            <button className='btn btn-solid' onClick={handleNext} ariaHidden="false">
                                <span style={{ color: "white", textDecoration: "none" }}>AGREE</span>
                            </button>
                        </div>
                    </div>
                </>
            )}

            {page === 5 && (
                <>
                    <div className='form-group col py-0 vh-100' style={{ position: 'absolute', width: "70%", height: '70%' }}>
                        {pdfBytes != null ?
                            <PdfFormFiller arrayBuffer={pdfBytes} fileType={openDocType} formDetails={userFormDetails} handleIAgree={handleSubmit} handleCancel={closeDoc}></PdfFormFiller>
                            : <></>
                        }

                    </div>
                </>
            )}

        </>
    );
};

export default SellerProperty;
