import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { forgotPasswordRequest } from '../../../api/api';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // TODO: handle form submission
    let res = forgotPasswordRequest(email)
    

    setShowSuccessAlert(true); // display success alert for demo purposes
    setTimeout(() => {
      if(res)  window.location.href = "/login"; 
    }
    ,2000)
    
  }

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h2 className="card-title text-center mb-4"><b>Reset Password</b></h2>
              {showSuccessAlert && <Alert variant="success">An email with instructions to reset your password has been sent to your email address.</Alert>}
              {showErrorAlert && <Alert variant="danger">There was an error resetting your password. Please try again.</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail">
                  <Form.Label><b>Email address</b></Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </Form.Group>
                <Button variant="primary" type="submit" className="btn-block mt-4" >
                  Reset Password
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
