import $ from "jquery";
import 'izitoast/dist/css/iziToast.min.css'; // loading css 
import iziToast from 'izitoast/dist/js/iziToast.min.js';  // you have access to iziToast now

export const formWizard = () => {
    //Horizontal form basic
    $("#wizard_horizontal").steps({
        headerTag: "h2",
        bodyTag: "section",
        transitionEffect: "slideLeft",
        onInit: function (event, currentIndex) {
            setButtonWavesEffect(event);
        },
        onStepChanged: function (event, currentIndex, priorIndex) {
            setButtonWavesEffect(event);
        },
    });

    //Vertical form basic
    $("#wizard_vertical").steps({
        headerTag: "h2",
        bodyTag: "section",
        transitionEffect: "slideLeft",
        stepsOrientation: "vertical",
        onInit: function (event, currentIndex) {
            setButtonWavesEffect(event);
        },
        onStepChanged: function (event, currentIndex, priorIndex) {
            setButtonWavesEffect(event);
        },
    });

    //Advanced form with validation
    var form = $("#wizard_with_validation").show();
    form.steps({
        headerTag: "h3",
        bodyTag: "fieldset",
        transitionEffect: "slideLeft",
        onInit: function (event, currentIndex) {
            //Set tab width
            var $tab = $(event.currentTarget).find('ul[role="tablist"] li');
            var tabCount = $tab.length;
            $tab.css("width", 100 / tabCount + "%");
            
            //set button waves effect
            setButtonWavesEffect(event);
        },
        onStepChanging: function (event, currentIndex, newIndex) {
            if (currentIndex > newIndex) {
                return true;
            }

            if (currentIndex < newIndex) {
                form.find(".body:eq(" + newIndex + ") label.error").remove();
                form.find(".body:eq(" + newIndex + ") .error").removeClass("error");
            }

            form.validate().settings.ignore = ":disabled,:hidden";
            return form.valid();
        },
        onStepChanged: function (event, currentIndex, priorIndex) {
            setButtonWavesEffect(event);
        },
        onFinishing: function (event, currentIndex) {
            form.validate().settings.ignore = ":disabled";
            return form.valid();
        },
        onFinished: function (event, currentIndex) {
            alert("Good job!", "Submitted!", "success");
        },
    });

    form.validate({
        highlight: function (input) {
            $(input).parents(".form-line").addClass("error");
        },
        unhighlight: function (input) {
            $(input).parents(".form-line").removeClass("error");
        },
        errorPlacement: function (error, element) {
            $(element).parents(".form-group").append(error);
        },
        rules: {
            confirm: {
                equalTo: "#password",
            },
        },
    });
}

export const setButtonWavesEffect = (event) => {
    $(event.currentTarget).find('[role="menu"] li a').removeClass("waves-effect");
    $(event.currentTarget)
        .find('[role="menu"] li:not(.disabled) a')
        .addClass("waves-effect");
};
export const notifyRegister = (event) => {
    if(event == "brokerRegistered"){
        iziToast.success({
            title: "Registration",
            message: "Your Broker profile is Registered Successfully !!", 
            position: "topRight",
          }); 
    }  
    if(event == "sellerRegistered"){
        iziToast.success({
            title: "Registration",
            message: "Your Seller profile is Registered Successfully !!", 
            position: "topRight",
          }); 
    }  

    if(event == "buyerRegistered"){
        iziToast.success({
            title: "Registration",
            message: "Your Buyer profile is Registered Successfully !!", 
            position: "topRight",
          }); 
    }  

    if(event.errorName === "BuyerError"){
        iziToast.error({
            title: "Error",
            message: event.errorMessage, 
            position: "topRight",
          });
    }
    
}

export const usStates = [
    { name: 'ALABAMA', abbreviation: 'AL'},
    { name: 'ALASKA', abbreviation: 'AK'},
    { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
    { name: 'ARIZONA', abbreviation: 'AZ'},
    { name: 'ARKANSAS', abbreviation: 'AR'},
    { name: 'CALIFORNIA', abbreviation: 'CA'},
    { name: 'COLORADO', abbreviation: 'CO'},
    { name: 'CONNECTICUT', abbreviation: 'CT'},
    { name: 'DELAWARE', abbreviation: 'DE'},
    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
    { name: 'FLORIDA', abbreviation: 'FL'},
    { name: 'GEORGIA', abbreviation: 'GA'},
    { name: 'GUAM', abbreviation: 'GU'},
    { name: 'HAWAII', abbreviation: 'HI'},
    { name: 'IDAHO', abbreviation: 'ID'},
    { name: 'ILLINOIS', abbreviation: 'IL'},
    { name: 'INDIANA', abbreviation: 'IN'},
    { name: 'IOWA', abbreviation: 'IA'},
    { name: 'KANSAS', abbreviation: 'KS'},
    { name: 'KENTUCKY', abbreviation: 'KY'},
    { name: 'LOUISIANA', abbreviation: 'LA'},
    { name: 'MAINE', abbreviation: 'ME'},
    { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
    { name: 'MARYLAND', abbreviation: 'MD'},
    { name: 'MASSACHUSETTS', abbreviation: 'MA'},
    { name: 'MICHIGAN', abbreviation: 'MI'},
    { name: 'MINNESOTA', abbreviation: 'MN'},
    { name: 'MISSISSIPPI', abbreviation: 'MS'},
    { name: 'MISSOURI', abbreviation: 'MO'},
    { name: 'MONTANA', abbreviation: 'MT'},
    { name: 'NEBRASKA', abbreviation: 'NE'},
    { name: 'NEVADA', abbreviation: 'NV'},
    { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
    { name: 'NEW JERSEY', abbreviation: 'NJ'},
    { name: 'NEW MEXICO', abbreviation: 'NM'},
    { name: 'NEW YORK', abbreviation: 'NY'},
    { name: 'NORTH CAROLINA', abbreviation: 'NC'},
    { name: 'NORTH DAKOTA', abbreviation: 'ND'},
    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
    { name: 'OHIO', abbreviation: 'OH'},
    { name: 'OKLAHOMA', abbreviation: 'OK'},
    { name: 'OREGON', abbreviation: 'OR'},
    { name: 'PALAU', abbreviation: 'PW'},
    { name: 'PENNSYLVANIA', abbreviation: 'PA'},
    { name: 'PUERTO RICO', abbreviation: 'PR'},
    { name: 'RHODE ISLAND', abbreviation: 'RI'},
    { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
    { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
    { name: 'TENNESSEE', abbreviation: 'TN'},
    { name: 'TEXAS', abbreviation: 'TX'},
    { name: 'UTAH', abbreviation: 'UT'},
    { name: 'VERMONT', abbreviation: 'VT'},
    { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
    { name: 'VIRGINIA', abbreviation: 'VA'},
    { name: 'WASHINGTON', abbreviation: 'WA'},
    { name: 'WEST VIRGINIA', abbreviation: 'WV'},
    { name: 'WISCONSIN', abbreviation: 'WI'},
    { name: 'WYOMING', abbreviation: 'WY' }
]

export const hotelTypes = [
    { type : "Any" },
    { type : "Turn Around -Management" },
    { type : "Value Add - Re-Branding Oppertunity" },
    { type : "Newly Built 1 - 5 years old" },
    { type : "Interior Corridor" },
    { type : "To Be Built Project" },
    { type : "Under Construction Close at CO" },
    { type : "Major PIP" },
    { type : "Minor PIP" },
    { type : "Distressed" },
    { type : "Lender Owned" }
]

export const brands = [
    { brand : "All" },
    { brand : "Economy" },
    { brand : "Life Style" },
    { brand : "Mid-Scale" },
    { brand : "Upper Mid-Scale" },
    { brand : "Upscale" },
    { brand : "Resort" },
    { brand : "Luxury" },
    { brand : "Select Service" }
]

export const hotelCategory = [
 { category : "Any" },
 { category : "Limited Service" },
 { category : "Full Service" },
 { category : "Select Service" },
 { category : "Extended Stay" }
]

export const marketType = [
    { market : "Any" },
    { market : "Primary" },
    { market : "Primary or Secondary" },
    { market : "Secondary Market" },
]

export const roomCount = [
    { count : "Any" },
    { count : "1-42" },
    { count : "41-60" },
    { count : "61-80" },
    { count : "81-100" },
    { count : "101-150" },
    { count : "151-300" },
    { count : "300+" },
    { count : "1-4" },
    { count : "10-40" }
]