import React, { useEffect, useState } from "react";
import { FaEye, FaHeart, FaHandHoldingUsd, FaRegHeart, FaCheckCircle } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";
import { AiFillEye, AiOutlineEye, AiOutlineFileExcel, AiOutlineFilePdf, AiOutlineFileWord, AiOutlineLeft } from "react-icons/ai";
import { useParams, useNavigate } from "react-router-dom";
import { getBuyer, getDashboard, getFileByKey, getProfilePicByKey, getProperty, updateInterestForProperty } from "../../../api/api";
import { toast } from "react-toastify";
import { Accordion, Badge, Button, Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import Swal from "sweetalert2";
import $ from 'jquery'
import { Broker_Signed_Confi, Buyer_Agent_Signed_Confi, Confi_Agreement_Key, Listing_Agreement_Key, S3_IMAGES } from "../../../constants/constants";
import FilePreview from "../../common/FilePreview";
import PdfFormFiller from "../../common/PdfFormFiller";
import { MdContactMail, MdContactPhone, MdGroup } from "react-icons/md";
import { Carousel } from 'react-bootstrap';
import DefaultUserImage from '../../../components/assets/default-user.png'
import { formatMobileNumber } from "../../common/utils";
import { getAgent } from "../../common/commonUtils";


const PropertyProfile = () => {
  const [data, setData] = useState();
  const [interest, setInterest] = useState(false);
  const [save, setSave] = useState(false);
  const [BuyersList, setBuyersList] = useState([]);
  const [interestedBuyer, setInterestedBuyer] = useState();
  const [interestedBuyerInfo, setInterestedBuyerInfo] = useState();
  const [spinner, setSpinner] = useState(false);
  const [defaultTab, setDefaultTab] = useState("images");
  const [pdfBytes, setPdfBytes] = useState();
  const [isDocOpen, setDocOpen] = useState(false);
  const [openDocType, setOpenDocType] = useState();
  const [currentInterest, setCurrentInterest] = useState();
  const [userFormDetails, setUserFormDetails] = useState([]);
  const [fullscreen, setFullscreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [hbaStaff, setHbaStaff] = useState();
  const [imageBytes, setImageBytes] = useState();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const { id } = useParams();
  const user_id = Number(sessionStorage.getItem("user_id"));
  const [customModal, setCustomModal] = useState({
    show: false,
    hide: true,
    title: "",
    message: "",
    submessage: "",
    button1Text: "",
    button2Text: "",
    button3Text: "",
    button4Text: "",
    button5Text: "",
    button6Text: ""
  })

  const interestHandler = async () => {
    setSpinner(true)
    if (interestedBuyer != undefined) {
      const payload = {
        property: {
          id: parseInt(id)
        },
        buyer: {
          id: interestedBuyer
        },
        interested: true
      }

      const propertyInterest = await updateInterestForProperty(payload);
      { propertyInterest?.status == 200 ? setInterest(true) : setInterest(false) }
      setDocOpen(false)
      setShow(false)

      {
        propertyInterest?.status == 200 ? await getPropertyById(id) :
          toast.error(propertyInterest.data?.message,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

      }



      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btnc-success',
          cancelButton: 'btn btn-outline-cancel',
          denyButton: 'btn btn-primary',
          container: 'my-swal',
          popup: 'my-swal-popup',
          header: 'my-swal-header',
          title: 'my-swal-title',
          closeButton: 'my-swal-close-button',
          content: 'my-swal-content'
        },
        buttonsStyling: false
      })

      {
        propertyInterest.status == 200 ?
          (JSON.parse(sessionStorage.getItem('user_type')) == 'Broker') ?
            setCustomModal({
              ...customModal,
              show: true,
              title: "Your request has been received",
              message: "You will be contacted by the seller's broker",
              message2: "This deal is now added to your dealbox",
              submessage: "What do you want to do next?",
              button1Text: "Save this hotel for another buyer",
              button2Text: "Go to Dashboard",
              button3Text: 'Continue Shopping for this buyer',
              button4Text: "Go to Deal-Box",
              button5Text: "View Due Diligence",
              button6Text: "Stay on this page"
            })
            :
            setCustomModal({
              ...customModal,
              show: true,
              title: "Your request has been received",
              message: "You will be contacted by the seller's broker",
              message2: "This deal is now added to your dealbox",
              submessage: "What do you want to do next?",
              button1Text: "",
              button2Text: "",
              button3Text: 'Continue Shopping',
              button4Text: "Go to Deal-Box",
              button6Text: "Stay on this page"
            })
          : <></>
      }

      $('#buyer_profiles').addClass('is-valid')
      setSpinner(false)
    } else {
      handleConfi()
      setSpinner(false)
    }

  };

  useEffect(async () => {
    
    await getPropertyById(id);
    await getDashboardInfo();


    setInterestedBuyer(JSON.parse(sessionStorage.getItem("currentPostid")))
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const approveToast = (message) => {
    toast.error(message);
  }

  const getPropertyById = async (id) => {
    let res = await getProperty(id);
    if (res.data) {
      setData(res.data)
      { (res?.data?.hbaStaff == null || res?.data?.hbaStaff == "") ? setHbaStaff(getAgent()) : setHbaStaff(res?.data?.hbaStaff) }
      { (res?.data?.hbaStaff == null || res?.data?.hbaStaff == "") ? getImageCards(getAgent()) : getImageCards(res?.data?.hbaStaff?.profilePic) }
      
    } else {
      navigate('/propertyList')
      approveToast(res.message);
    }
    ;
  };

  const getDashboardInfo = async () => {
    let res = await getDashboard();
    setBuyersList(res?.data?.buyers);

    if (!JSON.parse(sessionStorage.getItem("user_type")).includes("Broker") && !JSON.parse(sessionStorage.getItem("user_type")).includes("Agent")) {
      setInterestedBuyer(res.data?.buyers[0].id)
      sessionStorage.setItem("currentPostid", parseInt(res.data?.buyers[0].id))
    }

    ;
  };

  const checkInterestButton = () => {
    let interested = false
    data?.interestReceived?.map(buy => {
      buy?.buyer?.id == interestedBuyer ?
        interested = true
        : <></>
    })

    return interested
  }
  const handleBuyer = (value) => {
    setInterestedBuyer(value)
    sessionStorage.setItem("currentPostid", parseInt(value))
  }

  const getImageCards = async (key) => {
    try {
      await getProfilePicByKey(key).then(res => {
        const bytes = new Uint8Array(res?.data);
        setImageBytes(bytes)
        return bytes
      });
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }

  const getImagesArray = (urls) => {
    let urlsArray = urls?.split(',')
    let imagesArray = []

    urlsArray?.forEach(url => {

      if (url.includes("files")) {
        const index = url.indexOf("files");
        const substringBeforeKeyword = url.substring(0, index);

        if (substringBeforeKeyword.trim() === "") {
          let imagesObj = {
            original: S3_IMAGES + url,
            thumbnail: S3_IMAGES + url,
          }
          imagesArray.push(imagesObj)

        } else {
          let imagesObj = {
            original: url,
            thumbnail: url,
          }
          imagesArray.push(imagesObj)
        }

      }
    });
    // console.log(imagesArray)

    return imagesArray

  }

  const handleYourDealBoxRedirect = () => {
    console.log(sessionStorage.getItem("currentPostid"))
    if (sessionStorage.getItem("currentPostid") != null) {
      navigate('/BuyerPost/' + sessionStorage.getItem("currentPostid") + '/dealbox')
    } else {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btnc-success',
          cancelButton: 'btn btn-outline-cancel',
          container: 'my-swal',
          popup: 'my-swal-popup',
          header: 'my-swal-header',
          title: 'my-swal-title',
          closeButton: 'my-swal-close-button',
          content: 'my-swal-content'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: 'Which Seller Deal-Box do you want to see?',
        input: 'select',
        icon: 'question',
        inputOptions: getBuyerOptions().reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {}),
        showCancelButton: true,
        confirmButtonText: 'View Deal-Box',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/BuyerPost/' + result.value + '/dealbox')
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {

        }
      })
    }

  }

  const handleYourShopMoreRedirect = () => {
    if (sessionStorage.getItem("currentPostid") != null) {
      navigate('/BuyerPost/' + sessionStorage.getItem("currentPostid") + '/shopMore')
    } else {
      $('#buyer_profiles').addClass('is-invalid')

      navigate('/propertyList')

    }

  }

  const getDefaultBuyer = () => {
    if (sessionStorage.getItem("currentPostid") != null) {
      return sessionStorage.getItem("currentPostid")
    } else {
      return ""
    }
  }

  const getBuyerOptions = () => {
    let options = {}
    options = BuyersList?.map((buyer, index) => ({
      label: `${buyer.generatedBuyerId} - ${buyer.buyerName}`,
      value: buyer.id
    }));
    return options
  }

  const handleConfi = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary ml-2',
        cancelButton: 'btn btn-outline-cancel',
        container: 'my-swal',
        popup: 'my-swal-popup',
        header: 'my-swal-header',
        title: 'my-swal-title',
        closeButton: 'my-swal-close-button',
        content: 'my-swal-content'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Choose your 1031 buyer for whom you want to shop this hotel for',
      input: 'select',
      icon: 'question',
      inputOptions: getBuyerOptions().reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {}),
      showCancelButton: true,
      confirmButtonText: 'Sign Confidentiality',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async (value) => {
        let interested = false
        let res = await getProperty(id);
        res.data?.interestReceived?.map(buy => { buy?.buyer?.id == value ? interested = true : <></> })
        // eslint-disable-next-line no-lone-blocks
        {
          interested ?
            Swal.showValidationMessage('You have already shoped for this Buyer')
            : <></>
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        setSpinner(true)
        handleBuyer(result.value)
        handleConfiDoc()
        setSpinner(false)
      }
    })
  }

  const handleSwalClick = (e) => {
    let type = e.target.value
    if (type == "button1") {
      setCustomModal({ ...customModal, show: false })
      handleConfi()
    } else if (type == "button2") {
      if (JSON.parse(sessionStorage.getItem('user_type')) == 'Broker') navigate('/brokerDashboard')
    } else if (type == 'button3') {
      navigate('/buyerPost/' + sessionStorage.getItem("currentPostid") + '/shopMore')
    } else if (type == 'button4') {
      navigate('/buyerPost/' + sessionStorage.getItem("currentPostid") + '/dealBox')
    } else if (type == 'button5') {
      navigate(-1)
    } else if (type == 'button6') {
      setCustomModal({ ...customModal, show: false })
    }
  }

  const handleConfiDoc = async () => {
    let res = await getBuyer(interestedBuyer);
    const buyer = (res ? res.data : res);
    setInterestedBuyerInfo(data);
    let userIsBroker = JSON.parse(sessionStorage.getItem("user_type")).includes("Broker")
    let aggrement_key =""
    // setUserFormDetails([buyer?.user?.companyName, data?.generatedPropertyId,  buyer?.user.firstName.toUpperCase() + " " + buyer?.user?.lastName.toUpperCase(),  buyer?.user?.userId,  buyer?.user.firstName.toUpperCase() + " " + buyer?.user?.lastName.toUpperCase(), new Date().toLocaleDateString(), "A Unique Fingerprint will be generated once you sign this agreement"])
    if(userIsBroker){
      setUserFormDetails({
        "Text-Broker-Company" : buyer?.user?.companyName,
        "Text-Hotel-Id": data?.generatedPropertyId,
        "Text-Buyer-Id": buyer?.generatedBuyerId,
        "Text-Broker-Name" : buyer?.user.firstName?.toUpperCase() + " " + buyer?.user?.lastName?.toUpperCase(),
        "Text-Broker-Id" : buyer?.user?.userId,
        "Text-FingerPrint": "A Unique Fingerprint will be generated once you sign this agreement",
        "Text-Date":new Date().toLocaleDateString()
  
      })
      
    }else {
      setUserFormDetails({
        "Text-Broker-Company" : buyer?.user?.companyName,
        "Text-Hotel-Id": data?.generatedPropertyId,
        "Text-Buyer-Id": buyer?.generatedBuyerId,
        "Text-Broker-Name" : buyer?.user.firstName.toUpperCase() + " " + buyer?.user?.lastName.toUpperCase(),
        "Text-Broker-Id" : buyer?.user?.userId,
        "Text-FingerPrint": "A Unique Fingerprint will be generated once you sign this agreement",
        "Text-Date":new Date().toLocaleDateString()
  
      })
    }
    aggrement_key = userIsBroker ? Broker_Signed_Confi : Buyer_Agent_Signed_Confi 
    setSpinner(true)
    
    
    await handleDownload(aggrement_key, "pdf").then((res) => {
      setSpinner(false)
      setShow(true)
    })
  }

  const handleDownload = async (key, type) => {
    try {
      await getFileByKey(key).then(res => {
        const bytes = new Uint8Array(res.data);
        setPdfBytes(bytes)
        setOpenDocType(type)
      });
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Error downloading file");
    }
  };

  const openConfiDoc = async (type) => {
    let key = ""
    data?.interestReceived?.map(buy => {
      buy?.buyer?.id == interestedBuyer ?
        key = buy?.signedConf
        
        : <></>
    })
    setSpinner(true)
    console.log(key)
    await handleDownload(key, type).then((res => {
      setSpinner(false)
      setDocOpen(true)
    }));

  }

  const openDoc = async (key, type) => {
    setSpinner(true)
   
      await handleDownload(key, type).then((res => {
        setSpinner(false)
        setDocOpen(true)
      }));
    if(type != "pdf"){
      setTimeout(() => {
      closeDoc()
      },1000)
    }
  };


  const closeDoc = () => {
    setDocOpen(false);
  };


  const getIconByType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return <AiOutlineFilePdf className="font-24" />;
      case 'xls':
      case 'xlsx':
        return <AiOutlineFileExcel className="font-24" />;
      case 'doc':
      case 'docx':
        return <AiOutlineFileWord className="font-24" />;
      default:
        return null;
    }
  };

  const getFileExtention = (key) => {
    console.log(key.split('.').pop().toLowerCase())
    return key.split('.').pop().toLowerCase();
  }

  const listItemStyle = {
    listStyleType: 'none',
    marginBottom: '10px', // Add additional styling as needed
  };


  const getFileName = (key) => {
    const parts = key.split('/');
    let filename = parts[parts.length - 1];

    filename = filename.split('.')[0]; // Truncate after first dot
    filename = filename.replace(/-/g, ' ')
        .replace(/%20/g, ' ')
        .replace(/,/g, ' ')
        .replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/&/g, ' '); // Replace ampersand with underscore

    return filename;
}



  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setFullscreen(true);
  };

  const getCalculations = (name) => {
    let askingPrice = data?.askingPrice ? parseInt(data?.askingPrice) : 0;
    let pipEstimate = data?.pipEstimate ? parseInt(data?.pipEstimate) : 0;
    let otherRevenue = data?.otherRevenue ? parseInt(data?.otherRevenue) : 0;
    let fandbrevenue = data?.yearlyFNBRevenue ? parseInt(data?.yearlyFNBRevenue) : 0;
    let roomRevenue = data?.roomRevenue ? parseInt(data?.roomRevenue) : 0;
    let netOperatingRevenue = data?.netOperatingRevenue ? parseInt(data?.netOperatingRevenue) : 0;
    let noOfRooms = data?.noOfRooms ? parseInt(data?.noOfRooms) : 0;
    let netIncome = data?.netIncome ? parseInt(data?.netIncome) : 0;

    if (name == "downPayment")
      return (parseInt(askingPrice) + parseInt(pipEstimate)) * 0.2;

    if (name == "totalPrice")
      return (parseInt(askingPrice) + parseInt(pipEstimate));

    if (name == "toatlRevenue")
      return parseInt(otherRevenue) + parseInt(fandbrevenue) + parseInt(roomRevenue);

    if (name == "capRate")
      return Math.round(((parseInt(netIncome) / (parseInt(askingPrice) + parseInt(pipEstimate)))) * 100);

    if (name == "pricePerRoom")
      return Math.round(((parseInt(askingPrice) + parseInt(pipEstimate)) / noOfRooms + Number.EPSILON) * 100) / 100;;

    if (name == "revMultiplier")
      return Math.round((parseInt(askingPrice) + parseInt(pipEstimate)) / (parseInt(otherRevenue) + parseInt(fandbrevenue) + parseInt(roomRevenue)) * 100) / 100;
  }

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  return (
    <>
      <div className="property-profile-page fill-blue px-2">

        <div style={{ marginTop: "10px" }} className="section-body">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="">
                <div
                  className="card-header card-principal-title-buyer"
                >
                  <Row className="px-2">
                    <Col className="w-100">
                      <Col>
                        <span >CONFIDENTIAL HOTEL ID</span>
                      </Col>
                      <h1 className="property-profile-header fill-blue ">{data?.generatedPropertyId}</h1>
                    </Col>
                    <Col className="fit-content-max float-r">
                      {
                        hbaStaff ?
                          <>
                            <div className="row">
                              <div className="col fit-content-max">
                                <div className="profile-pic">
                                  <div className="d-flex justify-content-center">
                                    {imageBytes && (
                                      <img
                                        src={`data:image/jpeg;base64,${arrayBufferToBase64(imageBytes)}`}
                                        alt={DefaultUserImage}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col ">
                                <div className="row ">
                                  <label><h2 className="font-size-18">Your 1031 Agent is {hbaStaff?.firstName} {hbaStaff?.lastName} </h2></label>
                                </div>
                                <div className="row">
                                  <label className="col d-flex px-2" ><MdContactPhone style={{ fontSize: '20px', color: 'grey' , marginRight:"4px"}}></MdContactPhone>  <h2 className="font-size-15"> {formatMobileNumber(hbaStaff?.mobileNumber)}</h2></label>
                                  <label className="col px-2 d-flex"><MdContactMail style={{ fontSize: '20px', color: 'grey' , marginRight:"4px" }}></MdContactMail> <h2 className="font-size-15"> {hbaStaff?.email}</h2></label>
                                </div>
                              </div>
                            </div>
                          </>
                          :
                          <></>
                      }
                    </Col>

                  </Row>
                  <hr className=""></hr>
                  <div className="row py-2">
                    <div className="col d-flex justify-content-center align-items-center">
                      <div className="border-box w-100">
                        <div className="row px-0 py-0">
                          <div className="col">
                            <label className="d-flex justify-content-center  bottom-border"><h3 className="font-size-14-black">Total Price Including PIP</h3></label><br></br>
                          </div>
                        </div>
                        <div className="row py-1">
                          <div className="col">
                            <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font">$  {getCalculations("totalPrice") ? formatNumberWithCommas(getCalculations("totalPrice")) : 0}</h1></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-items-center">
                      <div className="border-box">
                        <div className="row">
                          <div className="col ">
                            <label className="d-flex justify-content-center   bottom-border"><h3 className="font-size-14-black">Price Per Key</h3></label><br></br>
                          </div>
                        </div>
                        <div className="row py-1">
                          <div className="col">
                            <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font">$  {getCalculations("pricePerRoom") ? formatNumberWithCommas(getCalculations("pricePerRoom")) : 0} </h1></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-items-center">
                      <div className="border-box">
                        <div className="row">
                          <div className="col ">
                            <label className="d-flex justify-content-center   bottom-border"><h3 className="font-size-14-black">Room Revenue Multiplier</h3></label><br></br>
                          </div>
                        </div>
                        <div className="row py-1">
                          <div className="col">
                            <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font"> {data?.roomRevenueMultiplier ? data?.roomRevenueMultiplier : 0}</h1></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-items-center">
                      <div className="border-box">
                        <div className="row">
                          <div className="col ">
                            <label className="d-flex justify-content-center   bottom-border"><h3 className="font-size-14-black">Cap Rate</h3></label><br></br>
                          </div>
                        </div>
                        <div className="row py-1">
                          <div className="col">
                            <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font"> {getCalculations("capRate") ? formatNumberWithCommas(getCalculations("capRate")) : 0}</h1></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-items-center">
                      <div className="border-box">
                        <div className="row ">
                          <div className="col ">
                            <label className="d-flex justify-content-center bottom-border w-100"><h2 className="font-size-14-black"># Buyers Interested in This Hotel</h2></label><br></br>
                          </div>
                        </div>
                        <div className="row py-1">
                          <div className="col">
                            <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font">
                              {data?.interestReceived?.length ? data?.interestReceived?.length : 0}
                            </h1> </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row className="py-0">
                    <Row>
                      <Col className="fit-content-max d-flex justify-content-start align-items-start">
                        <div >
                          {checkInterestButton() == true ?
                            <button className="btn-outline-primary-narrow-right" disabled={false} onClick={() => openConfiDoc("pdf")}>View Signed Confidentiality <AiFillEye className="font-20"></AiFillEye></button> :
                            <>
                              <Row>
                                <Col>
                                  {interest ?
                                    <button
                                      onClick={() => openConfiDoc("pdf")}
                                      className="btn-outline-primary-narrow-right"
                                      disabled={false}
                                    >
                                      <BsCheck
                                        style={{
                                          fontSize: "20px",
                                          marginTop: "-4px",
                                        }}
                                      />
                                      View Signed Confidentiality
                                    </button>
                                    :
                                    <button
                                      onClick={interestedBuyer != undefined ? handleConfiDoc : handleConfi}
                                      className="btn btn-outline-primary-narrow-right"
                                      disabled={interest}
                                    >
                                      Sign Confidentiality
                                    </button>
                                  }
                                </Col>
                              </Row>

                            </>
                          }
                        </div>
                      </Col>
                      <Col className="fit-content-max" >
                        <button
                          onClick={handleYourShopMoreRedirect}
                          className="btn btn-outline-primary-narrow-right"
                        >
                          Shop More Hotels
                        </button>
                      </Col>
                      <Col className="fit-content-max">
                        <button
                          onClick={handleYourDealBoxRedirect}
                          className="btn btn-outline-primary-narrow-right"
                        >
                          Your Deal Box
                        </button>


                      </Col>
                    </Row>
                    <Row className="py-0" >
                      <div className="col py-0 d-flex justify-content-start align-items-end md-0  px-0">
                        <span className="px-3"><sup>*</sup>Sign Confidentiality to view detailed financial and offering memorandum</span>
                      </div>
                      <Col >
                        <div className="fit-content-max float-r">
                          <div className="row px-4 py-0 d-flex justify-content-center align-item-center md-0">
                            <div className="col py-0 px-2 d-flex justify-content-end align-item-center fit-content-max">
                              <h6 className="fill-blue px-2 py-0  ml-0 mr-0 mb-0"><FaEye className="px-1 fill-green" style={{ fontSize: '30px' }}></FaEye>{data?.totalViewed ? data.totalViewed : 0} Views</h6>
                            </div>
                            <div className="col d-flex justify-content-center align-item-center fit-content-max px-0 py-0 ">
                              <div class="vertical-line px-0"></div>
                            </div>
                            <div className="col px-2 d-flex justify-content-start align-item-center fit-content-max">
                              <h6 className="fill-blue px-0 ml-0 mr-0 mb-0 w-100"><MdGroup className="px-1 fill-green" style={{ fontSize: '30px' }}> </MdGroup>{data?.totalInterested ? data?.totalInterested : 0} Buyers Interested</h6>
                            </div>
                          </div>
                          <div className="row py-0">
                            <div className="col  d-flex justify-content-center align-item-center">
                              <div class="horizontal-line"></div>
                            </div>
                          </div>

                        </div>

                      </Col>
                    </Row>
                  </Row>
                </div>

                <div className="">
                  <div style={{ justifyContent: 'space-evenly' }} className="">
                    <div className="row gx-1 pb-2 px-0 align-item-stretch">
                      <div className="col-md-4 pt-2 flex-1">
                        <div className="d-flex justify-content-start align-item-start card card-criteria card-top-border-buyer px-0 h-100">
                          <div className="card-header d-flex justify-content-start align-item-center py-1">
                            <h2 className="font-size-18 fill-blue">
                              HOTEL FACTS
                            </h2>
                          </div>

                          <div className="card-body buyer-profile-body fill-blue">
                            <div className="form-body" style={{ fontSize: '13px', fontWeight: "700" }}>

                              <Row className=" d-flex align-items-center">
                                <Col xs={12}>
                                  {data?.displayBrandOn1031 || checkInterestButton() == true ?
                                    <>
                                      <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                        <Col xs={8}><label className="font-size-16"><b>Hotel Brand</b></label></Col>
                                        <Col xs={4}>
                                          <label id="hotelbrand" className="font-size-16-lite" name='brandName'>
                                            {data?.propertyType?.brandName}
                                          </label>
                                        </Col>
                                      </Row>

                                      <Row className=" d-flex align-items-center py-2">
                                        <Col xs={8}><label className="font-size-16"><b>Hotel Franchise</b></label></Col>
                                        <Col xs={4}>
                                          <label id="hotelfranchise" className="font-size-16-lite" name='franchiseName' >
                                            {data?.propertyType?.franchiseName}
                                          </label>
                                        </Col>
                                      </Row>


                                    </> : null}
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>Hotel Brand Scale</b></label></Col>
                                    <Col xs={4}>
                                      <label id="hotelbrandtype" className="font-size-16-lite" name='hotelbrandtype' >
                                        {data?.propertyType?.brandType}
                                      </label >
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>Market Segment</b></label></Col>
                                    <Col xs={4}>
                                      <label id="hotelfranchisetype" className="font-size-16-lite" name='franchisetype' >
                                        {data?.propertyType?.franchiseType}
                                      </label>
                                    </Col>
                                  </Row>

                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>Market Type</b></label></Col>
                                    <Col xs={4}>
                                      <label id="markettype" className="font-size-16-lite" >
                                        {data?.marketType ? data?.marketType : "-"}
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>Investment Type</b></label></Col>
                                    <Col xs={4}>
                                      <label id="opportunityType" className="font-size-16-lite" >
                                        {data?.opportunityType ? data?.opportunityType : "-"}
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>State</b></label></Col>
                                    <Col xs={4}>
                                      <label id="sellerStates" className="font-size-16-lite" >
                                        {data?.address?.state?.name}
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>Nearest MSA</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite" id="nearestMSA"  >{data?.nearestMSA} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16">Loan Type</label></Col>
                                    <Col xs={4}>
                                      <label id="loantype" className="font-size-16-lite" >
                                        {data?.loanType}
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16">Year Built</label></Col>
                                    <Col xs={4}>
                                      <label id="yearbuilt" type="number" className="font-size-16-lite" name='yearBuilt' >
                                        {data?.yearBuilt}
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16" ><b>Year Renovated</b></label></Col>
                                    <Col xs={4}>
                                      <label id="yearrenovated" type="number" className="font-size-16-lite" name='lastRenovatedYear' >
                                        {data?.lastRenovatedYear}
                                      </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16" ><b>Room Count</b></label></Col>
                                    <Col xs={4}>
                                      <label type="number" className="font-size-16-lite" name='noOfRooms' >{data?.noOfRooms} </label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 pt-2">
                        <div className="card card-top-border-seller  h-100">
                          <div className="card-header d-flex justify-content-start align-item-center py-1">
                            <h2 className="font-size-18 fill-blue">
                              HOTEL NUMBERS
                            </h2>
                          </div>
                          <div className="card-body buyer-profile-body">
                            <div className="form-body" style={{ fontSize: '13px', fontWeight: "700" }}>
                              <Row className=" d-flex align-items-center">
                                <Col xs={12}>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>Asking Price</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.askingPrice ? data.askingPrice : 0)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>PIP Estimate</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.pipEstimate ? data.pipEstimate : 0)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>Total Price</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${getCalculations("totalPrice") ? formatNumberWithCommas(getCalculations("totalPrice")) : 0} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>Occupancy t-12</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">{formatNumberWithCommas(data?.yearlyOccupancy ? parseInt(data?.yearlyOccupancy).toFixed(2) : 0)} %</label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>ADR</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.yearlyADR ? data.yearlyADR : 0)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>Room Revenue t-12</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.roomRevenue ? data.roomRevenue : 0)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>Food and Beverage Revenue</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.yearlyFNBRevenue ? data.yearlyFNBRevenue : 0)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>Other Revenue</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.otherRevenue ? data.otherRevenue : 0)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>Total Revenue</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.otherRevenue + data?.yearlyFNBRevenue + data?.roomRevenueMultiplier)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>Net Operating Income</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.netOperatingRevenue ? data.netOperatingRevenue : 0)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                                    <Col xs={8}><label className="font-size-16"><b>Net Income</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.netIncome ? data.netIncome : 0)} </label>
                                    </Col>
                                  </Row>
                                  <Row className=" d-flex align-items-center py-2">
                                    <Col xs={8}><label className="font-size-16"><b>Cash on Cash Return</b></label></Col>
                                    <Col xs={4}>
                                      <label className="font-size-16-lite">${formatNumberWithCommas(data?.cashOnCashRet ? data.cashOnCashRet : 0)} </label>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5 pt-2">
                        <div className="card h-100">
                          <Tabs defaultActiveKey={defaultTab}>
                            <Tab eventKey="images" title="Photos">
                              <div className="px-4" >
                                {data?.imagesPath != "" ?
                                  <div className="row " >
                                    <Carousel
                                      autoplay
                                      fade={true}
                                      controls={true}
                                      interval={2000}
                                      pause={'hover'}
                                      data-bs-theme="dark"
                                      className={fullscreen ? 'fullscreen-carousel' : ''}
                                    >
                                      {getImagesArray(data?.imagesPath)?.map((image) => (
                                        <Carousel.Item key={image.src} onClick={() => handleImageClick(image)}  >
                                          <img className="d-block w-100 fixed-image-size" src={image?.original} alt={image?.thumbnail} />
                                        </Carousel.Item>
                                      ))}
                                    </Carousel>
                                    <Modal show={fullscreen} onHide={() => setFullscreen(false)} size="xl">
                                      <Modal.Header closeButton />
                                      <Modal.Body>
                                        {selectedImage && (
                                          <img className="d-block w-100" src={selectedImage.original} alt={selectedImage.thumbnail} />
                                        )}
                                      </Modal.Body>
                                    </Modal>
                                  </div>
                                  : <><br></br><span>No photos uploaded by the seller</span></>}
                              </div>
                            </Tab>


                            {checkInterestButton() || interest ?

                              <Tab eventKey="documents" title="Due Diligence">
                                <>
                                  <div className="row px-2 py-2 d-flex justify-content-center align-items-center">
                                    <div className="col-md-12 ">
                                      <Row>
                                        <Col>
                                          {(data?.last12MonthProfitReports == null || data?.last12MonthProfitReports == '') && (data?.lastMonthReports == null || data?.lastMonthReports == '') && (data?.plpReports == null || data?.plpReports == '') && (data?.additionalDocsList == null || data?.additionalDocsList == '') ?
                                            <span> No documents uploaded by seller </span>
                                            : <></>}
                                        </Col>
                                      </Row>

                                      <Row >
                                        <Col>
                                          <Accordion defaultActiveKey="0" alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                              <Accordion.Header className="w-100">
                                                STR REPORTS
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <ul className="cursor-pointer" style={listItemStyle}>
                                                  {data?.lastMonthReports != '' && data?.lastMonthReports?.map((file, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={index}
                                                          className="cursor-pointer"
                                                          onClick={() => openDoc(file?.key, getFileExtention(file?.key))}
                                                        >
                                                          {/* {getIconByType(key)} */}
                                                          <a
                                                            className="ms-2"
                                                          >
                                                            {file?.fileName}
                                                          </a>
                                                        </div>
                                                      </>
                                                    )
                                                  })}

                                                </ul>

                                              </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                              <Accordion.Header className="w-100">
                                                PROFIT AND LOSS STATEMENTS
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <ul className="cursor-pointer" style={listItemStyle}>
                                                  {data?.last12MonthProfitReports != '' && data?.last12MonthProfitReports?.map((file, index) => {

                                                    return (

                                                      <li>
                                                        <div
                                                          key={index}
                                                          className="cursor-pointer"
                                                          onClick={() => openDoc(file?.key, getFileExtention(file?.key))}
                                                        >
                                                          {/* {getIconByType(key)} */}
                                                          <a
                                                            className="ms-2 "

                                                          >
                                                            {file?.fileName}
                                                          </a>

                                                        </div>
                                                      </li>

                                                    )
                                                  })}

                                                </ul>

                                              </Accordion.Body>
                                            </Accordion.Item>


                                            <Accordion.Item eventKey="2">
                                              <Accordion.Header className="w-100">
                                              Franchise Documents/PIP
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <ul className="cursor-pointer" style={listItemStyle}>
                                                  {data?.plpReport != '' && data?.plpReports?.map((file, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={index}
                                                          className="cursor-pointer"
                                                          onClick={() => openDoc(file?.key, getFileExtention(file?.key))}
                                                        >
                                                          {/* {getIconByType(key)} */}
                                                          <a
                                                            className="px-2"
                                                          >
                                                            {file?.fileName}
                                                          </a>
                                                        </div>
                                                      </>
                                                    )
                                                  })}

                                                </ul>

                                              </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item eventKey="3">
                                              <Accordion.Header className="w-100">
                                              OFFERING MEMORANDUM
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                <ul className="cursor-pointer" style={listItemStyle}>
                                                  {data?.additionalDocsList != '' && data?.additionalDocsList?.map((file, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={index}
                                                          className="cursor-pointer"
                                                          onClick={() => openDoc(file?.key, getFileExtention(file?.key))}
                                                        >
                                                          {/* {getIconByType(key)} */}
                                                          <a
                                                            className="px-2"
                                                          >
                                                            {file?.fileName}
                                                          </a>
                                                        </div>
                                                      </>
                                                    )
                                                  })}

                                                </ul>

                                              </Accordion.Body>
                                            </Accordion.Item>
                                          </Accordion>

                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </>
                              </Tab>
                              :
                              <></>
                            }

                          </Tabs>

                          <Modal show={isDocOpen} size="xl" onHide={closeDoc}>
                            {pdfBytes != null ?
                              <FilePreview arrayBuffer={pdfBytes} fileType={openDocType}></FilePreview>
                              : <></>
                            }
                          </Modal>

                          <br></br>
                          <br></br>
                          <Row>

                            <Modal show={show} size="xl" onHide={closeDoc}>
                              {pdfBytes != null ?
                                <PdfFormFiller arrayBuffer={pdfBytes} fileType={openDocType} formDetails={userFormDetails} handleIAgree={interestHandler} handleCancel={handleClose}></PdfFormFiller>
                                : <></>
                              }
                            </Modal>

                            <Modal size="lg" className="d-flex align-items-center justify-content-center  custom-modal-hotel " show={customModal.show}>
                        <br></br>
                              <Modal.Body >
                                <Row>
                                  <Col className="d-flex align-items-center justify-content-center">
                                    <h3><b>{customModal.title}</b></h3>
                                  </Col>
                                </Row>
                                <br></br>
                                <br></br>
                                <Row>
                                  <Col className="d-flex align-items-center justify-content-center">
                                    <label className="font-size-primary-20">{customModal.message}</label>
                                  </Col>
                                </Row>
                                <br></br>
                                <Row>
                                  <Col className="d-flex align-items-center justify-content-center">
                                    <label className="font-size-primary-20">{customModal.message2}</label>
                                  </Col>
                                </Row>
                                <br></br>
                                <Row>
                                  <Col className="d-flex align-items-center justify-content-center">
                                    <label className="font-size-primary-20">{customModal.submessage}</label>
                                  </Col>
                                </Row>
                                <br></br>
                                <br></br>
                                <br></br>

                                {JSON.parse(sessionStorage.getItem("user_type")).includes("Broker") || JSON.parse(sessionStorage.getItem("user_type")).includes("Agent") ?
                                  <div >
                              <Col className="d-flex flex-column justify-content-center align-item-center">
                                <Row className="px-2 py-2 ">
                                        {customModal.button1Text != "" ?
                                    <button className="btn btn-outline" value='button1' onClick={handleSwalClick}>
                                            {customModal.button1Text}
                                          </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                        {customModal.button3Text != "" ?
                                    <button className="btn btn-outline  w-100" value='button3' onClick={handleSwalClick}>
                                            {customModal.button3Text}
                                          </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                  {customModal.button5Text != "" ?
                                    <button className="btn btn-outline w-100" value='button5' onClick={handleSwalClick}>
                                      {customModal.button5Text}
                                    </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                  {customModal.button2Text != "" ?
                                    <button className="btn btn-outline  w-100" value='button2' onClick={handleSwalClick}>
                                      {customModal.button2Text}
                                    </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                        {customModal.button4Text != "" ?
                                          <button className="btn btn-outline w-100" value='button4' onClick={handleSwalClick}>
                                            {customModal.button4Text}
                                          </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                        {customModal.button6Text != "" ?
                                    <button className="btn btn-outline-cancel  w-100" value='button6' onClick={handleSwalClick}>
                                            {customModal.button6Text}
                                          </button> : <></>}
                                </Row>

                                      </Col>

                            </div>
                                  :
                                  <>
                              <Row className="d-flex">

                                <Col className="fit-content-max">
                                        {customModal.button3Text != "" ?
                                    <button className="btn btn-outline fit-content-max" value='button3' onClick={handleSwalClick}>
                                            {customModal.button3Text}
                                          </button> : <></>}
                                      </Col>

                                <Col className="fit-content-max">
                                        {customModal.button4Text != "" ?
                                    <button className="btn btn-outline fit-content-max" value='button4' onClick={handleSwalClick}>
                                            {customModal.button4Text}
                                          </button> : <></>}
                                      </Col>

                                <Col className=" fit-content-max">
                                        {customModal.button6Text != "" ?
                                    <button className="btn btn-outline fit-content-max" value='button6' onClick={handleSwalClick}>
                                            {customModal.button6Text}
                                          </button> : <></>}
                                      </Col>
                                    </Row>
                            </>
                                }
                              </Modal.Body >
                              <Modal.Footer className="d-flex align-items-center justify-content-center">
                                <label>
                                  Go to your deal box to see Due Diligence and Offering Memorandum
                                </label>
                              </Modal.Footer>
                            </Modal>
                          </Row>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default PropertyProfile