import axios from "axios";
import { URL, AUTH } from "../constants/constants";

const authInstance = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "Basic " + btoa(AUTH),
  },
});

authInstance.interceptors.request.use(
  function (config) {
    document.getElementById("loading_spinner").hidden = false;
    return config;
  },

  function (error) {
    document.getElementById("loading_spinner").hidden = false;
    return Promise.reject(error);
  }
);

authInstance.interceptors.response.use(
  function (response) {
    document.getElementById("loading_spinner").hidden = true;
    return response;
  },

  function (error) {
    document.getElementById("loading_spinner").hidden = true;
    return Promise.reject(error);
  }
);

export default authInstance;
