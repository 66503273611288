import React, { useEffect, useState, useMemo, useCallback } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableComponent from "../../Tables/TableComponent";
import ListGroup from "react-bootstrap/ListGroup";
import { BiFilter, BiHeart } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  getDashboard,
  getDealBox,
  getPrimaryStates,
  getPropertyTypes,
  getUserInfo,
} from "../../../api/api";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdClose } from "react-icons/md";
import { SlArrowDown } from "react-icons/sl";
import CurrencyFormat from "react-currency-format";
import { brandTypes, buyingPowers, days45deadlines } from "../../common/utils";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";
import {
  agentHandler,
  stateFormaterByIds,
  stateFormatter,
  tableValueParser,
} from "../../common/commonUtils";
import { dealBox } from "../../../constants/constants";

const BrokerBuyersList = () => {
  const [buyers, setBuyers] = useState([]);
  const [allBuyers, setAllBuyers] = useState([]);
  const [unfilteredBuyers, setUnfilteredBuyers] = useState([]);
  const [dealBoxUpdate, setDealBoxUpdate] = useState(false);
  const [buyerDeals, setBuyerDeal] = useState([]);
  const [states, setStates] = useState([]);
  const [brand, setBrands] = useState([]);
  const [buyingPower, setBuyingPower] = useState([]);
  const [days45deadline, setDays45deadline] = useState([]);
  const [check, setCheckAll] = useState(false);
  const [filterList, setFilterList] = useState(true);
  const [large, setLarge] = useState(true);

  const [applied_filters, setApplied_filters] = useState({
    buyingPower: false,
    deadline_45: false,
    brand: false,
    state: false,
  });

  const initValues = {
    buyer_min: "",
    buyer_max: "",
    deadline_45_min: "",
    deadline_45_max: "",
    brand: "",
    state: "",
    buyingPowerId: "",
    days45deadlineId: "",
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleClosedPosts = (e) => {
    if (e.target.checked) {
      setBuyers(unfilteredBuyers);
    } else {
      setBuyers(allBuyers);
    }
    setIsChecked(!isChecked);
  };

  const handleFilterChange = (e) => {
    // console.log(formik.values)
    if (e.target.name == "buyingpower") {
      buyingPower.map((val) => {
        if (val.id == e.target.value) {
          formik.setFieldValue("buyer_min", val.min);
          formik.setFieldValue("buyer_max", val.max);
          formik.setFieldValue("buyingPowerId", val.id);
          formik.handleSubmit();
        }
      });
    }
    if (e.target.name == "45daysdeadline") {
      days45deadline.map((val) => {
        if (val.id == e.target.value) {
          formik.setFieldValue("deadline_45_min", val.min);
          formik.setFieldValue("deadline_45_max", val.max);
          formik.setFieldValue("days45deadlineId", val.id);
          formik.handleSubmit();
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {
      filterData();
      setFilterList((current) => !current);
    },
  });

  const navigate = useNavigate();

  useEffect( () => {
    getAllBuyers();
    getUserInfo().then((user) =>
      user?.hbaStaff ? agentHandler(user?.hbaStaff) : <></>
    );
    sessionStorage.removeItem("currentPostid");
  }, []);


  const getAllBuyers = async () => {
    let res = await getDashboard();
    setUnfilteredBuyers(res?.data?.buyers)
    let filteredBuyers = []
    res?.data?.buyers?.map((buyer) => {
      { buyer?.dealStatus != "Closed" ? filteredBuyers.push(buyer)  : <></>}
    })
    setBuyers(filteredBuyers);
    setAllBuyers(filteredBuyers);
    let val = await getPrimaryStates();
    setStates(val);

    setBrands(brandTypes);
    setBuyingPower(buyingPowers);
    setDays45deadline(days45deadlines);

    setFilterList((current) => !current);

    // getBuyerDeals(filteredBuyers, dealBox);
  };

  // const getBuyerDeals = async (buyers,dealBox) => {
  //   try {
      
  //     updateBuyerDealBox(dealBox,buyers);
      
  //   } catch (error) {
  //     console.error("Error fetching or processing deals:", error);
  //   }
  // };

  // const updateBuyerDealBox = (dealRes,buyers) => {
  //   let buyerUpdated = [];
  //   buyers?.forEach((buyer) => {
      
  //     const match = dealRes?.data?.buyers.find((deal) => buyer?.id === deal?.id);
  //     if (match) {
  //       buyer.dealBoxCount = match?.dealBox ? match?.dealBox?.length : 0;
  //       buyerUpdated.push(buyer);
  //     }
  //   });
  
  //   if (buyerUpdated.length > 0) {
  //     console.log(buyerUpdated)
  //     setBuyers(buyerUpdated);
  //     setDealBoxUpdate(true);
  //   }
  // };
  

  //-----------------------------------------------FILTERS LOGIC----------------------------------------------------//

  const filterData = () => {
    let currentFilteredData = allBuyers;

    if (formik.values.buyer_min !== "" || formik.values.buyer_max !== "") {
      const min = formik.values.buyer_min !== "" ? formik.values.buyer_min : 0;
      const max =
        formik.values.buyer_max !== ""
          ? formik.values.buyer_max
          : 5000000000000000;
      currentFilteredData = currentFilteredData.filter(
        (b) => b.buyingPower >= min && b.buyingPower <= max
      );
    }

    if (
      formik.values.deadline_45_min !== "" ||
      formik.values.deadline_45_max !== ""
    ) {
      const min =
        formik.values.deadline_45_min !== ""
          ? formik.values.deadline_45_min
          : 0;
      const max =
        formik.values.deadline_45_max !== ""
          ? formik.values.deadline_45_max
          : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((b) => {
        if (max <= 14 || (min == 0 && max == 5000000000000000)) {
          return (
            b.deadLineDate45DaysString == "critical" ||
            (b.deadLineDate45DaysString >= min &&
              b.deadLineDate45DaysString <= max)
          );
        } else {
          return (
            b.deadLineDate45DaysString >= min &&
            b.deadLineDate45DaysString <= max
          );
        }
      });
    }

    if (formik.values.brand !== "" && formik.values.brand !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => {
        if (b.interestedBrands) {
          const brands = b.interestedBrands.includes(",")
            ? b.interestedBrands.split(",").map((brand) => brand.trim())
            : [b.interestedBrands];
          return brands.includes(formik.values.brand);
        }
        return false;
      });
    }

    if (formik.values.state !== "" && formik.values.state !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => {
        if (b.interestedPrimaryStatesList) {
          const states = b.interestedPrimaryStatesList.map(
            (state) => state?.id
          );
          return states.includes(parseInt(formik.values.state));
        }
        return false;
      });
    }

    setBuyers(currentFilteredData);
  };

  //-----------------------------------------------------------------------------------------------------------------//

  const showDeadline = (date, color) => {
    let divClass = "";

    divClass = `right-div-${color}-button`;

    return (
      <div className="date-wrapper">
        <div className={divClass}>{date ? date : "-"}</div>
      </div>
    );
  };

  const showClosingDate = (date, color) => {
    let divClass = "";
    if (color === "green") {
      divClass = "closingdate-div-green";
    } else if (color === "red") {
      divClass = "closingdate-div-red";
    } else if (color === "yellow") {
      divClass = "closingdate-div-yellow";
    }

    return (
      <div className="date-wrapper">
        <div className={divClass}>{date ? date : "-"}</div>
      </div>
    );
  };

  const loginIfNot = () => (
    <div>
      <span>Please Login</span>
      <button className="login-toast" onClick={() => navigate("/register")}>
        Register
      </button>
      <button className="login-toast" onClick={() => navigate("/login")}>
        Login
      </button>
    </div>
  );

  const notifyLogin = () => {
    toast.error(loginIfNot, {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const notifyGetApproved = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: "my-swal",
        popup: "my-swal-popup-notify",

        content: "my-swal-content",
      },
    });

    swalWithBootstrapButtons.fire({
      title: "Your profile is being reviewed",
      html: `<div>
              <h4>Please wait until your profile is approved to access the buyers</h4>
              </br>    
              <h4> You can contact us on </h4>    
              <h4><b>info@1031hotels.com</b></h4>
              <h4><b>661-808-4998</b></h4>
            </div>
        `,
    });
  };

  const toastLogin = (id) => {
    const user = sessionStorage.getItem("user");
    const approvedSeller = sessionStorage.getItem("Appseller");
    if (user) {
      if (true) {
        navigate("/buyerPost/" + id);
      } else {
        notifyGetApproved();
      }
    } else {
      notifyLogin();
    }
  };

  const columns = [
    {
      dataField: "generatedBuyerId",
      text: "1031 Exchange Buyer ID",
      classes:
        large === true
          ? "data-stylebuyer-id-lg hand-cursor"
          : "data-style-id hand-cursor",
      formatter: (value) => (
        <label className="hand-cursor">
          {value ? value : "1031 Exchange Buyer ID"}
        </label>
      ),
      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let id = row.id;
          toastLogin(id);
        },
      },
      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>1031 Exchange Buyer ID</Tooltip>}
        >
          <label>1031 Exchange Buyer ID</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "buyerName",
      text: "Buyer Name",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value) => <label>{value !== null ? `${value}` : "-"}</label>,
      headerFormatter: (column) => (
        <OverlayTrigger placement="top" overlay={<Tooltip>Buyer Name</Tooltip>}>
          <label>Buyer Name</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "funds1031",
      text: "1031 Exchange Funds",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (value) => (
        <CurrencyFormat
          value={value != null ? value : 0}
          decimalScale={0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      ),
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>1031 Exchange Funds</Tooltip>}
        >
          <label>1031 Exchange Funds</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "buyingPower",
      text: "Max Buying Power",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (value) => (
        <CurrencyFormat
          value={value != null ? value : 0}
          decimalScale={0}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
        />
      ),
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Max Buying Power</Tooltip>}
        >
          <label>Max Buying Power</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "deadLineDate45DaysString",
      text: "Days to 45-day deadline",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (date, val) => showDeadline(date, val.deadLineDate45DaysColor),
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Days to 45-day deadline</Tooltip>}
        >
          <label>Days to 45-day deadline</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "closingDate1031String",
      text: "Days to 1031 closing",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (date, val) => showClosingDate(date, val.closingDate1031Color),
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Days to 1031 closing</Tooltip>}
        >
          <label>Days to 1031 closing</label>
        </OverlayTrigger>
      ),
    },
    // {
    //   dataField: "dealboxCount",
    //   text: "# Active Deals",
    //   headerStyle: { fontSize: "10px", textAlign: "center" },
    //   style: { fontSize: "10px", textAlign: "center" },
    //   sort: true,

    //   formatter: (value, row) => (
    //     <label>{value !== null || value != undefined ? `${value}` : <Spinner animation="border" size="sm" /> }</label>
    //   ),
    //   classes: large ? "data-style-lg" : "data-style",
    //   headerFormatter: (column) => (
    //     <OverlayTrigger
    //       placement="top"
    //       overlay={<Tooltip># Active Deals</Tooltip>}
    //     >
    //       <label># Active Deals</label>
    //     </OverlayTrigger>
    //   ),
    // },
    {
      dataField: "interestedBrands",
      text: "Interested Hotel Brand",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value) => (
        <label>{value !== null ? `${tableValueParser(value)}` : "-"}</label>
      ),
      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Interested Hotel Brand</Tooltip>}
        >
          <label>Interested Hotel Brand</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "interestedCategory",
      text: "Interested Market Segment",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value) => (
        <label>{value !== null ? `${tableValueParser(value)}` : "-"}</label>
      ),
      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Interested Market Segment</Tooltip>}
        >
          <label>Interested Market Segment</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "interestedRoomCount",
      text: "No.of Rooms",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (value, cell, row) => (
        <label>
          {value && cell.interestedRoomCount !== null ? `${value}` : "Any"}
        </label>
      ),
      sort: true,

      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>No.of Rooms</Tooltip>}
        >
          <label>No.of Rooms</label>
        </OverlayTrigger>
      ),
    },
    {
      dataField: "interestedPrimaryStates",
      text: "Primary States",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value) => <label>{stateFormaterByIds(value)}</label>,
      headerFormatter: (column) => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Primary States</Tooltip>}
        >
          <label>Primary States</label>
        </OverlayTrigger>
      ),
    },
  ];

  const filterHandler = () => {
    setFilterList((current) => !current);
  };

  const resetHandler = () => {
    formik.resetForm();
    setBuyers(allBuyers);
    setFilterList((current) => !current);
  };

  //------------------------------------------------------filter functions--------------------------------//

  const checkAllStates = () => {
    setCheckAll({ ...check, allStates: !check.allStates });
    setTimeout(
      setFilterList((current) => !current),
      1500
    );
    setApplied_filters({ ...applied_filters, state: !applied_filters.state });
  };

  const removeFilterHandler = (val, array) => {
    if (array === "brand") {
      let data = formik.values.brand;
      formik.values.brand = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setBuyers(allBuyers);
      } else filterData();
      setFilterList((current) => !current);
    } else if (array === "state") {
      let data = formik.values.state;
      formik.values.state = data.filter(
        (item) => JSON.parse(item).name !== val
      );
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setBuyers(allBuyers);
      } else filterData();
      setFilterList((current) => !current);
    }
  };

  const clearFilters = (val) => {
    const values = formik.values;
    if (val === "mbp") {
      formik.values.buyer_min = initValues.buyer_min;
      formik.values.buyer_max = initValues.buyer_max;
      if (
        values.deadline_45_min !== "" ||
        values.deadline_45_max !== "" ||
        values.brand.length ||
        values.state.length
      ) {
        filterData();
      } else {
        setBuyers(allBuyers);
      }
    } else {
      formik.values.deadline_45_min = initValues.deadline_45_min;
      formik.values.deadline_45_max = initValues.deadline_45_max;
      if (
        values.buyer_min !== "" ||
        values.buyer_max !== "" ||
        values.brand.length ||
        values.state.length
      ) {
        filterData();
      } else {
        setBuyers(allBuyers);
      }
    }
    setFilterList((current) => !current);
  };

  //------------------------------------------------------filter functions--------------------------------//

  const filterTabs = () => {
    return (
      <div className="filter-div">
        <div style={{ marginLeft: "-5px" }} className="row ">
          <div className="col col-xl col-lg-4 col-md-4">
            <label data="Maximum Buying Power">
              <b>Maximum Buying Power</b>
            </label>
            <br></br>
            <select
              name="buyingpower"
              onChange={(e) => {
                handleFilterChange(e);
              }}
              className="filter-div-button-wrapper"
              value={formik.values.buyingPowerId}
            >
              {buyingPower
                ? buyingPower.map((val, index) => (
                    <option
                      key={index}
                      className="filter-select"
                      name="buyingpower"
                      value={val.id}
                    >
                      {val.label}
                    </option>
                  ))
                : " "}
            </select>
          </div>

          <div className="col col-xl col-lg-4 col-md-4">
            <label data="Maximum Buying Power">
              <b>Days To 45-Days Deadline</b>
            </label>
            <br></br>
            <select
              name="45daysdeadline"
              onChange={(e) => {
                handleFilterChange(e);
              }}
              className="filter-div-button-wrapper"
              value={formik.values.days45deadlineId}
            >
              {days45deadline
                ? days45deadline.map((val, index) => (
                    <option
                      key={index}
                      className="filter-select"
                      name="45daysdeadline"
                      value={val.id}
                    >
                      {val.label}
                    </option>
                  ))
                : " "}
            </select>
          </div>

          <div className="col col-xl col-lg-4 col-md-4 ">
            <label data="Maximum Buying Power">
              <b>Brand</b>
            </label>
            <br></br>
            <div className="dropdown">
              <div
                className={
                  formik.values.brand.length > 0
                    ? "filter-div-button-wrapper-active"
                    : "filter-div-button-wrapper"
                }
              >
                <button
                  className="scroll filter-btn-icon"
                  type="button"
                  id="filterDrops"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <div className="scroll">
                    <div className="filter-select-wrapper">
                      {formik.values.brand.length ? (
                        formik.values.brand.map((val, index) => (
                          <div className="filter-select" key={index}>
                            {val}
                            <button
                              onClick={() => removeFilterHandler(val, "brand")}
                              className="remove-filter"
                            >
                              <MdClose />
                            </button>
                          </div>
                        ))
                      ) : (
                        <span style={{ paddingLeft: "5px" }}>Brand</span>
                      )}
                    </div>
                  </div>
                </button>
                <div className="filter-btn-icon">
                  <SlArrowDown />
                </div>
                <ul
                  id="brand-list"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                >
                  <form onSubmit={formik.handleSubmit}>
                    <div className="brands">
                      {brand
                        ? brand.map((val, index) => (
                            <li key={index}>
                              <input
                                name="brand"
                                value={val}
                                onChange={formik.handleChange}
                                defaultChecked={formik.values.brand?.includes(
                                  val
                                )}
                                id="brand"
                                type="checkbox"
                              />
                              <label className="label-class" htmlFor="brand">
                                {val}
                              </label>
                            </li>
                          ))
                        : " "}
                    </div>
                    <button
                      style={{ marginTop: "2px" }}
                      type="submit"
                      className="apply_filter"
                    >
                      Apply
                    </button>
                  </form>
                </ul>
              </div>
            </div>
          </div>
          <div className="col col-xl col-lg-4 col-md-4">
            <label data="Maximum Buying Power">
              <b>State</b>
            </label>
            <br></br>
            <div className="dropdown">
              <div
                className={
                  formik.values.state.length > 0
                    ? "filter-div-button-wrapper-active"
                    : "filter-div-button-wrapper"
                }
              >
                {/* <span data="State"></span> */}
                <button
                  className="scroll filter-btn-icon"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <div className="scroll">
                    <div className="filter-select-wrapper">
                      {formik.values.state.length ? (
                        formik.values.state.map((val, index) => (
                          <div className="filter-select" key={index}>
                            {JSON.parse(val).name}
                            <button
                              onClick={() =>
                                removeFilterHandler(
                                  JSON.parse(val).name,
                                  "state"
                                )
                              }
                              className="remove-filter"
                            >
                              <MdClose />
                            </button>
                          </div>
                        ))
                      ) : (
                        <span style={{ paddingLeft: "5px" }}>State</span>
                      )}
                    </div>
                  </div>
                </button>
                <div className="filter-btn-icon">
                  <SlArrowDown />
                </div>
                <ul
                  id="state_div"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                >
                  <form onSubmit={formik.handleSubmit}>
                    <li>
                      <input id="buying_check" type="checkbox" />
                      <label className="label-class" htmlFor="buying_check">
                        Any
                      </label>
                    </li>
                    <div className="states">
                      {states
                        ? states?.map((state, index) => (
                            <li key={index}>
                              <input
                                name="state"
                                onChange={formik.handleChange}
                                value={JSON.stringify(state)}
                                defaultChecked={formik.values.state?.includes(
                                  JSON.stringify(state)
                                )}
                                id="state"
                                type="checkbox"
                              />
                              <label
                                className="label-class"
                                htmlFor="buying_check"
                              >
                                {state.name}
                              </label>
                            </li>
                          ))
                        : " "}
                    </div>
                    <button
                      type="submit"
                      style={{ marginTop: "2px" }}
                      className="apply_filter"
                    >
                      Apply
                    </button>
                  </form>
                </ul>
              </div>
            </div>
          </div>
          <div className="col col-xl col-lg-4 col-md-4">
            <button onClick={resetHandler} type="button" className="reset-btn">
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  };
  const filteration = useMemo(() => filterTabs, [filterList]);

  const filterBtnTabs = useCallback(() => {
    return (
      <div className="filter-list">
        <button className="filter-list-btn" onClick={filterHandler}>
          Filters <BiFilter />
        </button>
        <div className={filterList === false ? "hide-tru" : "show-tru"}>
          <ListGroup>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  Buyer Power
                </button>
                <select
                  name="buyingpower"
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                  className="filter-div-button-wrapper"
                  value={formik.values.buyingPowerId}
                >
                  {buyingPower
                    ? buyingPower.map((val, index) => (
                        <option
                          key={index}
                          className="filter-select"
                          name="buyingpower"
                          value={val.id}
                        >
                          {val.label}
                        </option>
                      ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  Day to 45-day Deadline
                </button>
                <select
                  name="45daysdeadline"
                  onChange={(e) => {
                    handleFilterChange(e);
                  }}
                  className="filter-div-button-wrapper"
                  value={formik.values.days45deadlineId}
                >
                  {days45deadline
                    ? days45deadline.map((val, index) => (
                        <option
                          key={index}
                          className="filter-select"
                          name="45daysdeadline"
                          value={val.id}
                        >
                          {val.label}
                        </option>
                      ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  Brand Category
                </button>
                <ul
                  id="brand-list"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                >
                  <li>
                    <input id="buying_check" defaultChecked type="checkbox" />
                    <label className="label-class" htmlFor="buying_check">
                      Any
                    </label>
                  </li>
                  <div className="brands">
                    {brand
                      ? brand.map((val, index) => (
                          <li key={index}>
                            <input
                              name="brand"
                              value={val}
                              onChange={formik.handleChange}
                              id="brand"
                              type="checkbox"
                            />
                            <label className="label-class" htmlFor="brand">
                              {val}
                            </label>
                          </li>
                        ))
                      : " "}
                  </div>
                  <button
                    type="submit"
                    onClick={formik.handleSubmit}
                    style={{ marginTop: "2px" }}
                    className="apply_filter"
                  >
                    Apply
                  </button>
                </ul>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  State
                </button>
                <ul
                  id="state_div"
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenu2"
                >
                  <li>
                    <input id="state_check" defaultChecked type="checkbox" />
                    <label className="label-class" htmlFor="state_change">
                      Any
                    </label>
                  </li>
                  <div className="states">
                    {states
                      ? states.map((state, index) => (
                          <li key={index}>
                            <input
                              name="state"
                              value={state.abbreviation}
                              onChange={formik.handleChange}
                              id="buying_check"
                              type="checkbox"
                            />
                            <label
                              className="label-class"
                              htmlFor="buying_check"
                            >
                              {state.name}
                            </label>
                          </li>
                        ))
                      : " "}
                  </div>
                  <button
                    type="submit"
                    onClick={formik.handleSubmit}
                    style={{ marginTop: "2px" }}
                    className="apply_filter"
                  >
                    Apply
                  </button>
                </ul>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <button onClick={resetHandler} className="filter-list-btn">
          Reset
        </button>
      </div>
    );
  }, [filterList]);

  return (
    <div className="padding-wrapper-custom">
      <div className="buyer-profile-page ">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <h5 className="boldBlue-color-filler">
                  SELECT YOUR BUYER FOR WHICH YOU ARE SHOPPING FOR
                </h5>
              </div>
              <div className="d-flex justify-content-end">
                <div>
                  <label>
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleClosedPosts}
                    />
                    <b className="px-1">Show Closed Buyers</b>
                  </label>
                </div>
              </div>
              <div
                className="wrapper bd-buyer-interests table-hover"
                style={{ height: "75vh" }}
              >
                <TableComponent
                  columns={columns}
                  products={buyers}
                  filterTabs={""}
                  filterBtnTabs={""}
                  searchData={false}
                  noDataMessage={
                    <>
                      <br></br>
                      <br></br>
                      <span>You have no buyers posted</span>
                      <br></br>
                      <br></br>
                      <button
                        className="btn btn-primary"
                        onClick={() => navigate("/addBuyer")}
                      >
                        Post a Buyer
                      </button>
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerBuyersList;
