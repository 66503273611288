import React from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import CurrencyFormat from 'react-currency-format';

const DashboardPropertyCard = ({ property }) => {
  const navigate = useNavigate();

  const viewDealHandler = () => {
    navigate('/propertyPost/'+property.id)
  }

  return (
      <>
        <div className="propertycard-upper-portion">
          <h4 className="dashboard-card-heading font-size-25">{`${property.primaryState?.name}`}, {`${property.primaryState.code}`}</h4>
          <h4 className="dashboard-card-dealstatus font-size-25">{property.dealStatus ? property.dealStatus : '-'}</h4>
        </div>
        <div className="card-carousel card-carousel-red mt-0 add-buyer-card-body">
          <div className="row">
            <div id="prop-id" className="col-12 text-center card-text-carousel">
              { property.generatedPropertyId }
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center card-carousel-amount font-size-25">
            <CurrencyFormat value={property.askingPrice ? property.askingPrice : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              <span className="buying-power">ASKING PRICE</span>
            </div>
          </div>
          <div className="row">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row" width="60%">
                    <span className="background-layer">
                      Brand of Hotel For Sale
                    </span>
                  </th>
                  <td>
                    <span>
                      {property.propertyType?.brandName
                        ? property.propertyType.brandName
                        : "-"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <span className="background-layer">
                      Nearest MSA, State
                    </span>
                  </th>
                  <td>
                    <span>
                      {property.nearestMSA
                        ? property.nearestMSA
                        : "-"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    {" "}
                    <span className="background-layer">
                      Year Built
                    </span>
                  </th>
                  <td>{property.yearBuilt ? property.yearBuilt : "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
            <div className="col-6 offset-6">
              <button onClick={viewDealHandler} className="property-view-deal" >View Hotel < FaPlus style={{marginLeft : '5px'}} /></button>
            </div>
          </div>
      </>
  );
};

export default DashboardPropertyCard;
