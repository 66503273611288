import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import Table from "../Tables/Table";
import BuyerCriteria from "./buyerCriteriaPage";
import SellerCriteria from "./sellerCiteriaPage";

const BuyerPostTable = ({
  filterTabs: FilterTabs,
  products,
  column,
  reset: Reset,
  resetHandler,
  filtersapply,
  defaultFilter,
  blueFilters,
  enableSort,
  setEnableSort,
  user,
  refreshData,
  approved,
}) => {
  const buyerFilters = [
    "Deal Box",
    "Deals Selected By You",
    "Deals Received By You",
    "Deals Rejected By You",
    "Shop Hotels For Sale",
    "Confis You Signed",
    "Your Buyer Profile",
  ];

  const sellerFilters = [
    "Deal Box",
    "Deals Selected By You",
    "Deals Received By You",
    "Deals Rejected By You",
    "Shop 1031 Buyers",
    "Your Hotel Profile",
  ];

  const [activeFilter, setActiveFilter] = useState("");
  const [blueFiltersLoading, setBlueFiltersLoading] = useState(false);
  const [interestReceivedBtnFlag, setInterestReceivedBtnFlag] = useState(false);
  const [dealBoxFlag, setDealBoxFlag] = useState(false);
  const [filters, setFilter] = useState([]);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [rows, setRows] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [rejectedDealsFlag, setRejectedDealsFlag] = useState(false);

  useEffect(async () => {
    {
      column == "buyercolumns"
        ? setFilter(buyerFilters)
        : setFilter(sellerFilters);
    }
    applyFilter(defaultFilter);
  }, []);


  const getNoDataMessage = () => {
    if (column == "buyercolumns") {
      if (activeFilter == "Deal Box") {
        return (
          <div style={{pointerEvents: 'auto'}}>
            <br></br>
            <br></br>
            <span>You have no deals</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop Hotels For Sale"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop Hotels For Sale
            </button>
          </div>
        );
      } else if (activeFilter == "Deals Selected By You") {
        return (
          <>
            <br></br>
            <br></br>
            <span>You have no requested hotels</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop Hotels For Sale"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop Hotels For Sale
            </button>
          </>
        );
      } else if (activeFilter == "Deals Received By You") {
        return (
          <>
            <br></br>
            <br></br>
            <span>You have not received any hotel requests yet</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop Hotels For Sale"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop Hotels For Sale
            </button>
          </>
        );
      } else if (activeFilter == "Shop Hotels For Sale") {
        // return "You have no hotels to shop"
        return (
          <>
            <br></br>
            <br></br>
            <span>You have no hotels to shop</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop Hotels For Sale"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop Hotels For Sale
            </button>
          </>
        );
      } else if (activeFilter == "Confis You Signed") {
        return (
          <>
            <br></br>
            <br></br>
            <span>No Confis signed</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop Hotels For Sale"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop Hotels For Sale
            </button>
          </>
        );
      } else if (activeFilter == "Deals Rejected By You") {
        return (
          <>
            <br></br>
            <br></br>
            <span>No Deals Rejected By You</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop Hotels For Sale"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop Hotels For Sale
            </button>
          </>
        );
      }
    }

    if (column == "propscolumn") {
      if (activeFilter == "Deal Box") {
        // return "You have no deals"
        return (
          <>
            <br></br>
            <br></br>
            <span>You have no deals</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop 1031 Buyers"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop 1031 Buyers
            </button>
          </>
        );
      } else if (activeFilter == "Deals Selected By You") {
        // return "You have no requested buyers"
        return (
          <>
            <br></br>
            <br></br>
            <span>You have no requested buyers</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop 1031 Buyers"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop 1031 Buyers
            </button>
          </>
        );
      } else if (activeFilter == "Deals Received By You") {
        // return "You have not received any buyer requests yet"
        return (
          <>
            <br></br>
            <br></br>
            <span>You have not received any buyer requests yet</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop 1031 Buyers"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop 1031 Buyers
            </button>
          </>
        );
      } else if (activeFilter == "Shop 1031 Buyers") {
        // return "You have no buyers to shop"
        return (
          <>
            <br></br>
            <br></br>
            <span>You have no buyers to shop</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop 1031 Buyers"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop 1031 Buyers
            </button>
          </>
        );
      } else if (activeFilter == "Deals Rejected By You") {
        return (
          <>
            <br></br>
            <br></br>
            <span>No Deals Rejected By You</span>
            <br></br>
            <br></br>
            <button
              className="btn btn-primary"
              value={"Shop 1031 Buyers"}
              onClick={(e) => applyFilter(e.target.value)}
            >
              Shop 1031 Buyers
            </button>
          </>
        );
      }
    }
  };

  
  const getOverlayNoRowsTemplate = () => {
    let message = "";
    let buttonText = "";

    if (column === "buyercolumns") {
      message = "You have no deals";
      buttonText = "Shop Hotels For Sale";
    } else if (column === "propscolumn") {
      message = "You have no deals";
      buttonText = "Shop 1031 Buyers";
    }

    return `
        <div style="text-align: center; padding: 20px;">
            <br><br>
            <span>${message}</span>
            <br><br>
            <button class="btn btn-primary" onclick="applyFilter('${buttonText.replace(
              /'/g,
              "\\'"
            )}')">${buttonText}</button>
        </div>
    `;
  };

  const applyFilter = (flt) => {
    setBlueFiltersLoading(true);
    blueFilters(flt);
    setIsDropdownOpen(false);

    if (flt === "Deals Received By You") {
      setInterestReceivedBtnFlag(true);
      setDealBoxFlag(false);
    } else if (flt === "Deals Received By You") {
      setInterestReceivedBtnFlag(true);
      setDealBoxFlag(false);
    } else if (flt === "Deal Box") {
      setInterestReceivedBtnFlag(false);
      setDealBoxFlag(true);
    } else {
      setInterestReceivedBtnFlag(false);
      setDealBoxFlag(false);
    }

    if (flt.includes("rejected")) {
      console.log(flt);
      setRejectedDealsFlag(true);
    } else {
      setRejectedDealsFlag(false);
    }
    setTimeout(() => {
      setBlueFiltersLoading(false);
    }, 1100);

    setRows(products);
    setEnableSort(false);
    setActiveFilter(flt);
    setEnableSort(true);
  };

  return (
    <div className="buyer-post-table">
      <div className="row px-2 py-2">
        <div className="col">
          <div className="row px-4 py-2">
            <div className="col fit-content-max">
              <div className="dropdown ">
                <button
                  className={
                    activeFilter.includes("Deals") ||
                    activeFilter.includes("Deal")
                      ? "btn btn-primary-narrow dropdown-toggle w-100"
                      : "btn btn-outline-primary-narrow dropdown-toggle w-100"
                  }
                  type="button"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  {activeFilter.includes("Deal") ||
                  activeFilter.includes("Deals")
                    ? activeFilter
                    : "Deal Box"}
                </button>
                <div
                  className={`dropdown-menu w-auto px-1${
                    isDropdownOpen ? " show" : ""
                  }`}
                >
                  {filters.map((filter, idx) => {
                    return filter.includes("Deal") ||
                      filter.includes("Deals") ? (
                      <button
                        key={idx}
                        className={`dropdown-item w-100 ${
                          activeFilter === filter ? " active-item" : ""
                        }`}
                        onClick={() => applyFilter(filter)}
                      >
                        <span>
                          <b>{filter}</b>
                        </span>
                      </button>
                    ) : (
                      <></>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col px-0 fit-content-max">
              {filters.map((flt, idx) => {
                return flt.includes("Profile") || flt.includes("Confi") ? (
                  <></>
                ) : flt.includes("Shop 1031 Buyers") ||
                  flt.includes("Shop Hotels For Sale") ? (
                  <button
                    key={idx}
                    onClick={() => applyFilter(flt)}
                    className={
                      activeFilter == flt
                        ? "btn btn-red-narrow"
                        : " btn btn-outline-red-narrow "
                    }
                  >
                    {flt}
                  </button>
                ) : (
                  <></>
                );
              })}
            </div>

            <div className="col w-100 d-flex justify-content-center align-items-end py-0">
              <div className="row py-0">
                {approved == "Unapproved" ? (
                  <div className="bg-outline-red d-flex justify-content-center align-items-end py-0">
                    <h4 className="fill-white">
                      YOUR POSTING IS BEING REVIEWED
                    </h4>
                  </div>
                ) : approved == "Off-market" ? (
                  <div className="bg-outline-red d-flex justify-content-center align-items-end py-0">
                    <h4 className="fill-white">YOUR POSTING IS OFF-MARKET</h4>
                  </div>
                ) : approved == "In-contract" ? (
                  <div className="bg-outline-red d-flex justify-content-center align-items-end py-0">
                    <h4 className="fill-white">YOUR POSTING IS IN CONTRACT</h4>
                  </div>
                ) : approved == "Closed" ? (
                  <div className="bg-outline-red d-flex justify-content-center align-items-end py-0">
                    <h4 className="fill-white">YOUR POSTING IS CLOSED</h4>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="col px-0 fit-content-max">
              {filters.map((flt, idx) => {
                return flt.includes("Profile") || flt.includes("Confi") ? (
                  <button
                    key={idx}
                    onClick={() => applyFilter(flt)}
                    className={
                      activeFilter == flt
                        ? "btn btn-primary-narrow-right"
                        : "btn btn-outline-primary-narrow-right "
                    }
                  >
                    {flt}
                  </button>
                ) : flt.includes("Shop 1031 Buyers") ||
                  flt.includes("Shop Hotels For Sale") ? (
                  <></>
                ) : (
                  <></>
                );
              })}
            </div>
          </div>
          <>
            {activeFilter == "Shop Hotels For Sale" ||
            activeFilter == "Shop 1031 Buyers" ||
            activeFilter == "Shop Sellers" ? (
              <Row className="w-100">
                <Col xs={8}>
                  <FilterTabs />
                </Col>
                <Col xs={2} style={{ marginTop: "40px" }}>
                  <Reset />
                </Col>
                <Col xs={2}>
                  <div
                    className="d-flex justify-content-end align-items-end"
                    style={{ marginTop: "60px" }}
                  >
                    <span className="font-14">
                      <sup>*</sup>Hover over the Hotel ID to view images
                    </span>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </>

          <div className="row">
            <div className="col">
              <div
                className={
                  column == "buyercolumns"
                    ? "bd-buyer-interests table-hover"
                    : "bd-property-interests table-hover"
                }
              >
                {activeFilter == "Your Buyer Profile" &&
                column == "buyercolumns" ? (
                  <BuyerCriteria></BuyerCriteria>
                ) : activeFilter == "Your Hotel Profile" &&
                  column == "propscolumn" ? (
                  <SellerCriteria></SellerCriteria>
                ) : activeFilter == "Confis You Signed" &&
                  column == "buyercolumns" ? (
                  <Table
                    rows={products}
                    column={"conficolumns"}
                    user={user}
                    large={true}
                    applyFilter={applyFilter}
                    rejectedDeals={rejectedDealsFlag}
                    interest={interestReceivedBtnFlag}
                    dealbox={dealBoxFlag}
                    blueFilters={blueFilters}
                    enableSort={enableSort}
                    setEnableSort={setEnableSort}
                    loading={blueFiltersLoading}
                    refreshData={refreshData}
                    noDataMessage={getNoDataMessage}
                  />
                ) : (
                  <Table
                    rows={products}
                    column={column}
                    user={user}
                    large={true}
                    applyFilter={applyFilter}
                    rejectedDeals={rejectedDealsFlag}
                    interest={interestReceivedBtnFlag}
                    dealbox={dealBoxFlag}
                    blueFilters={blueFilters}
                    enableSort={enableSort}
                    setEnableSort={setEnableSort}
                    loading={blueFiltersLoading}
                    refreshData={refreshData}
                    noDataMessage={getNoDataMessage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyerPostTable;
