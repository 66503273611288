import React from "react";
import background from "../assets/pngwing.png";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa"
import CurrencyFormat from 'react-currency-format';

const DashboardCard = ({ buyer }) => {
  const navigate = useNavigate();

  const viewDealHandler = () => {
    navigate('/buyerPost/'+buyer.id)
  }

  const show45DayDeadline = (date, color) => {
    let divClass = "";
    if (color === "green") {
      divClass = "date-div-green";
    } else if (color === "red") {
      divClass = "date-div-red";
    } else if (color === "yellow") {
      divClass = "date-div-yellow";
    }

    return (
        <span className={divClass}>{date ? date : "-"}</span>
    );
  };

  return (
      <>
        <div className="buyercard-upper-portion">
          <h2 className="dashboard-card-heading">{buyer.primaryState ? `${buyer.primaryState?.name}, ${buyer.primaryState.code}` : "-"}</h2>
          <h2 className="dashboard-card-dealstatus">{buyer.dealStatus ? buyer.dealStatus : '-'}</h2>
        </div>
        <div className="card-carousel card-carousel-red mt-0 add-buyer-card-body">
          <div className="row">
            <div id="buyer-id" className="col-12 text-center card-text-carousel">
              {buyer?.generatedBuyerId ? buyer?.generatedBuyerId : '-'}
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center card-carousel-amount font-size-25">
              <span className="currency-color"><CurrencyFormat value={buyer.buyingPower ? buyer.buyingPower : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              <span className="buying-power">BUYING POWER</span>
            </div>
          </div>
          <div className="row" style={{marginTop:"20px"}}>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row" width="60%">
                    <span className="background-layer">
                      Days to 45-day deadline
                    </span>
                  </th>
                  <td>
                    {show45DayDeadline(buyer.deadLineDate45DaysString, buyer.deadLineDate45DaysColor)}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <span className="background-layer">
                      Days to 1031 closing
                    </span>
                  </th>
                  <td>
                    {show45DayDeadline(buyer.closingDate1031String, buyer.closingDate1031Color)}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    {" "}
                    <span className="background-layer">
                      Amount with 1031 exchange
                    </span>
                  </th>
                  {/* <td>$2.000.000</td> */}
                  <td className="currency-color"><CurrencyFormat value={buyer.funds1031 ? buyer.funds1031 : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
                </tr>
                <tr>
                  <th scope="row">
                    <span className="background-layer">
                      POF / PO 1031 / PO Escrow
                    </span>
                  </th>
                  <td>
                    {buyer.escrowClosingDateString
                      ? buyer.escrowClosingDateString
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <span className="background-layer">Looking for</span>
                  </th>
                  <td>
                    { buyer.interestedBrands === null ? '-' : buyer.interestedBrands  } &nbsp;<br/>
                    { buyer.interestedCategory === null ? '-' : buyer.interestedCategory  }  &nbsp;<br/>
                    { buyer.interestedRoomCount && buyer.interestedRoomCount === null ? '-' : `${buyer.interestedRoomCount} rooms in`  }  &nbsp;<br/>
                    { buyer.primaryState?.name === null ? '-' : buyer.primaryState?.name  } 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br></br>
        <div className="row">
            <div className="col-6 offset-6">
              <button onClick={viewDealHandler} className="buyer-view-deal" >View Buyer < FaPlus style={{marginLeft : '5px'}}/></button>
            </div>
        </div>
      </>
  );
};

export default DashboardCard;
