import React, { useEffect } from "react";
import { getAppUrl, GetClientTokenCookie } from "../../common/commonUtils";
import { getPropertyBrands, getPropertyCategories, getPropertyMarketTypes, getPropertyRoomCount } from "../../../api/api";
import vectorImage from '../../../components/assets/Vector 1.png';
import RegisterBuyerUser from "./RegisterBuyerUser";
import { CustomerSupportNumber } from "../../../constants/constants";

const Registration = () => {
  const user = sessionStorage.getItem("user");
  useEffect(() => {
    if (!user)
      document.body.classList.add("no-navbars");
    getAppUrl();
    getPropertyCategories();
    getPropertyMarketTypes();
    getPropertyRoomCount();
    getPropertyBrands();
  }, []);

  return (
    <>
      <div className="container-common registration">
        <div className="left ">

          <div className='box justify-content-center w-100'>
          
              <div className="status-nav d-flex justify-content-center align-start py-2 w-100">
                <label className="font-weight-b  fill-white px-2 highligter">Buyer Registration</label>
                <label className="font-weight-b fill-white px-2">Buyer Profile</label>
                <label className="font-weight-b fill-white px-2">Buyer Interests</label>
                <label className="font-weight-b fill-white px-2">Upload Documents</label>
                <label className="font-weight-b fill-white px-2">Accept Terms and Conditions</label>
              </div>
         
            
            <img className='common-vector margin-top-40' src={vectorImage} alt="No Vector"></img>

          </div>
          <h6 className="fill-white px-2 py-2">(Call 1031Hotels at {CustomerSupportNumber} if you need help with Registration)</h6>
        </div>
        <div className="right">
          <h2>REGISTER AS A 1031 EXCHANGE BUYER</h2>
          <form
            id="register_wizard_with_validation"
            className="form-control h-100"
            method="POST"
            data-token={GetClientTokenCookie()}
          >
            {!user && <RegisterBuyerUser userType="Buyer" />}
          </form>

        </div>
      </div>

    </>
  );
};

export default Registration;
