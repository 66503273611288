import React, { useMemo, useEffect, useState } from "react";
import Table from "../Tables/Table";
import BuyerPostTable from "./buyerPostTable";
import { useFormik } from "formik";
import { SlArrowDown } from "react-icons/sl";
import {
  getPrimaryStates,
  getProfilePicByKey,
  getProperty,
  getPropertyActivity,
  getPropertyTypes,
} from "../../api/api";
import { MdClose, MdContactMail, MdContactPhone, MdGroup } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillCaretDown, AiOutlineDown } from "react-icons/ai";
import { brandTypes, buyingPowers, dateBefore180Days, days45deadlines, formatMobileNumber } from "../common/utils";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CurrencyFormat from "react-currency-format";
import CurrencyInput from "react-currency-input-field";
import { Badge, Col, Row } from "react-bootstrap";
import DefaultUserImage from '../../components/assets/default-user.png'
import { agentHandler, getAgent, getDaysOnMarket } from "../common/commonUtils";
import { FaEye } from "react-icons/fa";
import PropertyPostTable from "./propertyPostTable";

const PropertyPostingPage = () => {
  const navigate = useNavigate();
  const [propertyloading, setPropertyLoading] = useState(true);
  const [applyFilter, setApplyFilter] = useState(false);
  const [activity, setActivity] = useState();
  const [check, setCheck] = useState({
    allStates: false,
    allBrands: false,
  });

  const [enableSort, setEnableSort] = useState(true);
  const [state, setStates] = useState([]);
  const [brand, setBrands] = useState([]);
  const [propertyInfo, setPropertyInfo] = useState();
  const [buyers, setBuyers] = useState([]);
  const [allBuyers, setAllBuyers] = useState();
  const [buyingPower, setBuyingPower] = useState([]);
  const [defaultFilter, setDefaultFilter] = useState("");
  const [days45deadline, setDays45deadline] = useState([]);
  const [imageBytes, setImageBytes] = useState();
  const [hbaStaff, setHbaStaff] = useState();
  const initValues = {
    buyer_min: "",
    buyer_max: "",
    deadline_45_min: "",
    deadline_45_max: "",
    brand: "",
    state: "",
    buyingPowerId: "",
    days45deadlineId: "",
  };

  useEffect(() => {
    getActivity(id);
    getPropertyInfo();
    
    if (filter == "dealbox") {
      blueFilters("Deal Box")
      setDefaultFilter("Deal Box")
    } else if (filter == "interestsReceived") {
      setDefaultFilter("Deals Received By You")
      blueFilters("Deals Received By You")
    } else if (filter == "shopMore") {
      setDefaultFilter("Shop 1031 Buyers")
      blueFilters("Shop 1031 Buyers")
    } else if (filter == "yourCriteria") {
      setDefaultFilter("Your Profile")
      blueFilters("Your Profile")
    } else {
      setDefaultFilter("Deal Box")
      blueFilters("Deal Box")
    }

    sessionStorage.setItem("currentPostid", id)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleFilterChange = (e) => {
    console.log(formik.values)

    if (e.target.name == "brand") {
      formik.setFieldValue('brand', e.target.value)
      formik.handleSubmit()
    }


    if (e.target.name == "state") {
      formik.setFieldValue('state', e.target.value)
      formik.handleSubmit()
    }

    if (e.target.name == "buyingpower") {
      buyingPower.map((val) => {
        if (val.id == e.target.value) {
          formik.setFieldValue('buyer_min', val.min)
          formik.setFieldValue('buyer_max', val.max)
          formik.setFieldValue('buyingPowerId', val.id)
          formik.handleSubmit()
        }
      })
    }
    if (e.target.name == "45daysdeadline") {
      days45deadline.map((val) => {
        if (val.id == e.target.value) {
          formik.setFieldValue('deadline_45_min', val.min)
          formik.setFieldValue('deadline_45_max', val.max)
          formik.setFieldValue('days45deadlineId', val.id)
          formik.handleSubmit()
        }
      })
    }


  }
  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {
      filterData();
      setApplyFilter((current) => !current);
      console.log(values);
    },
  });

  const { id, filter } = useParams();



  const getPropertyInfo = async () => {
    let res = await getProperty(id);

    const data = res.data ? res.data : res;

    if (data?.hbaStaff) agentHandler(data?.hbaStaff)

    setPropertyInfo(data);
    setBuyers(data.viewableBuyers);
    setAllBuyers(data.viewableBuyers);
   
    {(res?.data?.hbaStaff == null || res?.data?.hbaStaff == "") ?  setHbaStaff(getAgent()) :  setHbaStaff(res?.data?.hbaStaff)}

    {(res?.data?.hbaStaff == null || res?.data?.hbaStaff == "") ?  getImageCards(getAgent()?.profilePic) :  getImageCards(res?.data?.hbaStaff?.profilePic)}
    
    let val = await getPrimaryStates();
    setStates(val);
    setApplyFilter((current) => !current);


    setBrands(brandTypes)
    setBuyingPower(buyingPowers)
    setDays45deadline(days45deadlines)

    localStorage.setItem("Approved", data.dealStatus)

    setApplyFilter((current) => !current);
    setPropertyLoading(false);
  };

  const getActivity = async (id) => {
    let res = await getPropertyActivity(id);
    const data = res.data ? res.data : res;
    setActivity(data.activities);
  };

  function handleChildStateChange(childState) {
    getPropertyInfo();
  }

  const clearFilters = (val) => {
    const values = formik.values;
    if (val === "mbp") {
      formik.values.buyer_min = initValues.buyer_min;
      formik.values.buyer_max = initValues.buyer_max;
      if (
        values.deadline_45_min !== "" ||
        values.deadline_45_max !== "" ||
        values.brand.length ||
        values.state.length
      ) {
        filterData();
      } else {
        setBuyers(allBuyers);
      }
    } else {
      formik.values.deadline_45_min = initValues.deadline_45_min;
      formik.values.deadline_45_max = initValues.deadline_45_max;
      if (
        values.buyer_min !== "" ||
        values.buyer_max !== "" ||
        values.brand.length ||
        values.state.length
      ) {
        filterData();
      } else {
        setBuyers(allBuyers);
      }
    }
    setApplyFilter((current) => !current);
  };

  const removeFilterHandler = (val, array) => {
    if (array === "brand") {
      let data = formik.values.brand;
      formik.values.brand = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setBuyers(allBuyers);
      } else filterData();
      setApplyFilter((current) => !current);
    } else if (array === "state") {
      let data = formik.values.state;
      formik.values.state = data.filter(
        (item) => JSON.parse(item).name !== val
      );
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setBuyers(allBuyers);
      } else filterData();
      setApplyFilter((current) => !current);
    }
  };

  const resetHandler = () => {
    setBuyers(allBuyers);
    formik.resetForm();
    setApplyFilter((current) => !current);
  };

  //-------------------------------------------------BLUE FILTERS---------------------------------------------------//

  const last180Days = () => {
    const dateLimit = dateBefore180Days();
    let buyers = [];
    allBuyers?.forEach(byr => {
      let ptyDate = new Date(byr.creationTime);
      if (ptyDate > dateLimit) {
        buyers.push(byr);
      }
    })
    return buyers;
  }

  const blueFilters = (flt) => {
    if (flt == "Shop 1031 Buyers") {
      setBuyers([]);
      formik.resetForm();
      setApplyFilter((current) => !current);
      setTimeout(() => {
        setBuyers(allBuyers);
      }, 1000);
    }

    else if (flt == "Deals Selected By You") {
      console.log(propertyInfo?.interestSent)
      let interestedsentBuyers = []
      setBuyers([]);
      formik.resetForm();
      propertyInfo?.interestSent?.forEach(interest => interestedsentBuyers.push(interest.buyer))
      setTimeout(() => {
        setBuyers(interestedsentBuyers);
      }, 1000);
    }

    else if (flt == "Deals Received By You") {
      console.log(propertyInfo?.interestReceived)
      let interestedreceivedBuyers = []
      setBuyers([]);
      formik.resetForm();
      propertyInfo?.interestReceived?.forEach(interest => interestedreceivedBuyers.push(interest.buyer))
      setTimeout(() => {
        setBuyers(interestedreceivedBuyers);
      }, 1000);
    }
    else if (flt === "Deals Rejected By You") {
      console.log(propertyInfo?.interestReceived)
      let rejectedBuyers = [];
      setBuyers([]);
      propertyInfo?.interestReceived?.forEach(interest => {
        interest?.removeFromSellerDealBox ?
          rejectedBuyers.push(interest.buyer) : <></>
      }
      )
      propertyInfo?.interestReceived?.forEach(interest => {
        !interest?.sellerAccepted ?
          rejectedBuyers.push(interest.buyer) : <></>
      }
      )
      propertyInfo?.interestSent?.forEach(interest => {
        interest?.removeFromSellerDealBox ?
          rejectedBuyers.push(interest.buyer) : <></>
      }
      )
      setTimeout(() => {
        setBuyers(rejectedBuyers);
      }, 1000);
    }
    else if (flt == "Deal Box") {
      let dealBoxBuyers = [];
      setBuyers([]);
      formik.resetForm();
      propertyInfo?.dealBox?.sort((a, b) => a?.sellerRank - b?.sellerRank);   /// sort by rank

      propertyInfo?.dealBox?.forEach(buy => {
        let interestBuy = buy.buyer
        interestBuy.comments = buy.sellerComments
        interestBuy.rank = buy.sellerRank
        dealBoxBuyers.push(interestBuy)

      }
      );

      // propertyInfo?.dealBox?.forEach(buy => dealBoxBuyers.push(buy.buyer));
      setTimeout(() => {
        setBuyers(dealBoxBuyers);
      }, 1000);
    }
  }

  //------------------------------------------------------FILTERS LOGIC START------------------------------------------------//

  const filterData = () => {
    let currentFilteredData = allBuyers;

    if (formik.values.buyer_min !== "" || formik.values.buyer_max !== "") {
      const min = formik.values.buyer_min !== "" ? formik.values.buyer_min : 0;
      const max = formik.values.buyer_max !== "" ? formik.values.buyer_max : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((b) => b.buyingPower >= min && b.buyingPower <= max);
    }

    if (formik.values.deadline_45_min !== "" || formik.values.deadline_45_max !== "") {
      const min = formik.values.deadline_45_min !== "" ? formik.values.deadline_45_min : 0;
      const max = formik.values.deadline_45_max !== "" ? formik.values.deadline_45_max : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((b) => {
        if (max <= 14 || (min == 0 && max == 5000000000000000)) {
          return b.deadLineDate45DaysString == "critical" || (b.deadLineDate45DaysString >= min && b.deadLineDate45DaysString <= max);
        } else {
          return b.deadLineDate45DaysString >= min && b.deadLineDate45DaysString <= max;
        }
      });
    }

    if (formik.values.brand !== "" && formik.values.brand !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => {
        if (b.interestedBrands) {
          const brands = b.interestedBrands.includes(",") ? b.interestedBrands.split(",").map((brand) => brand.trim()) : [b.interestedBrands];
          return brands.includes(formik.values.brand);
        }
        return false;
      });
    }

    if (formik.values.state !== "" && formik.values.state !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => {
        if (b.interestedPrimaryStatesList) {
          const states = b.interestedPrimaryStatesList.map((state) => state?.id);
          return states.includes(parseInt(formik.values.state));
        }
        return false;
      });
    }

    setBuyers(currentFilteredData);
  };

  //------------------------------------------------------FILTERS LOGIC END------------------------------------------------//

  const filterTabs = () => {
    return (
      <div className="filter-div">
        <div
          style={{ marginLeft: "-5px" }}
          className="row justify-content-start gy-3"
        >
          <div className="col col-xl col-lg-4 col-md-4">
            <label data="Maximum Buying Power"><b>Maximum Buying Power</b></label><br></br>
            <select name="buyingpower" onChange={(e) => {
              handleFilterChange(e)
            }} className="filter-div-button-wrapper" value={formik.values.buyingPowerId}>
              {buyingPower
                ? buyingPower.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="buyingpower"
                    value={val.id}
                  >
                    {val.label}
                  </option>
                ))
                : " "}

            </select>
          </div>

          <div className="col col-xl col-lg-4 col-md-4">
            <label data="Maximum Buying Power"><b>Days To 45-Days Deadline</b></label><br></br>
            <select name="45daysdeadline" onChange={(e) => {
              handleFilterChange(e)
            }} className="filter-div-button-wrapper" value={formik.values.days45deadlineId}>
              {days45deadline
                ? days45deadline.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="45daysdeadline"
                    value={val.id}
                  >
                    {val.label}
                  </option>
                ))
                : " "}

            </select>
          </div>

          <div className="col col-xl col-lg-4 col-md-4 ">
            <label data="Maximum Buying Power"><b>Brand</b></label><br></br>
            <select name="brand" onChange={(e) => handleFilterChange(e)}
              className="filter-div-button-wrapper" value={formik.values.brand}>
              {brand
                ? brand.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val}
                  >
                    {val}
                  </option>
                ))
                : " "}

            </select>

          </div>


          <div className="col col-xl-2 col-lg-4 col-md-4 ">
            <label data="Maximum Buying Power"><b>State</b></label><br></br>
            <select name="state" onChange={(e) => handleFilterChange(e)}
              className="filter-div-button-wrapper" value={formik.values.state}>
              <option className="filter-select"
                name="askingprice"
                value="Any" >Any</option>
              {state
                ? state.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val.id}
                  >
                    {val.name}
                  </option>
                ))
                : " "}

            </select>

          </div>
          

        </div>
      </div>
    );
  };

  const filteration = useMemo(() => filterTabs, [applyFilter]);

  const resetBtn = () => {
    return (
      <button onClick={resetHandler} type="button" className="btn btn-outline-primary-narrow-right">
        Reset
      </button>
    );
  };


  const getBadgeColor = (value) => {
    if (value == "Approved") {
      return "fill-green   px-2 "
    } else if (value == "Unapproved") {
      return "fill-yellow   px-2 "
    } else if (value == "In-contract") {
      return "fill-orange   px-2 "
    } else if (value == "Closed") {
      return "fill-grey   px-2 "
    } else if (value == "Off-market") {
      return "fill-paleBlue   px-2 "
    }
  }



  const refreshData = async () => {
    let res = await getProperty(id);

    const data = res.data ? res.data : res;
    setPropertyInfo(data);
    setBuyers(data.viewableBuyers);
    setAllBuyers(data.viewableBuyers);


    setApplyFilter((current) => !current);

    setPropertyLoading(false);
  };

  const getCalculations = (name) => {
    let askingPrice = propertyInfo.askingPrice ? parseInt(propertyInfo?.askingPrice) : 0;
    let pipEstimate = propertyInfo.pipEstimate ? parseInt(propertyInfo?.pipEstimate) : 0;
    let otherRevenue = propertyInfo.otherRevenue ? parseInt(propertyInfo?.otherRevenue) : 0;
    let fandbrevenue = propertyInfo.yearlyFNBRevenue ? parseInt(propertyInfo?.yearlyFNBRevenue) : 0;
    let roomRevenue = propertyInfo.roomRevenue ? parseInt(propertyInfo?.roomRevenue) : 0;
    let netOperatingRevenue = propertyInfo.netOperatingRevenue ? parseInt(propertyInfo?.netOperatingRevenue) : 0;
    let noOfRooms = propertyInfo.noOfRooms ? parseInt(propertyInfo?.noOfRooms) : 0;
    let netIncome = propertyInfo.netIncome ? parseInt(propertyInfo?.netIncome) : 0;

    if (name == "downPayment")
      return (parseInt(askingPrice) + parseInt(pipEstimate)) * 0.2;

    if (name == "totalPrice")
      return (parseInt(askingPrice) + parseInt(pipEstimate));

    if (name == "toatlRevenue")
      return parseInt(otherRevenue) + parseInt(fandbrevenue) + parseInt(roomRevenue);

    if (name == "capRate")
      return Math.round(((parseInt(netIncome) / (parseInt(askingPrice) + parseInt(pipEstimate)))) * 100);

    if (name == "pricePerRoom")
      return Math.round(((parseInt(askingPrice) + parseInt(pipEstimate)) / noOfRooms + Number.EPSILON) * 100) / 100;;

    if (name == "revMultiplier")
      return Math.round((parseInt(askingPrice) + parseInt(pipEstimate)) / (parseInt(otherRevenue) + parseInt(fandbrevenue) + parseInt(roomRevenue)) * 100) / 100;
  }

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

const getImageCards = async (key) => {
  try {
    await getProfilePicByKey(key).then(res => {
      const bytes = new Uint8Array(res?.data);
      setImageBytes(bytes)
      return bytes
    });
  } catch (error) {
    console.error("Error downloading file:", error);
  }
}

  return (
    <>
      {propertyloading === true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "80vh",
            alignItems: "center",
          }}
        >
          <h2>Loading...</h2>
        </div>
      ) : (
        <div className="posting-page fill-blue">

          <div className="row profile-header">
            <div className="row">
              <div className="col py-1 fit-content-max">
                <div className="row">
                  <h4><><b>{propertyInfo?.sellerName.toUpperCase()}'s</b> CONFIDENTIAL HOTEL ID IS</></h4>
                </div>
                <div className="row d-flex justify-content-center">
                  <h1 className="fit-content-max  "><label className="font-size-header-id fill-blue px-2"> {propertyInfo?.generatedPropertyId}</label></h1>
                </div>
              </div>
              <div className="col py-1 d-flex justify-content-center align-items-end"> 
                <h4><b>{propertyInfo?.propertyType?.brandName.trim()},</b> <b>{propertyInfo?.address?.city} Hotel for Sale Information</b></h4>
              </div>
              <div className="col py-2 fit-content-max" style={{ marginLeft: "auto", right: "2%" }}>
                {
                  hbaStaff ?
                    <>
                      <div className="row">
                        <div className="col fit-content-max">
                          <div className="profile-pic">
                          <div className="d-flex justify-content-center">
                                {imageBytes && (
                                  <img
                                    src={`data:image/jpeg;base64,${arrayBufferToBase64(imageBytes)}`}
                                    alt={DefaultUserImage}
                                  />
                                )}
                              </div>
                          </div>
                        </div>
                        <div className="col ">
                          <div className="row ">
                            <label><h2 className="font-size-18">Your 1031 Agent is {hbaStaff?.firstName} {hbaStaff?.lastName} </h2></label>
                          </div>
                          <div className="row">
                            <label className="col d-flex px-2" ><MdContactPhone style={{ fontSize: '20px', color: 'grey' }}></MdContactPhone>  <h2 className="font-size-15"> {formatMobileNumber(hbaStaff?.mobileNumber)}</h2></label>
                            <label className="col px-2 d-flex"><MdContactMail style={{ fontSize: '20px', color: 'grey' }}></MdContactMail> <h2 className="font-size-15"> {hbaStaff?.email}</h2></label>
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <></>
                }
              </div>

            </div>
            <hr className=""></hr>
            <div className="row py-2">
              <div className="col d-flex justify-content-start align-items-start">
                <div className="border-box w-100">
                  <div className="row">
                    <div className="col">
                      <label className="d-flex justify-content-center align-items-center bottom-border"><h3 className="font-size-14-black">Days On Market</h3></label><br></br>
                    </div>
                  </div>
                  <div className="row py-1">
                    <div className="col">
                      <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font">{getDaysOnMarket(propertyInfo?.creationTime) ? getDaysOnMarket(propertyInfo?.creationTime) : 0} Days</h1></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col d-flex justify-content-center align-items-center">
                <div className="border-box">
                  <div className="row">
                    <div className="col ">
                      <label className="d-flex justify-content-center align-items-center bottom-border"><h3 className="font-size-14-black"># Interests Received from Buyers</h3></label><br></br>
                    </div>
                  </div>
                  <div className="row py-1">
                    <div className="col">
                      <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font">{propertyInfo?.interestReceived?.length ? propertyInfo?.interestReceived?.length : 0 }</h1></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col d-flex justify-content-end align-items-end">
                <div className="border-box">
                  <div className="row">
                    <div className="col">
                      <label className="d-flex justify-content-center align-items-center bottom-border"><h2 className="font-size-14-black">1031 Buyers Selected for This Hotel</h2></label><br></br>
                    </div>
                  </div>
                  <div className="row py-1">
                    <div className="col">
                      <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font">
                        {propertyInfo?.dealBox?.length ? propertyInfo?.dealBox?.length : 0}
                      </h1> </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <PropertyPostTable
            column={"propscolumn"}
            products={buyers ? buyers : []}
            filterTabs={filteration}
            reset={resetBtn}
            states={state}
            blueFilters={blueFilters}
            defaultFilter={defaultFilter}
            enableSort={enableSort}
            setEnableSort={setEnableSort}
            user={propertyInfo}
            refreshData={refreshData}
            viewsData={{totalViewed: propertyInfo?.totalViewed, totalInterested: propertyInfo?.totalInterested} }
            approved={propertyInfo?.dealStatus}
          />
        </div>
      )}
    </>
  );
};

export default PropertyPostingPage;
