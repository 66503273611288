import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { contactUsApi, getBuyer, getDashboard, getFileByKey, getProfilePicByKey, getProperty, updateInterestForBuyer } from "../../../api/api";
import { toast } from "react-toastify";
import { MdContactMail, MdContactPhone, MdGroup } from "react-icons/md";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import $ from 'jquery';
import { getAgent, getDateFormatted, tableValueParser } from "../../common/commonUtils";
import DefaultUserImage from '../../../components/assets/default-user.png'
import { formatMobileNumber } from "../../common/utils";
import PdfFormFiller from "../../common/PdfFormFiller";
import { Broker_solicits_Agreement, Seller_solicits_Agreement } from "../../../constants/constants";



const BuyerProfile = () => {
  const [data, setData] = useState();
  const [interested, setInterested] = useState(false);
  const [save, setSave] = useState(false);
  const [propertiesList, setPropertiesList] = useState([]);
  const [interestedProperty, setInterestedProperty] = useState();
  const [show, setShow] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [agent, setAgent] = useState();
  const [imageBytes, setImageBytes] = useState();
  const [showDoc, setShowDoc] = useState(false);
  const [pdfBytes, setPdfBytes] = useState();
  const [userFormDetails, setUserFormDetails] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [customModal, setCustomModal] = useState({
    show: false,
    hide: true,
    title: "",
    message: "",
    subMessage: "",
    button1Text: "",
    button2Text: "",
    button3Text: "",
    button4Text: "",
    button5Text: "",
    button6Text: ""
  })

  const [formState, setFormState] = useState({
    dealStatus: "",
    funds1031: "",
    additionalFunds: "",
    lastPropSalePrice: "",
    buyingPower: "",
    deadLineDate45DaysString: "",
    closingDate1031String: "",
    escrowClosingDateString: "",
    noOfHotelsOwned: "",
    description: "",
    primaryState: {
      id: "",
      name: "",
      code: "",
    },
    docProof: "",
  })

  const { id } = useParams();
  const navigate = useNavigate();

  const handleShowDoc = async () => {
    setShowDoc(true)
    const post_id = sessionStorage.getItem("currentPostid")
    let res = await getProperty(post_id)

    const hotel = res.data ? res.data : res;

    setSpinner(true)
    let userIsBroker = JSON.parse(sessionStorage.getItem("user_type")).includes("Broker")
    let aggrement_key =""
    aggrement_key = userIsBroker ? Broker_solicits_Agreement : Seller_solicits_Agreement 
    console.log(hotel)
    const currentDate = new Date();

    const oneEightyDaysFromNow = new Date(currentDate);
    oneEightyDaysFromNow.setDate(currentDate.getDate() + 180);

    const formattedCurrentDate = formatDateToMMDDYY(currentDate);
    const formattedoneEightyDaysFromNow = formatDateToMMDDYY(oneEightyDaysFromNow);
    if(userIsBroker){
      setUserFormDetails({
        "Text-Broker-Company" : hotel?.user?.companyName,
        "Text-Broker-Name":   hotel?.user?.firstName + " " +  hotel?.user?.lastName,
        "Text-Hotel-Name":   hotel?.propertyType?.brandName + " " + hotel?.propertyType?.franchiseName + ", " + hotel?.address?.city + " " + hotel?.address?.state?.code ,
        "Text-Hotel-Address": hotel?.address?.line1 +", " + hotel?.address?.city + ", " + hotel?.address?.state?.code + ", " + hotel?.address?.zipCode,
        "Text-Commission" : hotel?.totalCommission != null ? hotel?.totalCommission  : "3",
        "Text-Start-Date": formattedCurrentDate,
        "Text-End-Date": formattedoneEightyDaysFromNow,
        "Text-Buyer-Id": data?.generatedBuyerId,
        "Text-Hotel-Id": hotel?.generatedPropertyId,
        "Text-FingerPrint": "A Unique Fingerprint will be generated once you sign this agreement",
        "Text-Date": formattedCurrentDate
  
      })
      
    }else {
      setUserFormDetails({
        "Text-Buyer-Id": data?.generatedBuyerId,
        "Text-Hotel-Name":   hotel?.propertyType?.brandName + " " + hotel?.propertyType?.franchiseName + ", " + hotel?.address?.city + " " + hotel?.address?.state?.code ,
        "Text-Seller-Company": hotel?.user?.companyName,
        "Text-Seller-Name":   hotel?.user?.firstName + " " +  hotel?.user?.lastName,
        "Text-FingerPrint": "A Unique Fingerprint will be generated once you sign this agreement",
        "Text-Broker-Name": "Hotel Brokers of America",
        "Text-Local-Broker": "Bang Realty Inc",
        "Text-Date": formattedCurrentDate,
      })
    }
    
    await handleDownload(aggrement_key, "pdf").then((res) => {
      setSpinner(false)
      setShow(true)
    })
  }

  function formatDateToMMDDYY(date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString()

    return `${month}-${day}-${year}`;
}

  const closeDoc = () => {
    setShowDoc(false);
  };

  const handleDownload = async (key, type) => {
    try {
      await getFileByKey(key).then(res => {
        const bytes = new Uint8Array(res.data);
        setPdfBytes(bytes)
      });
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Error downloading file");
    }
  };




  const interestHandler = async () => {
    setSpinner(true);
    setTimeout(setSpinner((current) => !current),
      3000)
    const post_id = sessionStorage.getItem("currentPostid")
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btnc-success px-6 py-2',
        cancelButton: 'btn btn-outline-cancel px-4 py-2',
        denyButton: 'btn btn-outline-primary px-4 py-2',
        container: 'my-swal',
        popup: 'my-swal-popup',
        header: 'my-swal-header',
        title: 'my-swal-title',
        closeButton: 'my-swal-close-button',
        content: 'my-swal-content'
      },
      focusCancel: true,
      buttonsStyling: false,
      buttons: {
        customButton: {
          text: 'Go to Deal-Box',
          value: 'dealBox',
          className: 'btn btn-outline-primary'
        }
      }
    })

    if (interestedProperty != null || post_id != undefined) {
      let payload = {
        buyer: {
          id: parseInt(id)
        },
        property: {
          id: parseInt(post_id)
        },
        interested: true
      }
      

      let res = await updateInterestForBuyer(payload);
      

      {
        res?.status == 200 ?
          JSON.parse(sessionStorage.getItem("user_type")).includes("Broker") || JSON.parse(sessionStorage.getItem("user_type")).includes("Agent") ?
            setCustomModal({
              ...customModal,
              show: true,
              title: "Your request has been received",
              message: "You will be contacted by the buyer's broker",
              message2: "This deal is now added to your dealbox",
              subMessage: "What do you want to do next?",
              button1Text: "Save this hotel for another Seller",
              button2Text: "Go to Dashboard",
              button3Text: 'Continue Shopping for this Seller',
              button4Text: "Go to Deal-Box",
              button5Text: "View Buyer",
              button6Text: "Stay on this page"
            })
            :
            setCustomModal({
              ...customModal,
              show: true,
              title: "Your request has been received",
              message: "You will be contacted by the buyer's broker",
              message2: "This deal is now added to your dealbox",
              submessage1: "You can also contact your 1031 agent to pursue this deal.",
              subMessage2: "Here is your agent contact :",
              agentDetails: "",
              subMessage: "What do you want to do next?",
              button1Text: "",
              button2Text: "",
              button3Text: 'Continue Shopping',
              button4Text: "Go to Deal-Box",
              button5Text: "",
              button6Text: "Stay on this page"
            })
          :
          toast.error(res.data?.message,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            });

      }

      { res?.status == 200 ? setInterested(true) : setInterested(false) }
      setShowDoc(false)

      $('#buyer_profiles').addClass('is-valid')

    } else {

      handleSendInterst()
    }

  };

  const handleSwalClick = (e) => {
    let type = e.target.value
    console.log(type)
    if (type == "button1") {
      setCustomModal({ ...customModal, show: false })
      handleSendInterst()
    } else if (type == "button2") {
      if (JSON.parse(sessionStorage.getItem('user_type')) == 'Broker') navigate('/brokerDashboard')
    } else if (type == 'button3') {
      navigate('/propertyPost/' + sessionStorage.getItem("currentPostid") + '/shopMore')
    } else if (type == 'button4') {
      navigate('/propertyPost/' + sessionStorage.getItem("currentPostid") + '/dealBox')
    } else if (type == 'button5') {
      window.location.reload()
    } else if (type == 'button6') {
      setCustomModal({ ...customModal, show: false })
    }
  }


  const saveHandler = async () => {
    let payload = {
      id: 1,
      interested: data.interest.interested,
      saved: true,
      viewed: data.interest.views,
      buyer: {
        id: id
      }
    }
    await updateInterestForBuyer(payload);
    setSave((prev) => !prev)
  }

  useEffect(() => {

    getBuyerById(id);
    getDashboardInfo();

    setInterestedProperty(JSON.parse(sessionStorage.getItem("currentPostid")))
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const getBuyerById = async (id) => {
    let res = await getBuyer(id);
    const data = res.data ? res.data : res;
    setData(data);
    { (data?.hbaStaff == null || data?.hbaStaff == "") ? setAgent(getAgent()) : setAgent(data?.hbaStaff) }
    { (data?.hbaStaff == null || data?.hbaStaff == "") ? getImageCards(getAgent()?.profilePic) : getImageCards(data?.hbaStaff?.profilePic) }
  };

  const getDashboardInfo = async () => {
    let res = await getDashboard();
    setPropertiesList(res.data?.properties);

    if (!JSON.parse(sessionStorage.getItem("user_type")).includes("Broker") && !JSON.parse(sessionStorage.getItem("user_type")).includes("Agent")) {
      setInterestedProperty(res.data?.properties[0].id)
      sessionStorage.setItem("currentPostid", parseInt(res.data?.properties[0].id))
    }
    ;
  };

  const getImageCards = async (key) => {
    try {
      await getProfilePicByKey(key).then(res => {
        const bytes = new Uint8Array(res?.data);
        // setImageBytes(bytes)
        return bytes
      });
    } catch (error) {
      console.error("Error downloading file:", error);
    }

  }

  const saveToast = () => {
    toast.promise(
      saveHandler,
      {
        pending: 'Saving Buyer',
        success: 'Buyer Saved Successfully !',
        error: 'Something Went Wrong !'
      }
    )
  }

  const getColourClass = (colour) => {
    return `${colour}-color`
  }

  const getCurrencyColourClass = (colour) => {
    return `currancy-font ${colour}-color`
  }

  const getCalculations = (name) => {

    let funds1031 = data?.funds1031 ? data.funds1031 : 0
    let additionalFunds = data?.additionalFunds ? data.additionalFunds : 0
    if (name === "totalBuyingPower")
      return ((funds1031) / 0.5 + (additionalFunds));

  }

  const handleYourDealBoxRedirect = () => {
    if (sessionStorage.getItem("currentPostid") != null) {
      navigate('/propertyPost/' + sessionStorage.getItem("currentPostid") + '/dealbox')
    } else {

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-cancel',
          container: 'my-swal',
          popup: 'my-swal-popup',
          header: 'my-swal-header',
          title: 'my-swal-title',
          closeButton: 'my-swal-close-button',
          content: 'my-swal-content'
        },
        buttonsStyling: false,
      })


      swalWithBootstrapButtons.fire({
        title: 'Which 1031 buyer Deal-Box you want to see ?',
        input: 'select',
        icon: 'question',
        inputOptions: getPropertyOptions().reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {}),
        showCancelButton: true,
        confirmButtonText: 'View Deal-Box',
        cancelButtonText: 'Cancel',
        // reverseButtons: true,
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/propertyPost/' + result.value + '/dealbox')
        }
      })

    }
  }

  const handleYourShopMoreRedirect = () => {

    if (sessionStorage.getItem("currentPostid") != null) {
      navigate('/propertyPost/' + sessionStorage.getItem("currentPostid") + '/shopMore')
    } else {
      $('#buyer_profiles').addClass('is-invalid')
      navigate('/buyerList')
    }
  }



  const getDefaultHotel = () => {
    if (sessionStorage.getItem("currentPostid") != null) {
      return sessionStorage.getItem("currentPostid")
    } else {
      return ""
    }
  }

  const getPropertyOptions = () => {
    let options = {}

    options = propertiesList?.map((prop, index) => ({
      label: `${prop.generatedPropertyId} - ${prop.sellerName}`,
      value: prop.id
    }));

    // console.log(options)
    return options
  }

  const checkInterestButton = () => {
    let interested = false
    data?.interestReceived?.map(prop => { prop?.property?.id == interestedProperty ? interested = true : <></> })
    return interested
  }

  const handleProperty = (value) => {
    setInterestedProperty(value)
    sessionStorage.setItem("currentPostid", parseInt(value))
  }

  const handleSendInterst = async () => {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-outline-primary',
        cancelButton: 'btn btn-outline-cancel',
        denyButton: 'btn btn-outline-primary',
        container: 'my-swal',
        popup: 'my-swal-popup',
        header: 'my-swal-header',
        title: 'my-swal-title',
        closeButton: 'my-swal-close-button',
        content: 'my-swal-content'
      },
      buttonsStyling: false
    })

    // eslint-disable-next-line no-lone-blocks
    {
      JSON.parse(sessionStorage.getItem("user_type")) == "Seller" ?
        JSON.parse(sessionStorage.getItem("currentPostid")) == null ?
          swalWithBootstrapButtons.fire({
            title: 'How do you want to offer your hotel to this buyer',
            icon: 'question',
            showCancelButton: false,
            showDenyButton: true,
            confirmButtonText: 'Post a hotel for sale',
            denyButtonText: 'Have an agent contact you',
            // reverseButtons: true,
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            setSpinner(true);
            setTimeout(setSpinner((current) => !current),
              3000)
            if (result.isConfirmed) {

              navigate('/addseller')

            } else if (result.isDenied) {

              let payload = {
                fullName: sessionStorage.getItem('user'),
                email: sessionStorage.getItem('user_email'),
                message: `Seller, ${sessionStorage.getItem('user')} with seller ID ${sessionStorage.getItem('generatedUserId')} is interested in buyer ${data?.buyerName} with ID ${data.generatedBuyerId}. Please Contact the seller at ${sessionStorage.getItem('mobileNumber')} `,
                subject: `Seller with no hotel post is interested in a buyer`
              }

              const res = contactUsApi(payload)

              swalWithBootstrapButtons.fire({
                title: 'Your interest has been sent',
                icon: 'success',
                html: `<label>An agent will contact you <
                /br>
                <h4> You can contact us on </h4>    
                <h4><b>info@1031hotels.com</b></h4>
                <h4><b>661-808-4998</b></h4>  
                </label>`,
                showCloseButton: false,
                showCancelButton: false,
                focusConfirm: false,
                confirmButtonText: 'OK',
              })

            }
          })
          :
          checkInterestButton()
        :

        swalWithBootstrapButtons.fire({
          title: 'Choose your hotel for whom you want to contact this buyer',
          input: 'select',
          icon: 'question',
          inputOptions: getPropertyOptions().reduce((acc, cur) => ({ ...acc, [cur.value]: cur.label }), {}),
          showCancelButton: true,
          confirmButtonText: 'Contact Buyer',
          cancelButtonText: 'Cancel',
          // reverseButtons: true,
          showLoaderOnConfirm: true,
          preConfirm: async (value) => {
            let interested = false
            console.log(data?.interestReceived)
            let res = await getBuyer(id);
            res.data?.interestReceived?.map(prop => { prop?.property?.id == value ? interested = true : <></> })
            {
              interested ?
                Swal.showValidationMessage('You have already shoped this buyer for selected seller')
                : <></>
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {


            handleProperty(result.value)
            handleShowDoc()

          }

        })
    }

  }

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  return (
    <>
      <div className="buyer-profile-page fill-blue">
        <div style={{ marginTop: "10px" }} className="section-body">
          <div className="row px-3">
            <div className="col py-1 ">
              <div className="card-header card-principal-title-buyer w-100">
                <Row >
                  <div className="col w-100 px-2">
                    <div style={{ margin: "1rem 0 0.5rem 0" }} className="row w-100">
                      <h4 >THIS 1031 BUYER ID IS</h4>
                      <div className="col-12 col-sm-8 col-md-8 col-lg-9 d-flex align-items-center">
                        <h1 className="buyer-profile-header fill-blue">{data?.generatedBuyerId ? data.generatedBuyerId : '-'}</h1>
                      </div>
                      <br />

                    </div>
                  </div>
                  {
                    agent ?
                      <div className="col mt-2 fit-content-max float-r" style={{ marginLeft: "auto", right: "2%" }}>
                        <div className="row ">
                          <div className="col fit-content-max">
                            <div className="profile-pic">
                              <div className="d-flex justify-content-center">
                                {imageBytes && (
                                  <img
                                    src={`data:image/jpeg;base64,${arrayBufferToBase64(imageBytes)}`}
                                    alt={DefaultUserImage}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="row ">
                              <label><h2 className="font-size-18">Your 1031 Agent is {agent?.firstName} {agent?.lastName} </h2></label>
                            </div>
                            < div className="row">
                              <label className="col d-flex px-2" ><MdContactPhone style={{ fontSize: '20px', color: 'grey', minWidth: 'fit-content' }}></MdContactPhone><h2 className="font-size-15 px-1"> {formatMobileNumber(agent?.mobileNumber)}</h2></label>
                              <label className="col px-2 d-flex"><MdContactMail style={{ fontSize: '20px', color: 'grey' }}></MdContactMail>  <h2 className="font-size-15">{agent?.email}</h2></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      : <></>
                  }
                </Row>
                <hr className=""></hr>
                <div className="row py-2">
                  <div className="col d-flex justify-content-start align-items-start">
                    <div className="border-box w-100  h-100">
                      <div className="row">
                        <div className="col">
                          <label className="w-100 bottom-border d-flex justify-content-center align-items-center"><h3 className="font-size-14-black">Buyer's Buying Power</h3></label><br></br>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col">
                          <label className="d-flex justify-content-center align-items-center"><h1 className="fill-bold-blue currancy-font">$  {getCalculations("totalBuyingPower") ? formatNumberWithCommas(getCalculations("totalBuyingPower")) : 0}</h1></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col d-flex justify-content-center align-items-center">
                    <div className="border-box">
                      <div className="row">
                        <div className="col ">
                          <label className="d-flex justify-content-center align-items-center bottom-border"><h3 className="font-size-14-black">Days Remaining to Nominate</h3></label><br></br>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col">
                          <label className="d-flex justify-content-center align-items-center"><h1 className={getColourClass(data?.deadLineDate45DaysColor)}>
                            {data?.deadLineDate45DaysString !== null ? data?.deadLineDate45DaysString.includes('critical') ? data?.deadLineDate45DaysString : data?.deadLineDate45DaysString + " Days" : "-"}
                          </h1>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col d-flex justify-content-end align-items-end">
                    <div className="border-box">
                      <div className="row">
                        <div className="col">
                          <label className="d-flex justify-content-center align-items-center bottom-border"><h2 className="font-size-14-black"># Sellers Interested in this Buyer</h2></label><br></br>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label className="d-flex justify-content-center align-items-center"><h1 className="fill-blue" >
                            {data?.interestReceived?.length ? data?.interestReceived.length : 0}
                          </h1> </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Row className="row pt-2">
                  <Col className="float-r  d-flex justify-content-end align-items-start">
                    <>
                      {checkInterestButton() ?
                        <>
                          <button className="btn btn-primary-narrow-right" disabled>Interest Sent</button>
                        </>
                        :
                        <div className="interests-view">
                          <Row>
                            <Col className="interests-center" style={{ display: 'flex', marginBottom: '10px', justifyContent: 'center' }}>
                              <button
                                className="btn btn-outline-primary-narrow-right"
                                disabled={interested}
                                onClick={interestedProperty != null ? handleShowDoc : handleSendInterst}
                              // className="interest-btn col-12 mx-3 "
                              >
                                {interested ? (
                                  <BsCheck
                                    style={{
                                      fontSize: "20px",
                                      marginTop: "-4px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                {interested === true
                                  ? "Contacted Buyer"
                                  : "Contact Buyer"}
                              </button>
                            </Col>

                          </Row>
                        </div>
                      }
                    </>
                  </Col>
                  <Col className="float-r fit-content-max">
                    <button
                      className="btn btn-outline-primary-narrow-right "
                      onClick={handleYourShopMoreRedirect}
                    >
                      Shop Buyers
                    </button>
                  </Col>
                  {JSON.parse(sessionStorage.getItem("user_type")) == "Seller" ?
                    JSON.parse(sessionStorage.getItem("currentPostid")) == null ? <></> :
                      <Col className="float-r fit-content-max">
                        <button
                          className="btn btn-outline-primary-narrow-right "
                          onClick={handleYourDealBoxRedirect}
                        >
                          Your Deal-Box
                        </button>
                      </Col> :
                    <Col className="float-r fit-content-max">
                      <button
                        className="btn btn-outline-primary-narrow-right "
                        onClick={handleYourDealBoxRedirect}
                      >
                        Your Deal-Box
                      </button>
                    </Col>}
                </Row>

              </div>
            </div>

            <div className="px-2">
              <div style={{ justifyContent: 'space-evenly' }} className="">
                <div className="row gx-1 pb-2 px-0 align-item-stretch">
                  <div className="col-md-4 px-1 flex-1">
                    <div className="d-flex justify-content-center align-item-center card card-criteria card-top-border-buyer px-0 h-100">
                      <div className="card-profile-header-primaryBlue card-header d-flex justify-content-start align-item-center py-0 pt-2">
                        <h2 className="font-size-18 fill-blue col fit-content-max">
                          BUYER INFORMATION
                        </h2>
                      </div>

                      <div className="card-body buyer-profile-body">
                        <div className="form-body" style={{ fontSize: '13px', fontWeight: "700" }}>

                          <Row className=" d-flex align-items-center fill-alternate-row">
                            <Col xs={8}><label className="font-size-16"><b>Buyers Home State is</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">{data?.primaryState?.name}</label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}><label className="font-size-16"><b>Buyer's Cash Available in 1031ex. for Down Payment</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">${formatNumberWithCommas(data?.funds1031 ? data?.funds1031 : 0)} </label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row" >
                            <Col xs={8}><label className="font-size-16"><b>Additional Cash Available for Buyer's Down Payment</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">${formatNumberWithCommas(data?.additionalFunds ? data?.additionalFunds : 0)} </label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}><label className="font-size-16"><b>Total Down Payment</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">${formatNumberWithCommas((data?.additionalFunds ? data?.additionalFunds : 0) + (data?.funds1031 ? data?.funds1031 : 0))} </label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}><label className="font-size-16"><b>Total Buying Power with 20% Down</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">${formatNumberWithCommas(getCalculations("totalBuyingPower") ? getCalculations("totalBuyingPower") : 0)} </label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}><label className="font-size-16"><b>Buyer's Escrow Close Date</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">{getDateFormatted(data?.escrowClosingDate)} </label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}><label className="font-size-16"><b>45-Day Deadline Date</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">{getDateFormatted(data?.deadLineDate45Days)} </label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}><label className="font-size-16"><b>180-Day Escrow Close Date</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">{getDateFormatted(data?.closingDate1031)} </label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}><label className="font-size-16"><b># of Other Properties Owned</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">{data?.noOfHotelsOwned} </label>
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}><label className="font-size-16"><b>Final Amount Hotel Sold for</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">${formatNumberWithCommas(data?.buyingPower ? data?.buyingPower : 0)}</label>
                            </Col>
                          </Row>

                          {data?.interestReceived != null && data?.interestReceived?.map(prop => prop?.property?.id == interestedProperty)[0] ?
                            <>
                              {data?.docProof != null ?
                                <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                                  <Col xs={8}><label className="font-size-16"><b>Uploaded Proof of Funds : </b></label></Col>
                                  <Col xs={4}>
                                    <span>{data?.docProof?.split(',').map((url) => {
                                      return <> <a href={url}>{url.split('/files/')[1]}</a><br></br></>
                                    })}</span>
                                  </Col>
                                </Row>
                                : <></>}
                            </>
                            : <></>}

                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}><label className="font-size-16"><b>Is the Buyer Eligible for SBA7A/504 Type of Loan</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">
                                {data?.isSBA7A504LoanEligible ? "YES" : "NO"}
                              </label>
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2 ">
                            <Col xs={8}><label className="font-size-16"><b>Description</b></label></Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">{data?.description ? data.description : "-"}</label>
                            </Col>
                          </Row>


                          <div className="form-group">
                            <div id="spinner" className="spinner"></div>
                          </div>

                        </div>

                      </div>


                    </div>
                  </div>
                  <div className="col-md-5 px-1 flex-1">
                    <div className="card card-criteria card-top-border-buyer px-0 h-100">
                      <div className="card-profile-header-primaryBlue card-header d-flex justify-content-start py-0 pt-2">
                        <h2 className="font-size-18 fill-blue col">
                          THIS 1031 BUYER IS LOOKING FOR
                        </h2>
                      </div>

                      <div className="card-body buyer-profile-body">
                        <div className="form-body" >
                          <Row className=" d-flex align-items-center">
                            <Col xs={12}>
                              <div className="profile-right">
                                <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                                  <Col xs={4}><label className="font-size-16"><b>Brands Buyer is Interested in</b></label></Col>
                                  <Col xs={8}>
                                    <label className="font-size-16-lite">{data?.interestedBrands !== null ? tableValueParser(data?.interestedBrands) : "Any"}</label>
                                  </Col>
                                </Row>
                                <Row className=" d-flex align-items-center py-2">
                                  <Col xs={4}><label className="font-size-16"><b>Buyer's Interested States</b></label></Col>
                                  <Col xs={8}>
                                    <label
                                      className="font-size-16-lite"
                                    >{data?.interestedPrimaryStatesList !== null ?
                                      data?.interestedPrimaryStatesList?.length > 3 ? "Any" :
                                        data?.interestedPrimaryStatesList?.reduce((interestedStates, list) => interestedStates += list.name + ',', '') : "-"}</label>
                                  </Col>
                                </Row>
                                <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                                  <Col xs={4}><label className="font-size-16"><b>Maximum Room Count</b></label></Col>
                                  <Col xs={8}>
                                    <label
                                      className="font-size-16-lite">{data?.interestedRoomCount !== null ? data?.interestedRoomCount == '1-1000' ? "Any" : data?.interestedRoomCount : "-"}</label>
                                  </Col>
                                </Row>
                                <Row className=" d-flex align-items-center py-2">
                                  <Col xs={4}><label className="font-size-16" ><b>Buyer's Prefered Market</b></label></Col>
                                  <Col xs={8}>
                                    <label className="font-size-16-lite">{data?.interestedMarketType !== null ? tableValueParser(data?.interestedMarketType) : "Any"}</label>
                                  </Col>
                                </Row>
                                <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                                  <Col xs={4}><label className="font-size-16"><b>Buyer's Prefered Investment Type</b></label></Col>
                                  <Col xs={8}>
                                    <label
                                      className="font-size-16-lite"
                                    >{data?.opportunityType !== null ? data?.opportunityType : "Any"}</label>
                                  </Col>
                                </Row>
                                <Row className=" d-flex align-items-center py-2">
                                  <Col xs={4}><label className="font-size-16"><b>Buyer's Additional Preferences</b></label></Col>
                                  <Col xs={8}>
                                    <label
                                      className="font-size-16-lite"
                                    >{data?.additionalInformation != null ? data?.additionalInformation : "-"}</label>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>

                          <div className="form-group">
                            <div id="spinner" className="spinner"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 px-1 flex-1">
                    <div className="card card-criteria card-top-border-buyer px-0 h-100">
                      <div className="card-profile-header-primaryBlue card-header d-flex justify-content-start py-0 pt-2">
                        <h2 className="font-size-18 fill-blue">
                          BUYER STATS
                        </h2>
                      </div>
                      <div className="card-body text-center px-2 fill-blue">
                        <div>
                          <div className="row px-4 py-0 d-flex justify-content-center align-item-center md-0">
                            <div className="col py-0 px-2 d-flex justify-content-end align-item-center fit-content-max">
                              <h6 className="fill-blue px-2 py-0  ml-0 mr-0 mb-0"><FaEye className="px-1 fill-green" style={{ fontSize: '30px' }}></FaEye>{data?.totalViewed ? data?.totalViewed : 0} Views</h6>
                            </div>
                            <div className="col d-flex justify-content-center align-item-center fit-content-max px-0 py-0 ">
                              <div class="vertical-line px-0"></div>
                            </div>
                            <div className="col px-2 d-flex justify-content-start align-item-center fit-content-max">
                              <h6 className="fill-blue px-0 ml-0 mr-0 mb-0 w-100"><MdGroup className="px-1 fill-green" style={{ fontSize: '30px' }}> </MdGroup>{data?.interestReceived ? data?.interestReceived.length : 0} Sellers Interested</h6>
                            </div>
                          </div>
                          <div className="row py-0">
                            <div className="col  d-flex justify-content-center align-item-center">
                              <div class="horizontal-line"></div>
                            </div>
                          </div>
                          <div className="row py-0">
                            <div className="col  d-flex justify-content-center align-item-center">
                              <h6 className="fill-blue py-3 font-size-16">Max Buying Capacity ${formatNumberWithCommas(getCalculations("totalBuyingPower") ? getCalculations("totalBuyingPower") : 0)} </h6>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div>
                          <div className="row px-4 py-0 d-flex justify-content-center align-item-center">
                            <div className="col px-2  d-flex justify-content-end align-item-center fit-content-max">
                              <label className={getColourClass(data?.deadLineDate45DaysColor) + " px-2 ml-0 mr-0 mb-0 font-size-16"} >{data?.deadLineDate45DaysString !== null ? data?.deadLineDate45DaysString : "-"} Days</label>
                            </div>
                            <div className="col  d-flex justify-content-center align-item-center fit-content-max px-0 py-0 ">
                              <div class="vertical-line py-1 px-0"></div>
                            </div>
                            <div className="col px-2 d-flex justify-content-start align-item-center fit-content-max px-0 py-0 ">
                              <h6 className="fill-blue px-0 ml-0 mr-0 mb-0 w-100 font-size-16">{getDateFormatted(data?.deadLineDate45Days)}</h6>
                            </div>
                          </div>
                          <div className="row py-2">
                            <div className="col d-flex justify-content-center align-item-center">
                              <div class="horizontal-line"></div>
                            </div>
                          </div>
                          <div className="row py-0">
                            <div className="col  d-flex justify-content-center align-item-center">
                              <h6 className="fill-blue py-0 font-size-16">45 Days Deadline </h6>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div>
                          <div className="row px-4 py-0 d-flex justify-content-center align-item-center">
                            <div className="col px-2  d-flex justify-content-end align-item-center fit-content-max">
                              <h6 className={getColourClass(data?.closingDate1031Color) + " px-2 ml-0 mr-0 mb-0"} >{data?.closingDate1031String !== null ? data?.closingDate1031String : "-"} Days</h6>
                            </div>
                            <div className="col  d-flex justify-content-center align-item-center fit-content-max px-0 py-0 ">
                              <div class="vertical-line py-0 px-0"></div>
                            </div>
                            <div className="col px-2 d-flex justify-content-start align-item-center fit-content-max px-0 py-0 ">
                              <h6 className="fill-blue px-0 ml-0 mr-0 mb-0 w-100">{getDateFormatted(data?.closingDate1031)} </h6>
                            </div>
                          </div>
                          <div className="row py-2">
                            <div className="col d-flex justify-content-center align-item-center">
                              <div class="horizontal-line"></div>
                            </div>
                          </div>
                          <div className="row py-0">
                            <div className="col  d-flex justify-content-center align-item-center">
                              <h6 className="fill-blue py-0 font-size-16">1031 Exchange Closing Date </h6>
                            </div>
                          </div>
                        </div>


                      </div>
                      <Modal show={showDoc} size="xl" onHide={closeDoc}>
                        {pdfBytes != null ?
                          <PdfFormFiller arrayBuffer={pdfBytes} fileType={"pdf"} formDetails={userFormDetails} handleIAgree={interestHandler} handleCancel={closeDoc}></PdfFormFiller>
                          : <></>
                        }
                      </Modal>
                      <Modal size="lg" className="d-flex align-items-center justify-content-center custom-modal" show={customModal.show}>
                        <br></br>
                        <Modal.Body className="w-100 h-100" style={{ minWidth: "600px" }} >
                          <Row>
                            <Col className="d-flex align-items-center justify-content-center">
                              <h3><b>{customModal.title}</b></h3>
                            </Col>
                          </Row>

                          <br></br>
                          <Row>
                            <Col className="d-flex align-items-center justify-content-center">
                              <label className="font-size-primary-20">{customModal.message}</label>
                            </Col>
                          </Row>
                          <br></br>
                          <Row>
                            <Col className="d-flex align-items-center justify-content-center">
                              <label className="font-size-primary-20">{customModal.message2}</label>
                            </Col>
                          </Row>
                          {JSON.parse(sessionStorage.getItem("user_type")).includes("Buyer") || JSON.parse(sessionStorage.getItem("user_type")).includes("Seller") ?
                            <>
                              <br></br>
                              <Row>
                                <Col className="d-flex align-items-center justify-content-center">
                                  <label className="font-size-primary-20 fill-blue">{customModal.subMessage1}</label>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="d-flex align-items-center justify-content-start ">
                                  <label className="font-size-primary-20 fill-blue">{customModal.subMessage2}</label>
                                </Col>
                              </Row>
                              <Row className="d-flex justify-content-center">
                                <div className="col fit-content-max py-2">
                                  <div className="profile-pic">
                                    <div className="d-flex justify-content-center">
                                      {imageBytes && (
                                        <img
                                          src={`data:image/jpeg;base64,${arrayBufferToBase64(imageBytes)}`}
                                          alt={DefaultUserImage}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col py-2 fit-content-max">
                                  <div className="row ">
                                    <label><h2 className="font-size-18  fill-blue">{agent?.firstName} {agent?.lastName} </h2></label>
                                  </div>
                                  < div className="row">
                                    <label className="col d-flex px-2  " ><MdContactPhone className="" style={{ fontSize: '20px', color: 'grey', minWidth: 'fit-content' }}></MdContactPhone><h2 className="font-size-15 px-1 fill-blue"> {formatMobileNumber(agent?.mobileNumber)}</h2></label>
                                    <label className="col px-2 d-flex  "><MdContactMail className="" style={{ fontSize: '20px', color: 'grey' }}></MdContactMail>  <h2 className="font-size-15 fill-blue">{agent?.email}</h2></label>
                                  </div>
                                </div>
                              </Row>

                            </>

                            : <></>}
                          <br></br>
                          <Row>
                            <Col className="d-flex align-items-center justify-content-center">
                              <label className="font-size-primary-20">{customModal.subMessage}</label>
                            </Col>
                          </Row>


                          <br></br>
                          {JSON.parse(sessionStorage.getItem("user_type")).includes("Broker") || JSON.parse(sessionStorage.getItem("user_type")).includes("Agent") ?
                            <div >
                              <Col className="d-flex flex-column justify-content-center align-item-center">
                                <Row className="px-2 py-2 ">
                                        {customModal.button1Text != "" ?
                                    <button className="btn btn-outline" value='button1' onClick={handleSwalClick}>
                                            {customModal.button1Text}
                                          </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                        {customModal.button3Text != "" ?
                                    <button className="btn btn-outline  w-100" value='button3' onClick={handleSwalClick}>
                                            {customModal.button3Text}
                                          </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                  {customModal.button5Text != "" ?
                                    <button className="btn btn-outline w-100" value='button5' onClick={handleSwalClick}>
                                      {customModal.button5Text}
                                    </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                  {customModal.button2Text != "" ?
                                    <button className="btn btn-outline  w-100" value='button2' onClick={handleSwalClick}>
                                      {customModal.button2Text}
                                    </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                        {customModal.button4Text != "" ?
                                          <button className="btn btn-outline w-100" value='button4' onClick={handleSwalClick}>
                                            {customModal.button4Text}
                                          </button> : <></>}
                                </Row>
                                <Row className="px-2 py-2">
                                        {customModal.button6Text != "" ?
                                    <button className="btn btn-outline-cancel  w-100" value='button6' onClick={handleSwalClick}>
                                            {customModal.button6Text}
                                          </button> : <></>}
                                </Row>

                                      </Col>

                            </div>
                            :
                            <>
                              <Row className="d-flex">

                                <Col className="fit-content-max">
                                        {customModal.button3Text != "" ?
                                    <button className="btn btn-outline fit-content-max" value='button3' onClick={handleSwalClick}>
                                            {customModal.button3Text}
                                          </button> : <></>}
                                      </Col>

                                <Col className="fit-content-max">
                                        {customModal.button4Text != "" ?
                                    <button className="btn btn-outline fit-content-max" value='button4' onClick={handleSwalClick}>
                                            {customModal.button4Text}
                                          </button> : <></>}
                                      </Col>

                                <Col className=" fit-content-max">
                                        {customModal.button6Text != "" ?
                                    <button className="btn btn-outline fit-content-max" value='button6' onClick={handleSwalClick}>
                                            {customModal.button6Text}
                                          </button> : <></>}
                                      </Col>
                                    </Row>
                            </>}

                        </Modal.Body >
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerProfile;
