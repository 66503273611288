import React, { useEffect, useRef } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableComponent from "../Tables/TableComponent";
import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { BiFilter, BiHeart } from "react-icons/bi";
import { Carousel, ListGroup, Modal } from "react-bootstrap";
import { AiFillHeart } from "react-icons/ai";
import { getPrimaryStates, getProperties, getPropertyTypes, getUserInfo, verifyAndSetToken } from "../../api/api";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
import { useMemo } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SlArrowDown } from "react-icons/sl";
import { MdClose } from "react-icons/md";
import CurrencyFormat from 'react-currency-format';
import Image from 'react-bootstrap/Image';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import "../../img/image1.jfif"
import "../../img/posts/post2.png"
import "../../img/posts/post3.png"
import { askingPricevalues, brandTypes, mindownpayvalues, numberOfRoomsvalues } from "../common/utils";
import { agentHandler, isUserLoggedIn } from "../common/commonUtils";
import Swal from "sweetalert2";
import { S3_IMAGES } from "../../constants/constants";
import { MarginOutlined } from "@mui/icons-material";

const PropertyList = () => {
  const [filterList, setFilterList] = useState(false);

  const [states, setStates] = useState([]);
  const [brand, setBrands] = useState([]);
  const [approvedBuyer, setApprovedBuyer] = useState(false);
  const [approvedSeller, setApprovedSeller] = useState(false);
  const [hasNoProfiles, setHasNoProfiles] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState();
  const [loginToast, setLoginToast] = useState(false);
  const [loginRedirectId, setLoginRedirectId] = useState();

  const [sortIcon, setSortIcon] = useState({
    exchange_funds: "asc",
    max_buying_power: "asc",
    deadline: "asc",
    closing: "asc",
    no_of_rooms: "asc",
  });

  const [updateFilter, setUpdateFilter] = useState(false);

  const [allproperties, setAllProperties] = useState([]);

  const [properties, setProperties] = useState([]);

  const [showImages, setShowImages] = useState(false);
  const [showImagerow, setShowImagerow] = useState();
  const [askingPriceslist, setAskingPrices] = useState([])
  const [numberofroomslist, setNumberOfRooms] = useState([])
  const [minDownPaymentlist, setMinDownpaymentlist] = useState([])
  const [filterValues, setFilterValues] = useState({
    price_min: "",
    price_max: "",
    rooms_min: "",
    rooms_max: "",
    brand: "",
    state: "",
    downpayment_min: "",
    downpayment_max: "",
    askingpriceId: "",
    numberofroomsId: "",
    mindownpayId: "",

  })
  const [large, setLarge] = useState(true);


  const initValues = {
    price_min: "",
    price_max: "",
    rooms_min: "",
    rooms_max: "",
    brand: "",
    state: "",
    downpayment_min: "",
    downpayment_max: "",
    askingpriceId: "",
    numberofroomsId: "",
    mindownpayId: "",
  };

  function handleFilterChange(e) {
    let name = e.target.name
    let value = e.target.value

    if (name == "brand") {
      if (value) {
        setFilterValues({
          ...filterValues, brand: value
        })
      }

    }

    if (name == "state") {
      askingPriceslist.map(val => {
        if (value) {
          setFilterValues({
            ...filterValues, state: value
          })
        }
      })
    }

    if (name == "askingprice") {
      askingPriceslist.map(val => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues, askingpriceId: value, price_max: val.max, price_min: val.min
          })
        }
      })
    }

    if (name == "numberofrooms") {
      numberofroomslist.map(val => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues, numberofroomsId: value, rooms_max: val.max, rooms_min: val.min
          })
        }
      })
    }

    if (name == "mindownpay") {
      minDownPaymentlist.map(val => {
        if (val.id == value) {
          setFilterValues({
            ...filterValues, mindownpayId: value, downpayment_max: val.max, downpayment_min: val.min
          })
        }
      })
    }

    formik.handleSubmit()

  }

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {

      filterData();

      setUpdateFilter((current) => !current);
      filterHandler()
    },
  });

  const navigate = useNavigate();


  useEffect(() => {
    verifyAndSetToken();
    getPropertyList();
    if (isUserLoggedIn()) {
      checkApprovalStatus();
    }
    sessionStorage.removeItem("currentPostid")
  }, [])

  const getPropertyList = async () => {
    let res = await getProperties();
    const data = (res.data ? res.data : res);
    setProperties(data.properties);
    setAllProperties(data.properties);
    let val = await getPrimaryStates();
    setStates(val);

    // try {
    //   setStates(JSON.parse(val));
    // } catch (error) {
    //   window.location.reload()
    //   setStates(val);
    // }

    let bt = await getPropertyTypes();
    // setBrands(bt.propertyTypes.map(b => b.brandType).sort())
    setBrands(brandTypes)
    setAskingPrices(askingPricevalues)
    setNumberOfRooms(numberOfRoomsvalues);
    setMinDownpaymentlist(mindownpayvalues)
    setUpdateFilter((current) => !current);
  }



  const checkApprovalStatus = async () => {
    const user = await getUserInfo();

    if (user?.hbaStaff) agentHandler(user?.hbaStaff)

    setLoggedInUser(user);
    if (user.approvedBuyer == true) {
      setApprovedBuyer(true);
    } else {
      setApprovedBuyer(false);
    }

    if (user.approvedSeller == true) {
      setApprovedSeller(true);
    } else {
      setApprovedSeller(false);
    }
    if (user.buyersSize == 0) {
      setHasNoProfiles(true);
    }

  }

  const resetHandler = () => {
    formik.resetForm();
    setFilterValues(initValues)
    setProperties(allproperties);
    setUpdateFilter((current) => !current);
    setFilterList(false)
  }

  //---------------------------------------------FILTER LOGIC----------------------------------------------------------//

  const filterData = () => {
    let currentFilteredData = allproperties;


    if (filterValues.price_min !== "" || filterValues.price_max !== "") {
      const min = filterValues.price_min !== "" ? filterValues.price_min : 0;
      const max = filterValues.price_max !== "" ? filterValues.price_max : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((p) => p.askingPrice > min && p.askingPrice <= max);
    }

    if (filterValues.rooms_min !== "" || filterValues.rooms_max !== "") {
      const min = filterValues.rooms_min !== "" ? filterValues.rooms_min : 0;
      const max = filterValues.rooms_max !== "" ? filterValues.rooms_max : 300;
      currentFilteredData = currentFilteredData.filter((b) => b.noOfRooms >= min && b.noOfRooms <= max);
    }

    if (filterValues.brand !== "" && filterValues.brand !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => b.propertyType.brandType === filterValues.brand);
    }

    if (filterValues.state !== "" && filterValues.state !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => b.primaryState.id == filterValues.state);
    }

    if (filterValues.downpayment_min !== "" || filterValues.downpayment_max !== "") {
      const min = filterValues.downpayment_min !== "" ? filterValues.downpayment_min : 0;
      const max = filterValues.downpayment_max !== "" ? filterValues.downpayment_max : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((p) => p.minDownPayment >= min && p.minDownPayment <= max);
    }

    setProperties(currentFilteredData);
  }


  //---------------------------------------------FILTER LOGIC----------------------------------------------------------//

  const iconHandler = (order) => {
    if (!order)
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp style={{ color: "#d7d7d7" }} />
          </span>
          <span>
            <CgArrowLongDown style={{ color: "#d7d7d7" }} />
          </span>
        </div>
      );
    else if (order === "asc")
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp />
          </span>
          <span>
            <CgArrowLongDown style={{ color: "#d7d7d7" }} />
          </span>
        </div>
      );
    else if (order === "desc")
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp style={{ color: "#d7d7d7" }} />
          </span>
          <span>
            <CgArrowLongDown />
          </span>
        </div>
      );
    return null;
  };



  const filterHandler = () => {
    setFilterList((current) => !current);
  };

  const removeFilterHandler = (val, array) => {
    if (array === "brand") {
      let data = formik.values.brand
      formik.values.brand = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setProperties(allproperties);
      } else filterData();
    } else if (array === 'state') {
      let data = formik.values.state
      formik.values.state = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setProperties(allproperties);
      } else filterData();
    }
    setUpdateFilter((current) => !current);
  };

  const clearFilters = (val) => {
    const values = formik.values;
    if (val === 'rooms') {
      formik.values.rooms_min = initValues.rooms_min;
      formik.values.rooms_max = initValues.rooms_max;
      if (
        values.downpayment_min > initValues.downpayment_min ||
        values.downpayment_max < initValues.downpayment_max ||
        values.price_min > initValues.price_min ||
        values.price_max < initValues.price_max
      ) {
        filterData();
      } else setProperties(allproperties);
    } else if (val === 'price') {
      formik.values.price_min = initValues.price_min;
      formik.values.price_max = initValues.price_max;
      if (
        values.downpayment_min > initValues.downpayment_min ||
        values.downpayment_max < initValues.downpayment_max ||
        values.rooms_min > initValues.rooms_min ||
        values.rooms_max < initValues.rooms_max
      ) {
        filterData();
      } else setProperties(allproperties);
    } else {
      formik.values.downpayment_min = initValues.downpayment_min;
      formik.values.downpayment_max = initValues.downpayment_max;
      if (
        values.rooms_min > initValues.rooms_min ||
        values.rooms_max < initValues.rooms_max ||
        values.price_min > initValues.price_min ||
        values.price_max < initValues.price_max
      ) {
        filterData();
      } else setProperties(allproperties);
    }
    setUpdateFilter((current) => !current);
  }

  const filterTabs = () => {
    return (
      <div className="filter-div">
        <div
          style={{ marginLeft: "-5px" }}
          className="row justify-content-start gy-3"
        >
          <div className="col ">
            <label data="Maximum Buying Power"><b>Brand</b></label><br></br>
            <select name="brand" onChange={(e) => handleFilterChange(e)}
              className="form-select filter-div-button-wrapper" value={filterValues.brand}>
              {brand
                ? brand.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val}
                  >
                    {val}
                  </option>
                ))
                : " "}

            </select>

          </div>
          <div className="col  ">
            <label data="Maximum Buying Power"><b>State</b></label><br></br>
            <select name="state" onChange={(e) => handleFilterChange(e)}
              className="form-select filter-div-button-wrapper" value={filterValues.state}>
              <option className="filter-select"
                name="askingprice"
                value="Any" >Any</option>
              {states
                ? states.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val.id}
                  >
                    {val.name}
                  </option>
                ))
                : " "}

            </select>

          </div>

          <div className="col ">
            <label data="Maximum Buying Power"><b>AskingPrice</b></label><br></br>
            <select name="askingprice" onChange={(e) => handleFilterChange(e)}
              className="form-select filter-div-button-wrapper" value={filterValues.askingpriceId}>
              {askingPriceslist
                ? askingPriceslist.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val.id}
                    max={val.max}
                    min={val.min}
                  >
                    {val.label}
                  </option>
                ))
                : " "}

            </select>
          </div>
          <div className="col ">

            <label data="Maximum Buying Power"><b>Number of Rooms</b></label><br></br>
            <select name="numberofrooms" onChange={(e) => {
              handleFilterChange(e)
            }} className="form-select filter-div-button-wrapper" value={filterValues.numberofroomsId}>
              {numberofroomslist
                ? numberofroomslist.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="numberofrooms"
                    value={val.id}
                  >
                    {val.label}
                  </option>
                ))
                : " "}
            </select>

          </div>
          <div className="col ">

            <label data="Maximum Buying Power"><b>Minimum Downpayment</b></label><br></br>
            <select name="mindownpay" onChange={(e) => {
              handleFilterChange(e)
            }} className="form-select filter-div-button-wrapper" value={filterValues.mindownpayId}>
              {minDownPaymentlist
                ? minDownPaymentlist.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="mindownpay"
                    value={val.id}
                  >
                    {val.label}
                  </option>
                ))
                : " "}
            </select>

          </div>

          <div className="col  d-flex justify-content-center fit-content-max" style={{ marginTop: "2rem" }}>
            <button onClick={resetHandler} type="button" className="btn btn-outline-primary-narrow-right">
              Reset
            </button>
          </div>
          <div className="col  d-flex justify-content-end align-items-end fit-content-max">
            <span className="font-14"><sup>*</sup>Hover over the Hotel ID to view images</span>
          </div>
        </div>
      </div>
    );
  };
  const filteration = useMemo(() => filterTabs, [updateFilter]);

  const filterBtnTabs = () => {
    return (
      <div className="filter-list">
        <button className="filter-list-btn" onClick={filterHandler}>
          Filters <BiFilter />
        </button>
        <div className={filterList === false ? "hide-tru" : "show-tru"}>
          <ListGroup>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <b>Brand</b>
                </button>
                <select name="brand" onChange={(e) => handleFilterChange(e)}
                  className="form-select filter-div-button-wrapper" value={filterValues.brand}>
                  {brand
                    ? brand.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="askingprice"
                        value={val}
                      >
                        {val}
                      </option>
                    ))
                    : " "}

                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <b>State</b>
                </button>
                <select name="state" onChange={(e) => handleFilterChange(e)}
                  className="form-select filter-div-button-wrapper" value={filterValues.state}>
                  <option className="filter-select"
                    name="askingprice"
                    value="Any" >Any</option>
                  {states
                    ? states.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="askingprice"
                        value={val.id}
                      >
                        {val.name}
                      </option>
                    ))
                    : " "}

                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <label data="Maximum Buying Power"><b>Asking Price</b></label><br></br>
                <select name="askingprice" onChange={(e) => handleFilterChange(e)}
                  className="form-select filter-div-button-wrapper" value={filterValues.askingpriceId}>
                  {askingPriceslist
                    ? askingPriceslist.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="askingprice"
                        value={val.id}
                        max={val.max}
                        min={val.min}
                      >
                        {val.label}
                      </option>
                    ))
                    : " "}

                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <label data="Maximum Buying Power"><b>Number of Rooms</b></label><br></br>
                <select name="numberofrooms" onChange={(e) => {
                  handleFilterChange(e)
                }} className="form-select filter-div-button-wrapper" value={filterValues.numberofroomsId}>
                  {numberofroomslist
                    ? numberofroomslist.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="numberofrooms"
                        value={val.id}
                      >
                        {val.label}
                      </option>
                    ))
                    : " "}
                </select>

              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">

                <label data="Maximum Buying Power"><b>Minimum Downpayment</b></label><br></br>
                <select name="mindownpay" onChange={(e) => {
                  handleFilterChange(e)
                }} className="form-select filter-div-button-wrapper" value={filterValues.mindownpayId}>
                  {minDownPaymentlist
                    ? minDownPaymentlist.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="mindownpay"
                        value={val.id}
                      >
                        {val.label}
                      </option>
                    ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <br></br>
        <button onClick={resetHandler} className="filter-list-btn">
          Reset
        </button>
      </div>
    );
  };

  const Login = () => (
    <div>
      <span>Please Register to View this Profile</span>
      <button className="login-toast" onClick={() => navigate("/register")}>
        Register
      </button>
      <button className="login-toast" onClick={() => navigate("/login")}>
        Login
      </button>
    </div>
  );

  const notify = (id) => {

    setLoginToast(true)
    setLoginRedirectId(id)

  };

  const notifyGetApproved = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: 'my-swal',
        popup: 'my-swal-popup-notify',
        content: 'my-swal-content'
      }
    })

    swalWithBootstrapButtons.fire({
      title: 'Your profile is being reviewed',
      html: `<div>
                <h4>Please wait until your profile is approved to access the hotels</h4>    
                </br>    
                <h4> You can contact us on </h4>    
                <h4><b>info@1031hotels.com</b></h4>
                <h4><b>661-808-4998</b></h4>
              </div>
      `
    })
  };

  const notifyToCreateProfile = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: 'my-swal',
        popup: 'my-swal-popup-notify',
        content: 'my-swal-content',
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-cancel',

      }
    })

    swalWithBootstrapButtons.fire({
      title: 'Post a Buyer',
      text: 'Please post a Buyer to view this hotel',
      showCancelButton: true,
      confirmButtonText: "Post a Buyer",
      cancelButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/addBuyer')
      }
    })
  };

  const notifyToGetAssignedProfile = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: 'my-swal',
        popup: 'my-swal-popup-notify',

        content: 'my-swal-content',
      }

    })

    swalWithBootstrapButtons.fire({
      title: 'Attention !!',
      text: 'No Buyer assigned to shop hotel',
    })
  };

  const toastLogin = (id) => {
    const user = sessionStorage.getItem("user");
    if (user) {
      if (hasNoProfiles && loggedInUser.userTypes.includes("Agent")) {
        notifyToGetAssignedProfile();
      } else if (hasNoProfiles) {
        notifyToCreateProfile();
      } else {
        if (approvedBuyer) {
          navigate("/propertyProfile/" + id);
        } else {
          notifyGetApproved();
        }
      }
    } else {
      notify(id)
      // navigate("/buyersLanding")
    }
  };

  const getImagesArray = (urls) => {
    let urlsArray = urls.split(',')
    let imageUrls = []

    urlsArray.slice(0, 5).map(url => {
      if (url.includes("files")) {
        const index = url.indexOf("files");
        const substringBeforeKeyword = url.substring(0, index);

        if (substringBeforeKeyword.trim() === "") {
          imageUrls.push(S3_IMAGES + url)
        } else {
          imageUrls.push(url)
        }
      }
    })

    return imageUrls

  }

  const columns = [
    {
      dataField: "generatedPropertyId",
      text: "Hotel ID",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      classes: large ? "data-styleproperty-id-lg hand-cursor" : "data-style-id hand-cursor",
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) =>
        <>
          <OverlayTrigger
            placement="auto-end"

            overlay={

              <div className="row overlay-image-area carousel-overlay " >
                {getImagesArray(row?.imagesPath).map((url, index) => (
                  <img
                    key={index}
                    className="col-4 px-1x"
                    src={url}
                    style={{ width: '270px', height: '216px' }}
                    alt={`Image ${index + 1}`}
                  />
                ))}
              </div>
            }
          >
            <label>{value !== null ? `${value}` : "-"}</label>
          </OverlayTrigger>
        </>,

      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let id = row.id;
          toastLogin(id);
        },

      },
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Hotel ID</Tooltip>}
        >
          <label>Hotel ID</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "propertyType",
      text: "Brand",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      formatter: (value, row) =>
        <>

          {row?.displayBrandOn1031 ?
            <label>{value !== null ? `${value?.brandName}` : "-"}</label>
            :
            <label>{value !== null ? `${value?.brandType}` : "-"}</label>
          }
        </>

      ,
      classes: large ? "data-style-lg" : "data-style",
      sort: true,
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Brand</Tooltip>}
        >
          <label>Brand</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "primaryState.name",
      text: "State",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) =>
        <>

          <label>{value !== null ? `${value}` : "-"}</label>
        </>,
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>State</Tooltip>}
        >
          <label>State</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "nearestMSA",
      text: "Nearest MSA",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) =>
        <>
          <label>{value !== null ? `${value}` : "-"}</label>
        </>,
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Nearest MSA</Tooltip>}
        >
          <label>Nearest MSA</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "askingPrice",
      text: "Asking Price",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) => (
        <>
          <CurrencyFormat value={value != null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Asking Price</Tooltip>}
        >
          <label>Asking Price</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "pipEstimate",
      text: "Estimated PIP",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) => (
        <>
          <CurrencyFormat value={value != null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Estimated PIP</Tooltip>}
        >
          <label>Estimated PIP</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "noOfRooms",
      text: "Room Count",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) =>
        <>
          <label>{value !== null ? `${value}` : "-"}</label>
        </>,
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Room Count</Tooltip>}
        >
          <label>Room Count</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "minDownPayment",
      text: "Minimum Downpayment",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) => (
        <>
          <CurrencyFormat value={value != null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Minimum Downpayment</Tooltip>}
        >
          <label>Minimum Downpayment</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "totalRevenue",
      text: "Revenue",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) => (
        <>
          <CurrencyFormat value={value != null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </>

      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Revenue</Tooltip>}
        >
          <label>Revenue</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "netIncome",
      text: "Net Income",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) => (
        <>
          <CurrencyFormat value={value != null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Net Income</Tooltip>}
        >
          <label>Net Income</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "capRate",
      text: "Cap Rate %",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) =>
        <>
          <label>{value !== null ? `${value.toFixed(2)}` : "0.00"}</label>
        </>,
      classes: large ? "data-style-lg" : "data-style",
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Cap Rate %</Tooltip>}
        >
          <label>Cap Rate %</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "roomRevenueMultiplier",
      text: "Revenue Multiplier",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) => (
        <>
          <label>{value != null ? value.toFixed(2) : "0.00"} </label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Revenue Multiplier</Tooltip>}
        >
          <label>Revenue Multiplier</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "pricePerKey",
      text: "Price Per Room",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) => (
        <>
          <CurrencyFormat value={value != null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Price Per Room</Tooltip>}
        >
          <label>Price Per Room</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "cashOnCashRet",
      text: "Cash On Cash Return %",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) => (
        <>
          <label>{value != null ? value.toFixed(2) : "0.00"} </label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Cash On Cash Return %</Tooltip>}
        >
          <label>Cash On Cash Return %</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "yearBuilt",
      text: "Year Built",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) =>
        <>
          <label>{value !== null ? `${value}` : "-"}</label>
        </>,
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Year Built</Tooltip>}
        >
          <label>Year Built</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "user",
      text: "Posted By",
      headerStyle: { fontSize: "10px", textAlign: "center" },
      style: { fontSize: "10px", textAlign: "center" },
      sort: true,
      formatter: (value, row) =>
        <>
          <label>{value !== null ? postedByMask(value?.userTypes, row) : "-"}</label>
        </>,
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Posted By</Tooltip>}
        >
          <label>Posted By</label>
        </OverlayTrigger>
      )
    },

  ];

  const postedByMask = (userType, row) => {

    let type = userType.includes("Agent") ? "BROKER" : userType;
    if (row?.buyerCompanyName?.includes("Hotel Brokers of America", "HBA", "1031Agents") || row?.sellerCompanyName?.includes("Hotel Brokers of America", "HBA", "1031Agents")) {
      type?.toUpperCase()
    }
    return type;
  }

  const getRowStyle = (row, rowIndex) => {
    <div className="row">
      {getImagesArray(row?.imagesPath).map(url => {
        return {
          backgroundImage: `url(${url})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          backgroundSize: 'contain',
          cursor: 'pointer'
        };
      }
      )}
    </div>

  };



  return (

    <div className="padding-wrapper-custom">
      <div className="buyer-profile-page property-list-page">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div
                  className="buyerlist-heading d-flex justify-content-center  boldBlue-color-filler"
                >
                  <h4>SHOP HOTELS FOR SALE</h4>
                </div>
                <div className="wrapper bd-property-interests table-hover">
                  <TableComponent
                    columns={columns}
                    products={properties}
                    filterTabs={filteration}
                    filterBtnTabs={filterBtnTabs}
                    searchData={false}
                    showImages={showImages}
                    rowStyle={getRowStyle}
                    noDataMessage={'No Hotels'}
                    type={'Hotel'}
                    clickEvent={toastLogin}
                  />

                </div>

              </div>
            </div>
            <Modal size="lg" className="d-flex align-items-center justify-content-center " show={loginToast}>
              <br></br>
              <Modal.Body className="w-100 h-100" style={{ minWidth: "600px", minHeight: "200px" }} >
                <div>
                  <h3 className="d-flex align-items-center justify-content-center align-items-center">Please Register to View this Profile</h3>
                  <br></br>
                  <br></br>
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col">
                      <button className="btn btn-outline-primary w-100" onClick={() => window.location.href.includes("broker") ? navigate("/brokerRegistration") : navigate("/buyerRegistration")}>
                        Register
                      </button>
                    </div>
                    <div className="col">
                      <button className="btn btn-outline-primary w-100" onClick={() => {
                        sessionStorage.setItem("redirectAfterAuth", `/propertyProfile/${loginRedirectId}`)
                        navigate("/login")
                      }}>
                        Login
                      </button>
                    </div>

                    <div className="col">
                      <button className="btn btn-outline-red w-100" onClick={() => setLoginToast(false)}>
                        Stay on this page
                      </button>
                    </div>

                  </div>

                </div>
              </Modal.Body>
            </Modal>

          </div>
        </div>
      </div>
    </div>


  );
};

export default PropertyList;
