import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { resetPassword } from '../../../api/api';
import Swal from 'sweetalert2';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [formState, setFormState] = useState({
    token: "",
    password: "",
    email: ""
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      let payload = {
        token: token,
        password: password,
        email: email
      }

      let res = await  resetPassword(payload)
      {res.data ? 
        setTimeout(()=>{
          window.location.href = "/login"; 
        },2000)

        : Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.message
      })}
      
      setShowSuccessAlert(true); // display success alert for demo purposes
    } else {
      setShowErrorAlert(true);
    }
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h2 className="card-title text-center mb-4"><b>Set New Password</b></h2>
              {showSuccessAlert && <Alert variant="success">Your password has been updated successfully.</Alert>}
              {showErrorAlert && <Alert variant="danger">Your passwords do not match. Please try again.</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formPassword">
                  <Form.Label><b>Email</b></Form.Label>
                  <Form.Control type="email" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </Form.Group>
                <br></br>
                <Form.Group controlId="formPassword">
                  <Form.Label><b>New Password</b></Form.Label>
                  <Form.Control type="password" placeholder="Enter new password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </Form.Group>
                <br></br>
                <Form.Group controlId="formConfirmPassword">
                  <Form.Label><b>Confirm New Password</b></Form.Label>
                  <Form.Control type="password" placeholder="Confirm new password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                </Form.Group>
                <Button variant="primary" type="submit" className="btn-block mt-4">
                  Set New Password
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
