import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getAppUrl, GetClientTokenCookie, getUserType } from "../common/commonUtils";
import { getPrimaryStates, getPropertyBrands, getPropertyCategories, getPropertyMarketTypes, getPropertyRoomCount } from "../../api/api";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBuyerCard from "../CommonPages/registration/RegistrationBuyer";
import { useNavigate } from "react-router-dom";
import Logo from '../../img/home/1031LogoWhiteGLow.png';
import vectorImage from '../../components/assets/Vector 1.png';
import { BsCircle, BsCircleFill } from "react-icons/bs";
import { useState } from "react";

const AddBuyer = () => {
  const navigate = useNavigate();
  const [currentPage,SetcurrentPage] = useState(1);

  const handlePageChange = React.useCallback((newPage) => {
    SetcurrentPage(newPage);
 }, []);
 
  useEffect(async () => {
    getAppUrl();
    await getPrimaryStates();
    await getPropertyCategories();
    await getPropertyMarketTypes();
    await getPropertyRoomCount();
    await getPropertyBrands();
  }, []);

  return (
    <>
      <div className="container-common">
        <div className="left">
          <div className='box justify-content-center w-100'>
              <div className="status-nav d-flex justify-content-center align-start py-2 w-100" >
                {!getUserType()?.includes("Broker") ? <label className="fill-white font-weight-b   px-2 ">Buyer Registration</label> : <></>} 
                <label className={ currentPage == 1 ? `font-weight-b  fill-white px-2 highligter` : "font-weight-b fill-white px-2" }>Buyer Profile</label>
                <label className={ currentPage == 2 ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2" }>Buyer Interests</label>
                <label className={ currentPage == 3 ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2" }>Upload Documents</label>
                <label className={ currentPage == 4  ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2" }>Accept Terms and Conditions</label>
              </div>
          
            <img className='common-vector' src={vectorImage} alt="No Vector"></img>
          </div>
        </div>
        <div className="right">
          <div className="card-body w-100">
            <form
              id="register_wizard_with_validation"
              className="form-control"
              data-token={GetClientTokenCookie()}
            >
              {<AddBuyerCard changePage={handlePageChange}></AddBuyerCard>}

            </form>
          </div>
        </div>
      </div>


    </>
  );
};

export default AddBuyer;
