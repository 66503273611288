// AnimatedDiv component

import React, { useEffect, useRef, useState } from 'react';
import '../../css/custom/home.scss';

const AnimatedDiv = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current) {
                const elementOffset = elementRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (elementOffset < windowHeight) {
                    if (!isVisible) {
                        setIsVisible(true);
                    }
                } else {
                    setIsVisible(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isVisible]);

    return (
        <div ref={elementRef} className={`animated-div ${isVisible ? 'visible' : ''}`}>
            {children}
        </div>
    );
};

export default AnimatedDiv;
