import React, { useEffect, useState, useMemo, useCallback } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableComponent from "../Tables/TableComponent";
import ListGroup from "react-bootstrap/ListGroup";
import Overlay from 'react-bootstrap/Overlay';
import { BiFilter, BiHeart } from "react-icons/bi";
import "bootstrap/dist/css/bootstrap.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  getBuyers,
  getDashboard,
  getPrimaryStates,
  getPropertyTypes,
  getUserInfo,
  verifyAndSetToken
} from "../../api/api";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdClose } from "react-icons/md";
import { SlArrowDown } from "react-icons/sl";
import CurrencyFormat from 'react-currency-format';
import { agentHandler, getToken, getUser, isUserLoggedIn, stateFormatter, tableValueParser } from "../common/commonUtils";
import { brandTypes, buyingPowers, days45deadlines } from "../common/utils";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from "sweetalert2";

const BuyersList = () => {
  const [buyers, setBuyers] = useState([]);
  const [allBuyers, setAllBuyers] = useState([]);
  const [states, setStates] = useState([]);
  const [brand, setBrands] = useState([]);
  const [buyingPower, setBuyingPower] = useState([]);
  const [days45deadline, setDays45deadline] = useState([]);
  const [check, setCheckAll] = useState(false);
  const [filterList, setFilterList] = useState(true);
  const [large, setLarge] = useState(true);
  const [approvedBuyer, setApprovedBuyer] = useState(false);
  const [approvedSeller, setApprovedSeller] = useState(false);
  const [hasNoProfiles, setHasNoProfiles] = useState(false);
  const [userType, setUserType] = useState("");
  const [loginToast, setLoginToast] = useState(false);
  const [loginRedirectId, setLoginRedirectId] = useState();

  const [applied_filters, setApplied_filters] = useState({
    buyingPower: false,
    deadline_45: false,
    brand: false,
    state: false,
  });

  const initValues = {
    buyer_min: "",
    buyer_max: "",
    deadline_45_min: "",
    deadline_45_max: "",
    brand: "",
    state: "",
    buyingPowerId: "",
    days45deadlineId: "",
  };

  const handleFilterChange = (e) => {
    console.log(formik.values)
    if (e.target.name == "brand") {
      formik.setFieldValue('brand', e.target.value.trim())
      formik.handleSubmit()
    }

    if (e.target.name == "state") {
      formik.setFieldValue('state', e.target.value)
      formik.handleSubmit()
    }

    if (e.target.name == "buyingpower") {
      buyingPower.map((val) => {
        if (val.id == e.target.value) {
          formik.setFieldValue('buyer_min', val.min)
          formik.setFieldValue('buyer_max', val.max)
          formik.setFieldValue('buyingPowerId', val.id)
          formik.handleSubmit()
        }
      })
    }
    if (e.target.name == "45daysdeadline") {
      days45deadline.map((val) => {
        if (val.id == e.target.value) {
          formik.setFieldValue('deadline_45_min', val.min)
          formik.setFieldValue('deadline_45_max', val.max)
          formik.setFieldValue('days45deadlineId', val.id)
          formik.handleSubmit()
        }
      })
    }
  }

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values) => {
      console.log(values);
      filterData();
      setFilterList((current) => !current);
    },
  });

  const loggedInUser = sessionStorage.getItem("user");
  const navigate = useNavigate();

  useEffect(() => {
    verifyAndSetToken();
    getAllBuyers();
    if (isUserLoggedIn()) {
      checkApprovalStatus();
    }

    sessionStorage.removeItem("currentPostid")
  }, []);

  const getAllBuyers = async () => {
    let res = await getBuyers();
    setBuyers(res.buyers);
    setAllBuyers(res.buyers);
    let val = await getPrimaryStates();

    setStates(val);


    setBrands(brandTypes)
    setBuyingPower(buyingPowers)
    setDays45deadline(days45deadlines)
    setFilterList((current) => !current);
  };

  const checkApprovalStatus = async () => {
    const user = await getUserInfo();

    if (user?.hbaStaff) agentHandler(user?.hbaStaff)

    if (user.approvedBuyer === true) {
      setApprovedBuyer(true);
    } else {
      setApprovedBuyer(false);
    }

    if (user.approvedSeller === true) {
      setApprovedSeller(true);
    } else {
      setApprovedSeller(false);
    }

    if (user.propertiesSize == 0) {
      setHasNoProfiles(true);
    }

    setUserType(user?.userTypes);
  }


  //-----------------------------------------------FILTERS LOGIC----------------------------------------------------//


  const filterData = () => {
    let currentFilteredData = allBuyers;

    if (formik.values.buyer_min !== "" || formik.values.buyer_max !== "") {
      const min = formik.values.buyer_min !== "" ? formik.values.buyer_min : 0;
      const max = formik.values.buyer_max !== "" ? formik.values.buyer_max : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((b) => b.buyingPower >= min && b.buyingPower <= max);
    }

    if (formik.values.deadline_45_min !== "" || formik.values.deadline_45_max !== "") {
      const min = formik.values.deadline_45_min !== "" ? formik.values.deadline_45_min : 0;
      const max = formik.values.deadline_45_max !== "" ? formik.values.deadline_45_max : 5000000000000000;
      currentFilteredData = currentFilteredData.filter((b) => {
        if (max <= 14 || (min == 0 && max == 5000000000000000)) {
          return b.deadLineDate45DaysString == "critical" || (b.deadLineDate45DaysString >= min && b.deadLineDate45DaysString <= max);
        } else {
          return b.deadLineDate45DaysString >= min && b.deadLineDate45DaysString <= max;
        }
      });
    }

    if (formik.values.brand !== "" && formik.values.brand !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => {
        if (b.interestedBrands) {
          const brands = b.interestedBrands.includes(",") ? b.interestedBrands.split(",").map((brand) => brand.trim()) : [b.interestedBrands];
          return brands.includes(formik.values.brand);
        }
        return false;
      });
    }

    if (formik.values.state !== "" && formik.values.state !== "Any") {
      currentFilteredData = currentFilteredData.filter((b) => {
        if (b.interestedPrimaryStatesList) {
          const states = b.interestedPrimaryStatesList.map((state) => state?.id);
          return states.includes(parseInt(formik.values.state));
        }
        return false;
      });
    }

    setBuyers(currentFilteredData);
  };

  //-----------------------------------------------------------------------------------------------------------------//

  const iconHandler = (order) => {
    if (!order)
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp style={{ color: "#d7d7d7" }} />
          </span>
          <span>
            <CgArrowLongDown style={{ color: "#d7d7d7" }} />
          </span>
        </div>
      );
    else if (order === "asc")
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp />
          </span>
          <span>
            <CgArrowLongDown style={{ color: "#d7d7d7" }} />
          </span>
        </div>
      );
    else if (order === "desc")
      return (
        <div className="iconWrapper-small">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp style={{ color: "#d7d7d7" }} />
          </span>
          <span>
            <CgArrowLongDown />
          </span>
        </div>
      );
    return null;
  };


  const showDeadline = (date, color) => {
    let divClass = "";

    divClass = `right-div-${color}-button`

    return (
      <div className="date-wrapper">
        <div className={divClass}>{date ? date : "-"}</div>
      </div>
    );
  };

  const showClosingDate = (date, color) => {
    let divClass = "";
    if (color === "green") {
      divClass = "closingdate-div-green";
    } else if (color === "red") {
      divClass = "closingdate-div-red";
    } else if (color === "yellow") {
      divClass = "closingdate-div-yellow";
    }

    return (
      <div className="date-wrapper">
        <div className={divClass}>{date ? date : "-"}</div>
      </div>
    );
  };

  const loginIfNot = () => (
    <div>
      <span>Please Login</span>
      <button className="login-toast" onClick={() => navigate("/register")}>
        Register
      </button>
      <button className="login-toast" onClick={() => navigate("/login")}>
        Login
      </button>
    </div>
  );

  const notifyLogin = (id) => {
    // toast.error(loginIfNot, {
    //   position: toast.POSITION.BOTTOM_CENTER,
    // });
    setLoginToast(true)
    setLoginRedirectId(id)
  };

  const notifyGetApproved = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: 'my-swal',
        popup: 'my-swal-popup-notify',

        content: 'my-swal-content'
      }

    })


    swalWithBootstrapButtons.fire({
      title: 'Your profile is being reviewed',
      html: `<div>
                <h4>Please wait until your profile is approved to access the buyers</h4>
                </br>    
                <h4> You can contact us on </h4>    
                <h4><b>info@1031hotels.com</b></h4>
                <h4><b>661-808-4998</b></h4>
              </div>
      `
    })
  };

  const notifyToCreateProfile = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: 'my-swal',
        popup: 'my-swal-popup-notify',
        content: 'my-swal-content',
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-cancel',

      }

    })

    swalWithBootstrapButtons.fire({
      title: 'Post a Hotel',
      text: 'Please post a hotel to view this buyer',
      showCancelButton: true,
      confirmButtonText: "Post a Buyer",
      cancelButtonText: "OK",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/addSeller')
      }
    })
  };

  const notifyToGetAssignedProfile = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: 'my-swal',
        popup: 'my-swal-popup-notify',

        content: 'my-swal-content'
      }

    })

    swalWithBootstrapButtons.fire({
      title: 'Attention !!',
      text: 'No Hotel assigned to shop buyers',
    })
  };

  const toastLogin = (id) => {
    const user = sessionStorage.getItem("user");
    if (user) {
      if (userType == "Seller") {
        if (true) {
          navigate("/buyerProfile/" + id);
        } else {
          notifyGetApproved();
        }
      } else {
        if (hasNoProfiles && userType.includes("Agent")) {
          notifyToGetAssignedProfile();
        } else if (hasNoProfiles) {
          notifyToCreateProfile();
        } else {
          if (true) {
            navigate("/buyerProfile/" + id);
          } else {
            notifyGetApproved();
          }
        }

      }

    } else {
      notifyLogin(id)
      // navigate("/sellersLanding")
    }
  };

  const columns = [
    {
      dataField: "generatedBuyerId",
      text: "1031 Exchange Buyer ID",
      classes: large === true ? "data-stylebuyer-id-lg hand-cursor" : "data-style-id hand-cursor",
      formatter: (value) => <label className="hand-cursor">{value ? value : "1031 Exchange Buyer ID"}</label>,
      sort: true,

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          let id = row.id;
          toastLogin(id);
        },
      },
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>1031 Exchange Buyer ID</Tooltip>}
        >
          <label>1031 Exchange Buyer ID</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "funds1031",
      text: "1031 Exchange Funds",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (value) => (
        <CurrencyFormat value={value != null ? value : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      ),
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>1031 Exchange Funds</Tooltip>}
        >
          <label>1031 Exchange Funds</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "buyingPower",
      text: "Max Buying Power",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (value, row) => (
        // console.log(cell)
        <CurrencyFormat value={value ? ((parseInt(row?.funds1031) + parseInt(row?.additionalFunds)) * 5) : 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      ),
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Max Buying Power</Tooltip>}
        >
          <label>Max Buying Power</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "deadLineDate45DaysString",
      text: "Days to 45-day deadline",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (date, val) => showDeadline(date, val.deadLineDate45DaysColor),
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Days to 45-day deadline</Tooltip>}
        >
          <label>Days to 45-day deadline</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "closingDate1031String",
      text: "Days to 1031 closing",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (date, val) => showClosingDate(date, val.closingDate1031Color),
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Days to 1031 closing</Tooltip>}
        >
          <label>Days to 1031 closing</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "interestedBrands",
      text: "Interested Hotel Brand",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value) => <label>{value !== null ? `${tableValueParser(value)}` : "-"}</label>,
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Interested Hotel Brand</Tooltip>}
        >
          <label>Interested Hotel Brand</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "interestedCategory",
      text: "Interested Market Segment",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value) => <label>{value !== null ? `${tableValueParser(value)}` : "-"}</label>,
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Interested Market Segment</Tooltip>}
        >
          <label>Interested Market Segment</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "interestedRoomCount",
      text: "No.of Rooms",
      classes: large === true ? "data-style-lg" : "data-style",
      formatter: (value, cell, row) => <label>{value && cell.interestedRoomCount !== null ? `${value}` : "-"}</label>,
      sort: true,

      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>No.of Rooms</Tooltip>}
        >
          <label>No.of Rooms</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "opportunityType",
      text: "Preferred Investment Type",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value) => <label>{value !== null ? `${tableValueParser(value)}` : "-"}</label>,
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Preferred Investment Type</Tooltip>}
        >
          <label>Preferred Investment Type</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "interestedPrimaryStatesList",
      text: "Primary States",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value) => <label>{stateFormatter(value)}</label>,
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Primary States</Tooltip>}
        >
          <label>Primary States</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "user",
      text: "Posted By",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,

      formatter: (value, row) => (
        <label>{value !== null ? postedByMask(value?.userTypes, row) : "-"}</label>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Posted By</Tooltip>}
        >
          <label>Posted By</label>
        </OverlayTrigger>
      )
    },
  ];

  const postedByMask = (userType, row) => {

    let type = userType.includes("Agent") ? "BROKER" : userType;
    if (row?.buyerCompanyName?.includes("Hotel Brokers of America", "HBA", "1031Agents") || row?.sellerCompanyName?.includes("Hotel Brokers of America", "HBA", "1031Agents")) {
      type?.toUpperCase()
    }
    return type;
  }

  const filterHandler = () => {
    setFilterList((current) => !current);
  };

  const resetHandler = () => {
    formik.resetForm();
    setBuyers(allBuyers);
    setFilterList((current) => false);
  };

  //------------------------------------------------------filter functions--------------------------------//

  const checkAllStates = () => {
    setCheckAll({ ...check, allStates: !check.allStates });
    setTimeout(
      setFilterList((current) => !current),
      1500
    );
    setApplied_filters({ ...applied_filters, state: !applied_filters.state });
  };

  const removeFilterHandler = (val, array) => {
    if (array === "brand") {
      let data = formik.values.brand;
      formik.values.brand = data.filter((item) => item !== val);
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setBuyers(allBuyers);
      } else filterData();
      setFilterList((current) => !current);
    } else if (array === "state") {
      let data = formik.values.state;
      formik.values.state = data.filter(
        (item) => JSON.parse(item).name !== val
      );
      if (JSON.stringify(formik.values) === JSON.stringify(initValues)) {
        setBuyers(allBuyers);
      } else filterData();
      setFilterList((current) => !current);
    }
  };

  const clearFilters = (val) => {
    const values = formik.values;
    if (val === "mbp") {
      formik.values.buyer_min = initValues.buyer_min;
      formik.values.buyer_max = initValues.buyer_max;
      if (
        values.deadline_45_min !== "" ||
        values.deadline_45_max !== "" ||
        values.brand.length ||
        values.state.length
      ) {
        filterData();
      } else {
        setBuyers(allBuyers);
      }
    } else {
      formik.values.deadline_45_min = initValues.deadline_45_min;
      formik.values.deadline_45_max = initValues.deadline_45_max;
      if (
        values.buyer_min !== "" ||
        values.buyer_max !== "" ||
        values.brand.length ||
        values.state.length
      ) {
        filterData();
      } else {
        setBuyers(allBuyers);
      }
    }
    setFilterList((current) => !current);
  };

  //------------------------------------------------------filter functions--------------------------------//

  const filterTabs = () => {
    return (
      <div className="filter-div">
        <div
          style={{ marginLeft: "-5px" }}
          className="row "
        >
          <div className="col">
            <label data="Maximum Buying Power"><b>Maximum Buying Power</b></label><br></br>
            <select name="buyingpower" onChange={(e) => {
              handleFilterChange(e)
            }} className="form-select filter-div-button-wrapper" value={formik.values.buyingPowerId}>
              {buyingPower
                ? buyingPower.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="buyingpower"
                    value={val.id}
                  >
                    {val.label}
                  </option>
                ))
                : " "}
            </select>
          </div>
          <div className="col">
            <label data="Maximum Buying Power"><b>Days To 45-Days Deadline</b></label><br></br>
            <select name="45daysdeadline" onChange={(e) => {
              handleFilterChange(e)
            }} className="form-select filter-div-button-wrapper" value={formik.values.days45deadlineId}>
              {days45deadline
                ? days45deadline.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="45daysdeadline"
                    value={val.id}
                  >
                    {val.label}
                  </option>
                ))
                : " "}
            </select>
          </div>
          <div className="col ">
            <label data="Maximum Buying Power"><b>Brand</b></label><br></br>
            <select name="brand" onChange={(e) => handleFilterChange(e)}
              className="form-select filter-div-button-wrapper" value={formik.values.brand}>
              {brand
                ? brand.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val}
                  >
                    {val}
                  </option>
                ))
                : " "}
            </select>
          </div>
          <div className="col ">
            <label data="Maximum Buying Power"><b>State</b></label><br></br>
            <select name="state" onChange={(e) => handleFilterChange(e)}
              className="form-select filter-div-button-wrapper" value={formik.values.state}>
              <option className="filter-select"
                name="askingprice"
                value="Any" >Any</option>
              {states
                ? states.map((val, index) => (
                  <option key={index}
                    className="filter-select"
                    name="askingprice"
                    value={val.id}
                  >
                    {val.name}
                  </option>
                ))
                : " "}
            </select>
          </div>
          <div className="col  d-flex justify-content-end py-2" style={{ marginTop: "0.7rem" }}>
            <br></br>
            <button onClick={resetHandler} type="button" className="btn btn-outline-primary-narrow-right">
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  };
  const filteration = useMemo(() => filterTabs, [filterList]);

  const filterBtnTabs = useCallback(() => {
    return (
      <div className="filter-list">
        <button className="filter-list-btn" onClick={filterHandler}>
          Filters <BiFilter />
        </button>
        <div className={filterList === false ? "hide-tru" : "show-tru"}>
          <ListGroup>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <b>Maximum Buying Power</b>
                </button>
                <select name="buyingpower" onChange={(e) => {
                  handleFilterChange(e)
                }} className="form-select filter-div-button-wrapper" value={formik.values.buyingPowerId}>
                  {buyingPower
                    ? buyingPower.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="buyingpower"
                        value={val.id}
                      >
                        {val.label}
                      </option>
                    ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <b>Days To 45-Days Deadline</b>
                </button>

                <select name="45daysdeadline" onChange={(e) => {
                  handleFilterChange(e)
                }} className="form-select filter-div-button-wrapper" value={formik.values.days45deadlineId}>
                  {days45deadline
                    ? days45deadline.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="45daysdeadline"
                        value={val.id}
                      >
                        {val.label}
                      </option>
                    ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <b>Brand</b>
                </button>
                <select name="brand" onChange={(e) => handleFilterChange(e)}
                  className="form-select filter-div-button-wrapper" value={formik.values.brand}>
                  {brand
                    ? brand.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="askingprice"
                        value={val}
                      >
                        {val}
                      </option>
                    ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
            <ListGroup.Item>
              <div className="dropdown">
                <button
                  className="filter-btn-dropdown"
                  type="button"
                  id="dropdownMenu2"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="outside"
                >
                  <b>State</b>
                </button>
                <select name="state" onChange={(e) => handleFilterChange(e)}
                  className="form-select filter-div-button-wrapper" value={formik.values.state}>
                  <option className="filter-select"
                    name="askingprice"
                    value="Any" >Any</option>
                  {states
                    ? states.map((val, index) => (
                      <option key={index}
                        className="filter-select"
                        name="askingprice"
                        value={val.id}
                      >
                        {val.name}
                      </option>
                    ))
                    : " "}
                </select>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </div>
        <br></br>
        <button onClick={resetHandler} className="filter-list-btn">
          Reset
        </button>
      </div>
    );
  }, [filterList]);

  return (
    <div className="padding-wrapper-custom">
      <div className="buyer-profile-page global-hboa-table">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="">
                <div
                  style={{
                    padding: "10px 18px 5px",
                    borderBottom: "1px solid #f3f3f363",
                  }}
                  className="buyerlist-heading d-flex justify-content-center  boldBlue-color-filler"
                >
                  <h4>SHOP FOR 1031 BUYERS</h4>
                </div>
                <div className="wrapper bd-buyer-interests table-hover">
                  <TableComponent
                    columns={columns}
                    products={buyers}
                    filterTabs={filteration}
                    filterBtnTabs={filterBtnTabs}
                    searchData={false}
                    noDataMessage={'No Buyers'}
                    clickEvent={toastLogin}
                  />
                </div>
              </div>
            </div>
          </div>
          <Modal size="lg" className="d-flex align-items-center justify-content-center " show={loginToast}>
            <br></br>
            <Modal.Body className="w-100 h-100" style={{ minWidth: "600px", minHeight: "200px" }} >
              <div>
                <h3 className="d-flex align-items-center justify-content-center align-items-center">Please Register to View this Profile</h3>
                <br></br>
                <br></br>
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col">
                    <button className="btn btn-outline-primary w-100" onClick={() => window.location.href.includes("broker") ? navigate("/brokerRegistration") : navigate("/sellerRegistration")}>
                      Register
                    </button>
                  </div>
                  <div className="col">
                    <button className="btn btn-outline-primary w-100" onClick={() => {
                      sessionStorage.setItem("redirectAfterAuth", `/buyerProfile/${loginRedirectId}`)
                      navigate("/login")
                    }}>
                      Login
                    </button>
                  </div>

                  <div className="col">
                    <button className="btn btn-outline-red w-100" onClick={() => setLoginToast(false)}>
                      Stay on this page
                    </button>
                  </div>

                </div>

              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default BuyersList;
