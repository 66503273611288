import React, { useEffect, useState } from 'react';
import 'react-data-grid/lib/styles.css';
import 'react-table-6/react-table.css';
import { PDFDocument } from 'pdf-lib';
const PdfFormFiller = ({ arrayBuffer, fileType, formDetails, handleIAgree, handleCancel }) => {
    const [fileURL, setFileURL] = useState('');
    const [fileText, setFileText] = useState('');
    const [iframeHeight, setIframeHeight] = useState('500px');
    const [spreadsheetData, setSpreadsheetData] = useState(null);

    useEffect(() => {
        const updateIframeHeight = () => {
            const screenHeight = window.innerHeight || document.documentElement.clientHeight;
            const newHeight = screenHeight - 150;

            setIframeHeight(`${newHeight}px`);
        };

        window.addEventListener('resize', updateIframeHeight);
        updateIframeHeight();

        return () => {
            window.removeEventListener('resize', updateIframeHeight);
        };
    }, []);

    useEffect(async () => {

        formPdfDoc().then(pdfBytes => {
            const blob = new Blob([pdfBytes], { type: getFileType(fileType) });
            const url = URL.createObjectURL(blob);

            setFileURL(url);

            return () => {
                URL.revokeObjectURL(url);
            };
        })


    }, [arrayBuffer]);

    const formPdfDoc = async () => {
        const pdfDoc = await PDFDocument.load(arrayBuffer)
        const form = pdfDoc.getForm();
        const fieldNames = []
        const fieldNameToRemove = ['Text-Location'];

        fieldNameToRemove?.forEach(field => {
            removeField(pdfDoc, form, field)
        })

        form?.getFields()?.forEach(async (field, index) => {
            let fieldName = field?.getName();
            console.log(fieldName)
            if (fieldNameToRemove?.includes(field?.getName())) {
                removeField(pdfDoc, form, field?.getName())
            } else {
                console.log(formDetails[fieldName])
                field?.setText(formDetails[fieldName] ? formDetails[fieldName] : "")
                field?.enableReadOnly(true)

            }

        });
        const pdfBytes = pdfDoc.save();
        return pdfBytes;
    }

    const removeField = async (pdfDoc, form, fieldNameToRemove) => {
        const field = '';
        try {
            field = form?.getTextField(fieldNameToRemove);
        } catch (err) {

        }

        if (field) {
            form?.removeField(field);
        }

        const modifiedPdfBytes = await pdfDoc.save();
        return modifiedPdfBytes
    };



    const getFileType = (extension) => {
        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'doc':
            case 'docx':
                return 'application/msword';
            case 'xls':
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            default:
                return 'application/octet-stream';
        }
    };




    return (
        <div>

            <div className='container'>
                {fileURL ? (
                    <>
                        <div className=''>
                            <iframe className='card-body' src={fileURL} title="File Preview" width="100%" height={iframeHeight} />
                            {handleIAgree != "" || handleCancel != "" ?
                                <div className="d-flex flex-wrap justify-content  px-3 py-2">
                                    <div>
                                        <button className="btn btn-outline-cancel" onClick={handleCancel}>CANCEL</button>
                                    </div>
                                    <div className='px-2'>
                                        <button className="btn btn-solid" onClick={handleIAgree}>
                                            I AGREE
                                        </button>
                                    </div>
                                </div>
                                : <></>}

                        </div>

                    </>

                ) : (
                    <div>Loading...</div>
                )}

            </div>

        </div>
    );
};

export default PdfFormFiller;
