import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import $ from "jquery";
import { uploadFiles } from "../../../api/api";
import CurrencyInput from "react-currency-input-field";
import {Breadcrumbs, Link, Typography} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

const BuyerRegistration = () => {
  const [cashAvailable, setcashAvailable] = useState("");
  const [downPayment, setdownPayment] = useState("");
  const [totalAmount, settotalAmount] = useState("");
  const [closeDatePicker, setcloseDatePicker] = useState("");
  const [deadLine, setdeadLine] = useState("");
  const [closeDate, setcloseDate] = useState("");
  const [properties, setproperties] = useState("");
  const [proof, setproof] = useState("");
  const [description, setdescription] = useState("");
  const [addFile, setAddFile] = useState([]);
  const [uploadFileUrls, setuploadFileUrls] = useState();
  const [state, setState] = useState("");

  function onChangecashAvailable(e) {
    setcashAvailable(e.target.value);
  }

  useEffect(() => {
    localStorage.setItem("uploaded_file", JSON.stringify([0]));
    localStorage.setItem("uploadFileUrls","");
  }, []);

  function onChangedownPayment(e) {
    setdownPayment(e.target.value);
  }

  function onChangetotalAmount(e) {
    console.log(uploadFileUrls)
    settotalAmount(e.target.value);
  }

  function onChangecloseDatePicker(e) {
    setcloseDatePicker(e.target.value);
  }

  function onChangedeadLine(e) {
    setdeadLine(e.target.value);
  }

  function onChangecloseDate(e) {
    setcloseDate(e.target.value);
  }

  function onChangeproperties(e) {
    setproperties(e.target.value);
  }

  function onChangeproof(e) {
    setproof(e.target.value);
  }

  function onChangedescription(e) {
    setdescription(e.target.value);
  }

  const onChangeState = (e) => {
    setState(e.target.value);
  };

  const addCustomFile = (e) => {
    e.preventDefault();
    setAddFile([...addFile, { addfile: 1 }]);
  };

  var selectedFile;
  let fileName = "";

  $(document).on("change", "#upload_file", (e) => {
    fileName = "";
    selectedFile = e.currentTarget.files;
    if (document.getElementById("upload_file_btn").className === "disable") {
      document.getElementById("upload_file_btn").classList.remove("disable");
      document.getElementById("upload_file_btn").classList.add("enable");
    } else if (
      document.getElementsByName("error_icon")[0].className === "display"
    ) {
      document.getElementsByName("error_icon")[0].classList.remove("display");
      document.getElementById("upload_file_btn").classList.add("enable");
    } else document.getElementById("upload_file_btn").classList.add("enable");
    for (const file of e.currentTarget.files) {
      var sizeInMb = file.size/1024;
      console.log(sizeInMb)
      var sizeLimit= 800;
      if (sizeInMb > sizeLimit) {
          alert("File Should be less than 0.8 Mb")
      }
      else {
        fileName += `${file.name}` + "<i class='fas fa-check'></i>" + "<br>";
      } 
     
    }
    document.getElementById("file_list").innerHTML = fileName;
  });

  $(document).one("click", "#upload_file_btn", async (e) => {
    var loader = document.getElementsByName("loading_icon");
    var success = document.getElementsByName("success_icon");
    var error = document.getElementsByName("error_icon");
    var fileList = document.getElementsByName("file_list");
    let res;

    if (document.getElementsByName("error_icon")[0].className === "display") {
      document.getElementsByName("error_icon")[0].classList.remove("display");
      document.getElementById("upload_file_btn").classList.add("enable");
    } else document.getElementById("upload_file_btn").classList.add("enable");

    loader[0].classList.add("display");
    e.stopImmediatePropagation();
    const allPromises = [];

    if (selectedFile && selectedFile.length) {
      for (var file of selectedFile) {
        allPromises.push(uploadFiles(file, "escrowProof"));
      }
      try {
        res = res = await Promise.all(allPromises);
      } catch (error) {
        loader[0].classList.remove("display");
        res = error;
      }
    }

    for (const val in res) {
      if (res[val].data?.success === true) {
        setuploadFileUrls(uploadFileUrls + ',' + res[val].data?.fileURL)
        console.log(res[val].data?.fileURL)
        {localStorage.getItem("uploadFileUrls") != null ? localStorage.setItem("uploadFileUrls", res[val].data?.fileURL + ',' + localStorage.getItem("uploadFileUrls") ) : localStorage.setItem("uploadFileUrls", res[val].data?.fileURL)}
        setTimeout(() => {
          loader[0].classList.remove("display");
          success[0].classList.add("display");
        }, 2000);
        setTimeout(() => {
          // success[0].classList.remove("display");
          fileList[0].classList.add("success");
          document.getElementById("upload_file_btn").classList.remove("enable");
          document.getElementById("upload_file_btn").classList.add("disable");
        }, 5000);
      } else {
        setTimeout(() => {
          loader[0].classList.remove("display");
          error[0].classList.add("display");
        }, 2000);
      }
    }
  });

  return (
    <>
      <h3>Buyer Registration</h3>

      <fieldset id="buyer-registration">
        <h2>Buyer Registration</h2>
        <div className="form-body">
          <div className={"row"}>
            <div className="form-group col-6">

              <label>Primary State<sup>*</sup></label>
              <select id="primaryStates" name="primaryStates" className="form-control form-select select-state"  >
              </select>
            </div>
          </div>

          <div className={"row"}>
            <div className="form-group col-6">
              <label>Cash available in 1031ex. for Down Payment</label>
              <input
                className="currency form-control"
                type="text"
                name="cashAvailable"
                id="cashAvailable"
                required=""
                maxLength={128}
              />
              <div className="invalid-feedback">Please enter proper amount</div>
            </div>
            <div className="form-group col-6">
              <label>Additional Down Payment Amount</label>
              <input
                type="text"
                className="currency form-control"
                name="downPayment"
                id="downPayment"
                required=""
                maxLength={128}
                value={downPayment}
                onChange={onChangedownPayment}
              />
              <div className="invalid-feedback">Please enter proper amount</div>
            </div>
          </div>

          <div className="row">
          <div className="form-group col-6">
            <label>Total amount the hotel was sold for</label>
            <input
              type="text"
              className="currency form-control"
              name="totalAmount"
              id="totalAmount"
              maxLength={128}
              value={totalAmount}
              onChange={onChangetotalAmount}
            />
            <div className="invalid-feedback">Please enter proper amount</div>
          </div>
          <div className="form-group col-6">
            <label>Total Buying Power</label>
            <input
              type="text"
              className="currency form-control"
              name="totalBuyingPower"
              id="totalBuyingPower"
              maxLength={128}
              disabled={true}
            />
            <div className="invalid-feedback">Please enter proper amount</div>
          </div>
          </div>

          <div className="row">
          <div className="form-group col-4" id="dateinput">
            <label>Escrow close date</label>
            {/* <DatePicker
            type="date"
            id="closeDatePicker"
            name="closeDatePicker"
            className="form-control"
            value={closeDatePicker}
            onChange={onChangecloseDatePicker}
            /> */}
            <input
              type="date"
              id="closeDatePicker"
              name="closeDatePicker"
              className="form-control form-control-date"
              value={closeDatePicker}
              onChange={onChangecloseDatePicker}
              style={{ width: "100%" }}
            />
            <div className="invalid-feedback">Please Choose date</div>
          </div>
          <div className="form-group col-4">
            <label>45-Day Deadline Date</label>
            <input
              type="text"
              className="form-control"
              id="deadLine"
              name="deadLine"
              disabled={"disabled"}
              placeholder="45-Day Deadline Date"
              maxLength={128}
              value={deadLine}
              onChange={onChangedeadLine}
            />
          </div>
          <div className="form-group col-4">
            <label>180-Day Escrow close Date</label>
            <input
              type="text"
              className="form-control"
              id="closeDate"
              name="closeDate"
              disabled={"disabled"}
              placeholder="180-Day Escrow close Date"
              maxLength={128}
              value={closeDate}
              onChange={onChangecloseDate}
            />
          </div>
          </div>
         
         <div className="row">
         <div className="form-group col-4">
            <label>Number of other properties owned</label>
            <input
              type="text"
              className="form-control"
              id="noOfHotelsOwned"
              name="noOfHotelsOwned"
              maxLength={128}
              // min="0"
              // value={properties}
              onChange={onChangeproperties}
            />
            <div className="invalid-feedback">Please enter proper number</div>
          </div>
         

          <div className="form-group col-8">
            <label>
              Upload Proof of 1031 Exchange (1031 acommodator statement) or
              Proof of Escrow
            </label>

            <div className="upload-wrapper mb-4">
              <input
                type="file"
                accept=".pdf, .xlsx"
                className="form-control"
                id="upload_file"
                name="upload_file"
                multiple="multiple"
                maxLength={128}
                onChange={onChangeproof}
              />
              <div id="loading" name="loading_icon"></div>
              <div id="success" name="success_icon">
                <FaCheckCircle />
              </div>
              <div id="error" name="error_icon">
                Unable to uplaod, try again <MdError />
              </div>
              <button type="button" id="upload_file_btn" name="upload_file_btn">
                Upload
              </button>
              <div id="file_list" name="file_list"></div>
              <div className="invalid-feedback">Invalid file format</div>
            </div>
          </div>
         </div>
         <div className="form-group">
            <div id="spinner" className="spinner"></div>
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              className="form-control"
              required=""
              id="description-text"
              name="description-text"
              maxLength={256}
              // value={description}
              onChange={onChangedescription}
            ></textarea>
            <div className="invalid-feedback">What do you wanna say?</div>
          </div>
        </div>
      </fieldset>
    </>
  );
};

export default BuyerRegistration;
