import React, { useMemo, useEffect, useState } from "react";
import {
  getFileByKey,
  getProperty,
  getPropertyBrands,
  getPropertyLoanTypes,
  getStateMsa,
  updatePropertyById,
  updateStatusById,
} from "../../api/api";
import { useParams } from "react-router-dom";
import {
  Accordion,
  Carousel,
  Col,
  Dropdown,
  DropdownButton,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  FaEye,
  FaHandHoldingUsd,
  FaHeart,
  FaRegEdit,
  FaRegSave,
} from "react-icons/fa";
import { S3_IMAGES } from "../../constants/constants";
import {
  AiOutlineEye,
  AiOutlineFileExcel,
  AiOutlineFilePdf,
  AiOutlineFileWord,
} from "react-icons/ai";
import FilePreview from "../common/FilePreview";
import { MdGroup, MdOutlineCancel } from "react-icons/md";
import {
  USAstates,
  marketTypes,
  opportunityTypes,
  propertyCategories,
} from "../common/utils";
import $ from "jquery";
import CurrencyInput from "react-currency-input-field";
import Swal from "sweetalert2";

const SellerCriteria = () => {
  const [propertyloading, setPropertyLoading] = useState(true);
  const [propertyInfo, setPropertyInfo] = useState();
  const [pdfBytes, setPdfBytes] = useState();
  const [isDocOpen, setDocOpen] = useState(false);
  const [openDocType, setOpenDocType] = useState();
  const [spinner, setSpinner] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [factsEdit, setFactsEdit] = useState(false);
  const [numbersEdit, setNumbersEdit] = useState(false);
  const [msaArray, setMsaArray] = useState([]);
  const [loanTypes, setLoanTypes] = useState([]);
  const [loading_facts, setLoadingFacts] = useState(false);
  const [loading_numbers, setNumberLoading] = useState(false);
  const [usStates, setUSstates] = useState();
  const [categoryArray, setCategoryArray] = useState([]);
  const [marketTypeArray, setMarketTypeArray] = useState([]);
  const [opportunityTypeArray, setOpportunityTypeArray] = useState([]);
  const [brandsArray, setBrandsArray] = useState([]);

  const { id, filter } = useParams();

  let user_type = sessionStorage.getItem("user_type");

  useEffect(() => {
    getPropertyInfo();
    sessionStorage.setItem("currentPostid", id);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setUSstates(USAstates);
    setCategoryArray(propertyCategories);
    setMarketTypeArray(marketTypes);
    setOpportunityTypeArray(opportunityTypes);
    getPropertyLoanTypes().then((loans) => setLoanTypes(loans?.loanTypes));
    getPropertyBrands().then((res) => {
      const sortedArray = res.propertyTypes.sort((a, b) => {
        const brandNameA = a.brandName.toUpperCase();
        const brandNameB = b.brandName.toUpperCase();

        if (brandNameA < brandNameB) {
          return -1;
        }
        if (brandNameA > brandNameB) {
          return 1;
        }
        return 0;
      });

      setBrandsArray(sortedArray);
    });
  }, []);

  const getPropertyInfo = async () => {
    let res = await getProperty(id);

    const data = res.data ? res.data : res;
    setPropertyInfo(data);
    getMSA(data?.address?.state?.code);

    setPropertyLoading(false);
  };

  const getImagesArray = (urls) => {
    let urlsArray = urls.split(",");
    let imagesArray = [];

    urlsArray.forEach((url) => {
      if (url.includes("files")) {
        const index = url.indexOf("files");
        const substringBeforeKeyword = url.substring(0, index);

        if (substringBeforeKeyword.trim() === "") {
          let imagesObj = {
            original: S3_IMAGES + url,
            thumbnail: S3_IMAGES + url,
          };
          imagesArray.push(imagesObj);
        } else {
          let imagesObj = {
            original: url,
            thumbnail: url,
          };
          imagesArray.push(imagesObj);
        }
      }
    });

    return imagesArray;
  };

  const approveHandler = async (status, id) => {
    let updatedstatus = {};

    updatedstatus = {
      propertyId: id,
      dealStatus: status,
    };

    try {
        showCustomAlert(status,updatedstatus)
     
    } catch (error) {
      console.log(error);
    }
  };

  const showCustomAlert = (status,updatedstatus) => {
    let text_mssg  = ""
    let confirm_mssg = ""  
    let short_mssg = ""
    if(status == "Off-market"){
        text_mssg = 'You want to make this off-market!'
        confirm_mssg = 'Your post has been taken off-market'
        short_mssg = 'Off-market!'
    }else if(status ==  "Closed"){
        text_mssg = 'You want to delete this post!'
        confirm_mssg = 'Your post has been deleted'
        short_mssg = "Deleted!"
    }else if (status == "Unapproved"){
        text_mssg = 'You want to make this online!'
        confirm_mssg = "Your post is now online"
        short_mssg = "Online!"
    }else {

    }
   
    Swal.fire({
      title: 'Are you sure?',
      text: text_mssg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2F67CA',
      cancelButtonColor: '#FF0000',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await updateStatusById(updatedstatus);
        
        console.log(res)
        {
          res?.data ? (
            // propertyInfo["dealStatus"] = status
            window.location.reload()
          ): 
            <></>
          ;
        }
        Swal.fire(
            short_mssg,
          confirm_mssg,
          'success'
        )
     
      }
    });
  };

  const handleDownload = async (key, type) => {
    try {
      await getFileByKey(key).then((res) => {
        console.log(key);
        const bytes = new Uint8Array(res.data);
        setPdfBytes(bytes);
        setOpenDocType(type);
      });
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Error downloading file");
    }
  };

  const openDoc = async (key, type) => {
    setSpinner(true);

    await handleDownload(key, type).then((res) => {
      setSpinner(false);
      setDocOpen(true);
    });
    if (type != "pdf") {
      setTimeout(() => {
        closeDoc();
      }, 1000);
    }
  };

  const closeDoc = () => {
    setDocOpen(false);
  };

  const getIconByType = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return <AiOutlineFilePdf className="font-24" />;
      case "xls":
      case "xlsx":
        return <AiOutlineFileExcel className="font-24" />;
      case "doc":
      case "docx":
        return <AiOutlineFileWord className="font-24" />;
      default:
        return null;
    }
  };

  const listItemStyle = {
    listStyleType: "none",
    marginBottom: "10px", // Add additional styling as needed
  };

  const getFileExtention = (key) => {
    return key.split(".").pop().toLowerCase();
  };

  const getFileName = (key) => {
    const parts = key.split("/");
    let filename = parts[parts.length - 1];

    filename = filename.split(".")[0]; // Truncate after first dot
    filename = filename
      .replace(/-/g, " ")
      .replace(/%20/g, " ")
      .replace(/,/g, " ")
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/&/g, " "); // Replace ampersand with underscore

    return filename;
  };

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setFullscreen(true);
  };

  const handleEdit = (event) => {
    if (event == "facts") {
      if (factsEdit) {
        setLoadingFacts(true);
        getPropertyInfo().then(() => setLoadingFacts(false));
      }
      setFactsEdit(!factsEdit);
    }
    if (event == "numbers") {
      if (numbersEdit) {
        setNumberLoading(true);
        getPropertyInfo().then(() => setNumberLoading(false));
      }
      setNumbersEdit(!numbersEdit);
    }
    setLoadingFacts(false);
    setNumberLoading(false);
  };

  const getMSA = async (statecode) => {
    let res = await getStateMsa();
    let msaArray = [];
    let allMsa = res?.stateMsaMap;

    msaArray = allMsa[statecode];

    setMsaArray(msaArray);
  };

  const handleInputChange = (evt) => {
    const value = evt?.target?.value;
    if (evt.target.name == "state") {
      console.log("address");
      usStates?.map((statenew) => {
        if (statenew.id == value) {
          let address = {
            line1: propertyInfo.address.line1,
            city: propertyInfo.address.city,
            state: statenew,
            zipCode: propertyInfo.address.zipCode,
          };

          setPropertyInfo({
            ...propertyInfo,
            address: address,
          });
          getMSA(statenew?.code);
        }
      });
    } else if (evt.target.name == "brandName") {
      brandsArray?.forEach((val) => {
        if (val.id == value) {
          console.log(val);
          setPropertyInfo({
            ...propertyInfo,
            propertyType: val,
            brandName: val.brandName,
            franchiseName: val.franchiseName,
          });
        }
      });
    } else if (evt.target.name == "line1") {
      console.log(value);
      let addressnew = {
        line1: value,
        city: propertyInfo.address.city,
        state: propertyInfo.address.state,
        zipCode: propertyInfo.address.zipCode,
      };
      setPropertyInfo({
        ...propertyInfo,
        address: addressnew,
      });
      console.log(propertyInfo);
    } else if (evt.target.name == "city") {
      console.log(value);
      let addressnew = {
        line1: propertyInfo.address.line1,
        city: value,
        state: propertyInfo.address.state,
        zipCode: propertyInfo.address.zipCode,
      };
      setPropertyInfo({
        ...propertyInfo,
        address: addressnew,
      });
      console.log(propertyInfo);
    } else if (evt.target.name == "zipCode") {
      console.log(value);
      let addressnew = {
        line1: propertyInfo.address.line1,
        city: propertyInfo.address.city,
        state: propertyInfo.address.state,
        zipCode: value,
      };
      setPropertyInfo({
        ...propertyInfo,
        address: addressnew,
      });
      console.log(propertyInfo);
    } else if (evt.target.name == "displayBrandOn1031") {
      if ($("#displayBrandCheck").is(":checked")) {
        // $("#displayBrandCheck").addClass("is-invalid"); // unchecked
        setPropertyInfo({
          ...propertyInfo,
          displayBrandOn1031: true,
        });
      } else {
        $("#defaultCheck1").removeClass("is-invalid");
        setPropertyInfo({
          ...propertyInfo,
          displayBrandOn1031: false,
        });
      }
    } else if (evt.target.name == "agent") {
      let agt = {
        id: value,
      };
      setPropertyInfo({
        ...propertyInfo,
        agent: agt,
      });
    } else if (evt.target.name == "hbaStaff") {
      let staff = {
        id: value,
      };
      setPropertyInfo({
        ...propertyInfo,
        hbaStaff: staff,
      });
    } else {
      setPropertyInfo({
        ...propertyInfo,
        [evt.target.name]: value,
      });
    }

    console.log(propertyInfo);
    // validateForm(evt.target.name, evt.target.value);
  };

  const getMaxYearLimit = () => {
    let now = new Date().getFullYear();
    return now + 1;
  };

  function handleCurrencyChange(value, name) {
    setPropertyInfo({
      ...propertyInfo,
      [name]: value,
    });
    // validateForm(name, value);
  }

  const getCalculations = (name) => {
    let askingPrice = propertyInfo.askingPrice
      ? parseInt(propertyInfo?.askingPrice)
      : 0;
    let pipEstimate = propertyInfo.pipEstimate
      ? parseInt(propertyInfo?.pipEstimate)
      : 0;
    let otherRevenue = propertyInfo.otherRevenue
      ? parseInt(propertyInfo?.otherRevenue)
      : 0;
    let fandbrevenue = propertyInfo.yearlyFNBRevenue
      ? parseInt(propertyInfo?.yearlyFNBRevenue)
      : 0;
    let roomRevenue = propertyInfo.roomRevenue
      ? parseInt(propertyInfo?.roomRevenue)
      : 0;
    let netOperatingRevenue = propertyInfo.netOperatingRevenue
      ? parseInt(propertyInfo?.netOperatingRevenue)
      : 0;
    let noOfRooms = propertyInfo.noOfRooms
      ? parseInt(propertyInfo?.noOfRooms)
      : 0;
    let netIncome = propertyInfo.netIncome
      ? parseInt(propertyInfo?.netIncome)
      : 0;

    if (name == "downPayment")
      return (parseInt(askingPrice) + parseInt(pipEstimate)) * 0.2;

    if (name == "totalPrice")
      return parseInt(askingPrice) + parseInt(pipEstimate);

    if (name == "toatlRevenue")
      return (
        parseInt(otherRevenue) + parseInt(fandbrevenue) + parseInt(roomRevenue)
      );

    if (name == "capRate")
      return Math.round(
        (parseInt(netIncome) /
          (parseInt(askingPrice) + parseInt(pipEstimate))) *
          100
      );

    if (name == "pricePerRoom")
      return (
        Math.round(
          ((parseInt(askingPrice) + parseInt(pipEstimate)) / noOfRooms +
            Number.EPSILON) *
            100
        ) / 100
      );

    if (name == "revMultiplier")
      return (
        Math.round(
          ((parseInt(askingPrice) + parseInt(pipEstimate)) /
            (parseInt(otherRevenue) +
              parseInt(fandbrevenue) +
              parseInt(roomRevenue))) *
            100
        ) / 100
      );
  };

  const handleSave = async (id) => {
    setLoadingFacts(true);
    setNumberLoading(true);
    let payload = propertyInfo;
    try {
      let res = await updatePropertyById(payload);
      if (res?.status == 200) {
        setPropertyInfo(res.data);
        setFactsEdit(false);
        setNumbersEdit(false);
        setLoadingFacts(false);
        setNumberLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    setLoadingFacts(false);
    setNumberLoading(false);
  };

  return (
    <>
      {propertyloading === true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "80vh",
            alignItems: "center",
          }}
        >
          <h2>Loading...</h2>
        </div>
      ) : (
        <div className="px-4">
          <div style={{ justifyContent: "space-evenly" }} className="">
            <div className="row gx-1 pb-2 px-0 align-item-stretch">
              <div className="col-md-4 pt-2 flex-1">
                <div className="d-flex justify-content-start align-item-start card card-criteria card-top-border-buyer px-0 h-100">
                  <div className="card-profile-header-primaryBlue card-header d-flex justify-content-start py-0 pt-1">
                    <h2 className="font-size-18 fill-blue w-100">
                      HOTEL FACTS
                    </h2>
                    {user_type?.includes("Agent") ? (
                      <>
                        {factsEdit ? (
                          <h2
                            className="font-size-18 fill-green col d-flex justify-content-end hand-cursor fit-content-max px-3"
                            onClick={handleSave}
                          >
                            <FaRegSave />{" "}
                          </h2>
                        ) : (
                          <></>
                        )}
                        <h2
                          className={
                            factsEdit
                              ? "font-size-18 fill-red col d-flex justify-content-end hand-cursor"
                              : "font-size-18 fill-blue col d-flex justify-content-end hand-cursor"
                          }
                          onClick={() => handleEdit("facts")}
                        >
                          {factsEdit ? <MdOutlineCancel /> : <FaRegEdit />}{" "}
                        </h2>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="card-body buyer-profile-body">
                    <div
                      className="form-body"
                      style={{ fontSize: "13px", fontWeight: "700" }}
                    >
                      {propertyInfo?.displayBrandOn1031 ? (
                        <>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Hotel Brand</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {factsEdit ? (
                                <select
                                  id="hotelbrand"
                                  className="fill-blue form-select form-input"
                                  name="brandName"
                                  onChange={handleInputChange}
                                  value={propertyInfo?.propertyType?.id}
                                  required=""
                                >
                                  {console.log(brandsArray)}
                                  {brandsArray?.map((brand) => {
                                    return (
                                      <option
                                        name={brand.brandName}
                                        value={brand.id}
                                      >
                                        {brand.brandName}
                                      </option>
                                    );
                                  })}
                                </select>
                              ) : (
                                <label
                                  id="hotelbrand"
                                  className="font-size-16-lite"
                                  name="brandName"
                                >
                                  {propertyInfo?.propertyType?.brandName}
                                </label>
                              )}
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Hotel Franchise</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              <label
                                id="hotelfranchise"
                                className="font-size-16-lite"
                                name="franchiseName"
                              >
                                {propertyInfo?.propertyType?.franchiseName}
                              </label>
                            </Col>
                          </Row>
                        </>
                      ) : null}
                      <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                        <Col xs={8}>
                          <label className="font-size-16">
                            <b>Hotel Brand Scale</b>
                          </label>
                        </Col>
                        <Col xs={4}>
                          <label
                            id="hotelbrandtype"
                            className="font-size-16-lite"
                            name="hotelbrandtype"
                          >
                            {propertyInfo?.propertyType?.brandType}
                          </label>
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2">
                        <Col xs={8}>
                          <label className="font-size-16">
                            <b>Market Segment</b>
                          </label>
                        </Col>
                        <Col xs={4}>
                          <label
                            id="hotelfranchisetype"
                            className="font-size-16-lite"
                            name="franchisetype"
                          >
                            {propertyInfo.propertyType.franchiseType}
                          </label>
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                        <Col xs={8}>
                          <label className="font-size-16">
                            <b>Market Type</b>
                          </label>
                        </Col>
                        <Col xs={4}>
                          {factsEdit ? (
                            <select
                              id="marketType"
                              name="marketType"
                              className="fill-blue form-select form-input"
                              onChange={handleInputChange}
                              value={propertyInfo.marketType}
                            >
                              <option hidden>--- Select ---</option> : <></>
                              {marketTypeArray.map((market) => {
                                return (
                                  <option name={market} value={market}>
                                    {market}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <label
                              id="markettype"
                              className="font-size-16-lite"
                            >
                              {propertyInfo.marketType}
                            </label>
                          )}
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2">
                        <Col xs={8}>
                          <label className="font-size-16">
                            <b>Investment Type</b>
                          </label>
                        </Col>
                        <Col xs={4}>
                          {factsEdit ? (
                            <select
                              id="opportunityType"
                              name="opportunityType"
                              className="fill-blue form-select form-input"
                              onChange={handleInputChange}
                              value={propertyInfo?.opportunityType}
                            >
                              <option hidden>--- Select ---</option> : <></>
                              {opportunityTypeArray.map((opportunity, key) => {
                                return (
                                  <option
                                    id={key}
                                    name={opportunity}
                                    value={opportunity}
                                  >
                                    {opportunity}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <label
                              id="opportunityType"
                              className="font-size-16-lite"
                            >
                              {propertyInfo?.opportunityType
                                ? propertyInfo?.opportunityType
                                : "-"}
                            </label>
                          )}
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                        <Col xs={8}>
                          <label className="font-size-16">
                            <b>State</b>
                          </label>
                        </Col>
                        <Col xs={4}>
                          {factsEdit ? (
                            <select
                              id="state"
                              className="fill-blue form-select form-input"
                              name="state"
                              onChange={handleInputChange}
                              value={propertyInfo.address.state.id}
                              required=""
                            >
                              <option hidden>--- Select ---</option> : <></>
                              {usStates.map((state) => {
                                return (
                                  <option name={state.name} value={state.id}>
                                    {state.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <label
                              id="sellerStates"
                              className="font-size-16-lite"
                            >
                              {propertyInfo.address.state.name}
                            </label>
                          )}
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2">
                        <Col xs={8}>
                          <label className="font-size-16">
                            <b>Nearest MSA</b>
                          </label>
                        </Col>
                        <Col xs={4}>
                          {factsEdit ? (
                            msaArray.length == 0 ? (
                              <input
                                type="text"
                                className="form-input"
                                disabled
                                required=""
                                placeholder="Choose State"
                              />
                            ) : (
                              <select
                                id="nearestMSA"
                                className="fill-blue form-select form-input"
                                name="nearestMSA"
                                style={{ backgroundColor: "white" }}
                                onChange={handleInputChange}
                                value={propertyInfo.nearestMSA}
                                required=""
                              >
                                <option
                                  value=""
                                  selected="selected"
                                  disabled
                                  hidden
                                >
                                  --- Select ---
                                </option>
                                {msaArray.map((msa, key) => {
                                  return (
                                    <option key={key} value={msa}>
                                      {msa}
                                    </option>
                                  );
                                })}
                              </select>
                            )
                          ) : (
                            <label
                              className="font-size-16-lite"
                              id="nearestMSA"
                            >
                              {propertyInfo.nearestMSA}{" "}
                            </label>
                          )}
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                        <Col xs={8}>
                          <label className="font-size-16">Loan Type</label>
                        </Col>
                        <Col xs={4}>
                          {factsEdit ? (
                            <select
                              id="loantype"
                              className="fill-blue form-select form-input"
                              name="loanType"
                              onChange={handleInputChange}
                              value={propertyInfo.loanType}
                              required=""
                            >
                              <option
                                value=""
                                selected="selected"
                                disabled
                                hidden
                              >
                                --- Select ---
                              </option>{" "}
                              : <></>
                              {loanTypes?.map((loan) => {
                                return (
                                  <option name={loan.name} value={loan.name}>
                                    {loan.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <label id="loantype" className="font-size-16-lite">
                              {propertyInfo.loanType}
                            </label>
                          )}
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2">
                        <Col xs={8}>
                          <label className="font-size-16">Year Built</label>
                        </Col>
                        <Col xs={4}>
                          {factsEdit ? (
                            <input
                              id="yearbuilt"
                              type="number"
                              min="1400"
                              max={getMaxYearLimit}
                              className="fill-blue form-input"
                              name="yearBuilt"
                              onChange={handleInputChange}
                              value={propertyInfo.yearBuilt}
                              required=""
                            ></input>
                          ) : (
                            <label
                              id="yearbuilt"
                              type="number"
                              className="font-size-16-lite"
                              name="yearBuilt"
                            >
                              {propertyInfo.yearBuilt}
                            </label>
                          )}
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                        <Col xs={8}>
                          <label className="font-size-16">
                            <b>Year Renovated</b>
                          </label>
                        </Col>
                        <Col xs={4}>
                          {factsEdit ? (
                            <input
                              id="yearrenovated"
                              type="number"
                              min="1400"
                              max={getMaxYearLimit}
                              className="fill-blue form-input"
                              name="lastRenovatedYear"
                              onChange={handleInputChange}
                              value={propertyInfo.lastRenovatedYear}
                              required=""
                            ></input>
                          ) : (
                            <label
                              id="yearrenovated"
                              type="number"
                              className="font-size-16-lite"
                              name="lastRenovatedYear"
                            >
                              {propertyInfo.lastRenovatedYear}
                            </label>
                          )}
                        </Col>
                      </Row>

                      <Row className=" d-flex align-items-center py-2">
                        <Col xs={8}>
                          <label className="font-size-16">
                            <b>Room Count</b>
                          </label>
                        </Col>
                        <Col xs={4}>
                          {factsEdit ? (
                            <input
                              type="number"
                              className="fill-blue form-input"
                              name="noOfRooms"
                              onChange={handleInputChange}
                              value={propertyInfo.noOfRooms}
                              required=""
                              maxLength={128}
                            />
                          ) : (
                            <label
                              type="number"
                              className="font-size-16-lite"
                              name="noOfRooms"
                            >
                              {propertyInfo.noOfRooms}{" "}
                            </label>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 pt-2">
                {loading_numbers ? <div id="loading_seller"></div> : null}
                <div className="d-flex justify-content-start align-item-start card card-criteria card-top-border-buyer px-0 h-100">
                  <div className="card-profile-header-primaryBlue card-header d-flex justify-content-start py-0 pt-1">
                    <h2 className="font-size-18 fill-blue w-100">
                      HOTEL NUMBERS
                    </h2>
                    {user_type?.includes("Agent") ? (
                      <>
                        {numbersEdit ? (
                          <h2
                            className="font-size-18 fill-green col d-flex justify-content-end hand-cursor fit-content-max px-3"
                            onClick={handleSave}
                          >
                            <FaRegSave />{" "}
                          </h2>
                        ) : (
                          <></>
                        )}
                        <h2
                          className={
                            numbersEdit
                              ? "font-size-18 fill-red col d-flex justify-content-end hand-cursor"
                              : "font-size-18 fill-blue col d-flex justify-content-end hand-cursor"
                          }
                          onClick={() => handleEdit("numbers")}
                        >
                          {numbersEdit ? <MdOutlineCancel /> : <FaRegEdit />}{" "}
                        </h2>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="card-body">
                    <div
                      className="form-body"
                      style={{ fontSize: "13px", fontWeight: "700" }}
                    >
                      <Row>
                        <Col xs={12}>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Asking Price</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  className="fill-blue form-input"
                                  name="askingPrice"
                                  onValueChange={handleCurrencyChange}
                                  value={propertyInfo.askingPrice}
                                  required=""
                                  maxLength={128}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.askingPrice
                                      ? propertyInfo.askingPrice
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>PIP Estimate</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  className="fill-blue form-input"
                                  name="pipEstimate"
                                  onValueChange={handleCurrencyChange}
                                  value={propertyInfo.pipEstimate}
                                  required=""
                                  maxLength={128}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.pipEstimate
                                      ? propertyInfo.pipEstimate
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Total Price</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  className="fill-blue form-input"
                                  name="totalPrice"
                                  disabled
                                  value={getCalculations("totalPrice")}
                                  required=""
                                  maxLength={128}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {getCalculations("totalPrice")
                                    ? formatNumberWithCommas(
                                        getCalculations("totalPrice")
                                      )
                                    : 0}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Occupancy t-12</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <input
                                  type="text"
                                  className="fill-blue form-input"
                                  name="yearlyOccupancy"
                                  min={0}
                                  max={100}
                                  onChange={handleInputChange}
                                  placeholder={"%"}
                                  value={propertyInfo?.yearlyOccupancy}
                                  required=""
                                  maxLength={128}
                                ></input>
                              ) : (
                                <label className="font-size-16-lite">
                                  {formatNumberWithCommas(
                                    propertyInfo?.yearlyOccupancy
                                      ? parseInt(
                                          propertyInfo?.yearlyOccupancy
                                        ).toFixed(2)
                                      : 0
                                  )}{" "}
                                  %
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>ADR</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  className="fill-blue form-input"
                                  name="yearlyADR"
                                  value={propertyInfo?.yearlyADR}
                                  onValueChange={handleCurrencyChange}
                                  required=""
                                  maxLength={128}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.yearlyADR
                                      ? propertyInfo.yearlyADR
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Room Revenue t-12</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  id="roomRevenue"
                                  className="fill-blue form-input"
                                  name="roomRevenue"
                                  onValueChange={handleCurrencyChange}
                                  value={propertyInfo?.roomRevenue}
                                  required=""
                                ></CurrencyInput>
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.roomRevenue
                                      ? propertyInfo.roomRevenue
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Food and Beverage Revenue</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  id="yearlyFNBRevenue"
                                  className="fill-blue form-input"
                                  name="yearlyFNBRevenue"
                                  onValueChange={handleCurrencyChange}
                                  value={propertyInfo.yearlyFNBRevenue}
                                  required=""
                                ></CurrencyInput>
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.yearlyFNBRevenue
                                      ? propertyInfo.yearlyFNBRevenue
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Other Revenue</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  id="otherRevenue"
                                  className="fill-blue form-input"
                                  name="otherRevenue"
                                  onValueChange={handleCurrencyChange}
                                  value={propertyInfo.otherRevenue}
                                  required=""
                                ></CurrencyInput>
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.otherRevenue
                                      ? propertyInfo.otherRevenue
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Total Revenue</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  id="toatlRevenue"
                                  className="fill-blue form-input"
                                  name="toatlRevenue"
                                  value={getCalculations("toatlRevenue")}
                                  disabled
                                  required=""
                                ></CurrencyInput>
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.otherRevenue +
                                      propertyInfo?.yearlyFNBRevenue +
                                      propertyInfo?.roomRevenueMultiplier
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Net Operating Income</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  id="netOperatingRevenue"
                                  className="fill-blue form-input"
                                  name="netOperatingRevenue"
                                  onValueChange={handleCurrencyChange}
                                  value={propertyInfo.netOperatingRevenue}
                                  required=""
                                ></CurrencyInput>
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.netOperatingRevenue
                                      ? propertyInfo.netOperatingRevenue
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Net Income</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <CurrencyInput
                                  id="netIncome"
                                  className="fill-blue form-input"
                                  name="netIncome"
                                  onValueChange={handleCurrencyChange}
                                  value={propertyInfo.netIncome}
                                  required=""
                                ></CurrencyInput>
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.netIncome
                                      ? propertyInfo.netIncome
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Cash on Cash Return</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {numbersEdit ? (
                                <input
                                  type="text"
                                  id="cashOnCashRet"
                                  className="fill-blue form-input"
                                  name="cashOnCashRet"
                                  onChange={handleInputChange}
                                  value={propertyInfo.cashOnCashRet}
                                  prefix="$"
                                  pattern="^\d{1,2}(\.\d{1,2})?%"
                                  required=""
                                ></input>
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    propertyInfo?.cashOnCashRet
                                      ? propertyInfo.cashOnCashRet
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 pt-2">
                <div className="card card-criteria h-100 fill-blue">
                  <Tabs defaultActiveKey="images" className="fill-blue">
                    <Tab eventKey="images" title="PHOTOS">
                      <div className="px-4">
                        {propertyInfo.imagesPath != "" ? (
                          <div className="row ">
                            <Carousel
                              autoplay
                              fade={true}
                              controls={true}
                              interval={2000}
                              pause={"hover"}
                              data-bs-theme="dark"
                              className={
                                fullscreen ? "fullscreen-carousel" : ""
                              }
                            >
                              {getImagesArray(propertyInfo?.imagesPath)?.map(
                                (image) => (
                                  <Carousel.Item
                                    key={image.src}
                                    onClick={() => handleImageClick(image)}
                                  >
                                    <img
                                      className="d-block w-100 fixed-image-size"
                                      src={image?.original}
                                      alt={image?.thumbnail}
                                    />
                                  </Carousel.Item>
                                )
                              )}
                            </Carousel>
                            <Modal
                              show={fullscreen}
                              onHide={() => setFullscreen(false)}
                              size="xl"
                            >
                              <Modal.Header closeButton />
                              <Modal.Body>
                                {selectedImage && (
                                  <img
                                    className="d-block w-100"
                                    src={selectedImage.original}
                                    alt={selectedImage.thumbnail}
                                  />
                                )}
                              </Modal.Body>
                            </Modal>
                          </div>
                        ) : (
                          <>
                            <br></br>
                            <span>No photos uploaded by the seller</span>
                          </>
                        )}
                      </div>
                    </Tab>
                    <Tab eventKey="documents" title="DUE DILIGENCE">
                      <>
                        <div className="row px-2 py-2 d-flex justify-content-center align-items-center">
                          <div className="col-md-12 ">
                            <Row>
                              <Col className="d-flex justify-content-center align-items-center">
                                {(propertyInfo?.last12MonthProfitReports ==
                                  null ||
                                  propertyInfo?.last12MonthProfitReports ==
                                    "") &&
                                (propertyInfo?.lastMonthReports == null ||
                                  propertyInfo?.lastMonthReports == "") &&
                                (propertyInfo?.plpReports == null ||
                                  propertyInfo?.plpReports == "") &&
                                (propertyInfo?.additionalDocs == null ||
                                  propertyInfo?.additionalDocs == "") ? (
                                  <span> No documents uploaded by seller </span>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                            <br></br>

                            <Row className="w-100 px-0">
                              <Col>
                                <Accordion defaultActiveKey="0" alwaysOpen>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header className="w-100">
                                      STR REPORTS
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul
                                        className="cursor-pointer"
                                        style={listItemStyle}
                                      >
                                        {propertyInfo?.lastMonthReports != "" &&
                                          propertyInfo?.lastMonthReports?.map(
                                            (file, index) => {
                                              return (
                                                <li>
                                                  <div
                                                    key={index}
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                      openDoc(
                                                        file?.key,
                                                        getFileExtention(
                                                          file?.key
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <a className="px-2">
                                                      {file?.fileName}
                                                    </a>
                                                  </div>
                                                </li>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                  <Accordion.Item eventKey="1">
                                    <Accordion.Header className="w-100">
                                      PROFIT AND LOSS STATEMENTS
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul
                                        className="cursor-pointer"
                                        style={listItemStyle}
                                      >
                                        {propertyInfo?.last12MonthProfitReports !=
                                          "" &&
                                          propertyInfo?.last12MonthProfitReports?.map(
                                            (file, index) => {
                                              return (
                                                <li>
                                                  <div
                                                    key={index}
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                      openDoc(
                                                        file?.key,
                                                        getFileExtention(
                                                          file?.key
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <a className="ms-2 ">
                                                      {file?.fileName}
                                                    </a>
                                                  </div>
                                                </li>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>

                                  <Accordion.Item eventKey="2">
                                    <Accordion.Header className="w-100">
                                      Franchise Documents/PIP
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul
                                        className="cursor-pointer"
                                        style={listItemStyle}
                                      >
                                        {propertyInfo?.plpReports != "" &&
                                          propertyInfo?.plpReports?.map(
                                            (file, index) => {
                                              return (
                                                <>
                                                  <div
                                                    key={index}
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                      openDoc(
                                                        file?.key,
                                                        getFileExtention(
                                                          file?.key
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <a className="px-2">
                                                      {file?.fileName}
                                                    </a>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>

                                  <Accordion.Item eventKey="3">
                                    <Accordion.Header className="w-100">
                                      OFFERING MEMORANDUM
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul
                                        className="cursor-pointer"
                                        style={listItemStyle}
                                      >
                                        {propertyInfo?.additionalDocsList !=
                                          "" &&
                                          propertyInfo?.additionalDocsList?.map(
                                            (file, index) => {
                                              return (
                                                <>
                                                  <div
                                                    key={index}
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                      openDoc(
                                                        file?.key,
                                                        getFileExtention(
                                                          file?.key
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <a className="px-2">
                                                      {file?.fileName}
                                                    </a>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </>
                    </Tab>
                    <Tab eventKey="agreements" title="AGREEMENTS">
                      <>
                        <div className="row px-2 py-2 d-flex justify-content-center align-items-center">
                          <div className="col-md-12">
                            <Row className="w-100">
                              <Col>
                                <Accordion defaultActiveKey="0">
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header className="w-100">
                                      LISTING AGREEMENT
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <ul
                                        className="cursor-pointer"
                                        style={listItemStyle}
                                      >
                                        <li>
                                          {propertyInfo?.listingAgreements !=
                                            "" &&
                                            propertyInfo?.listingAgreements?.map(
                                              (file, index) => {
                                                return (
                                                  <div
                                                    key={index}
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                      openDoc(
                                                        file?.key,
                                                        getFileExtention(
                                                          file?.key
                                                        )
                                                      )
                                                    }
                                                  >
                                                    {/* {getIconByType(key)} */}
                                                    <a className="px-2">
                                                      {file?.fileName}
                                                    </a>

                                                    <AiOutlineEye className="text-light hover-text-dart ms-auto font-18" />
                                                  </div>
                                                );
                                              }
                                            )}
                                        </li>
                                      </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </>
                    </Tab>
                  </Tabs>
                  {propertyInfo?.dealStatus != "Closed" &&
                  propertyInfo?.dealStatus != "Off-market" ? (
                    <div className="row d-flex justify-content-center align-item-center h-100 mt-2">
                      <div className="col d-flex justify-content-center align-item-center">
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={<strong>Delete Post</strong>}
                          drop="down"
                          variant="outline-danger"
                          className="custom-dropdown-button"
                        >
                          <Dropdown.Item
                            onClick={() =>
                              approveHandler("Closed", propertyInfo?.id)
                            }
                          >
                            Delete Post
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              approveHandler("Off-market", propertyInfo?.id)
                            }
                          >
                            Make it Off-Market
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  ) : propertyInfo?.dealStatus == "Off-market" ? (
                    <div className="row d-flex justify-content-center align-item-center h-100 mt-2">
                      <div className="col d-flex justify-content-center align-item-center">
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={<strong>Make it Online</strong>}
                          drop="down"
                          variant="outline-danger"
                          className="custom-dropdown-button"
                        >
                          <Dropdown.Item
                            onClick={() =>
                              approveHandler("Unapproved", propertyInfo?.id)
                            }
                          >
                            Make it Online
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <Modal show={isDocOpen} size="xl" onHide={closeDoc}>
                    {pdfBytes != null ? (
                      <FilePreview
                        arrayBuffer={pdfBytes}
                        fileType={openDocType}
                      ></FilePreview>
                    ) : (
                      <></>
                    )}
                  </Modal>
                  <br></br>
                  <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SellerCriteria;
