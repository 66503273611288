import axios from "axios";
import { URL } from "../constants/constants";
import { authUserHeader } from "../services/authHeader";

const instance = axios.create({
  baseURL: URL,
});

// Add a request interceptor 
instance.interceptors.request.use(   
  function (config) { 
    // config.headers = authUserHeader()
    document.getElementById("loading_spinner").hidden = false;
    return config;
  },

  function (error) {  
    document.getElementById("loading_spinner").hidden = false;
    return Promise.reject(error);  
  }
); 

// Add a response interceptor 
instance.interceptors.response.use( 
  function (response) { 
    document.getElementById("loading_spinner").hidden = true;
    return response;  
  }, 

  function (error) { 
    document.getElementById("loading_spinner").hidden = true;
    return Promise.reject(error);
  } 
); 


export default instance; 
