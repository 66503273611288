import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  getBuyer,
  getPrimaryStates,
  updateBuyerById,
  updateStatusById,
} from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { getDateFormatted, tableValueParser } from "../common/commonUtils";
import { Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { FaEye, FaRegEdit, FaRegSave } from "react-icons/fa";
import { MdGroup, MdOutlineCancel } from "react-icons/md";
import Select from "react-select";
import {
  USAstates,
  brandTypes,
  geographicRegion,
  marketTypes,
  opportunityTypes,
  prefferedBrands,
  propertyCategories,
  propertyRoomCount,
} from "../common/utils";
import CurrencyInput from "react-currency-input-field";
import Swal from "sweetalert2";

const BuyerCriteria = () => {
  const navigate = useNavigate();
  const [buyersLoading, setBuyersLoading] = useState(true);
  const [factsEdit, setFactsEdit] = useState(false);
  const [searchReqEdit, setSearchReqEdit] = useState(false);
  const [buyerInfo, setBuyerInfo] = useState();
  const [loading_facts, setLoadingFacts] = useState(false);
  const [loadingInterests, setLoadingInterests] = useState(false);
  const [primaryStates, setPrimaryStates] = useState([]);
  const [primaryStatesSelected, setPrimaryStatesSelected] = useState([]);
  const [geographicRegionSelected, setGeographicRegionSelected] = useState([]);
  const [brandScaleSelected, setBrandScaleSelected] = useState([]);
  const [preferredBrandsSelected, setPreferresBrandsSelected] = useState([]);
  const [hotelCategorySelected, setHotelCategorySelected] = useState([]);
  const [locationSelected, setLocationSelected] = useState([]);
  const [opportunitySelected, setOpportunitySelected] = useState([]);
  const [roomCountSelected, setRoomCountSelected] = useState([]);

  const { id, filter } = useParams();

  let user_type = sessionStorage.getItem("user_type");

  useEffect(async () => {
    getBuyerInfo();
    // getPrimaryStates().then(states => setPrimaryStates(states))
    setPrimaryStates(USAstates);
    sessionStorage.setItem("currentPostid", id);

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getStateValuesToDisplay = () => {
    const statesList = buyerInfo?.interestedPrimaryStatesList;

    if (statesList) {
      if (statesList.length > 3) {
        return "Any";
      } else {
        return statesList.map((state) => state.name).join(", ");
      }
    } else {
      return "Open for All";
    }
  };

  const getBuyerInfo = async () => {
    let res = await getBuyer(id);
    const data = res ? res.data : res;
    setBuyerInfo(data);
    setBuyersLoading(false);
    setLoadingFacts(false);
  };

  const getCalculations = (name) => {
    const funds1031 = buyerInfo?.funds1031 ? parseInt(buyerInfo?.funds1031) : 0;
    const additionalFunds = buyerInfo?.additionalFunds
      ? parseInt(buyerInfo?.additionalFunds)
      : 0;
    if (name === "totalBuyingPower")
      return (parseInt(funds1031) + parseInt(additionalFunds)) * 5;
  };

  const getColourClass = (colour) => {
    return `${colour}-color`;
  };

  function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getDateValues = (name, value) => {
    var date = new Date(value);

    if (name == "deadLineDate45Days") {
      const date = new Date(value);
      const newDate = new Date(date.getTime() + 45 * 24 * 60 * 60 * 1000);
      return newDate.toISOString().slice(0, 10); // returns date in 'YYYY-MM-DD' format
    }
    if (name == "closingDate1031") {
      const date = new Date(value);
      const newDate = new Date(date.getTime() + 180 * 24 * 60 * 60 * 1000);
      return newDate.toISOString().slice(0, 10); // returns date in 'YYYY-MM-DD' format
    }
  };

  const handleEdit = (event) => {
    if (event == "facts") {
      if (factsEdit) {
        setLoadingFacts(true);
        getBuyerInfo().then(() => {
          setLoadingFacts(false);
        });
      }
      setFactsEdit(!factsEdit);
    }
    if (event == "search") {
      if (searchReqEdit) {
        setLoadingInterests(true);
        getBuyerInfo().then(() => {
          setDefaultValues();
          setLoadingInterests(false);
        });
      }
      setDefaultValues();
      setSearchReqEdit(!searchReqEdit);
    }
  };

  const approveHandler = async (status, id) => {
    let updatedstatus = {};

    updatedstatus = {
      buyerId: id,
      dealStatus: status,
    };

    try {
      showCustomAlert(status, updatedstatus);
    } catch (error) {
      console.log(error);
    }
  };

  const showCustomAlert = (status, updatedstatus) => {
    let text_mssg = "";
    let confirm_mssg = "";
    let short_mssg = "";
    if (status == "Off-market") {
      text_mssg = "You want to make this off-market!";
      confirm_mssg = "Your post has been taken off-market";
      short_mssg = "Off-market!";
    } else if (status == "Closed") {
      text_mssg = "You want to delete this post!";
      confirm_mssg = "Your post has been deleted";
      short_mssg = "Deleted!";
    } else if (status == "Unapproved") {
      text_mssg = "You want to make this online!";
      confirm_mssg = "Your post is now online";
      short_mssg = "Online!";
    } else {
    }

    Swal.fire({
      title: "Are you sure?",
      text: text_mssg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2F67CA",
      cancelButtonColor: "#FF0000",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await updateStatusById(updatedstatus);

        console.log(res);

        Swal.fire(short_mssg, confirm_mssg, "success").then(() => {
          window.location.reload();
        });
      }
    });
  };

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "primaryState") {
      primaryStates.map((state) => {
        if (state.id == value) {
          setBuyerInfo((prevBuyerInfo) => ({
            ...prevBuyerInfo,
            primaryState: {
              id: state.id,
              name: state.name,
              code: state.code,
            },
          }));
        }
      });
    } else if (name == "escrowClosingDate") {
      let deadline45 = getDateValues("deadLineDate45Days", value);
      let closing = getDateValues("closingDate1031", value);

      setBuyerInfo((prevBuyerInfo) => ({
        ...prevBuyerInfo,
        escrowClosingDate: value,
        deadLineDate45Days: deadline45,
        closingDate1031: closing,
      }));
    } else {
      setBuyerInfo((prevBuyerInfo) => ({
        ...prevBuyerInfo,
        [name]: value,
      }));
    }

    console.log(buyerInfo);
  };

  const handleMultiSelectChange = (name, value) => {
    let selected = [];

    value?.map((val) => selected.push(val.value));

    console.log(value);
    console.log(selected);
    if (selected.includes("Any") && selected.length > 1) {
    } else {
    }

    if (name == "interestedPrimaryStates") {
      if (value.length > 3) {
        return;
      } else if (value.includes(0)) {
        selected = [];
        value?.map((val) => selected.push(val.value));
        setPrimaryStatesSelected(value);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      } else if (selected.includes(0)) {
        selected = [];
        states.map((val) => selected.push(val.value));
        setPrimaryStatesSelected({ value: 0, label: "Any" });
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      } else {
        setPrimaryStatesSelected(value);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      }
    } else if (name == "interestedRoomCount") {
      if (value.length > 1) {
        return;
      } else if (value[0]?.label == "Any") {
        setRoomCountSelected({ value: 0, label: "Any" });
        setBuyerInfo({
          ...buyerInfo,
          [name]: "1-1000",
        });
      } else {
        setRoomCountSelected(value);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      }
    } else if (name == "geographicRegion") {
      if (selected.indexOf("Any") > 0 && selected.includes("Any")) {
        selected = [];
        geographic_Region.map((val) => selected.push(val.value));
        setGeographicRegionSelected([{ value: "Any", label: "Any" }]);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      } else {
        setGeographicRegionSelected(value.filter((val) => val.value != "Any"));
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      }
    } else if (name == "interestedBrands") {
      if (selected.indexOf("Any") > 0 && selected.includes("Any")) {
        selected = [];
        Interested_Brands.map((val) => selected.push(val.value));
        setBrandScaleSelected([{ value: "Any", label: "Any" }]);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      } else {
        setBrandScaleSelected(value.filter((val) => val.value != "Any"));
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      }
    } else if (name == "preferredBrands") {
      if (selected.indexOf("Any") > 0 && selected.includes("Any")) {
        selected = [];
        preffered_brands.map((val) => selected.push(val.value));
        setPreferresBrandsSelected([{ value: "Any", label: "Any" }]);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      } else {
        setPreferresBrandsSelected(value.filter((val) => val.value != "Any"));
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      }
    } else if (name == "interestedCategory") {
      if (selected.indexOf("Any") > 0 && selected.includes("Any")) {
        selected = [];
        Interested_Category.map((val) => selected.push(val.value));
        setHotelCategorySelected([{ value: "Any", label: "Any" }]);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      } else {
        setHotelCategorySelected(value.filter((val) => val.value != "Any"));
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      }
    } else if (name == "interestedMarketType") {
      if (selected.indexOf("Any") > 0 && selected.includes("Any")) {
        selected = [];
        Market_Types.map((val) => selected.push(val.value));
        setLocationSelected([{ value: "Any", label: "Any" }]);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      } else {
        setLocationSelected(value.filter((val) => val.value != "Any"));
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      }
    } else if (name == "opportunityType") {
      if (selected.indexOf("Any") > 0 && selected.includes("Any")) {
        selected = [];
        opportunity_types.map((val) => selected.push(val.value));
        setOpportunitySelected([{ value: "Any", label: "Any" }]);
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      } else {
        setOpportunitySelected(value.filter((val) => val.value != "Any"));
        setBuyerInfo({
          ...buyerInfo,
          [name]: selected.toString(),
        });
      }
    } else {
      setBuyerInfo({
        ...buyerInfo,
        [name]: selected.toString(),
      });
    }

    console.log(buyerInfo);
  };

  function handleCurrencyChange(value, name) {
    console.log(value);

    if (value != undefined) {
      setBuyerInfo({
        ...buyerInfo,
        [name]: parseInt(value),
        buyingPower: getCalculations("buyingPower"),
      });
    } else {
      setBuyerInfo({
        ...buyerInfo,
        [name]: "",
        buyingPower: getCalculations("buyingPower"),
      });
    }

    console.log(buyerInfo);
  }

  const handleSave = async () => {
    setLoadingFacts(true);
    let res = await updateBuyerById(buyerInfo).then((res) => {
      if (res?.status == 200) {
        setBuyerInfo(res.data);
        setFactsEdit(false);
        setSearchReqEdit(false);
      }
      setLoadingFacts(false);
    });
    setDefaultValues();
    setLoadingFacts(false);
  };

  //options for Primary_Status
  const states = [];
  states.push({ value: 0, label: "Any" });
  primaryStates.map((val) => states.push({ value: val.id, label: val.name }));

  //options for Interested_Hotel_Type
  const Interested_Hotel_Type = [];
  brandTypes.forEach(
    (val, key) => (Interested_Hotel_Type[key] = { value: val, label: val })
  );

  //options for Interested_Brands
  const Interested_Brands = [];
  brandTypes.forEach(
    (val, key) => (Interested_Brands[key] = { value: val, label: val })
  );

  const geographic_Region = [];
  geographicRegion.forEach(
    (val, key) => (geographic_Region[key] = { value: val, label: val })
  );

  const preffered_brands = [];
  prefferedBrands.forEach(
    (val, key) => (preffered_brands[key] = { value: val, label: val })
  );

  const opportunity_types = [];
  opportunityTypes.forEach(
    (val, key) => (opportunity_types[key] = { value: val, label: val })
  );

  const Interested_Category = [];
  propertyCategories.forEach(
    (val, key) => (Interested_Category[key] = { value: val, label: val })
  );

  const Market_Types = [];
  marketTypes.forEach(
    (val, key) => (Market_Types[key] = { value: val, label: val })
  );

  const Room_Counts = [];
  propertyRoomCount.forEach(
    (val, key) => (Room_Counts[key] = { value: val, label: val })
  );

  const ArrayToString = (array) => {
    const result = array.join(", ");
    return result;
  };

  const setDefaultValues = () => {
    getCategoryDefaultValue();
    getGeoLocDefaultValue();
    getMarketTypeDefaultValue();
    getRoomCountDefaultValue();
    getOpportunityTypeDefaultValue();
    getStatesDefaultValues();
    getBrandDefaultValues();
  };

  const getBrandDefaultValues = async (value) => {
    let brands = buyerInfo?.interestedBrands?.split(",");
    let interested = [];
    if (brands != undefined) {
      if (brands.includes("Any")) {
        interested.push({ value: "Any", label: "Any" });
      } else {
        brands.forEach((intBrand) => {
          Interested_Brands.map((brand) => {
            if (brand.value == intBrand.trim()) interested.push(brand);
          });
        });
      }
    }
    setBrandScaleSelected(interested);
    return interested;
  };

  const getTypeDefaultValue = () => {
    let brands = buyerInfo?.interestedTypeOfHotel?.split(",");
    let interested = [];
    if (brands != undefined) {
      brands.forEach((brands) => {
        Interested_Hotel_Type.map((brand) => {
          if (brand.value == brands) interested.push(brand);
        });
      });
    }
    return interested;
  };

  const getCategoryDefaultValue = async () => {
    let categorys = buyerInfo?.interestedCategory?.split(",");
    let interested = [];
    if (categorys != undefined) {
      if (categorys.includes("Any")) {
        interested.push({ value: "Any", label: "Any" });
      } else {
        categorys.forEach((cat) => {
          Interested_Category.map((category) => {
            if (category.label == cat.trim()) interested.push(category);
          });
        });
      }
    }

    setHotelCategorySelected(interested);
    return interested;
  };

  const getGeoLocDefaultValue = async () => {
    let geo = buyerInfo?.geographicRegion?.split(",");
    let interested = [];
    if (geo != undefined) {
      if (geo.includes("Any")) {
        interested.push({ value: "Any", label: "Any" });
      } else {
        geo.forEach((cat) => {
          geographic_Region.map((geo) => {
            if (geo.label == cat.trim()) interested.push(geo);
          });
        });
      }
    }
    setGeographicRegionSelected(interested);
    return interested;
  };

  const getMarketTypeDefaultValue = async () => {
    let markets = buyerInfo?.interestedMarketType?.split(",");
    let interested = [];
    if (markets != undefined) {
      if (markets.includes("Any")) {
        interested.push({ value: "Any", label: "Any" });
      } else {
        markets.forEach((markets) => {
          Market_Types.map((market) => {
            if (market.label == markets.trim()) interested.push(market);
          });
        });
      }
    }
    setPreferresBrandsSelected(interested);
    setLocationSelected(interested);
    return interested;
  };

  const getRoomCountDefaultValue = async () => {
    let roomCount = buyerInfo?.interestedRoomCount?.split(",");
    let interested = [];
    console.log(roomCount);
    if (roomCount != undefined) {
      if (roomCount.includes("1-1000")) {
        interested.push({ value: "1-1000", label: "1-1000" });
      } else {
        roomCount.forEach((count) => {
          Room_Counts.map((room) => {
            if (room.label == count.trim()) interested.push(room);
          });
        });
      }
    }
    setRoomCountSelected(interested);
    return interested;
  };

  const getOpportunityTypeDefaultValue = async () => {
    let opportunitys = buyerInfo?.opportunityType?.split(",");
    let interested = [];
    if (opportunitys != undefined) {
      if (opportunitys.includes("Any")) {
        interested.push({ value: "Any", label: "Any" });
      } else {
        opportunitys.forEach((opportunitys) => {
          opportunity_types.map((opportunity) => {
            if (opportunity.label == opportunitys.trim())
              interested.push(opportunity);
          });
        });
      }
    }
    setOpportunitySelected(interested);
    return interested;
  };

  const getStatesDefaultValues = async () => {
    if (buyerInfo?.interestedPrimaryStatesList?.length > 3) {
      let states = [{ value: "0", label: "Any" }];
      setPrimaryStatesSelected(states);
      return states;
    } else {
      let st = buyerInfo?.interestedPrimaryStatesList?.map(
        (interestedStates) => states[interestedStates?.id]
      );
      setPrimaryStatesSelected(st);
      return st;
    }
  };

  return (
    <>
      {buyersLoading === false ? (
        <div className="px-2">
          <div style={{ justifyContent: "space-evenly" }} className="">
            <div className="row gx-1 pb-2 px-0 align-item-stretch">
              <div className="col-md-4 px-1 flex-1">
                <div className="d-flex justify-content-start align-item-start card card-criteria card-top-border-buyer px-0 h-100">
                  <div className="card-profile-header-primaryBlue card-header d-flex justify-content-start py-0 pt-1">
                    <h2 className="font-size-18 fill-blue col fit-content-max">
                      YOUR 1031 EXCHANGE FACTS
                    </h2>
                    {user_type?.includes("Agent") ? (
                      <>
                        {factsEdit ? (
                          <h2
                            className="font-size-18 fill-green col d-flex justify-content-end hand-cursor"
                            onClick={handleSave}
                          >
                            <FaRegSave />{" "}
                          </h2>
                        ) : (
                          <></>
                        )}
                        <h2
                          className={
                            factsEdit
                              ? "font-size-18 fill-red col d-flex justify-content-end hand-cursor"
                              : "font-size-18 fill-blue col d-flex justify-content-end hand-cursor"
                          }
                          onClick={() => handleEdit("facts")}
                        >
                          {factsEdit ? <MdOutlineCancel /> : <FaRegEdit />}{" "}
                        </h2>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="card-body buyer-profile-body">
                    <div
                      className="form-body"
                      style={{ fontSize: "13px", fontWeight: "700" }}
                    >
                      <Row className="d-flex align-items-center ">
                        <Col className="align-items-end" xs={12}>
                          <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Buyers ID</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">
                                {buyerInfo?.generatedBuyerId}
                              </label>
                            </Col>
                          </Row>
                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Buyers Home State is</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {/* <label className="font-size-16-lite">{buyerInfo?.primaryState?.name}</label> */}
                              {false ? (
                                <select
                                  id="primaryState"
                                  name="primaryState"
                                  className="form-input form-select select-state"
                                  onChange={handleInputChange}
                                  value={buyerInfo?.primaryState?.id}
                                >
                                  <option id={0} hidden>
                                    Please Choose
                                  </option>
                                  {primaryStates.map((state) => {
                                    return (
                                      <option
                                        id={state.id}
                                        name={state.name}
                                        value={state.id}
                                      >
                                        {state.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              ) : (
                                <label className="font-size-16-lite">
                                  {buyerInfo?.primaryState?.name}
                                </label>
                              )}
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Cash Available for Down Payment</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {/* <label className="font-size-16-lite">{buyerInfo?.primaryState?.name}</label> */}
                              {factsEdit ? (
                                <CurrencyInput
                                  displayType={"text"}
                                  className="form-input currency-input remove-left-border-radius fill-blue"
                                  name="funds1031"
                                  id="funds1031"
                                  placeholder="From Your 1031 Exchange"
                                  onValueChange={handleCurrencyChange}
                                  value={buyerInfo?.funds1031}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    buyerInfo?.funds1031
                                      ? buyerInfo?.funds1031
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>
                                  Additional Cash Available for Down Payment
                                </b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {factsEdit ? (
                                <CurrencyInput
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  className="form-input currency-input remove-left-border-radius fill-blue"
                                  name="additionalFunds"
                                  id="additionalFunds"
                                  placeholder="Available Cash"
                                  maxLength={128}
                                  onValueChange={handleCurrencyChange}
                                  value={buyerInfo?.additionalFunds}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    buyerInfo?.additionalFunds
                                      ? buyerInfo?.additionalFunds
                                      : 0
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Total Down Payment</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">
                                $
                                {formatNumberWithCommas(
                                  (buyerInfo?.additionalFunds
                                    ? parseInt(buyerInfo?.additionalFunds)
                                    : 0) +
                                    (buyerInfo?.funds1031
                                      ? parseInt(buyerInfo?.funds1031)
                                      : 0)
                                )}{" "}
                              </label>
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Total Buying Power with 20% down</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              <label className="font-size-16-lite">
                                $
                                {formatNumberWithCommas(
                                  getCalculations("totalBuyingPower")
                                    ? getCalculations("totalBuyingPower")
                                    : 0
                                )}{" "}
                              </label>
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Buyer's Escrow Close Date</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {factsEdit ? (
                                <div className="form-group mb-0">
                                  <input
                                    type="date"
                                    className="form-input form-input-date fill-blue "
                                    id="deadLine"
                                    name="escrowClosingDate"
                                    //  disabled={"disabled"}
                                    placeholder="Escrow Closing Date"
                                    onChange={(e) => handleInputChange(e)}
                                    value={buyerInfo.escrowClosingDate}
                                  />
                                </div>
                              ) : (
                                <label className="font-size-16-lite">
                                  {getDateFormatted(
                                    buyerInfo?.escrowClosingDate
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>45-Day Deadline Date</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {factsEdit ? (
                                <input
                                  type="date"
                                  className="form-input fill-blue"
                                  id="deadLine"
                                  name="deadLineDate45Days"
                                  disabled={true}
                                  placeholder="45-Day Deadline Date"
                                  maxLength={128}
                                  value={buyerInfo.deadLineDate45Days}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  {getDateFormatted(
                                    buyerInfo?.deadLineDate45Days
                                  )}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2 fill-alternate-row ">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>180-Day Escrow Close Date</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {factsEdit ? (
                                <input
                                  type="date"
                                  className="form-input fill-blue"
                                  id="closeDate"
                                  disabled={true}
                                  name="closingDate1031"
                                  placeholder="180-Day Escrow close Date"
                                  maxLength={128}
                                  value={buyerInfo.closingDate1031}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  {getDateFormatted(buyerInfo.closingDate1031)}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b># of Other Properties Owned</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {factsEdit ? (
                                <input
                                  type="text"
                                  className="form-input fill-blue"
                                  name="noOfHotelsOwned"
                                  value={buyerInfo?.noOfHotelsOwned}
                                  onChange={(e) => handleInputChange(e)}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  {buyerInfo?.noOfHotelsOwned}{" "}
                                </label>
                              )}
                            </Col>
                          </Row>

                          <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                            <Col xs={8}>
                              <label className="font-size-16">
                                <b>Price You Sold Your Last Hotel</b>
                              </label>
                            </Col>
                            <Col xs={4}>
                              {factsEdit ? (
                                <CurrencyInput
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  className="currency-input form-input remove-left-border-radius fill-blue"
                                  name="lastPropSalePrice"
                                  id="lastPropSalePrice"
                                  placeholder="Final sale of your hotel sold"
                                  maxLength={128}
                                  onValueChange={handleCurrencyChange}
                                  value={buyerInfo?.lastPropSalePrice}
                                />
                              ) : (
                                <label className="font-size-16-lite">
                                  $
                                  {formatNumberWithCommas(
                                    buyerInfo?.lastPropSalePrice
                                      ? buyerInfo?.lastPropSalePrice
                                      : 0
                                  )}
                                </label>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-1 flex-1">
                <div className="card card-criteria card-top-border-buyer px-0 h-100">
                  <div className="card-profile-header-primaryBlue card-header d-flex justify-content-start py-0 pt-1">
                    <h2 className="font-size-18 fill-blue col">
                      YOUR HOTEL SEARCH REQUIREMENTS{" "}
                    </h2>
                    {user_type?.includes("Agent") ? (
                      <>
                        {searchReqEdit ? (
                          <h2
                            className="font-size-18 fill-green col d-flex justify-content-center hand-cursor fit-content-max px-2 "
                            onClick={handleSave}
                          >
                            <FaRegSave />{" "}
                          </h2>
                        ) : (
                          <></>
                        )}
                        <h2
                          className={
                            searchReqEdit
                              ? "font-size-18 fill-red col d-flex justify-content-center hand-cursor fit-content-max px-1"
                              : "font-size-18 fill-blue col d-flex justify-content-center hand-cursor fit-content-max px-1"
                          }
                          onClick={() => handleEdit("search")}
                        >
                          {searchReqEdit ? <MdOutlineCancel /> : <FaRegEdit />}{" "}
                        </h2>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="card-body buyer-profile-body">
                    <div className="form-body">
                      <Row className=" d-flex align-items-center">
                        <Col xs={12}>
                          <div className="profile-right">
                            <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                              <Col xs={4}>
                                <label className="font-size-16">
                                  <b>Brands</b>
                                </label>
                              </Col>
                              <Col xs={8}>
                                {searchReqEdit ? (
                                  <Select
                                    id="interestedBrands"
                                    name="interestedBrands"
                                    value={brandScaleSelected}
                                    defaultValue={getBrandDefaultValues}
                                    onChange={(e) => {
                                      handleMultiSelectChange(
                                        "interestedBrands",
                                        e
                                      );
                                    }}
                                    isMulti
                                    options={Interested_Brands.sort(
                                      (a, b) => a.label - b.label
                                    )}
                                  ></Select>
                                ) : (
                                  <label className="font-size-16-lite">
                                    {buyerInfo?.interestedBrands !== null
                                      ? tableValueParser(
                                          buyerInfo.interestedBrands
                                        )
                                      : "Any"}
                                  </label>
                                )}
                              </Col>
                            </Row>
                            <Row className=" d-flex align-items-center py-2">
                              <Col xs={4}>
                                <label className="font-size-16">
                                  <b>Maximum Room Count</b>
                                </label>
                              </Col>
                              <Col xs={8}>
                                {searchReqEdit ? (
                                  <Select
                                    id="interestedRoomCount"
                                    value={roomCountSelected}
                                    defaultValue={getRoomCountDefaultValue}
                                    onChange={(e) => {
                                      handleMultiSelectChange(
                                        "interestedRoomCount",
                                        e
                                      );
                                    }}
                                    name="interestedRoomCount"
                                    isMulti
                                    options={Room_Counts.sort(
                                      (a, b) => a.label - b.label
                                    )}
                                  ></Select>
                                ) : (
                                  <label className="font-size-16-lite">
                                    {buyerInfo?.interestedRoomCount !== null
                                      ? buyerInfo.interestedRoomCount
                                      : "Any"}
                                  </label>
                                )}
                              </Col>
                            </Row>

                            <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                              <Col xs={4}>
                                <label className="font-size-16">
                                  <b>Markets</b>
                                </label>
                              </Col>
                              <Col xs={8}>
                                {searchReqEdit ? (
                                  <Select
                                    id="interestedMarketType"
                                    name="interestedMarketType"
                                    defaultValue={getMarketTypeDefaultValue}
                                    value={locationSelected}
                                    onChange={(e) =>
                                      handleMultiSelectChange(
                                        "interestedMarketType",
                                        e
                                      )
                                    }
                                    isMulti
                                    options={Market_Types.sort(
                                      (a, b) => a.label - b.label
                                    )}
                                  ></Select>
                                ) : (
                                  <label className="font-size-16-lite">
                                    {buyerInfo?.interestedMarketType !== null
                                      ? tableValueParser(
                                          buyerInfo?.interestedMarketType
                                        )
                                      : "Any"}
                                  </label>
                                )}
                              </Col>
                            </Row>

                            <Row className=" d-flex align-items-center py-2">
                              <Col xs={4}>
                                <label className="font-size-16">
                                  <b>Investment Type</b>
                                </label>
                              </Col>
                              <Col xs={8}>
                                {searchReqEdit ? (
                                  <Select
                                    id="opportunityType"
                                    name="opportunityType"
                                    defaultValue={
                                      getOpportunityTypeDefaultValue
                                    }
                                    value={opportunitySelected}
                                    onChange={(e) =>
                                      handleMultiSelectChange(
                                        "opportunityType",
                                        e
                                      )
                                    }
                                    isMulti
                                    options={opportunity_types.sort(
                                      (a, b) => a.label - b.label
                                    )}
                                  ></Select>
                                ) : (
                                  <label className="font-size-16-lite">
                                    {buyerInfo?.opportunityType !== null
                                      ? tableValueParser(
                                          buyerInfo?.opportunityType
                                        )
                                      : "Any"}
                                  </label>
                                )}
                              </Col>
                            </Row>
                            <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                              <Col xs={4}>
                                <label className="font-size-16">
                                  <b>Market Segment</b>
                                </label>
                              </Col>
                              <Col xs={8}>
                                {searchReqEdit ? (
                                  <Select
                                    id="interestedCategory"
                                    name="interestedCategory"
                                    value={hotelCategorySelected}
                                    defaultValue={getCategoryDefaultValue}
                                    onChange={(e) =>
                                      handleMultiSelectChange(
                                        "interestedCategory",
                                        e
                                      )
                                    }
                                    isMulti
                                    options={Interested_Category.sort(
                                      (a, b) => a.label - b.label
                                    )}
                                  ></Select>
                                ) : (
                                  <label className="font-size-16-lite">
                                    {buyerInfo?.hotelCategory !== null
                                      ? tableValueParser(
                                          buyerInfo?.hotelCategory
                                        )
                                      : "Any"}
                                  </label>
                                )}
                              </Col>
                            </Row>
                            <Row className=" d-flex align-items-center py-2">
                              <Col xs={4}>
                                <label className="font-size-16">
                                  <b>States</b>
                                </label>
                              </Col>
                              <Col xs={8}>
                                {searchReqEdit ? (
                                  <Select
                                    id="interestedPrimaryStates"
                                    name="interestedPrimaryStates"
                                    value={primaryStatesSelected}
                                    defaultValue={getStatesDefaultValues}
                                    onChange={(e) => {
                                      handleMultiSelectChange(
                                        "interestedPrimaryStates",
                                        e
                                      );
                                    }}
                                    options={states}
                                    isMulti
                                  ></Select>
                                ) : (
                                  <label className="font-size-16-lite">
                                    {tableValueParser(
                                      getStateValuesToDisplay()
                                    )}
                                  </label>
                                )}
                              </Col>
                            </Row>
                            <Row className=" d-flex align-items-center py-2 fill-alternate-row">
                              <Col xs={4}>
                                <label className="font-size-16">
                                  <b>Geographic Region</b>
                                </label>
                              </Col>
                              <Col xs={8}>
                                {searchReqEdit ? (
                                  <Select
                                    name="geographicRegion"
                                    id="geographicRegion"
                                    value={geographicRegionSelected}
                                    isMulti
                                    options={geographic_Region}
                                    defaultValue={getGeoLocDefaultValue}
                                    onChange={(e) =>
                                      handleMultiSelectChange(
                                        "geographicRegion",
                                        e
                                      )
                                    }
                                  />
                                ) : (
                                  <label className="font-size-16-lite">
                                    {buyerInfo?.geographicRegion !== null
                                      ? tableValueParser(
                                          buyerInfo.geographicRegion
                                        )
                                      : "Any"}
                                  </label>
                                )}
                              </Col>
                            </Row>
                            <Row className=" d-flex align-items-center py-2">
                              <Col xs={4}>
                                <label className="font-size-16">
                                  <b>Additional Preferences</b>
                                </label>
                              </Col>
                              <Col xs={8}>
                                {searchReqEdit ? (
                                  <textarea
                                    id="additionalInformation"
                                    className="text-area w-100"
                                    rows="6"
                                    name="additionalInformation"
                                    onChange={handleInputChange}
                                    value={
                                      buyerInfo.additionalInformation != null
                                        ? buyerInfo.additionalInformation
                                        : ""
                                    }
                                  />
                                ) : (
                                  <label className="font-size-16-lite">
                                    {buyerInfo.additionalInformation != null
                                      ? buyerInfo.additionalInformation
                                      : ""}
                                  </label>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                      <div className="form-group">
                        {loadingInterests ? (
                          <div id="loading_seller"></div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 px-1 flex-1">
                <div className="card card-criteria card-top-border-buyer px-0 h-100">
                  <div className="card-profile-header-primaryBlue card-header d-flex justify-content-start py-0 pt-1">
                    <h2 className="font-size-18 fill-blue">BUYER STATS</h2>
                  </div>
                  <div className="card-body text-center px-2 fill-blue">
                    <div>
                      <div className="row px-4 py-0 d-flex justify-content-center align-item-center md-0">
                        <div className="col py-0 px-2 d-flex justify-content-end align-item-center fit-content-max">
                          <h6 className="fill-blue px-2 py-0  ml-0 mr-0 mb-0">
                            <FaEye
                              className="px-1 fill-green"
                              style={{ fontSize: "30px" }}
                            ></FaEye>
                            {buyerInfo?.totalViewed ? buyerInfo.totalViewed : 0}{" "}
                            Views
                          </h6>
                        </div>
                        <div className="col d-flex justify-content-center align-item-center fit-content-max px-0 py-0 ">
                          <div class="vertical-line px-0"></div>
                        </div>
                        <div className="col px-2 d-flex justify-content-start align-item-center fit-content-max">
                          <h6 className="fill-blue px-0 ml-0 mr-0 mb-0 w-100">
                            <MdGroup
                              className="px-1 fill-green"
                              style={{ fontSize: "30px" }}
                            >
                              {" "}
                            </MdGroup>
                            {buyerInfo?.interestReceived
                              ? buyerInfo?.interestReceived.length
                              : 0}{" "}
                            Sellers Interested
                          </h6>
                        </div>
                      </div>
                      <div className="row py-0">
                        <div className="col  d-flex justify-content-center align-item-center">
                          <div class="horizontal-line"></div>
                        </div>
                      </div>
                      <div className="row py-0">
                        <div className="col  d-flex justify-content-center align-item-center">
                          <h6 className="fill-blue py-3 font-size-16">
                            Max Buying Capacity $
                            {formatNumberWithCommas(
                              getCalculations("totalBuyingPower")
                                ? getCalculations("totalBuyingPower")
                                : 0
                            )}{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div>
                      <div className="row px-4 py-0 d-flex justify-content-center align-item-center">
                        <div className="col px-2  d-flex justify-content-end align-item-center fit-content-max">
                          <h6
                            className={
                              getColourClass(
                                buyerInfo?.deadLineDate45DaysColor
                              ) + " px-2 ml-0 mr-0 mb-0"
                            }
                          >
                            {buyerInfo?.deadLineDate45DaysString !== null
                              ? buyerInfo.deadLineDate45DaysString
                              : "-"}{" "}
                            Days
                          </h6>
                        </div>
                        <div className="col  d-flex justify-content-center align-item-center fit-content-max px-0 py-0 ">
                          <div class="vertical-line py-1 px-0"></div>
                        </div>
                        <div className="col px-2 d-flex justify-content-start align-item-center fit-content-max px-0 py-0 ">
                          <h6 className="fill-blue px-0 ml-0 mr-0 mb-0 w-100">
                            {getDateFormatted(buyerInfo?.deadLineDate45Days)}
                          </h6>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div className="col d-flex justify-content-center align-item-center">
                          <div class="horizontal-line"></div>
                        </div>
                      </div>
                      <div className="row py-0">
                        <div className="col  d-flex justify-content-center align-item-center">
                          <h6 className="fill-blue py-0 font-size-16">
                            45 Days Deadline{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <br></br>
                    <div>
                      <div className="row px-4 py-0 d-flex justify-content-center align-item-center">
                        <div className="col px-2  d-flex justify-content-end align-item-center fit-content-max">
                          <h6
                            className={
                              getColourClass(buyerInfo?.closingDate1031Color) +
                              " px-2 ml-0 mr-0 mb-0"
                            }
                          >
                            {buyerInfo?.closingDate1031String !== null
                              ? buyerInfo.closingDate1031String
                              : "-"}{" "}
                            Days
                          </h6>
                        </div>
                        <div className="col  d-flex justify-content-center align-item-center fit-content-max px-0 py-0 ">
                          <div class="vertical-line py-0 px-0"></div>
                        </div>
                        <div className="col px-2 d-flex justify-content-start align-item-center fit-content-max px-0 py-0 ">
                          <h6 className="fill-blue px-0 ml-0 mr-0 mb-0 w-100">
                            {getDateFormatted(buyerInfo.closingDate1031)}{" "}
                          </h6>
                        </div>
                      </div>
                      <div className="row py-2">
                        <div className="col d-flex justify-content-center align-item-center">
                          <div class="horizontal-line"></div>
                        </div>
                      </div>
                      <div className="row py-0">
                        <div className="col  d-flex justify-content-center align-item-center">
                          <h6 className="fill-blue py-0 font-size-16">
                            1031 Exchange Closing Date{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    <br></br>
                  </div>
                  {buyerInfo?.dealStatus != "Closed" &&
                  buyerInfo?.dealStatus != "Off-market" ? (
                    <div className="row d-flex justify-content-center align-item-center h-100 pt-0">
                      <div className="col d-flex justify-content-center align-item-center">
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={<strong>Delete Post</strong>}
                          drop="down"
                          variant="outline-danger"
                          className="custom-dropdown-button"
                        >
                          <Dropdown.Item
                            onClick={() =>
                              approveHandler("Closed", buyerInfo?.id)
                            }
                          >
                            Delete Post
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              approveHandler("Off-market", buyerInfo?.id)
                            }
                          >
                            Make it Off-Market
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  ) : buyerInfo?.dealStatus == "Off-market" ? (
                    <div className="row d-flex justify-content-center align-item-center h-100 pt-0">
                      <div className="col d-flex justify-content-center align-item-center">
                        <DropdownButton
                          id="dropdown-basic-button"
                          title={<strong>Make it Online</strong>}
                          drop="down"
                          variant="outline-danger"
                          className="custom-dropdown-button"
                        >
                          <Dropdown.Item
                            onClick={() =>
                              approveHandler("Unapproved", buyerInfo?.id)
                            }
                          >
                            Make it Online
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <span>
            <AiOutlineInfoCircle style={{ marginBottom: "5px" }} />
            <label className="px-1">
              If you want to change your profile, please contact your agent
            </label>
          </span>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "80vh",
            alignItems: "center",
          }}
        >
          <h2>Loading...</h2>
        </div>
      )}
    </>
  );
};

export default BuyerCriteria;
