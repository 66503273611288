const LIVE_URL = process.env.REACT_APP_LIVE_API_URL;
const LOCAL_URL = process.env.REACT_APP_LOCAL_API_URL;
// export const URL = process.env.url;

export const URL = LIVE_URL;
export const AUTH = process.env.REACT_APP_API_AUTH;
export const S3_IMAGES = process.env.REACT_APP_IMAGES_BUCKET;

export const CustomerSupportNumber = "661-808-4998";
export const CustomerSupportEmail = "info@1031hotels.com"

export const Listing_Agreement_Key = "templates/listing-agreement-v1.pdf";
export const Confi_Agreement_Key = "templates/confi-agreement-v1.pdf"
export const Confi_Agreement_Auth_Key = "templates/confi-agreement-auth-v1.pdf"
export const Terms_And_Conditions = "templates/terms-and-conditions-1031Hotels-v1.pdf"
export const Broker_Signed_Confi = "templates/buyers-broker-confidentiality-agreement-v1.pdf"
export const Buyer_Agent_Signed_Confi = "templates/buyer-confidentiality-agreement-v1.pdf"
export const Seller_Posting_Fee_Agreement = "templates/seller-posting-fee-agreement-v1.pdf"
export const Broker_Posting_Fee_Agreement = "templates/broker-authorization-to-post-hotel-agreement-v1.pdf"
export const Broker_solicits_Agreement = "templates/broker-solicits-buyer-agreement-v1.pdf";
export const Seller_solicits_Agreement = "templates/seller-solicites-buyer-agreement-v1.pdf";

// -------------------------------------- API URLS ---------------------------------------//

export const loginuser = URL + "oauth/token?grant_type=password&app=1031";

export const getuser = URL + "hba/user";
export const emailValidity = "hba/user/validateEmail?emailAddress="
export const entityValidity = "hba/user/entity/"
export const forgotPassword = "/hba/resetpassword/"
export const passwordReset = "/hba/resetpassword";
export const contactus = URL + "hba/commons/contactus";
export const primaryStates = URL + "hba/commons/states";
export const stateMsa = URL + "hba/commons/stateMsa";

export const buyers = "hba/buyers";
export const buyer = "hba/buyer/";
export const buyerActivity = "hba/activity/buyer/";

export const properties = "hba/properties";
export const property = "hba/property/";
export const buyerR = "hba/buyer";
export const propertyActivity = "hba/activity/property/";
export const propertyCategories = URL + "hba/commons/propertycategories";
export const PropertyMarketType = URL + "hba/commons/propertymarkettype";
export const PropertyRoomCount = URL + "hba/commons/propertyroomcount";
export const PropertyLoanType = URL + "hba/commons/loantypes";
export const PropertyBrands = URL + "hba/commons/propertytypes";


export const interest = "hba/interests";
export const interestForBuyer = "hba/interests/buyer";
export const interestForSeller = "hba/interests/property";

export const dashboard = "hba/user/dashboard";
export const dealBox = URL + "hba/user/dashboard/dealbox";

export const uploadFile = "hba/commons/uploadfile";

export const faqs = "hba/faqs/getfaqs";

export const verifyAccount = URL + "hba/user/confirm-account";

// -------------------------------------- API URLS ---------------------------------------//
