import React, { useEffect, useState } from "react";
import VideoPlayer from "react-background-video-player";
import "../../../css/custom/home.scss";
import logoOverlay from "../../../img/home/1031LogoWhiteGLow.png";
import footerLogo from "../../../img/home/1031-hotels-logo-white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaMapMarker, FaPhone, FaMailchimp, FaMailBulk } from "react-icons/fa";
import { getUser } from "../../common/commonUtils";
import { useNavigate } from "react-router-dom";
import { LiaFlagUsaSolid } from "react-icons/lia";
import { CgWebsite } from "react-icons/cg";
import { FaPeopleLine } from "react-icons/fa6";
import { BsDot } from "react-icons/bs";
import AnimatedDiv from "../../common/Animation";

const Home = () => {
    const [backgroundVideo, setBackgroundVideo] = useState();
    const loggedInUser = sessionStorage.getItem('user')

    let navigate = useNavigate();

    useEffect(() => {

        console.log(getUser() != null)
        console.log((JSON.parse(sessionStorage.getItem("user_type")) == "Buyer"))
    }, []);

    return (
        <>
            <div className="home-background-wrapper-margin">
                <VideoPlayer
                    name="video-bg"
                    className="home-background"
                    src={
                        "https://hba-landing-page-media.s3.amazonaws.com/web/Dallas.mp4"
                    }
                    autoPlay={true}
                    muted={true}

                />
            </div>

            <div className="overlay-wrapper transform-Y" >

                <img src={logoOverlay} className="home-logo-overlay" />
                <h2 className="animated-text fill-white">America’s Only 1031 Exchange Marketplace:<br />Matchmaking
                    Hotel Buyers and Sellers!</h2>

            </div>


            <div className="container bottom-container transform-Y">
                <div className="row w-100 py-4">
                    <div className="mobile-column-flex justify-content-center align-items-center ">
                        {getUser() != null ?
                            <>
                                {(JSON.parse(sessionStorage?.getItem("user_type")) == "Buyer") ?
                                    <div className="col mobile-zero-padding  d-flex justify-content-center align-items-center flex-column fit-content-max px-4" >
                                        <div className="row " style={{ maxWidth: 'fit-content' }}>
                                            {(JSON.parse(sessionStorage?.getItem("user_type")) == "Buyer") ?
                                                <a href="/buyersLanding"
                                                    className="btn btn-outline-primary font-size-16 button-width-500 border-radius-10 min-button-height">1031
                                                    Exchange Buyers Enter Here</a>
                                                : <></>
                                            }
                                        </div>
                                        {(JSON.parse(sessionStorage?.getItem("user_type")) == "Buyer") ?
                                            <AnimatedDiv>
                                                <div className="row py-4 d-flex justify-content-center " >
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Exclusively for 1031 Exchange Buyers {"  "}  </div>
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Deals Matched Confidentially on Website</div>
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Access to Sellers Nationwide           </div>
                                                </div>
                                            </AnimatedDiv>
                                            : <></>}
                                    </div>
                                    : <></>}

                                {(JSON.parse(sessionStorage?.getItem("user_type")) == "Seller") ?
                                    <div className="col mobile-zero-padding  d-flex justify-content-center align-items-center flex-column px-4">
                                        <div className="row d-flex justify-content-center">
                                            {(JSON.parse(sessionStorage?.getItem("user_type")) == "Seller") ?
                                                <a href="/sellersLanding" className="btn btn-outline-primary font-size-16 button-width-500 border-radius-10 min-button-height">Sellers
                                                    Enter Here</a>
                                                : <></>}
                                        </div>
                                        {(JSON.parse(sessionStorage?.getItem("user_type")) == "Seller") ?
                                            <AnimatedDiv>
                                                <div className="row py-4 custom-width-400 d-flex justify-content-center ">
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Perfect for Off Market Confidential Sellers</div>
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Deals Matched Confidentially on Website{" "}</div>
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Access to 1031 Buyers Nationwide{"  "}</div>
                                                </div>
                                            </AnimatedDiv>
                                            : <></>}
                                    </div>
                                    : <></>}

                                {(JSON.parse(sessionStorage?.getItem("user_type")).includes("Broker")) ?
                                    <div className="col mobile-zero-padding  d-flex justify-content-center align-items-center flex-column px-4">
                                        <div className="row d-flex justify-content-center">
                                            {(JSON.parse(sessionStorage?.getItem("user_type")).includes("Broker")) ?
                                                <a href={getUser() == null ? "/brokersLanding" : "/brokerDashboard"}
                                                    className="btn btn-outline-red font-size-16 button-width-500 border-radius-10 min-button-height"
                                                > <FontAwesomeIcon icon="fa-solid fa-house" />Brokers
                                                    Enter Here</a>
                                                : <></>}
                                        </div>
                                        {(JSON.parse(sessionStorage?.getItem("user_type")).includes("Broker")) ?
                                            <AnimatedDiv>
                                                <div className="row py-4custom-width-400  d-flex justify-content-center   ">
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Brokers Welcome / Invited to make Deals</div>
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>All Brokers are Protected by Non-Circumvention</div>
                                                    <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Access to 1031 Buyers and Sellers Nationwide</div>
                                                </div>
                                            </AnimatedDiv>
                                            : <></>}

                                    </div>
                                    : <></>}

                            </>
                            :
                            <>
                                <div className="col mobile-zero-padding  d-flex justify-content-center align-items-center flex-column px-4">
                                    <div className="row  d-flex justify-content-center align-items-center">
                                        <a href="/buyersLanding"
                                            className="btn btn-outline-primary font-size-16 button-width-500 border-radius-10 min-button-height">1031
                                            Exchange Buyers Enter Here</a>
                                    </div>
                                    <AnimatedDiv>
                                        <div className="row custom-width-400 py-4 d-flex justify-content-center ">
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Exclusively for 1031 Exchange Buyers {"  "}  </div>
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Deals Matched Confidentially on Website</div>
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Access to Sellers Nationwide           </div>
                                        </div>
                                    </AnimatedDiv>
                                </div>
                                <div className="col mobile-zero-padding  d-flex justify-content-center align-items-center flex-column px-4">
                                    <div className="row  d-flex justify-content-center align-items-center">
                                        <a href="/sellersLanding" className="btn btn-outline-primary font-size-16 button-width-500 border-radius-10 min-button-height">Sellers
                                            Enter Here</a>
                                    </div>
                                    <AnimatedDiv>
                                        <div className="row custom-width-400 py-4 d-flex justify-content-center px-2">
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Perfect for Off Market Confidential Sellers</div>
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Deals Matched Confidentially on Website{" "}</div>
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Access to 1031 Buyers Nationwide{"  "}</div>
                                        </div>
                                    </AnimatedDiv>
                                </div>
                                <div className="col mobile-zero-padding  d-flex justify-content-center align-items-center flex-column px-4">
                                    <div className="row  d-flex justify-content-center align-items-center">
                                        <a href={getUser() == null ? "/brokersLanding" : "/brokerDashboard"}
                                            className="btn btn-outline-red font-size-16 button-width-500 border-radius-10 min-button-height"
                                        > <FontAwesomeIcon icon="fa-solid fa-house" />Brokers
                                            Enter Here</a>
                                    </div>
                                    <AnimatedDiv>
                                        <div className="row py-4 custom-width-400 d-flex justify-content-center align-items-center ">
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Brokers Welcome / Invited to make Deals</div>
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>All Brokers are Protected by Non-Circumvention</div>
                                            <div className="fill-textBlack font-size-20 d-flex justify-content-start align-items-center " ><BsDot></BsDot>Access to 1031 Buyers and Sellers Nationwide</div>
                                        </div>
                                    </AnimatedDiv>
                                </div>
                            </>
                        }
                    </div>
                </div>
                <AnimatedDiv>
                    <div className="row mobile-margin-zero mt-5 mb-4">
                        <div className="col mobile-margin-zero mobile-zero-padding d-flex justify-content-center align-item-center mt-5 mb-4">
                            <h2 className="mobile-title-flex">Matchmaking 1031 Hotel Buyers & Sellers</h2>
                        </div>
                    </div>
                </AnimatedDiv>


                <AnimatedDiv>
                    <div className="row row-centered py-3">
                        <div className="col-md-4 col-centered text-center">
                            <LiaFlagUsaSolid className="fill-blue" style={{ height: "200px", width: "300px" }} />
                            <br /><br />
                            <div className="top-head fill-textBlack">Access To Buyers &amp;<br />Sellers Nationwide</div>
                            <br />
                            <div className="fill-textBlack font-size-18" >Exclusively for 1031<br />Exchange
                                Buyers &amp; Sellers</div>
                        </div>
                        <div className="col-md-4 col-centered text-center">
                            <CgWebsite className="fill-blue" style={{ height: "200px", width: "300px" }} />
                            <br /><br />
                            <div className="top-head fill-textBlack">Deals Matched<br />Confidentially on Website</div>
                            <br />
                            <div className="fill-textBlack font-size-18" >Perfect for Off Market<br />Confidential Sellers
                            </div>
                        </div>
                        <div className="col-md-4 col-centered text-center">
                            <FaPeopleLine className="fill-blue " style={{ height: "200px", width: "300px" }} />
                            <br /><br />
                            <div className="top-head fill-textBlack">Brokers Welcome &amp;<br />Invited to make Deals
                            </div>
                            <br />
                            <div className="fill-textBlack font-size-18" >All Brokers Protected by<br />Non-Circumvention
                            </div>
                        </div>
                    </div>
                </AnimatedDiv>


            </div>
            <div className="footer-area">
                <div className="row footer-big">
                    <div className="col-md-4 col-sm-12">
                        <div className="footer-widget">
                            <div className="widget-about">

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <div className="footer-widget">
                            <div className="footer-menu" ><img
                                src={footerLogo} alt="logo" width="100%" /></div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 py-2">
                        <div className="mobile-fotter d-flex justify-content-center align-items-center">
                            <div className="footer-menu footer-menu--1 ">
                                <h6 className="footer-widget-title">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; CONTACT US</h6>
                                <ul className="contact-details">
                                    <li>
                                        <FaMapMarker />&nbsp;
                                        <span className="footer-head"> 1031 HOTELS.com</span><br />&nbsp;&nbsp;
                                        <span className="sub-head" >12655 North central expressway</span><br />&nbsp;&nbsp;
                                        <span className="sub-head" >#550 Dallas 75243</span>
                                        <br />
                                        <FaPhone />&nbsp;<a
                                            href="tel:123-123-1234"
                                            className="font-size-20"> 661-808-4998</a></li>
                                    <li><FaMailBulk />&nbsp;<a
                                        href="mailto:info@1031hotels.com"
                                        className="font-size-20" > info@1031hotels.com</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
