import React, { useState } from 'react';

function AccordionList(props) {
    const { items } = props;
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (

        <div className=''>
            <div className="accordion">
                {items.map((item, index) => (
                    <div className="accordion-item" key={index}>
                        <button
                            onClick={() => handleClick(index)}
                            className={activeIndex === index ? 'accordion-button active' : 'accordion-button'}
                        >
                            <div className="question" dangerouslySetInnerHTML={{ __html: item.question }}>
                            </div>
                        </button>
                        {activeIndex === index && (
                            <div className="accordion-content">
                                <p><div className="answer px-2 py-2" dangerouslySetInnerHTML={{ __html: item.answer }}>
                                </div></p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>





    );
}

export default AccordionList;

