import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const SellerTutorial = () => {

    const { id } = useParams();
    let navigate = useNavigate();

    
    return (

        <div className='d-flex justify-content-center align-items-center flex-column'>
            <div id="iframeContainer" >
                {/* <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vS90VKTbuzpwNkdpCNopSc7ApA1OG1EwgpHKq4popHhOWPD5e5f1OiPhhf7R9Cb-90p0-TDvEki8fqO/embed?start=true&loop=false&delayms=15000" frameborder="0" width="960" height="569" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe> */}
                <iframe src="https://player.vimeo.com/video/930141069?h=425affaf31" width="640" height="564" frameborder="0" allow="autoplay"  mozallowfullscreen="true" webkitallowfullscreen="true" name='selerTutorial'></iframe>
            </div>
            <div  className='w-80 d-flex justify-content-end py-2 px-0'>
                <button className='btn btn-outline-primary font-size-16 button-width-300 border-radius-10 min-button-height' onClick={() =>{ id ? navigate("/propertyPost/" + JSON.parse(sessionStorage.getItem("user_seller_id"))) : navigate(-1)}}>Skip</button>
            </div>
        </div>
        

    );
};

export default SellerTutorial;
