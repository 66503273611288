import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SellerProperty from "../CommonPages/registration/sellerProperty";
import { getPropertyBrands, getPropertyCategories, getPropertyLoanTypes, getPropertyMarketTypes, getPropertyTypes } from "../../api/api";
import { getAppUrl, getUserType } from "../common/commonUtils";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useNavigate } from "react-router-dom";
import Logo from '../../img/home/1031LogoWhiteGLow.png';
import vectorImage from '../../components/assets/Vector 1.png';

const AddProperty = () => {

  const [currentPage, SetcurrentPage] = useState(1);

  const handlePageChange = React.useCallback((newPage) => {
    SetcurrentPage(newPage);
  }, []);

  const navigate = useNavigate();
  const user = sessionStorage.getItem("user");
  useEffect(() => {
    if (!user)
      document.body.classList.add("no-navbars");
    getAppUrl();
    getPropertyCategories();
    getPropertyMarketTypes();
    getPropertyLoanTypes();
    getPropertyBrands();
  }, []);

  return (
    <>
      <div className="container-common">
        <div className="left">

          {/* 
          <div className="box px-2">
            <img className="logo-overlay" src={Logo} alt="No Vector"></img>
            <h3 className="fill-white  px-4">Tell us about the hotel you want to post for sale</h3>
            <img className='common-vector' src={vectorImage} alt="No Vector"></img>
          </div> */}
          <div className='box justify-content-center w-100'>
            <div className="status-nav d-flex justify-content-center align-start py-2 w-100" >
              {!getUserType()?.includes("Broker") ? <label className="fill-white font-weight-b   px-2 ">Seller Registration</label> : <></>}
              <label className={currentPage == 1 ? `font-weight-b  fill-white px-2 highligter` : "font-weight-b fill-white px-2"}>Seller Profile</label>
              <label className={currentPage == 2 ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2"}>Upload Images</label>
              <label className={currentPage == 3 ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2"}>Upload Documents</label>
              <label className={currentPage == 4 ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2"}>Accept Terms and Conditions</label>
              <label className={currentPage == 5 ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2"}>Listing Agreement</label>
            </div>

            <img className='common-vector' src={vectorImage} alt="No Vector"></img>
          </div>
        </div>
        <div className="right">
          <div className="card-body w-100">
            <form
              id="wizard_with_validation"
              className="form-control"
            >
              {<SellerProperty changePage={handlePageChange} />}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProperty;
