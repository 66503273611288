import React from 'react';
import LoginFormCard from './login';
import './Login.css';
import Logo from '../../../img/home/1031LogoWhiteGLow.png';
import vectorImage from '../../../components/assets/Vector 1.png'

const Login = () => {
    return (
        <div className="container-common">
            <div className="left-login">
                
                <div className='box px-2'>
                    <img className="logo-overlay" src={Logo} alt="No Vector"></img>
                    <h3 className='fill-white px-2'>America's only 1031 Exchange Marketplace</h3>
                    <img className='common-vector' src={vectorImage} alt="No Vector"></img>
                </div>
            </div>
            <div className="right">
                <LoginFormCard></LoginFormCard>
            </div>
        </div>
    );
}

export default Login;
