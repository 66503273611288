import React, { useState } from "react";
import {
    Navbar,
    Nav,
    Container,
    NavDropdown
} from "react-bootstrap";
import navLogo from "../../img/logo_home.png";
import { NavLink } from "react-router-dom";
import { GetUserTokenCookie, getUser, getUserType, removeUserSession, setUserSession } from "../common/commonUtils";
import { handleBurgerIconClick } from "./navbarUtils";
import { AiFillCaretDown } from "react-icons/ai";
import logo from "../../img/home/1031LogoWhiteGLow.png";
import { getUserInfo, getUserInformation } from "../../api/api";
import { useEffect } from "react";


function handleLogout(event) {
    event.preventDefault();
    removeUserSession();
    window.location = "/";
}

function handleBurgerIconToggle(event) {
    handleBurgerIconClick();
}

let user = "";
let user_type = "";
let user_buyers = "";
let user_sellers = "";
let user_buyer_id = "";
let user_seller_id = "";
let buyer_profile = "/buyerPost/";
let seller_profile = "/propertyPost/";
let currentUser = sessionStorage.getItem("user");
user = currentUser?.slice(1, currentUser.length - 1);

const Navigation = () => {
    const [modalShow, setModalShow] = useState(false);
    const [userR, setUser] = useState();
    const [expanded, setExpanded] = useState(false);

    if (getUser()) {

        let currentUser = sessionStorage.getItem("user");

        user = currentUser?.slice(1, currentUser.length - 1);
        let userType = sessionStorage.getItem("user_type");
        user_type = userType?.slice(1, userType.length - 1);
        user_buyers = sessionStorage.getItem("user_buyers");
        user_sellers = sessionStorage.getItem("user_sellers");
        user_buyer_id = sessionStorage.getItem("user_buyer_id");
        user_seller_id = sessionStorage.getItem("user_seller_id");
        buyer_profile = "/buyerPost/" + user_buyer_id;
        seller_profile = "/propertyPost/" + user_seller_id;

    }

    useEffect(() => {
        console.log("navbar")
        if (getUser()) {


            user = "";
            user_type = "";
            user_buyers = "";
            user_sellers = "";
            user_buyer_id = "";
            user_seller_id = "";
            buyer_profile = "/buyerPost/";
            seller_profile = "/propertyPost/";



            let userType = sessionStorage.getItem("user_type");
            user_type = userType?.slice(1, userType.length - 1);
            user_buyers = sessionStorage.getItem("user_buyers");
            user_sellers = JSON.parse(sessionStorage.getItem("user_sellers"));
            user_buyer_id = JSON.parse(sessionStorage.getItem("user_buyer_id"));
            user_seller_id = JSON.parse(sessionStorage.getItem("user_seller_id"));
            buyer_profile = buyer_profile + user_buyer_id;
            seller_profile = seller_profile + user_seller_id;

        }
        else {
            checkKeepSignedIn()

        }
    })

    const getTutorialPath = () => {
        let userType = sessionStorage.getItem("user_type");


        if (userType.includes("Buyer")) window.location.href = "/buyerTutorial"
        if (userType.includes("Seller")) window.location.href = "/sellerTutorial"
        if (userType.includes("Broker")) window.location.href = "/brokerTutorial"
    }

    const checkKeepSignedIn = async () => {
        console.log("From Cookies")
        const userCookie = GetUserTokenCookie();
        console.log(userCookie?.toString())
        console.log(localStorage.getItem("LoggedIn"))
        if (userCookie) {
            localStorage.setItem(
                "login_user_token",
                JSON.stringify(userCookie)
            );

            let redirectURL = "";
            await getUserInformation(JSON.stringify(userCookie)).then((res) => {

                let user = res.data
                if (user?.userTypes.includes('Broker')) {
                    redirectURL = "/brokerDashboard";
                }
                if (user?.userTypes.includes('Agent')) {
                    redirectURL = "/agentDashboard";
                }
                if (user?.userTypes.includes('Buyer')) {
                    redirectURL = "/addBuyer";
                    if (user?.buyersSize > 0) {
                        redirectURL = "/buyerPost/" + user.buyerId;
                    }
                    sessionStorage.setItem("user_buyers", user.buyersSize);
                    sessionStorage.setItem("user_buyer_id", user.buyerId);
                }
                if (user?.userTypes.includes('Seller')) {
                    redirectURL = "/sellerDashboard";
                    if (user?.propertiesSize > 0) {
                        redirectURL = "/propertyPost/" + user.propId;
                    }
                    sessionStorage.setItem("user_sellers", user.propertiesSize);
                    sessionStorage.setItem("user_seller_id", user.propId);
                }
                console.log(user)
                setUserSession(user.firstName, user.lastName, user.userTypes, user.id, user.email, user.mobileNumber, user.userId);
                localStorage.setItem("LoggedIn", true)
                let currentUser = sessionStorage.getItem("user");
            });

            let currentUser = sessionStorage.getItem("user");
            console.log(currentUser)
            user = currentUser;
            user_type = sessionStorage.getItem("user_type");
            user_buyers = JSON.parse(sessionStorage.getItem("user_buyers"));
            user_sellers = JSON.parse(sessionStorage.getItem("user_sellers"));
            user_buyer_id = JSON.parse(sessionStorage.getItem("user_buyer_id"));
            user_seller_id = JSON.parse(sessionStorage.getItem("user_seller_id"));
            buyer_profile = buyer_profile + user_buyer_id;
            seller_profile = seller_profile + user_seller_id;

            window.location = redirectURL;
        }

    }

    const handleToggle = () => setExpanded(!expanded);
    const handleSelect = () => setExpanded(false);

    return (
        <>
            <Navbar expanded={expanded} onToggle={handleToggle} bg="light" variant="light" expand="lg" fixed="top" className="d-flex nav-mob ">
                <Container fluid style={{ paddingRight: "0px" }}>
                    <Navbar.Brand href="/">
                        <img alt="logo" className="logo-image" width="175px" src={logo} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ marginRight: "15px" }} />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto move-right-nav" >
                            {user_type.includes("Broker") && (
                                <NavLink to="/brokerDashboard" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>DASHBOARD</b>
                                </NavLink>
                            )}
                            {user_type.includes("Agent") && (
                                <NavLink to="/agentDashboard" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>DASHBOARD</b>
                                </NavLink>
                            )}
                            {user_type === "Buyer" && user_buyers === 0 && (
                                <NavLink to="/addBuyer" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>ADD BUYER</b>
                                </NavLink>
                            )}
                            {user_type === "Seller" && user_sellers === 0 && (
                                <NavLink to="/addSeller" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>POST A HOTEL FOR SALE</b>
                                </NavLink>
                            )}
                            {user_type.includes("Buyer") && user_buyers > 0 && (
                                <NavLink to={buyer_profile} onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>YOUR DEAL BOX</b>
                                </NavLink>
                            )}
                            {user_type.includes("Seller") && user_sellers > 0 && (
                                <NavLink to={seller_profile} onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>YOUR DEAL BOX</b>
                                </NavLink>
                            )}
                            {(getUserType() == null || getUserType() === "Seller" || getUserType().includes("Broker") || getUserType().includes("Agent")) && (
                                <NavLink to="/buyerList" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>1031 BUYERS</b>
                                </NavLink>
                            )}
                            {(getUserType() == null || getUserType() === "Buyer" || getUserType().includes("Broker") || getUserType().includes("Agent")) && (
                                <NavLink to="/propertyList" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>HOTELS FOR SALE</b>
                                </NavLink>
                            )}
                            {getUser() == null && (
                                <NavLink to="/brokersLanding" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                    <b>BROKERS</b>
                                </NavLink>
                            )}
                            <NavLink to="/faqs" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                <b>INFO/FAQs</b>
                            </NavLink>
                            <NavLink to="/contactus" onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                <b>CONTACT US</b>
                            </NavLink>
                            {getUser() == null ? (
                                <>
                                    <NavLink to="/login"  onClick={handleSelect} className="nav-link navbar-text nav-link-lg">
                                        <b>LOGIN</b>
                                    </NavLink>
                                    <NavLink to="/register" onClick={handleSelect}  className="nav-link navbar-text nav-link-lg">
                                        <b>REGISTER</b>
                                    </NavLink>
                                </>
                            ) : (
                                <>
                                    <NavDropdown title={<label style={{ color: '#000', fontWeight: '800' }}>
                                        Welcome <span style={{ color: "#6610f2" }}>{JSON.parse(sessionStorage.getItem("user_type"))} {user}</span>
                                    </label>} 
                                    id="navbarScrollingDropdown">
                                        <NavDropdown.Item >
                                            <NavLink to="/profile" onClick={handleSelect} className="dropdown-item has-icon">Profile</NavLink>
                                        </NavDropdown.Item>
                                        <NavDropdown.Item >  
                                            <a onClick={() =>{getTutorialPath(); handleSelect()}} className="dropdown-item has-icon">
                                                <i className="fas fa-sign-out-alt"></i>Tutorial
                                            </a>
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item >
                                            <a href="/login" onClick={(e) => {
                                                handleLogout(e); handleSelect()
                                            }} className="dropdown-item has-icon text-danger">
                                                <i className="fas fa-sign-out-alt"></i>Logout
                                            </a>
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    {/* <li id="my-account-li" className="nav-item dropdown">
                                        <a
                                            href="#"
                                            data-bs-toggle="dropdown"
                                            className="nav-link dropdown-toggle nav-link-lg nav-link-user"
                                            aria-expanded="false"
                                            id="account-dropdown"
                                        >
                                            <label style={{ color: '#000', fontWeight: '800' }}>
                                                Welcome <span style={{ color: "#6610f2" }}>{JSON.parse(sessionStorage.getItem("user_type"))} {user}</span>
                                                <AiFillCaretDown className="down-icon" />
                                            </label>
                                        </a>
                                        <div id="login-dropdown" className="dropdown-menu dropdown-menu-right pullDown">
                                            <NavLink to="/profile" className="dropdown-item has-icon">Profile</NavLink>
                                            <div className="dropdown-divider"></div>
                                            <a onClick={getTutorialPath} className="dropdown-item has-icon">
                                                <i className="fas fa-sign-out-alt"></i>Tutorial
                                            </a>
                                            <div className="dropdown-divider"></div>
                                            <a href="/login" onClick={handleLogout} className="dropdown-item has-icon text-danger">
                                                <i className="fas fa-sign-out-alt"></i>Logout
                                            </a>
                                        </div>
                                    </li> */}
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>


    );
};
export default Navigation;