import React, { useEffect, useState } from 'react';
import { getPrimaryStates, registerUser, updateUser, validateEmail, validateEntity } from '../../api/api';
import $ from "jquery"
import InputMask from 'react-input-mask';
import { getUserInformation } from '../../api/api';
import { AiOutlineLeft } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { brokerDescription, brokerLookingFor, brokerTypes, hotelsSoldRanges } from '../common/utils';

const URL = process.env.REACT_APP_API_URL;

const UserEdit = (props) => {

    const [primaryStates, setPrimaryStates] = useState([]);
    const [isValidMail, setIsValidMail] = useState(true);
    const [isValidEntity, setIsValidEntity] = useState(true);
    const [agreeTerms, setAgreeTerms] = useState();
    const [spinner, setSpinner] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [formState, setFormState] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        confirmPassword: "",
        companyName: "",
        website: "",
        additionalInformation: "",
        stateLicensed: {
            id: "",
            name: "",
            code: "",
        },
        userTypes: props?.userType
    });
    const [formValidity, setFormValidity] = useState({
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        mobileNumber: null,
        confirmPassword: null,
        companyName: null,
        website: null,
        additionalInformation: null,
        stateLicensed: {
            id: null,
            name: null,
            code: null,
        },
        type: {
            code: null
        }
    });

    const [formErrorMessage, setFormErrorMessage] = useState({
        email: "Email is mandatory",
        password: "",
        firstName: "",
        lastName: "",
        mobileNumber: "Phone Number is mandatory",
        confirmPassword: "Confirm Password is mandatory",
        companyName: "Please provide valid entity",
        website: "",
        additionalInformation: "",
        stateLicensed: {
            id: "",
            name: "",
            code: "",
        },
        type: {
            code: ""
        }
    });

    const navigate = useNavigate();

    let userType = props?.userType;
    localStorage.setItem("registeringUserType", userType);

    useEffect(async () => {
        getPrimaryStates().then(states => setPrimaryStates(states))

        getUserInfo();


    }, [])

    const getUserInfo = async () => {
        let res = await getUserInformation();
        setUserInfo(res.data);
        setFormState(res?.data)

        let val = await getPrimaryStates();
        setPrimaryStates(val);
    };

    const handleEdit = () => {
        setEditMode(true)
    }
    const handleCancel = () => {
        setEditMode(false)
    }


    const handleChange = (e) => {
        const { name, value } = e.target;


        if (name == "email") {

        } else if (name == "mobileNumber") {
            var unformattedMaskNumber = value.replace(/[\s()-]/g, '');

            setFormState((prevState) => ({
                ...prevState,
                mobileNumber: unformattedMaskNumber,
            }));
        } else if (name === "stateLicensed") {

            setFormState((prevState) => ({
                ...prevState,
                stateLicensed: primaryStates[value]
            }));

        } else if (name == "agreeterms") {
            console.log(e.target.checked)
            setAgreeTerms(e.target.checked)
        }
        else {
            setFormState((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
        console.log(formState)
    };

    const handleSubmit = async (e) => {

        e.preventDefault()
        setSpinner(true);
        console.log("edit")
        if (validateRegisterUser(e)) {
            let payload = formState;
            if (isValidMail && isValidEntity && formState.firstName && formState.lastName && formState.mobileNumber && formState.email && formState.password) {
                let res = await updateUser(payload)
                if (res.status == 200) {
                    setSpinner(false)
                }
                setSpinner(false)

            }
        }
        setEditMode(false)
        setSpinner(false)
    }

    function validateRegisterUser(event) {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;
        let state = formState
        let errorCount = 0;


        if (state.firstName == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                firstName: false,
            }));
            $("#firstName").addClass("is-invalid");
            errorCount += 1
        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                firstName: true,
            }));
            $("#firstName").removeClass("is-invalid");
        }

        if (state.lastName == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                lastName: false,
            }));
            $("#lastName").addClass("is-invalid");
            errorCount += 1
        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                lastName: true,
            }));
            $("#lastName").removeClass("is-invalid");
        }


        if (state.mobileNumber == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                mobileNumber: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                mobileNumber: "Phone Number is mandatory",
            }));
            $("#mobileNumber").addClass("is-invalid");
            errorCount += 1

        } else if (!state.mobileNumber.match(/^[0-9]{10}$/)) {
            setFormValidity((prevState) => ({
                ...prevState,
                mobileNumber: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                mobileNumber: "Please provide valid Phone Number",
            }));
            $("#mobileNumber").addClass("is-invalid");
            errorCount += 1

        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                mobileNumber: true,
            }));
            $("#mobileNumber").removeClass("is-invalid");
        }


        if (state.stateLicensed.id == "") {
            $("#stateLicensed").addClass("is-invalid");
            errorCount += 1
        } else {
            $("#stateLicensed").removeClass("is-invalid");
        }
        return errorCount == 0;
    }

    const checkEntity = async (e) => {
        let entity = e.target.value;
        let res = await validateEntity(entity);
        console.log(res)

        if (res?.data) {
            $("#companyName").addClass("is-valid");
            $("#companyName").removeClass("is-invalid");
            setIsValidEntity(res?.data)
        } else {
            $("#companyName").addClass("is-invalid");
            $("#companyName").removeClass("is-valid");
            setFormValidity((prevState) => ({
                ...prevState,
                companyName: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                companyName: "Please provide valid entity",
            }));
            setIsValidEntity(false)
        }

    }


    const checkEmailAvailability = async (e) => {

        let email = e.target.value;
        let name = e.target.name;


        setFormValidity((prevState) => ({
            ...prevState,
            email: false,
        }));
        setFormErrorMessage((prevState) => ({
            ...prevState,
            email: "Please provide valid email",
        }));

        $("#email").addClass("is-invalid");
        $("#email").removeClass("is-valid");



    };




    return (
        <>
            <div>
                <div className="previous-page" onClick={() => navigate(-1)}>
                    <AiOutlineLeft /> Go Back
                </div>
                <div
                    style={{ paddingTop: "30px" }}
                    className="section-body user-registration-page"
                >
                    <div className="row clearfix justify-content-center">
                        <div className="col-12 col-md-10 col-lg-10">
                            <div className="card">

                                <div id="user-profile-card" className="card-body">
                                    <div className='row'>
                                        <h2 className='col'>User Profile</h2>
                                        <div className="col form-group" style={{ alignItems: "end" }}>
                                            {!editMode ?
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleEdit}
                                                    disabled={editMode}
                                                >
                                                    Edit
                                                </button>
                                                : <></>}
                                        </div>
                                    </div>

                                    {false? (
                                        <div className="loading-block">
                                            <div className="loading-wrapper">
                                                <span className="loading-icon"></span>
                                            </div>
                                        </div>
                                    ) : (
                                        <>

                                            <div className="form-body">
                                                <div className={"row"}>
                                                    <div className="form-group col-12 col-lg-4">
                                                        <label>First Name<sup>*</sup></label>

                                                        <input id="firstName" type="text" className={"form-input"} name="firstName" disabled={!editMode} required="" maxLength={128} onChange={handleChange} value={formState?.firstName} />
                                                        <div className="invalid-feedback">
                                                            First Name is mandatory
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-12 col-lg-4">
                                                        <label>Last Name<sup>*</sup></label>
                                                        <input id="lastName" type="text" className={"form-input"} name="lastName" disabled={!editMode} required="" maxLength={128} onChange={handleChange} value={formState?.lastName} />
                                                        <div className="invalid-feedback">
                                                            Last Name is mandatory
                                                        </div>
                                                    </div>
                                                    {userInfo?.userTypes == "Buyer" ?
                                                        <div className="form-group col-12 col-lg-4">
                                                            <label>Entity Name<sup>*</sup></label>
                                                            <input id="companyName" type="text" className="form-input" name="companyName" disabled={!editMode} required="" maxLength={128} onChange={handleChange} value={formState?.companyName} />
                                                            <div className="invalid-feedback">
                                                                {formErrorMessage?.companyName}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="form-group col-12 col-lg-4">
                                                            <label>Entity Name<sup>*</sup></label>
                                                            <input id="companyName" type="text" className="form-input" name="companyName" disabled={!editMode} required="" maxLength={128} onChange={handleChange} value={formState?.companyName} />
                                                            <div className="invalid-feedback">
                                                                Entity Name is mandatory
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className={"row"}>
                                                    <div className="form-group col-12 col-lg-4" >
                                                        <label>What is your home state</label>
                                                        {!editMode ?
                                                            <>
                                                                <input id="stateLicensed" name="stateLicensed" className="form-input select-state" disabled={true} value={formState?.stateLicensed?.name} />

                                                            </> :

                                                            <select id="stateLicensed" name="stateLicensed" className="form-input form-select select-state" value={formState?.stateLicensed?.id} onChange={handleChange} >
                                                                {primaryStates?.map(state => {
                                                                    return <option id={state?.id} name={state?.name} value={state?.id}>{state?.name}</option>
                                                                })}
                                                            </select>
                                                        }
                                                        {/* <select
                                                            className="form-input form-select select-state"
                                                            id="stateLicensed"
                                                            name="stateLicensed"
                                                            placeholder="Select State"
                                                            disabled={!editMode}
                                                            defaultValue={formState?.stateLicensed}
                                                            onChange={(event) =>
                                                                handleChange(event)
                                                            }
                                                        >
                                                            <option value="">Please Select</option>
                                                            {primaryStates.map((state, key) => {
                                                                return (
                                                                    <option
                                                                        id={key}
                                                                        value={state}
                                                                    >
                                                                        {state.name} - {state.code}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select> */}
                                                        <div className="invalid-feedback">
                                                            Please select your home state
                                                        </div>
                                                    </div>

                                                    <div className="form-group col-12 col-lg-4">
                                                        <label>Phone Number<sup>*</sup></label>

                                                        <InputMask
                                                            mask="(999) 999-9999"
                                                            maskChar=" "
                                                            className="phone form-input"
                                                            id="mobileNumber"
                                                            name="mobileNumber"
                                                            required
                                                            disabled={!editMode}
                                                            value={formState?.mobileNumber}
                                                            onChange={handleChange}
                                                        />
                                                        <div className="invalid-feedback">
                                                            {formErrorMessage.mobileNumber}
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-12 col-lg-4">
                                                        <label>Website</label>
                                                        <input type="text" className="form-input" name="website" required="" maxLength={128} onChange={handleChange} disabled={!editMode} value={formState?.website} />
                                                        <div className="invalid-feedback">
                                                            Invalid website.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"row"}>
                                                    <div className="form-group col-12 col-lg-4">
                                                        <label>Email (Username)<sup>*</sup></label>
                                                        <input type="text"
                                                            id="email"
                                                            className={"form-input"}
                                                            disabled={true}
                                                            value={formState?.email}
                                                            name="email" required="" maxLength={128} />
                                                        <div id="email-feedback" className="invalid-feedback">
                                                            {/* {isValidMail == undefined ? "Email is mandatory" : isValidMail ? "" : "Email is already in use"} */}
                                                            {formErrorMessage?.email}

                                                        </div>
                                                    </div>
                                                </div>
                                                {formState?.userTypes?.includes("Broker") ?
                                                    <>
                                                        <div className={"row"}>
                                                            <div className="form-group col-12 col-lg-6">
                                                                <label>
                                                                    NUMBER OF HOTELS YOU SOLD IN PAST
                                                                </label>
                                                                <select
                                                                    className="form-input form-select select-state"
                                                                    id="hotelsSoldByBroker"
                                                                    name="hotelsSoldByBroker"
                                                                    placeholder="Select number of hotels you sold in past"
                                                                    disabled={!editMode}
                                                                    value={formState?.hotelsSoldByBroker}
                                                                    onChange={(event) =>
                                                                        handleChange(event)
                                                                    }
                                                                >
                                                                    <option value="" selected disabled>Please Select</option>
                                                                    {hotelsSoldRanges.map((type, key) => {
                                                                        return (
                                                                            <option
                                                                                id={key}
                                                                                value={type}
                                                                            >
                                                                                {type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <div className="invalid-feedback">
                                                                    Please Select Broker Type
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-12 col-lg-6">
                                                                <label>
                                                                    WHAT DESCRIBES YOU THE BEST
                                                                </label>
                                                                <select
                                                                    className="form-input form-select select-state"
                                                                    id="brokerageType"
                                                                    name="brokerageType"
                                                                    placeholder="Select what describes you the best"
                                                                    disabled={!editMode}
                                                                    value={formState?.brokerageType}
                                                                    onChange={(event) =>
                                                                        handleChange(event)
                                                                    }
                                                                >
                                                                    <option value="" selected disabled>Please Select</option>
                                                                    {brokerDescription.map((type, key) => {
                                                                        return (
                                                                            <option
                                                                                id={key}
                                                                                value={type}
                                                                            >
                                                                                {type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <div className="invalid-feedback">
                                                                    Company Name is mandatory
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={"row"}>
                                                            <div className="form-group col-12 col-lg-6">
                                                                <label>
                                                                    WHAT ARE YOU LOOKING FOR ON 1031Hotels.com?
                                                                </label>
                                                                <select
                                                                    className="form-input form-select select-state"
                                                                    id="brokerInterest"
                                                                    name="brokerInterest"
                                                                    placeholder="Select number of hotels you sold in past"
                                                                    disabled={!editMode}
                                                                    value={formState?.brokerInterest}
                                                                    onChange={(event) =>
                                                                        handleChange(event)
                                                                    }
                                                                >
                                                                    <option value="" selected disabled>Please Select</option>
                                                                    {brokerLookingFor.map((type, key) => {
                                                                        return (
                                                                            <option
                                                                                id={key}
                                                                                value={type}
                                                                            >
                                                                                {type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <div className="invalid-feedback">
                                                                    Please Select Broker Type
                                                                </div>
                                                            </div>
                                                            <div className="form-group col-12 col-lg-6">
                                                                <label>
                                                                    BROKER TYPE<sup>*</sup>
                                                                </label>
                                                                <select
                                                                    className="form-input form-select select-state"
                                                                    id="brokerType"
                                                                    name="brokerType"
                                                                    placeholder="Select Broker Type"
                                                                    disabled={!editMode}
                                                                    value={formState?.brokerType}
                                                                    onChange={(event) =>
                                                                        handleChange(event)
                                                                    }
                                                                >
                                                                    <option value="" selected disabled>Please Select</option>
                                                                    {brokerTypes.map((type, key) => {
                                                                        return (
                                                                            <option
                                                                                id={key}
                                                                                value={type}
                                                                            >
                                                                                {type}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </select>
                                                                <div className="invalid-feedback">
                                                                    Please Select Broker Type
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : <></>}


                                                <div className="form-group">
                                                    <label>Additional Information</label>
                                                    <textarea className="form-input" name="additionalInformation" required="" disabled={!editMode} maxLength={256} value={formState?.additionalInformation} onChange={handleChange} />
                                                    <div className="invalid-feedback">
                                                        Oh no! You entered an inappropriate word.
                                                    </div>
                                                </div>


                                                <div className="row" style={{ float: "right" }}>
                                                    {editMode ? (
                                                        <>
                                                            <button
                                                                className="btn btn-primary col"
                                                                onClick={handleSubmit}

                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                className="btn btn-primary col"
                                                                onClick={handleCancel}

                                                            >
                                                                Cancel
                                                            </button>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
};

export default UserEdit;
