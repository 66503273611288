import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { FaTrash } from 'react-icons/fa';
import { S3_IMAGES } from '../../constants/constants';

function ImageGalleryEditor({ initialImageSrcs, formSubmissable }) {
    const [imageSrcs, setImageSrcs] = useState(initialImageSrcs.split(','));
    
    useEffect(() => {
        console.log(initialImageSrcs);
        setImageSrcs(initialImageSrcs.split(','))
    }, [initialImageSrcs]);

    const handleDelete = (index) => {
        const newImageSrcs = imageSrcs.filter((_, i) => i !== index);
        setImageSrcs(newImageSrcs);
        formSubmissable(newImageSrcs.toString());
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newImageSrcs = arrayMove(imageSrcs, oldIndex, newIndex);
        setImageSrcs(newImageSrcs);
        formSubmissable(newImageSrcs.toString());
    };

    const SortableImage = SortableElement(({ src, index }) => (
        <div
            style={{
                position: 'relative',
                width: '250px',
                height: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '5px',
                cursor: 'grab',
            }}
        >
            <img src={src} alt={`image-${index}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            <button
                onClick={() => handleDelete(index)}
                className="delete-button"
            >
                <FaTrash className="delete-icon" />
            </button>
            <style jsx>{`
                .delete-button {
                    display: none;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    background: rgba(0, 0, 0, 0.5);
                    border: none;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    align-items: center;
                    justify-content: center;
                    transition: color 0.3s ease;
                }

                .delete-icon {
                    color: white;
                    font-size: 18px;
                }

                .delete-button:hover {
                    background: #ee6c4d;
                }

                div:hover .delete-button {
                    display: flex;
                }
            `}</style>
        </div>
    ));

    const SortableImageGallery = SortableContainer(({ images }) => {
        return (
            <div className="grid-container image-gallery">
                {images.map((src, index) => (
                    <SortableImage key={src} index={index} src={S3_IMAGES + src} />
                ))}
            </div>
        );
    });

    return (
        <SortableImageGallery images={imageSrcs} onSortEnd={onSortEnd} axis="xy" />
    );
}

export default ImageGalleryEditor;
