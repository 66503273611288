import React from 'react';
import { useNavigate } from 'react-router-dom';
import AnimatedDiv from '../common/Animation';
import { FaRegPlayCircle } from 'react-icons/fa';
import { getUser } from '../common/commonUtils';

const BuyerLanding = () => {

    let navigate = useNavigate();

    return (
        <>

            <div className=" gradient-background container-landing d-flex justify-content-start flex-column py-4">
                <div className='row transform-Y'>
                    <h2 className='row px-4 font-size-50'>BUYERS</h2>
                    <h1 className='row px-4'>WELCOME TO 1031 HOTELS</h1>
                    <label className='row fill-white px-4 font-size-24 px-4'>Congrats on closing the sale of your downleg hotel!
                        <br></br>
                        We know the 180-day journey to closing your upleg hotel is time-sensitive and weʼre here to make phase 2 smooth for you.</label>
                </div>

                <div className='row py-5 mt-5 mobile-column-flex'>
                    {console.log(getUser())}
                    {getUser() != null ? <></>
                        :
                        <>
                            <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                                <button className='btn btn-white-outline h-100 px-4 transform-Y button-mobile-width-300' onClick={() => navigate("/buyerRegistration")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0'>Register as 1031 Buyer </span> </button>
                            </div>

                            <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                                <button className='btn btn-white-outline px-4 h-100 transform-Y button-mobile-width-300' onClick={() => navigate("/login")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0'>Login as Buyer</span></button>
                            </div>
                        </>}

                    <div className='col transform-Y px-4 py-2 d-flex justify-content-center align-items-center'>
                        <button className='btn btn-white-outline px-4    h-100 transform-Y button-mobile-width-300' onClick={() => navigate("/propertyList")}><span className='d-flex justify-content-center align-items-center font-size-20-landing mb-0 '>View Hotels for sale</span></button>
                    </div>

                </div>

            </div>

            <div className='row landing-page-list  mobile-zero-padding px-5 pb-5 mobile-column-flex'>
                <div className='col-8 px-4 transform-Y mobile-w-100' >
                    <div className="row px-2 py-5">
                        <div className="col d-flex justify-content-center align-item-center mt-2 mb-4 transform-Y">

                            <h2 className='fill-black mobile-text-align-center'>EXCLUSIVELY FOR 1031 BUYERS</h2>

                        </div>
                    </div>
                    <div className='landing-page-list mobile-w-100'>

                        <div className='row d-flex justify-content-center align-item-center px-4 py-2  '>
                            <div className='listing-index-card col '>
                            </div>
                            <div className='col px-0 '>

                                <div className='listing-note-card card card-body'>
                                    <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                        Find your own Deal for your 1031 Exchange from
                                        hotels posted by private sellers & brokers all in
                                        one place
                                    </h4>
                                </div>

                            </div>
                        </div>

                        <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                            <div className='listing-index-card col '>
                            </div>
                            <div className='col px-0'>
                                <div className='listing-note-card card card-body'>
                                    <AnimatedDiv>
                                        <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                            Let any Seller in America find You and offer His or
                                            Her hotel based on your requirements
                                        </h4>
                                    </AnimatedDiv>
                                </div>
                            </div>
                        </div>

                        <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                            <div className='listing-index-card col '>
                            </div>
                            <div className='col px-0'>
                                <div className='listing-note-card card card-body'>
                                    <AnimatedDiv>
                                        <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                            Finally, a simple, efficient & effective way to find
                                            your next 1031 hotel deal and get it done, without
                                            the stress
                                        </h4>
                                    </AnimatedDiv>
                                </div>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                            <div className='listing-index-card col '>
                            </div>
                            <div className='col px-0'>
                                <div className='listing-note-card card card-body'>
                                    <AnimatedDiv>
                                        <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                            It’s all about the Numbers seen, Brand info,
                                            Revenue, NOI, Revenue Multiplier, & Cap Rate from
                                            100s of hotels-even search for your own Deal
                                        </h4>
                                    </AnimatedDiv>
                                </div>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                            <div className='listing-index-card col '>
                            </div>
                            <div className='col px-0'>
                                <div className='listing-note-card card card-body'>
                                    <AnimatedDiv>
                                        <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                            Your information is confidential until we have a
                                            hotel that matches your profile & you accept
                                        </h4>
                                    </AnimatedDiv>
                                </div>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                            <div className='listing-index-card col '>
                            </div>
                            <div className='col px-0'>
                                <div className='listing-note-card card card-body'>
                                    <AnimatedDiv>
                                        <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                            Connect with 1031 Advisors that will help facilitate
                                            your 1031 exchange before you sell your hotel
                                        </h4>
                                    </AnimatedDiv>
                                </div>
                            </div>
                        </div>

                        <div className='row d-flex justify-content-center align-item-center px-4 py-2'>
                            <div className='listing-index-card col '>
                            </div>
                            <div className='col px-0'>
                                <div className='listing-note-card card card-body'>
                                    <AnimatedDiv>
                                        <h4 className="font-size-18 font-weight-400 px-2  pb-0 fill-textBlue">
                                            Get informed on the 1031 process, easily & quickly
                                        </h4>
                                    </AnimatedDiv>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-4 px-4 py-4 transform-Y mobile-w-100'>

                    <div className="tip-area-landing " >
                        <div className="card">

                            <div className='card-body '>
                                <h3 className='d-flex justify-content-center align-items-center fill-red px-2 py-2 font-weight-400'>How it works</h3>
                                <div className='row d-flex justify-content-center align-items-center py-0'>
                                    <button className='btn btn-primary fit-content-max d-flex justify-content-center align-items-center' onClick={() => navigate("/buyerTutorial")}><FaRegPlayCircle className='font-size-24 px-1' /> Watch a Tutorial</button>
                                </div>
                                <ol className='landing-page-ol' >
                                    <li>
                                        <a href='/buyerRegistration'>Register</a> as a 1031 Buyer.
                                    </li>
                                    <li>
                                        You will receive a BUYER ID. Your details will remain confidential until a deal is made.
                                    </li>
                                    <li>
                                        Tell us what you are looking for.
                                    </li>
                                    <li>
                                        Upload the documents to prove your 1031 Exchange funds.
                                    </li>
                                    <li>
                                        We will verify your 1031 Exchange and approve your profile.
                                    </li>
                                    <li>
                                        You will be assigned with a 1031 agent.
                                    </li>
                                    <li>
                                        Your agent will be your primary point of contact for everything you need from 1031Hotels.com.
                                    </li>
                                    <li>
                                        Once approved, your profile will be live for sellers to find you confidentially.
                                    </li>
                                    <li>
                                        You can, now, start shopping the hotels listed for sale.
                                    </li>
                                    <li>
                                        Save the deals you like to your dealbox.
                                    </li>
                                </ol>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default BuyerLanding;
