import React, { useEffect, useRef, useState } from "react";
import { FaCheckCircle, FaWindowClose } from "react-icons/fa";
import { MdError, MdTipsAndUpdates } from "react-icons/md";
import $ from "jquery";
import { getAppFileByKey, getPrimaryStates, getPropertyCategories, getPropertyMarketTypes, getPropertyRoomCount, getUserInformation, registerBuyerforUser, uploadFiles } from "../../../api/api";
import { brandTypes, geographicRegion, marketTypes, opportunityType, opportunityTypes, prefferedBrands, propertyCategories, propertyRoomCount } from "../../common/utils";
import CurrencyInput from "react-currency-input-field";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import Select from 'react-select';
import CurrencyFormat from "react-currency-format";
import { NumericFormat } from 'react-number-format';
import { toast } from "react-toastify";
import { PiCurrencyDollarSimpleBold } from "react-icons/pi";
import { BsFillCalendar3WeekFill } from "react-icons/bs";
import TermsAndConditions from "./TermsAndConditions";
import { IoClose } from "react-icons/io5";
import { Terms_And_Conditions } from "../../../constants/constants";
import PdfFormFiller from "../../common/PdfFormFiller";


const AddBuyerCard = ({ changePage }) => {
    const [cashAvailable, setcashAvailable] = useState("");
    const [downPayment, setdownPayment] = useState("");
    const [totalAmount, settotalAmount] = useState("");
    const [closeDatePicker, setcloseDatePicker] = useState("");
    const [deadLine, setdeadLine] = useState("");
    const [closeDate, setcloseDate] = useState("");
    const [properties, setproperties] = useState("");
    const [proof, setproof] = useState("");
    const [description, setdescription] = useState("");
    const [addFile, setAddFile] = useState([]);
    const [uploadFileUrls, setuploadFileUrls] = useState();
    const [primaryStates, setPrimaryStates] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [docProofList, setDocProofList] = useState([]);
    const [accommodatorStatementList, setAccomodationStatementList] = useState([]);
    const [proofOfEscrowList, setProofOfEscrowList] = useState([]);
    const [additionalDocsList, setAdditionalDocsList] = useState([]);
    const [uploadButtonFlag, setUploadButtonFlag] = useState(false);
    const [accomUploadFlag, setAccomUploadFlag] = useState(false);
    const [proofOfEscrowUploadFlag, setProofOfEscrowFlag] = useState(false);
    const [additionalDocUploadFlag, setAdditionlDocUploadFlag] = useState(false);
    const [page, setPage] = useState(1);
    const [primaryStatesSelected, setPrimaryStatesSelected] = useState([]);
    const [geographicRegionSelected, setGeographicRegionSelected] = useState([]);
    const [brandScaleSelected, setBrandScaleSelected] = useState([]);
    const [preferredBrandsSelected, setPreferresBrandsSelected] = useState([]);
    const [hotelCategorySelected, setHotelCategorySelected] = useState([]);
    const [locationSelected, setLocationSelected] = useState([]);
    const [opportunitySelected, setOpportunitySelected] = useState([]);
    const [roomCountSelected, setRoomCountSelected] = useState([]);
    const [redirect_url, setRedirectUrl] = useState();
    const [TermsAndConditionsPdf, setTermsAndConditionsPdf] = useState();
    const [tcFormDetails, setTcFormDetail ] = useState({"Text-iTvuSuGI6-": "A Unique Fingerprint will be generated once you sign this agreement"});
    const [formState, setFormState] = useState({
        "dealStatus": "",
        "buyerName": "",
        "funds1031": "",
        "additionalFunds": "",
        "buyingPower": "",
        "buyerCompanyName" : "",
        "lastPropSalePrice": "",
        "noOfHotelsOwned": "",
        "closingDate1031": "",
        "deadLineDate45Days": "",
        "escrowClosingDate": "",
        "description": "",
        "primaryState": {
            "id": "",
            "code": "",
            "name": ""
        },
        "docProof": "",
        "isSBA7A504LoanEligible": false

    })
    const dateInputRef = useRef(null);



    const [user, setUser] = useState();

    const [formMessage, setFormMessage] = useState({
        escrowClosingDateString: ""
    });
    const [formValid, setFormValid] = useState({
        primaryState: null
    });
    const [formValidForSbmission, setFormValidForSumisson] = useState(false);


    let user_type = sessionStorage.getItem("user_type");

    useEffect(() => {
        localStorage.setItem("uploaded_file", JSON.stringify([0]));
        localStorage.setItem("uploadFileUrls", "");
        getPrimaryStates().then(states => setPrimaryStates(states))

        getUserInformation().then(user => {

            setUser(user?.data);
            { (user_type.includes("Broker") || user_type.includes("Agent")) ? <></> : handleBuyerDetails(user?.data) }

        }
        );

        handleDownload(Terms_And_Conditions, "pdf")
        let redirect_url = sessionStorage.getItem("user_type").includes('Broker') ? "/brokerDashboard" : "/";
        setRedirectUrl(redirect_url)

    }, []);

    const handleDateClick = () => {
        dateInputRef.current.focus();
    };

    //options for Primary_Status
    const states = [];
    states.push({ value: 0, label: "Any" })
    primaryStates.map((val) =>
        states.push({ value: val.id, label: val.name })
    );

    //options for Interested_Hotel_Type
    const Interested_Hotel_Type = [];
    brandTypes.forEach((val, key) =>
        Interested_Hotel_Type[key] = ({ value: val, label: val })
    );

    //options for Interested_Brands
    const Interested_Brands = [];
    brandTypes.forEach((val, key) =>
        Interested_Brands[key] = ({ value: val, label: val })
    );

    const geographic_Region = [];
    geographicRegion.forEach((val, key) =>
        geographic_Region[key] = ({ value: val, label: val })
    );

    const preffered_brands = [];
    prefferedBrands.forEach((val, key) =>
        preffered_brands[key] = ({ value: val, label: val })
    );

    const opportunity_types = [];
    opportunityTypes.forEach((val, key) =>
        opportunity_types[key] = ({ value: val, label: val })
    );


    const Interested_Category = [];
    propertyCategories.forEach((val, key) =>
        Interested_Category[key] = ({ value: val, label: val })
    );

    const Market_Types = [];
    marketTypes.forEach((val, key) =>
        Market_Types[key] = ({ value: val, label: val })
    );

    const Room_Counts = [];
    propertyRoomCount.forEach((val, key) =>

        Room_Counts[key] = ({ value: val, label: val })
    );


    const ArrayToString = (array) => {
        const result = array.join(', ');
        console.log(result)
        return result;
    }

    const handleMultiSelectChange = (name, value) => {
        let selected = []

        value?.map((val) => selected.push(val.value))


        console.log(selected)
        if (name == "interestedPrimaryStates") {
            if (value.length > 3) {
                return;
            } else if (selected.includes(0)) {
                selected = []
                states.map((val) => selected.push(val.value));
                setPrimaryStatesSelected({ value: 0, label: "Any" })
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            } else {
                setPrimaryStatesSelected(value)
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });


            }
        } else if (name == "interestedRoomCount") {

            if (value.length > 1) {
                return;
            } else if (value[0]?.label == "Any") {
                setRoomCountSelected({ value: 0, label: "Any" })
                setFormState({
                    ...formState,
                    [name]: "1-1000"
                });
            } else {
                setRoomCountSelected(value)
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });

            }
        }

        else if (name == "geographicRegion") {
            if (selected.includes("Any")) {
                selected = []
                geographic_Region.map((val) => selected.push(val.value));
                setGeographicRegionSelected([{ value: "Any", label: "Any" }])
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            } else {
                setGeographicRegionSelected(value)
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            }
        } else if (name == "interestedBrands") {
            if (selected.includes("Any")) {
                selected = []
                Interested_Brands.map((val) => selected.push(val.value));
                setBrandScaleSelected([{ value: "Any", label: "Any" }])
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            } else {
                setBrandScaleSelected(value)
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            }
        } else if (name == "preferredBrands") {
            if (selected.includes("Any")) {
                selected = []
                preffered_brands.map((val) => selected.push(val.value));
                setPreferresBrandsSelected([{ value: "Any", label: "Any" }])
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            } else {
                setPreferresBrandsSelected(value)
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            }
        } else if (name == "interestedCategory") {
            if (selected.includes("Any")) {
                selected = []
                Interested_Category.map((val) => selected.push(val.value));
                setHotelCategorySelected([{ value: "Any", label: "Any" }])
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            } else {
                setHotelCategorySelected(value)
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            }
        } else if (name == "interestedMarketType") {
            if (selected.includes("Any")) {
                selected = []
                Market_Types.map((val) => selected.push(val.value));
                setLocationSelected([{ value: "Any", label: "Any" }])
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            } else {
                setLocationSelected(value)
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            }
        } else if (name == "opportunityType") {
            if (selected.includes("Any")) {
                selected = []
                opportunity_types.map((val) => selected.push(val.value));
                setOpportunitySelected([{ value: "Any", label: "Any" }])
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            } else {
                setOpportunitySelected(value)
                setFormState({
                    ...formState,
                    [name]: selected.toString()
                });
            }
        }
        else {

            setFormState({
                ...formState,
                [name]: ArrayToString(selected)
            });
        }


        // console.log(formState)
    }

    function handleCurrencyChange(value, name) {
        console.log(value)

        if (value != undefined) {
            setFormState({
                ...formState,
                [name]: parseInt(value), buyingPower: getCalculations("buyingPower")
            });
        } else {
            setFormState({
                ...formState,
                [name]: "", buyingPower: getCalculations("buyingPower")
            });
        }

        console.log(formState)

    }


    function handleChange(evt) {
        evt.preventDefault()
        const value = evt?.target?.value;
        const name = evt?.target?.name;
        const form = formState

        if (name == "primaryState") {
            primaryStates.map(state => {

                if (state.id == value) {
                    setFormState({
                        ...formState,
                        primaryState: {
                            id: state.id,
                            name: state.name,
                            code: state.code
                        }
                    });
                }
            })

        } else if (name == "escrowClosingDate") {

            let deadline45 = getDateValues("deadLineDate45Days", value)
            let closing = getDateValues("closingDate1031", value)


            setFormState({
                ...formState,
                escrowClosingDate: value,
                deadLineDate45Days: deadline45,
                closingDate1031: closing,

            });


            // console.log(getDateValues("deadLineDate45Days", value))
            // setFormState({
            //     ...formState,
            //     escrowClosingDate: value, deadLineDate45Days: getDateValues("deadLineDate45Days", value), closingDate1031: getDateValues("closingDate1031", value)
            // })
        } else if (name == "noOfHotelsOwned") {
            if (value != "") {
                setFormState({
                    ...formState,
                    noOfHotelsOwned: parseInt(value)
                })
            } else {
                setFormState({
                    ...formState,
                    noOfHotelsOwned: ""
                })
            }
        } else if (name == "isSBA7A504LoanEligible") {
            console.log(evt.target.checked)
            setFormState({
                ...formState,
                isSBA7A504LoanEligible: evt.target.checked
            })
        }
        else {
            setFormState({
                ...formState,
                [name]: value
            });
        }


        console.log(formState)

    }

    const listItemStyle = {
        listStyleType: 'none',
        marginBottom: '10px', // Add additional styling as needed
    };


    const getFileName = (key) => {
        const parts = key.split('/');
        let filename = parts[parts.length - 1];
    
        filename = filename.split('.')[0]; // Truncate after first dot
        filename = filename.replace(/-/g, ' ')
            .replace(/%20/g, ' ')
            .replace(/,/g, ' ')
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/&/g, ' '); // Replace ampersand with underscore
    
        return filename;
    }

    const handleFileChange = (e, type) => {

        let name = e.target.name
        let fileList = e.target.files;
        let files = fileList ? [...fileList] : [];

        if (type == "accommodatorStatement") {
            setAccomUploadFlag(true)
        }
        if (type == "proofOfEscrow") {
            setProofOfEscrowFlag(true)
        }
        if (type == "additional") {
            setAdditionlDocUploadFlag(true)
        }

        setUploadButtonFlag(true)
        if (validateSelectedFile(files)) {
            handleUploadClick(name, files, type)
            $(`[name=${name}]`).removeClass("is-invalid");

        } else {
            $(`[name=${name}]`).addClass("is-invalid");
            setUploadButtonFlag(false)
        }


        // console.log(formState)

    };

    const validateSelectedFile = (files) => {
        const MIN_FILE_SIZE = 1024 // 1MB
        const MAX_FILE_SIZE = 30720 // 5MB
        let valid = true
        files.map(selectedFile => {

            const fileSizeKiloBytes = selectedFile.size / 1024

            console.log(fileSizeKiloBytes)
            if (fileSizeKiloBytes > MAX_FILE_SIZE) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File is too large!',
                    footer: '<span>File size should be less than 30MB</span>'
                })
                valid = false

            }
        })

        return valid
    };


    const handleRemoveFile = (fileName, key, type) => {
        let filesArray = formState[fileName]
        console.log(filesArray)
        let fileIndex = filesArray?.indexOf(key)
        if (fileIndex >= 0) {
            filesArray.splice(fileIndex, 1);
        }

        
        setFormState({
            ...formState,
            [fileName]: filesArray
        })


        if (fileName == "docProofs") {

            let fileIndex = docProofList.findIndex(file => file.key == key);


            if (fileIndex >= 0) {
                docProofList.splice(fileIndex, 1);
            }

        }

        if (type == "accommodatorStatement") {

            let fileIndex = accommodatorStatementList.findIndex(file => file.key == key);

            if (fileIndex >= 0) {
                accommodatorStatementList.splice(fileIndex, 1);
            }

            setAccomodationStatementList(accommodatorStatementList)
        }
        if (type == "proofOfEscrow") {
            let fileIndex = proofOfEscrowList.findIndex(file => file.key == key);

            if (fileIndex >= 0) {
                proofOfEscrowList.splice(fileIndex, 1);
            }

            setProofOfEscrowList(proofOfEscrowList)
        }
        if (type == "additional") {
            let fileIndex = additionalDocsList.findIndex(file => file.key == key);

            if (fileIndex >= 0) {
                additionalDocsList.splice(fileIndex, 1);
            }
            setAdditionalDocsList(additionalDocsList);
        }

    }

    // const handleUploadClick = async (name, files, type) => {
    //     // setUploadButtonFlag(false)
    //     let fileObj = {
    //         id: "",
    //         key: ""
    //     }
    //     let prevDocs = []
    //     if (name == "docProof") {

    //         // let files = docProofList ? [...docProofList] : [];
    //         for (var file of files) {
    //             console.log(file)
    //             try {

    //                 const res = await uploadFiles(file, "escrowProof")
    //                 { res.status == 200 ? setUploadButtonFlag(false) : <></> }
    //                 if (res) $('#docProof').addClass('is-valid')
    //                 fileObj = {
    //                     id: res?.data?.fileName,
    //                     key: res?.data?.key
    //                 }

    //                 // prevDocs =  docProofList != '' ? docProofList : [] 

    //                 prevDocs.push(fileObj)

    //                 setUploadButtonFlag(false)

    //                 setTimeout(() => {
    //                     if (res) $('#docProof').removeClass('is-valid')
    //                     document.getElementById("docProof").value = "";
    //                     setUploadButtonFlag(false)
    //                 }, 2000)


    //             } catch (error) {
    //                 $('#docProof').addClass('is-invalid')
    //             }

    //         }
    //         setDocProofList(prevDocs)

    //         if (type == "accommodatorStatement") {
    //             let Docs = accommodatorStatementList
    //             Docs.push(prevDocs)
    //             console.log(Docs)
    //             setAccomodationStatementList(Docs)
    //             setAccomUploadFlag(false)
    //         }
    //         if (type == "proofOfEscrow") {
    //             let Docs = proofOfEscrowList
    //             Docs.push(prevDocs)
    //             setProofOfEscrowList(Docs)
    //             setProofOfEscrowFlag(false)
    //         }
    //         if (type == "additional") {
    //             let Docs = additionalDocsList
    //             Docs.push(prevDocs)
    //             setAdditionalDocsList(Docs);
    //             setAdditionlDocUploadFlag(false)
    //         }
    //         console.log(prevDocs)
    //         if (docProofList.length <= 25) {
    //             let docs =[]
    //             prevDocs.map(fileObj => {
    //                 console.log(fileObj)
    //                 docs.push(fileObj.key.toString())

    //             })

    //             console.log(docs.toString())
    //             console.log(formState.docProof)

    //             formState?.docProof != null && formState?.docProof != '' ?
    //                 setFormState({
    //                     ...formState,
    //                     docProof: formState?.docProof + ',' + docs.toString()
    //                 })
    //                 :
    //                 setFormState({
    //                     ...formState,
    //                     docProof: fileObj.key.toString()
    //                 })


    //         } else {
    //             Swal.fire(
    //                 "Images Limit Reached!!",
    //                 "Maximum of 25 images can be uploaded",
    //                 "warning"
    //             );
    //         }
    //         console.log(formState)
    //     }
    // }

    const handleUploadClick = async (name, files, type) => {
        let prevDocs = docProofList;
        let presentDocs = []

        if (name === "docProofs") {
            for (var file of files) {
                try {
                    const res = await uploadFiles(file, "escrowProof");

                    if (res.status === 200) {
                        setUploadButtonFlag(false);
                        $('#docProof').addClass('is-valid');

                        const fileObj = {
                            fileName: res?.data?.fileName,
                            key: res?.data?.key,
                            user:{
                                id: user?.id
                            }
                        };

                        prevDocs.push(fileObj);
                        presentDocs.push(fileObj)

                        setUploadButtonFlag(false);

                        setTimeout(() => {
                            $('#docProof').removeClass('is-valid');
                            document.getElementById("docProof").value = "";
                            setUploadButtonFlag(false);
                        }, 2000);
                    }
                } catch (error) {
                    $('#docProof').addClass('is-invalid');
                }
            }

            setDocProofList(prevDocs);

            // Handle the array based on the document type
            if (type === "accommodatorStatement") {
                setAccomodationStatementList([...accommodatorStatementList, ...presentDocs]);
                setAccomUploadFlag(false);
            }
            if (type === "proofOfEscrow") {
                setProofOfEscrowList([...proofOfEscrowList, ...presentDocs]);
                setProofOfEscrowFlag(false);
            }
            if (type === "additional") {
                setAdditionalDocsList([...additionalDocsList, ...presentDocs]);
                setAdditionlDocUploadFlag(false);
            }

            if (docProofList.length <= 25) {
                // const keys = prevDocs.map(fileObj => fileObj.key.toString()).join(',');

                setFormState({
                    ...formState,
                    docProofs: prevDocs
                });
            } else {
                Swal.fire(
                    "Images Limit Reached!!",
                    "Maximum of 25 images can be uploaded",
                    "warning"
                );
            }

            // console.log(formState);
        }
    };




    const getDateValues = (name, value) => {

        var date = new Date(value);

        if (name == "deadLineDate45Days") {

            const date = new Date(value);
            const newDate = new Date(date.getTime() + (45 * 24 * 60 * 60 * 1000));
            return newDate.toISOString().slice(0, 10); // returns date in 'YYYY-MM-DD' format

        }
        if (name == "closingDate1031") {

            const date = new Date(value);
            const newDate = new Date(date.getTime() + (180 * 24 * 60 * 60 * 1000));
            return newDate.toISOString().slice(0, 10); // returns date in 'YYYY-MM-DD' format

        }


    }

    const getCalculations = (name) => {

        if (name === "buyingPower")

            return parseInt((parseInt(formState.funds1031 ? formState.funds1031 : 0) + parseInt(formState.additionalFunds ? formState.additionalFunds : 0)) * 5);

    }

    const handlePrevious = (e) => {
        e.preventDefault();

        if (validateForm("check", 0)) {
            setPage(page - 1)
            changePage(page - 1)
        } else {
            $('#primaryState').addClass('is-invalid')
        }
    }

    const handleNext = (e) => {
        e.preventDefault();

        if (validateForm("check", 0)) {
            setPage(page + 1)
            changePage(page + 1)
        } else {
            $('#primaryState').addClass('is-invalid')
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        let form = formState;

        let user_buyers = JSON.parse(sessionStorage.getItem("user_buyers"));
        if (validateForm("check", 0)) {
            let res = await registerBuyerforUser(form).then(res => {
                let buyerid = res.data.id;
                if (res.status === 200) {
                    user_buyers = user_buyers + 1;
                    sessionStorage.setItem("user_buyers", user_buyers);
                    sessionStorage.setItem("user_buyer_id", res.data.id);
                    Swal.fire({
                        title: 'Your information is being verified.',
                        html: `<div>
                                <h3 className="font-size-primary-24"> You will be notified once upon approval.</h3>   
                                </br>    
                                <h4> You can contact us on </h4>    
                                <h4><b>info@1031hotels.com</b></h4>
                                <h4><b>661-808-4998</b></h4>
                            </div>
                    `,
                        icon: 'success',
                        showCloseButton: false,
                        showCancelButton: false,
                        focusConfirm: false,
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        let userType = sessionStorage.getItem("user_type");

                        if (userType.includes("Broker")) {
                            window.location.href = "/buyerPost/" + res.data.id
                        } else {
                            window.location.href = `/buyerTutorial/` + + res.data.id
                        }
                    })
                } else {

                    window.location = "/addbuyer";
                    toast.error(res.data?.message,
                        {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                }
            })

        } else {
            $('#primaryState').addClass('is-invalid')
        }
    }

    const validateForm = (name, value) => {
        name = 'check'
        let fieldName = ""
        var validity = formValid
        var messages = formMessage

        if (name == "primaryState" || name == "check") {
            if (value == "" || name == "check") {
                if (formState.primaryState?.id === "") {
                    messages.primaryState = "Enter the field";
                    validity.primaryState = false;
                    $('#primaryState').addClass('is-invalid')
                }
                else {
                    messages.primaryState = "";
                    validity.primaryState = true;
                }
            }
        }

            
        if (value == "" || name == "check") {
            if (formState.buyerName === "") {
                messages.buyerName = "Enter the field";
                validity.buyerName = false;
                $('#buyerName').addClass('is-invalid')
            }
            else {
                messages.buyerName = "";
                validity.buyerName = true;
            }
        }

        if (formState.buyerCompanyName == "") {
            messages.buyerCompanyName = "Enter Buyer Company Name";
            validity.buyerCompanyName = false;
            $('#buyerCompanyName').addClass('is-invalid')
        }
        else {
            messages.buyerCompanyName = "";
            validity.buyerCompanyName = true;
        }
        

        if (formState.funds1031 === "") {
            messages.funds1031 = "Please enter proper amount";
            validity.funds1031 = false;
            $('#funds1031').addClass('is-invalid')
        }
        else {
            messages.funds1031 = "";
            validity.funds1031 = true;
        }

        if (name == "escrowClosingDateString" || name == "check") {
            if (fieldName == "line1" || name == "check") {
                if (formState.escrowClosingDateString === "") {
                    messages.escrowClosingDateString = "Enter the field";
                    validity.escrowClosingDateString = false;
                    $('#escrowClosingDateString').addClass('is-invalid')
                }
                else {
                    messages.escrowClosingDateString = "";
                    validity.escrowClosingDateString = true;
                }
            }
        }

        if (name == "escrowClosingDateString" || name == "check") {
            if (fieldName == "line1" || name == "check") {
                if (formState.escrowClosingDateString === "") {
                    messages.escrowClosingDateString = "Enter the field";
                    validity.escrowClosingDateString = false;
                    $('#escrowClosingDateString').addClass('is-invalid')
                }
                else {
                    messages.escrowClosingDateString = "";
                    validity.escrowClosingDateString = true;
                }
            }
        }

        setFormMessage(messages);
        setFormValid(validity)
        let formready = "";
        if (!user_type.includes("Broker")) {
            formready = true
        } else {
            formready = validity.primaryState
        }

        setFormValidForSumisson(formready)
        return formready
    }


    var selectedFile;
    let fileName = "";

    $(document).on("change", "#upload_file", (e) => {
        fileName = "";
        selectedFile = e.currentTarget.files;
        if (document.getElementById("upload_file_btn").className === "disable") {
            document.getElementById("upload_file_btn").classList.remove("disable");
            document.getElementById("upload_file_btn").classList.add("enable");
        } else if (
            document.getElementsByName("error_icon")[0].className === "display"
        ) {
            document.getElementsByName("error_icon")[0].classList.remove("display");
            document.getElementById("upload_file_btn").classList.add("enable");
        } else document.getElementById("upload_file_btn").classList.add("enable");
        for (const file of e.currentTarget.files) {
            var sizeInMb = file.size / 1024;
            // console.log(sizeInMb)
            var sizeLimit = 800;
            if (sizeInMb > sizeLimit) {
                alert("File Should be less than 0.8 Mb")
            }
            else {
                fileName += `${file.name}` + "<i class='fas fa-check'></i>" + "<br>";
            }

        }
        document.getElementById("file_list").innerHTML = fileName;
    });

    $(document).one("click", "#upload_file_btn", async (e) => {
        var loader = document.getElementsByName("loading_icon");
        var success = document.getElementsByName("success_icon");
        var error = document.getElementsByName("error_icon");
        var fileList = document.getElementsByName("file_list");
        let res;

        if (document.getElementsByName("error_icon")[0].className === "display") {
            document.getElementsByName("error_icon")[0].classList.remove("display");
            document.getElementById("upload_file_btn").classList.add("enable");
        } else document.getElementById("upload_file_btn").classList.add("enable");

        setTimeout(() => {
            loader[0].classList.add("display");
        }, 2000)
        e.stopImmediatePropagation();
        const allPromises = [];

        if (selectedFile && selectedFile.length) {
            for (var file of selectedFile) {
                allPromises.push(uploadFiles(file, "escrowProof"));
            }
            try {
                res = res = await Promise.all(allPromises);
            } catch (error) {
                loader[0].classList.remove("display");
                res = error;
            }
        }

        for (const val in res) {
            if (res[val].data?.success === true) {
                setuploadFileUrls(uploadFileUrls + ',' + res[val].data?.fileURL)
                console.log(res[val].data?.fileURL)
                { localStorage.getItem("uploadFileUrls") != null ? localStorage.setItem("uploadFileUrls", res[val].data?.fileURL + ',' + localStorage.getItem("uploadFileUrls")) : localStorage.setItem("uploadFileUrls", res[val].data?.fileURL) }
                setTimeout(() => {
                    loader[0].classList.remove("display");
                    success[0].classList.add("display");
                }, 2000);
                setTimeout(() => {
                    // success[0].classList.remove("display");
                    fileList[0].classList.add("success");
                    document.getElementById("upload_file_btn").classList.remove("enable");
                    document.getElementById("upload_file_btn").classList.add("disable");
                }, 5000);
            } else {
                setTimeout(() => {
                    loader[0].classList.remove("display");
                    error[0].classList.add("display");
                    document.getElementById("upload_file_btn").classList.remove("add");
                }, 2000);
            }
        }
    });

    const handleBuyerDetails = (user) => {
        setFormState({
            ...formState,
            buyerName: user?.firstName + ' ' + user?.lastName, primaryState: user?.stateLicensed,
            buyerMobileNumber: user?.mobileNumber,
            buyerEmail: user?.email,
            buyerCompanyName : user?.companyName
        })
        setFormValid({
            ...formValid,
            primaryState: true
        })

    }

    const handleDownload = async (key, type) => {
        try {
            await getAppFileByKey(key).then(res => {
                const bytes = new Uint8Array(res.data);
                setTermsAndConditionsPdf(bytes)

            });
        } catch (error) {
            console.error("Error downloading file:", error);
            alert("Error downloading file");
        }
    };

    return (


        <>
            {page === 1 && (
                <>

                    <h2 className="d-flex justify-content-center py-2 fill-text-black">CREATE YOUR 1031 BUYER PROFILE</h2>
                    <div className="form-body">
                        <div className="row mobile-column-flex-inverted">
                            <div className="col">
                                <div className={"row"}>
                                    <div className="form-group col-12">
                                        <label>BUYER'S NAME<sup>*</sup></label>
                                        <input id="buyerName" className="form-input" name='buyerName' disabled={(user?.userTypes.includes("Broker") || user?.userTypes.includes("Agent")) ? false : true} value={formState?.buyerName} onChange={handleChange} required="" maxLength={128}>
                                        </input>
                                        <div className="invalid-feedback">
                                            Please Enter Buyer's Name
                                        </div>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className="form-group col-12">
                                        <label>BUYERS'S HOME STATE<sup>*</sup></label>
                                        <select id="primaryState" name="primaryState" className="form-input form-select select-state" disabled={user?.userTypes == "Buyer"} onChange={handleChange} value={user?.userTypes == "Buyer" ? user?.stateLicensed?.id : formState?.primaryState?.id}>
                                            <option id={0} hidden>Please Choose</option>
                                            {primaryStates.map(state => {
                                                return <option id={state.id} name={state.name} value={state.id}>{state.name}</option>
                                            })}
                                        </select>
                                        <div className="invalid-feedback">
                                            Select Your Buyers Home State
                                        </div>
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className="form-group col-12">
                                    <label>BUYER'S COMPANY NAME<sup>*</sup></label>
                                        <input id="buyerCompanyName" className="form-input" name='buyerCompanyName' disabled={(user?.userTypes.includes("Broker") || user?.userTypes.includes("Agent")) ? false : true} value={formState?.buyerCompanyName} onChange={handleChange} required="" maxLength={128}>
                                        </input>
                                        <div className="invalid-feedback">
                                            Please Enter Buyer's Company Name
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col py-0" >
                                <div className="tip-area" style={{ border: '5px solid red' }}>
                                    <div className="card">
                                        <div className="card-body d-flex flex-column">
                                            <div className="row py-2">
                                                <div className="col font-size-18 fit-content-max px-1">
                                                    <MdTipsAndUpdates></MdTipsAndUpdates>
                                                </div>
                                                <div className="col">
                                                    <label>
                                                        Youʼll receive a confidential 1031 Buyer
                                                        Profile ID to keep everything confidential
                                                        till a deal is made.</label>
                                                </div>
                                                <div className="col " style={{ width: "100px", maxWidth: 'fit-content', minHeight: 'fit-content' }}>
                                                    <div className="row id-area ">
                                                        <h4 className="px-2">TX93B</h4>
                                                    </div>
                                                    <div className="row py-2">
                                                        <span className="fill-label-color "><b>Example of Profile ID</b></span>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row py-2 px-4 red-label fill-label-red">

                                                <label className="fill-label-red">All <b>1031 Buyer Profile IDs</b> will expire
                                                    automatically with their 1031 Exchange.
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row mobile-column-flex"}>

                            <div className="form-group col-6">
                                <label>HOW MUCH IS IN YOUR 1031 EXCHANGE<sup>*</sup></label>
                                <div className="input-container d-flex align-item-center">
                                    <span class="currency-symbol"><PiCurrencyDollarSimpleBold></PiCurrencyDollarSimpleBold></span>
                                    <CurrencyInput
                                        displayType={'text'}
                                        className="form-input currency-input remove-left-border-radius"
                                        name="funds1031"
                                        id="funds1031"
                                        placeholder="From Your 1031 Exchange"
                                        onValueChange={handleCurrencyChange}
                                        value={formState?.funds1031}
                                    />
                                </div>
                                {formValid?.funds1031 ?
                                <div className="invalid-feedback-span">Please enter a proper amount.</div>
                                :<></> }
                            </div>
                            <div className="form-group col-6">
                                <label>ADDITIONAL DOWNPAYMENT AVAILABLE TOWARDS YOUR PURCHASE<sup>*</sup></label>
                                <div className="input-container d-flex align-item-center">
                                    <span class="currency-symbol"><PiCurrencyDollarSimpleBold></PiCurrencyDollarSimpleBold></span>
                                    <CurrencyInput
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        className="form-input currency-input remove-left-border-radius"
                                        name="additionalFunds"
                                        id="additionalFunds"
                                        placeholder="Available Cash"
                                        maxLength={128}
                                        onValueChange={handleCurrencyChange}
                                        value={formState?.additionalFunds}
                                    />
                                </div>
                                <div className="invalid-feedback">Please enter a proper amount.</div>
                            </div>
                        </div>

                        <div className="row mobile-column-flex">
                            <div className="form-group col-6">
                                <label>WHAT PRICE DID YOU SELL YOUR HOTEL FOR</label>
                                <div className="input-container d-flex align-item-center">
                                    <span class="currency-symbol"><PiCurrencyDollarSimpleBold></PiCurrencyDollarSimpleBold></span>
                                    <CurrencyInput
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        className="currency-input form-input remove-left-border-radius"
                                        name="lastPropSalePrice"
                                        id="lastPropSalePrice"
                                        placeholder="Final sale of your hotel sold"
                                        maxLength={128}
                                        onValueChange={handleCurrencyChange}
                                        value={formState?.lastPropSalePrice}
                                    />
                                </div>
                                <div className="invalid-feedback">Please enter a proper amount.</div>
                            </div>
                            <div className="form-group col-6">
                                <label>YOUR TOTAL BUYING POWER WITH 20% DOWN</label>
                                <div className="input-container d-flex align-item-center">
                                    <span class="currency-symbol"><PiCurrencyDollarSimpleBold></PiCurrencyDollarSimpleBold></span>
                                    <CurrencyInput
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        className="currency-input form-input remove-left-border-radius"
                                        name="buyingPower"
                                        id="buyingPower"
                                        maxLength={128}
                                        onValueChange={handleCurrencyChange}
                                        disabled={true}
                                        value={formState.buyingPower}
                                    />
                                </div>
                                <div className="invalid-feedback">Please enter a proper amount.</div>
                            </div>
                        </div>

                        <div className="row mobile-column-flex">
                            <div className="form-group col-4" id="dateinput">
                                <label>ESCROW CLOSED / ESCROW CLOSING DATE<sup>*</sup></label>
                                <div className="input-container d-flex align-item-center" onClick={handleDateClick}>
                                    <span class="calendar-symbol"><BsFillCalendar3WeekFill></BsFillCalendar3WeekFill></span>
                                    <input
                                        type="date"
                                        id="escrowClosingDate"
                                        name="escrowClosingDate"
                                        ref={dateInputRef}
                                        className={formValid.loanType == null || formValid?.loanType ? "form-input form-input-date remove-left-border-radius" : "form-input form-input-date is-invalid remove-left-border-radius"}
                                        onChange={handleChange}
                                        value={formState.escrowClosingDate}
                                    />
                                </div>

                                <div className="invalid-feedback">
                                    {!formValid.loanType ? (
                                        <>Please Enter the Field</>
                                    ) : null}
                                </div>
                            </div>

                            <div className="form-group col-4">
                                <label>45-DAY DEADLINE</label>
                                <input
                                    type="date"
                                    className="form-input"
                                    id="deadLineDate45Days"
                                    name="deadLineDate45Days"
                                    disabled={"disabled"}
                                    placeholder="45-Day Deadline Date"
                                    onChange={handleChange}
                                    maxLength={128}
                                    value={formState.deadLineDate45Days}
                                />
                            </div>
                            <div className="form-group col-4">
                                <label>180-DAY DEADLINE</label>
                                <input
                                    type="date"
                                    className="form-input"
                                    id="closingDate1031"
                                    name="closingDate1031"
                                    disabled={"disabled"}
                                    placeholder="180-Day Escrow close Date"
                                    onChange={handleChange}
                                    maxLength={128}
                                    value={formState.closingDate1031}
                                />
                            </div>
                        </div>

                        <div className="row justify-content-start mobile-column-flex">
                            <div className="form-group col-4">
                                <label>TOTAL PROPERTIES OWNED</label>
                                <input
                                    type="text"
                                    className="form-input"
                                    id="noOfHotelsOwned"
                                    name="noOfHotelsOwned"
                                    maxLength={128}
                                    onChange={handleChange}
                                    value={formState.noOfHotelsOwned}
                                />
                                <div className="invalid-feedback">Please enter proper number</div>
                            </div>

                            <div className="form-group col-4 justify-content-start align-item-center">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="isSBA7A504LoanEligible"
                                        id="isSBA7A504LoanEligible"
                                        onChange={handleChange}
                                        checked={formState.isSBA7A504LoanEligible}
                                    />
                                    <label >
                                        I am eligible for SBA7A/504 type of loan
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div className="form-group">
                            <div id="spinner" className="spinner"></div>
                        </div>


                    </div>
                    <br></br>
                    <div className='row' style={{ width: "25%", float: 'right', textAlign: "right" }} >
                        <div className=' col-8'>
                            <button className='btn-outline-cancel' ariaDisabled="true">
                                <a href={user_type.includes('Broker') ? "/brokerDashboard" : "/"} className="fill-label-red">CANCEL</a>
                            </button>
                        </div>

                        <div className='col-4'>
                            <button className='btn btn-lg btn-solid' onClick={handleNext} ariaHidden="false">
                                <span style={{ color: "white", textDecoration: "none" }}>NEXT</span>
                            </button>
                        </div>
                    </div>
                </>
            )}

            {
                page === 2 && (
                    <>
                        <h2 className="d-flex justify-content-center py-2">TELL US ABOUT THE IDEAL HOTEL FOR YOUR 1031 EXCHANGE</h2>
                        <div className='card-body' >
                            <div className="form-body">
                                <div
                                    className={"row"}
                                    id="searchcriteria-header"
                                    name="searchcriteria-header"
                                >
                                    <div className="form-group col-6 ">
                                        <label>YOUR MAXIMUM BUYING POWER WITH 20% DOWN (1031 EXCHANGE + ADDITIONAL CASH)</label>
                                        <div className="input-container d-flex align-item-center">
                                            <span class="currency-symbol"><PiCurrencyDollarSimpleBold></PiCurrencyDollarSimpleBold></span>
                                            <CurrencyFormat
                                                className="currency-input form-input remove-left-border-radius"
                                                displayType={'text'} thousandSeparator={true}
                                                name="buyingPower"
                                                id="buyingPower"
                                                required=""
                                                style={{ backgroundColor: "#e9ecef" }}
                                                disabled={true}
                                                value={formState?.buyingPower ? formState?.buyingPower : 0}
                                            />

                                        </div>
                                        <label style={{ float: 'right' }}>(To change your buying power hit the back button)</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12 col-lg-6">
                                        <label>WHAT STATES ARE YOU LOOKING TO BUY A HOTEL (SELECT UP TO 3)</label>
                                        <Select
                                            id="interestedPrimaryStates"
                                            name="interestedPrimaryStates"
                                            value={primaryStatesSelected}
                                            //   className="form-input form-select select-state"
                                            onChange={(e) => {
                                                handleMultiSelectChange("interestedPrimaryStates", e)

                                            }}
                                            options={states}
                                            isMulti

                                        >

                                        </Select>
                                    </div>

                                    <div className="form-group col-12 col-lg-6">
                                        <label>GEOGRAPHIC REGION</label>
                                        <Select
                                            id="geographicRegion"
                                            name="geographicRegion"
                                            value={geographicRegionSelected}
                                            onChange={(e) => {
                                                // handleChange(e)
                                                handleMultiSelectChange("geographicRegion", e)
                                                console.log(e)
                                            }}
                                            isMulti
                                            options={geographic_Region.sort((a, b) => a.label - b.label)}
                                        >
                                        </Select>
                                    </div>

                                </div>


                                <div className="row">
                                    <div className="form-group col-12 col-lg-6">
                                        <label>WHAT BRAND SCALE OF HOTEL ARE YOU LOOKING FOR</label>
                                        <Select
                                            // className="form-input form-select "
                                            id="interestedBrands"
                                            name="interestedBrands"
                                            value={brandScaleSelected}
                                            onChange={(e) => {
                                                // handleChange(e)
                                                handleMultiSelectChange("interestedBrands", e)
                                                console.log(e)
                                            }}
                                            isMulti
                                            options={Interested_Brands.sort((a, b) => a.label - b.label)}

                                        >
                                        </Select>
                                    </div>

                                    <div className="form-group col-12 col-lg-6">
                                        <label>WHAT ARE YOUR PREFERRED BRANDS</label>
                                        <Select id="preferredBrands"
                                            name="preferredBrands"
                                            // className="form-input form-select"
                                            value={preferredBrandsSelected}
                                            onChange={(e) =>
                                                handleMultiSelectChange("preferredBrands", e)
                                            }
                                            isMulti
                                            options={
                                                preffered_brands.sort((a, b) => a.label - b.label)
                                            }
                                        >
                                        </Select>
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="form-group col-12 col-lg-6">
                                        <label>HOTEL CATEGORY</label>
                                        <Select id="interestedCategory"
                                            name="interestedCategory"
                                            value={hotelCategorySelected}
                                            // className="form-input form-select"
                                            onChange={(e) =>
                                                handleMultiSelectChange("interestedCategory", e)
                                            }
                                            isMulti
                                            options={
                                                Interested_Category.sort((a, b) => a.label - b.label)
                                            }
                                        >
                                        </Select>
                                    </div>

                                    <div className="form-group col-12 col-lg-6">
                                        <label>LOCATION / MARKET TYPE</label>
                                        <Select id="interestedMarketType"
                                            // className="form-input form-select" 
                                            name="interestedMarketType"
                                            value={locationSelected}
                                            onChange={(e) =>
                                                handleMultiSelectChange("interestedMarketType", e)
                                            }
                                            isMulti
                                            options={Market_Types.sort((a, b) => a.label - b.label)}
                                        >

                                        </Select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-12 col-lg-6">
                                        <label>WHAT TYPE OF OPPORTUNITY ARE YOU LOOKING FOR</label>
                                        <Select id="opportunityType"
                                            // className="form-input form-select" 
                                            name="opportunityType"
                                            value={opportunitySelected}
                                            onChange={(e) =>
                                                handleMultiSelectChange("opportunityType", e)
                                            }
                                            isMulti
                                            options={opportunity_types.sort((a, b) => a.label - b.label)}
                                        >

                                        </Select>
                                    </div>
                                    <div className="form-group co-12 col-lg-6">
                                        <label>ROOM COUNT</label>
                                        <Select id="interestedRoomCount"
                                            // className="form-input form-select" 
                                            value={roomCountSelected}
                                            onChange={(e) => {
                                                handleMultiSelectChange("interestedRoomCount", e)
                                            }}
                                            name="interestedRoomCount"
                                            isMulti
                                            options={Room_Counts.sort((a, b) => a.label - b.label)}
                                        >
                                        </Select>
                                    </div>
                                </div>

                                <br></br>
                                <div className="form-group mb-0">
                                    <label>DESCRIBE YOUR PERFECT DEAL</label>
                                    <textarea className="form-input" id='additionalInformation' name="additionalInformation" onChange={handleChange} required=""></textarea>
                                    <div className="invalid-feedback">What do you wanna say?</div>
                                </div>
                            </div>
                        </div>
                        <div className='row' style={{ width: "40%", float: 'right', textAlign: "right", marginTop: "10px" }} >
                            <div className='form-group  col'>
                                <button className='btn-outline-cancel' ariaDisabled="true">
                                    <a href={redirect_url} className="fill-red">CANCEL</a>
                                </button>
                            </div>

                            <div className='form-group  col'>
                                <button className='btn-outline-primary' onClick={handlePrevious} ariaDisabled="true">
                                    <a className="fill-label-blue font-bold">BACK</a>
                                </button>
                            </div>

                            <div className='form-group col'>
                                <button className='btn btn-lg btn-solid' onClick={handleNext} ariaHidden="false">
                                    <span style={{ color: "white", textDecoration: "none" }}>NEXT</span>
                                </button>
                            </div>


                        </div>
                    </>
                )
            }

            {
                page === 3 && (
                    <>
                        <div className='form-body'>
                            <div className="row">

                                <div className="form-group col-12">
                                    <>
                                        <label>
                                            UPLOAD YOUR 1031 EXCHANGE BALANCE OR STATEMENT
                                        </label>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <div className="upload-wrapper mb-4">
                                                    <input
                                                        type="file"
                                                        accept=".pdf, .xlsx"
                                                        className="form-input"
                                                        id="docProof"
                                                        name="docProofs"
                                                        multiple="multiple"
                                                        onChange={(e) => handleFileChange(e, "accommodatorStatement")}
                                                    />
                                                    {accomUploadFlag ? <div className="display" id="loading" name="loading_icon"></div> : <></>}
                                                    <div id="error" name="error_icon">
                                                        Unable to uplaod, try again <MdError />
                                                    </div>
                                                    <div id="file_list" name="file_list">
                                                        <>
                                                            {formState?.docProofs != null && formState?.docProofs != '' && accommodatorStatementList.map((file) => {
                                                                return <>
                                                                    <ul style={listItemStyle}>
                                                                        <li className=" px-1 " >
                                                                            {file?.fileName}
                                                                            <button type="button" className="px-0 cross-button" onClick={(e) => handleRemoveFile("docProofs", file.key, "accommodatorStatement")}><IoClose className="font-sixe-18 fill-red" /></button>
                                                                        </li>
                                                                    </ul>

                                                                </>
                                                            })}
                                                        </>
                                                    </div>
                                                    <div className="invalid-feedback">Invalid file format</div>
                                                </div>
                                            </div>
                                            <div className=" col-6 px-4 py-2">
                                                <h6>This is critical and needed to verify your 1031 Exchange and Entity name</h6>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                            <div className="row">

                                <div className="form-group col-12">
                                    <>
                                        <label>
                                            PROOF OF ESCROW (IF YOUR DEAL HAS NOT CLOSED YET)
                                        </label>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <div className="upload-wrapper mb-4">
                                                    <input
                                                        type="file"
                                                        accept=".pdf, .xlsx"
                                                        className="form-input"
                                                        id="docProof"
                                                        name="docProofs"
                                                        multiple="multiple"
                                                        onChange={(e) => handleFileChange(e, "proofOfEscrow")}
                                                    />
                                                    {proofOfEscrowUploadFlag ? <div className="display" id="loading" name="loading_icon"></div> : <></>}
                                                    <div id="error" name="error_icon">
                                                        Unable to uplaod, try again <MdError />
                                                    </div>
                                                    <div id="file_list" name="file_list">
                                                        <>
                                                            {formState?.docProofs != null && formState?.docProofs != '' && proofOfEscrowList.map((file) => {
                                                                return <>
                                                                    <ul style={listItemStyle}>
                                                                        <li className=" px-1 " >
                                                                            {file?.fileName}
                                                                            <button type="button" className="px-0 cross-button" onClick={(e) => handleRemoveFile("docProofs", file.key, "proofOfEscrow")}><IoClose className="font-size-18 fill-red" /></button>
                                                                        </li>
                                                                    </ul>

                                                                </>
                                                            })}
                                                        </>
                                                    </div>
                                                    <div className="invalid-feedback">Invalid file format</div>
                                                </div>
                                            </div>
                                            <div className="col-6 px-4 py-2">
                                                <h6>Please provide your Escrow receipt including contract date</h6>
                                            </div>
                                        </div>
                                    </>

                                </div>

                            </div>
                            <div className="row">

                                <div className="form-group col-12">
                                    <>
                                        <label>
                                            ADDITIONAL DOCUMENTS
                                        </label>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <div className="upload-wrapper mb-4">
                                                    <input
                                                        type="file"
                                                        accept=".pdf, .xlsx"
                                                        className="form-input"
                                                        id="docProof"
                                                        name="docProofs"
                                                        multiple="multiple"
                                                        onChange={(e) => handleFileChange(e, "additional")}
                                                    />
                                                    {additionalDocUploadFlag ? <div className="display" id="loading" name="loading_icon"></div> : <></>}
                                                    <div id="error" name="error_icon">
                                                        Unable to uplaod, try again <MdError />
                                                    </div>
                                                    <div id="file_list" name="file_list">
                                                        <>
                                                            {formState?.docProofs != null && formState?.docProofs != '' && additionalDocsList.map((file) => {
                                                                return <>
                                                                    <ul style={listItemStyle}>
                                                                        <li className=" px-1 " >
                                                                            {file?.fileName}
                                                                            <button type="button" className="px-0 cross-button" onClick={(e) => handleRemoveFile("docProof", file.key, "additional")}><IoClose className="font-size-18 fill-red" /></button>
                                                                        </li>
                                                                    </ul>
                                                                </>
                                                            })}
                                                        </>
                                                    </div>
                                                    <div className="invalid-feedback">Invalid file format</div>
                                                </div>
                                            </div>
                                            <div className="col-6 px-4 py-2">
                                                <h6>Please provide any other documents you want us to consider (including Proof of Funds)</h6>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>

                            <div className='row' style={{ width: "40%", float: 'right', textAlign: "right", marginTop: "10px" }} >
                                <div className='form-group  col'>
                                    <button className='btn-outline-cancel' ariaDisabled="true">
                                        <a href={redirect_url} className="fill-red">CANCEL</a>
                                    </button>
                                </div>

                                <div className='form-group  col'>
                                    <button className='btn-outline-primary' onClick={handlePrevious} ariaDisabled="true">
                                        <a className="fill-label-blue font-bold">BACK</a>
                                    </button>
                                </div>

                                <div className='form-group col'>
                                    <button className='btn btn-lg btn-solid' onClick={handleNext} ariaHidden="false">
                                        <span style={{ color: "white", textDecoration: "none" }}>NEXT</span>
                                    </button>
                                </div>


                            </div>
                        </div>

                    </>
                )}
            {
                page === 4 && (
                    <>


                        <div className="row" style={{ height: "80vh" }}>
                            <PdfFormFiller arrayBuffer={TermsAndConditionsPdf} fileType={"pdf"} formDetails={tcFormDetails} handleIAgree={""} handleCancel={""}></PdfFormFiller>
                            <br></br>
                            <div className='form-group'>
                                <button type='submit' className='btn btn-lg btn-solid float-r py-2 col-lg-3' onClick={(e) => handleSubmit(e)} ariaHidden="false">
                                    <span style={{ color: "white", textDecoration: "none" }}>Agree</span>
                                </button>
                            </div>
                        </div>

                    </>
                )}
        </>

    );


};

export default AddBuyerCard;
