import axios from "axios";
import { URL } from "../constants/constants";
import $ from 'jquery'

const instance = axios.create({
  baseURL: URL,  
});

// Request interceptor
instance.interceptors.request.use(
  function (config) { 
    if (!config.skipSpinner) {
      document.getElementById("loading_spinner").hidden = false;
    }
    return config;
  },
  function (error) {  
    document.getElementById("loading_spinner").hidden = false;
    return Promise.reject(error);  
  }
); 

// Response interceptor
instance.interceptors.response.use(
  function (response) {
    if (!response.config.skipSpinner) {
      document.getElementById("loading_spinner").hidden = true;
    }
    return response;
  },
  function (error) {
    document.getElementById("loading_spinner").hidden = true;
    return Promise.reject(error);
  }
);


export default instance; 
