import React, { useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import 'react-data-grid/lib/styles.css';
import { FaDownload } from 'react-icons/fa';
import ExcelViewer from "excel-viewer";

const FilePreview = ({ arrayBuffer, fileType }) => {
    const [fileURL, setFileURL] = useState('');
    const [iframeHeight, setIframeHeight] = useState('500px');
    const [spreadsheetData, setSpreadsheetData] = useState(null);
    const [rows, setRows] = useState();
    const [cols, setCols] = useState();
    const [blob, setBlob] = useState();


    const viewer = useRef(null);

    useEffect(() => {

        const updateIframeHeight = () => {
            const screenHeight = window.innerHeight || document.documentElement.clientHeight;
            const newHeight = screenHeight - 100;

            setIframeHeight(`${newHeight}px`);
        };

        window.addEventListener('resize', updateIframeHeight);
        updateIframeHeight();

        return () => {
            window.removeEventListener('resize', updateIframeHeight);
        };
    }, []);

    useEffect(() => {
        const blob = new Blob([arrayBuffer], { type: getFileType(fileType) });
        const url = URL.createObjectURL(blob);
        setBlob(blob)



        setFileURL(url);
        if (fileType != "pdf") {
            handleExcelExport(arrayBuffer);

        }

        return () => {
            URL.revokeObjectURL(url);
        };
    }, [arrayBuffer]);

    const getFileType = (extension) => {
        switch (extension) {
            case 'pdf':
                return 'application/pdf';
            case 'doc':
            case 'docx':
                return 'application/msword';
            case 'xls':
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            default:
                return 'application/octet-stream';
        }
    };

    const handleExcelExport = (arrayBuffer) => {

        // new ExcelViewer("#excel-view", arrayBuffer);
        // // setTimeout(()=>{
        // //     handleDownload()
        // // },2000)

        const data = Uint8Array.from(arrayBuffer);
        const content = new Blob([data.buffer], { type: getFileType(fileType) });

        const encodedUri = window.URL.createObjectURL(content);
        const link = document.createElement("a");

        link.setAttribute("href", encodedUri);
        // link.setAttribute("download", "downloads");

        link.click();


    };

    const handleDownload = () => {
        if (spreadsheetData) {
            const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const fileName = 'Report.xlsx';
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(data, fileName);
            } else {
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        }
    };

    const columns = spreadsheetData ? spreadsheetData[0].map((col, index) => ({
        Header: col.toString(),
        accessor: index.toString(),
    })) : [];

    return (
        <div>
            {fileType == 'pdf' ? (
                <div>
                    {fileURL ? (
                        <iframe src={fileURL} title="File Preview" width="100%" height={iframeHeight} />

                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            ) : (
                // <div className='d-flex flex-column w-100 view-height-90'>
                //     <div className='row justify-content-end px-4'>
                //         <button
                //             className="d-flex align-items-center btn btn-sm btn-primary mt-2 mb-2 ms-2 px-2 py-2"
                //             onClick={handleDownload}
                //         >
                //             <FaDownload />
                //         </button>
                //     </div>
                //     <div className='row view-height-90' style={{ overflow: 'scroll' }}>
                //         <div id="excel-view"></div>
                //     </div>

                // </div>
                <></>


            )}
        </div>
    );
};

export default FilePreview;
