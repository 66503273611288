import React, { useEffect, useState } from "react";
import { GetClientTokenCookie, setUserSession } from "../../common/commonUtils";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { notifyRegister, usStates } from "../../common/formUtils";
import "izitoast-react/dist/iziToast.css";
import InputMask from 'react-input-mask';
import { Breadcrumbs, Link, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import $ from "jquery"
import { getAppFileByKey, getPrimaryStates, registerUser, validateEmail } from "../../../api/api";
import { toast } from "react-toastify";
import Logo from '../../../img/home/1031LogoWhiteGLow.png';
import vectorImage from '../../../components/assets/Vector 1.png';
import TermsAndConditions from "./TermsAndConditions";
import { brokerDescription, brokerLookingFor, brokerTypes, hotelsSoldRanges } from "../../common/utils";
import { AUTH, CustomerSupportNumber, Terms_And_Conditions, loginuser } from "../../../constants/constants";
import axios from "axios";
import PdfFormFiller from "../../common/PdfFormFiller";
import { useNavigate } from "react-router-dom";

const BrokerRegistration = () => {
  const [primaryStates, setPrimaryStates] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [isValidMail, setIsValidMail] = useState();
  const [agreeTerms, setAgreeTerms] = useState();
  const [page, setPage] = useState(1);
  const [TermsAndConditionsPdf, setTermsAndConditionsPdf] = useState();
  const [tcFormDetails, setTcFormDetail] = useState({ "Text-iTvuSuGI6-": "A Unique Fingerprint will be generated once you sign this agreement" });
  const [formState, setFormState] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    confirmPassword: "",
    companyName: "",
    website: "",
    additionalInformation: "",
    stateLicensed: {
      id: "",
      name: "",
      code: "",
    },
    licenseNumber: "",
    userTypes: "Broker"
  });

  const [formValidity, setFormValidity] = useState({
    email: null,
    password: null,
    firstName: null,
    lastName: null,
    mobileNumber: null,
    confirmPassword: null,
    companyName: null,
    website: null,
    additionalInformation: null,
    licenseNumber: null,
    stateLicensed: {
      id: null,
      name: null,
      code: null,
    },
    type: {
      code: null
    }
  });

  const [formErrorMessage, setFormErrorMessage] = useState({
    email: "Email is mandatory",
    password: "",
    firstName: "",
    lastName: "",
    mobileNumber: "Phone Number is mandatory",
    confirmPassword: "Confirm Password is mandatory",
    companyName: "",
    website: "",
    additionalInformation: "",
    stateLicensed: {
      id: "",
      name: "",
      code: "",
    },
    type: {
      code: ""
    }
  });

  localStorage.setItem("registeringUserType", 'Broker');
  let navigate = useNavigate();

  useEffect(() => {
    getPrimaryStates().then(states => setPrimaryStates(states))



    const brokerError = localStorage.getItem("BrokerError");
    if (brokerError) {
      notifyRegister({
        errorName: "BrokerError",
        errorMessage: "Something went wrong",
      });
      localStorage.removeItem("BrokerError")
    }

  }, [])

  const handlePrevious = (e) => {
    e.preventDefault();
    setPage(page - 1)


  }

  const handleNext = (e) => {
    e.preventDefault();
    setPage(page + 1)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;


    if (name == "email") {

    } else if (name == "mobileNumber") {
      var unformattedMaskNumber = value.replace(/[\s()-]/g, '');

      setFormState((prevState) => ({
        ...prevState,
        mobileNumber: unformattedMaskNumber,
      }));
    } else if (name === "stateLicensed") {

      setFormState((prevState) => ({
        ...prevState,
        stateLicensed: primaryStates[value]
      }));

    } else if (name == "agreeterms") {
      console.log(e.target.checked)
      setAgreeTerms(e.target.checked)
    } else if (name == "companyName") {
      if (value.includes("Hotel Brokers of America", "HBA", "1031Agents")) {
        setFormState((prevState) => ({
          ...prevState,
          userType: "BROKER"
        }));
      }
    }
    else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    // e.preventDefault()
    console.log(validateRegisterUser(e))
    if (validateRegisterUser(e) && isValidMail) {

      if (formState.confirmPassword == formState.password) {
        let payload = formState;
        let res = await registerUser(payload)
        let user = res?.data
        if (res.status == 200) {
          localStorage.setItem("registered", "brokerRegistered");

          let username = payload?.email;
          let password = payload?.password;
          const data = new URLSearchParams({
            username,
            password,
          }).toString();
          var config = {
            method: "post",
            url: loginuser,
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Basic " + btoa(AUTH),
            },
            data: data,
          };

          await axios(config).then(res => {

            let token = res?.data?.access_token

            localStorage.setItem(
              "login_user_token",
              JSON.stringify(token)
            );
            let redirectURL = "";
            redirectURL = "/brokerTutorial";
            // redirectURL = "/brokerDashboard";
            setPage(page + 1)
            handleDownload(Terms_And_Conditions, "pdf")

            setUserSession(user.firstName, user.lastName, user.userTypes, user.id, user.email, user.mobileNumber, user.userId);
            sessionStorage.setItem("user_buyers", 0);
            sessionStorage.setItem("user_sellers", 0);
            // window.location = redirectURL;

          }).catch(function (error) {
            toast.error(error?.message,
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
          });

        } else {
          toast.error(res?.data?.message,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
        $("#confirmPassword").removeClass("is-invalid");
      } else {
        $("#confirmPassword").addClass("is-invalid");
      }
    };



  }

  function validateRegisterUser(event) {
    event.preventDefault();
    let name = event.target.name;
    let value = event.target.value;
    let state = formState
    let errorCount = 0;


    if (state.firstName == "") {
      setFormValidity((prevState) => ({
        ...prevState,
        firstName: false,
      }));
      $("#firstName").addClass("is-invalid");
      errorCount += 1
    } else {
      setFormValidity((prevState) => ({
        ...prevState,
        firstName: true,
      }));
      $("#firstName").removeClass("is-invalid");
    }

    if (state.lastName == "") {
      setFormValidity((prevState) => ({
        ...prevState,
        lastName: false,
      }));
      $("#lastName").addClass("is-invalid");
      errorCount += 1
    } else {
      setFormValidity((prevState) => ({
        ...prevState,
        lastName: true,
      }));
      $("#lastName").removeClass("is-invalid");
    }

    if (state.email == "" || !state.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setFormValidity((prevState) => ({
        ...prevState,
        email: false,
      }));
      $("#user-email").addClass("is-invalid");
      errorCount += 1
    } else {
      setFormValidity((prevState) => ({
        ...prevState,
        email: true,
      }));
      $("#user-email").removeClass("is-invalid");
    }

    if (state.mobileNumber == "") {
      setFormValidity((prevState) => ({
        ...prevState,
        mobileNumber: false,
      }));
      setFormErrorMessage((prevState) => ({
        ...prevState,
        mobileNumber: "Phone Number is mandatory",
      }));
      $("#mobileNumber").addClass("is-invalid");
      errorCount += 1

    } else if (!state.mobileNumber.match(/^[0-9]{10}$/)) {
      setFormValidity((prevState) => ({
        ...prevState,
        mobileNumber: false,
      }));
      setFormErrorMessage((prevState) => ({
        ...prevState,
        mobileNumber: "Please provide valid Phone Number",
      }));
      $("#mobileNumber").addClass("is-invalid");
      errorCount += 1

    } else {
      setFormValidity((prevState) => ({
        ...prevState,
        mobileNumber: true,
      }));
      $("#mobileNumber").removeClass("is-invalid");
    }


    if (state.password == "") {
      setFormValidity((prevState) => ({
        ...prevState,
        password: false,
      }));
      $("#password").addClass("is-invalid");
      errorCount += 1
    } else {
      setFormValidity((prevState) => ({
        ...prevState,
        password: true,
      }));
      $("#password").removeClass("is-invalid");
    }


    if (state.confirmPassword == "") {
      setFormValidity((prevState) => ({
        ...prevState,
        confirmPassword: false,
      }));
      setFormErrorMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Confirm Password is mandatory",
      }));
      $("#confirmPassword").addClass("is-invalid");
      errorCount += 1
    } else if (state.password != state.confirmPassword) {

      setFormValidity((prevState) => ({
        ...prevState,
        confirmPassword: false,
      }));
      setFormErrorMessage((prevState) => ({
        ...prevState,
        confirmPassword: "Password mismatch",
      }));
      $("#confirmPassword").addClass("is-invalid");
      errorCount += 1

    } else {
      setFormValidity((prevState) => ({
        ...prevState,
        confirmPassword: true,
      }));
      $("#confirmPassword").removeClass("is-invalid");
    }


    if (state.stateLicensed.id == "") {
      $("#stateLicensed").addClass("is-invalid");
      errorCount += 1
    } else {
      $("#stateLicensed").removeClass("is-invalid");
    }

    return errorCount == 0;
  }

  const checkEmailAvailability = async (e) => {

    let email = e.target.value;
    let name = e.target.name;

    if (email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {

      let res = await validateEmail(email);

      setIsValidMail(!res?.data?.isPresent);
      if (!res?.data?.isPresent) {
        setFormState((prevState) => ({
          ...prevState,
          [name]: email,
        }));

        $("#user-email").addClass("is-valid");
        $("#user-email").removeClass("is-invalid");

      } else {
        setFormValidity((prevState) => ({
          ...prevState,
          email: false,
        }));
        setFormErrorMessage((prevState) => ({
          ...prevState,
          email: "Email is already in use",
        }));

        $("#user-email").removeClass("is-valid");
        $("#user-email").addClass("is-invalid");
      }

    } else {

      setFormValidity((prevState) => ({
        ...prevState,
        email: false,
      }));
      setFormErrorMessage((prevState) => ({
        ...prevState,
        email: "Please provide valid email",
      }));

      $("#user-email").addClass("is-invalid");
      $("#user-email").removeClass("is-valid");

    }


  };

  const handleDownload = async (key, type) => {
    try {
      await getAppFileByKey(key).then(res => {
        const bytes = new Uint8Array(res.data);
        setTermsAndConditionsPdf(bytes)

      });
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("Error downloading file");
    }
  };

  return (
    <>
      <div className="container-common">
        <div className="left">
          <div className="box  justify-content-center w-100">
            <div className="status-nav d-flex justify-content-center align-start py-2 w-100" >
              <label className={page == 1 ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2"}>Broker Registration</label> : <></>

              <label className={page == 2 ? `font-weight-b fill-white px-2 highligter` : "font-weight-b fill-white px-2"}>Accept Terms and Conditions</label>

            </div>
            <img className='common-vector' src={vectorImage} alt="No Vector"></img>
          </div>

        </div>
        <div className="right">
          <h2>REGISTER AS A BUYER'S OR A SELLER'S BROKER</h2>
          <form
            id="broker_register_form_wizard"
            className="form-control h-100"
            method="POST"
            data-token={GetClientTokenCookie()}
          >
            <>
              <div className="form-body">
                {page == 1 ? (
                  <>
                    <div className={"row px-0"}>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          BROKER FIRST NAME<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          className="form-input"
                          name="firstName"
                          required=""
                          maxLength={128}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          First Name is mandatory
                        </div>
                      </div>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          BROKER LAST NAME<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-input"
                          id="lastName"
                          name="lastName"
                          required=""
                          maxLength={128}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Last Name is mandatory
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          COMPANY NAME<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-input"
                          id="companyName"
                          name="companyName"
                          required=""
                          maxLength={128}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Company Name is mandatory
                        </div>
                      </div>
                    </div>

                    <div className={"row"}>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          MOBILE NUMBER<sup>*</sup>
                        </label>
                        <InputMask
                          mask="(999) 999-9999"
                          maskChar=" "
                          className="phone form-input"
                          id="mobileNumber"
                          name="mobileNumber"
                          required
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          {formErrorMessage?.mobileNumber}
                        </div>
                      </div>
                      <div className="form-group col-12 col-lg-6">
                        <label>BROKER WEBSITE</label>
                        <input
                          type="text"
                          className="form-input"
                          name="website"
                          required=""
                          maxLength={128}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Broker Website is mandatory
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className="form-group col-12 col-lg-6">

                        <label>EMAIL (USERNAME)<sup>*</sup></label>
                        <input type="text"
                          id="user-email"
                          className={"form-input form-control"}
                          autoComplete="none"
                          name="email" required="" maxLength={128} onBlur={checkEmailAvailability} />
                        <div id="email-feedback" className="invalid-feedback">
                          {formErrorMessage?.email}
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          PASSWORD<sup>*</sup>
                        </label>
                        <input
                          type="password"
                          id="user-password"
                          name="password"
                          className="form-input"
                          autoComplete="none"
                          maxLength={128}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          Password is mandatory
                        </div>
                      </div>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          CONFIRM PASSWORD<sup>*</sup>
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          className="form-input"
                          autoComplete="none"
                          maxLength={128}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          {formErrorMessage.confirmPassword}
                        </div>
                      </div>
                    </div>
                    <div className={"row"}>
                      <div className="form-group col-12 col-lg-6">
                        <label>LICENSED STATE</label>

                        <select
                          className="form-input form-select select-state"
                          id="stateLicensed"
                          name="stateLicensed"
                          placeholder="Select State"
                          disabled={disableButton}
                          defaultValue={primaryStates[0]}
                          onChange={(event) =>
                            handleChange(event)
                          }
                        >
                          <option value="">Please Select</option>
                          {primaryStates.map((state, key) => {
                            return (
                              <option
                                id={key}
                                value={key}
                              >
                                {state.name} - {state.code}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          Please select your licensed state
                        </div>

                      </div>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          LICENSE NUMBER<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-input"
                          id="licenseNumber"
                          name="licenseNumber"
                          required=""
                          maxLength={15}
                          onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                          License Number is mandatory
                        </div>
                      </div>

                      <div className="form-group col-12 col-lg-6">
                        <label>
                          NUMBER OF HOTELS YOU SOLD IN PAST
                        </label>
                        <select
                          className="form-input form-select select-state"
                          id="hotelsSoldByBroker"
                          name="hotelsSoldByBroker"
                          placeholder="Select number of hotels you sold in past"
                          disabled={disableButton}
                          // defaultValue={brokerTypes[0]}
                          onChange={(event) =>
                            handleChange(event)
                          }
                        >
                          <option value="" selected disabled>Please Select</option>
                          {hotelsSoldRanges.map((type, key) => {
                            return (
                              <option
                                id={key}
                                value={type}
                              >
                                {type}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          Please Select Broker Type
                        </div>
                      </div>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          WHAT DESCRIBES YOU THE BEST
                        </label>
                        <select
                          className="form-input form-select select-state"
                          id="brokerageType"
                          name="brokerageType"
                          placeholder="Select what describes you the best"
                          disabled={disableButton}
                          // defaultValue={brokerTypes[0]}
                          onChange={(event) =>
                            handleChange(event)
                          }
                        >
                          <option value="" selected disabled>Please Select</option>
                          {brokerDescription.map((type, key) => {
                            return (
                              <option
                                id={key}
                                value={type}
                              >
                                {type}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          Company Name is mandatory
                        </div>
                      </div>


                      <div className="form-group col-12 col-lg-6">
                        <label>
                          WHAT ARE YOU LOOKING FOR ON 1031Hotels.com?
                        </label>
                        <select
                          className="form-input form-select select-state"
                          id="brokerInterest"
                          name="brokerInterest"
                          placeholder="Select number of hotels you sold in past"
                          disabled={disableButton}
                          // defaultValue={brokerTypes[0]}
                          onChange={(event) =>
                            handleChange(event)
                          }
                        >
                          <option value="" selected disabled>Please Select</option>
                          {brokerLookingFor.map((type, key) => {
                            return (
                              <option
                                id={key}
                                value={type}
                              >
                                {type}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          Please Select Broker Type
                        </div>
                      </div>
                      <div className="form-group col-12 col-lg-6">
                        <label>
                          BROKER TYPE<sup>*</sup>
                        </label>
                        <select
                          className="form-input form-select select-state"
                          id="brokerType"
                          name="brokerType"
                          placeholder="Select Broker Type"
                          disabled={disableButton}
                          onChange={(event) =>
                            handleChange(event)
                          }
                        >
                          <option value="" selected disabled>Please Select</option>
                          {brokerTypes.map((type, key) => {
                            return (
                              <option
                                id={key}
                                value={type}
                              >
                                {type}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-feedback">
                          Please Select Broker Type
                        </div>
                      </div>

                    </div>
                    <div className='row' style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end ", width: "100%" }} >
                      <div className='form-group  col fit-content-max'>
                        <button className="btn-outline-cancel" ariaDisabled="true" style={{ maxWidth: "150px" }}>
                          <a href={"/"} className='fill-label-red'>CANCEL</a>
                        </button>
                      </div>

                      <div className='form-group col fit-content-max' style={{ minWidth: "150px" }} >
                        <button className='btn btn-solid' onClick={handleSubmit} ariaHidden="false" style={{ maxWidth: "150px" }}>
                          <span style={{ color: "white", textDecoration: "none" }}>NEXT</span>
                        </button>
                      </div>
                    </div>
                  </>
                ) : <></>}

                {page == 2 ? <>
                  {/* <h3 className='d-flex justify-content-center' >Terms And Conditions</h3> */}
                  {/* <TermsAndConditions></TermsAndConditions> */}

                  <div className='row' style={{ width: "100%" }} >
                    <PdfFormFiller arrayBuffer={TermsAndConditionsPdf} fileType={"pdf"} formDetails={tcFormDetails} handleIAgree={""} handleCancel={""}></PdfFormFiller>
                  </div>
                  <br></br>
                  <div className='row' style={{ width: "50%", float: 'right', textAlign: "right" }} >
                    <div className='form-group  col'>
                      <button className="btn-outline-cancel" ariaDisabled="true">
                        <a href={"/"} className='fill-label-red'>CANCEL</a>
                      </button>
                    </div>
                    <div className='form-group  col'>
                      <button className='btn-outline' onClick={handlePrevious} ariaDisabled="true">
                        <a >BACK</a>
                      </button>
                    </div>

                    <div className="form-group col" style={{ float: "right" }}>

                      {!false ? (
                        <>
                          <button
                            className="btn btn-solid"
                            onClick={() => navigate("/brokerTutorial")}
                          >
                            AGREE
                          </button>

                        </>
                      ) : null}
                    </div>
                  </div>

                </>
                  : <></>}



              </div>
              <label className="text-center mb-3">
                (Call {CustomerSupportNumber} if you need help with Registration)
              </label>
            </>

          </form>


        </div>
      </div>
    </>
  );
};

export default BrokerRegistration;
