import React, { useEffect, useState } from 'react';
import { notifyRegister } from '../../common/formUtils'
import { getPrimaryStates, registerUser, validateEmail, validateEntity } from '../../../api/api';
import $ from "jquery"
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { AUTH, CustomerSupportNumber, loginuser } from '../../../constants/constants';
import { setUserSession } from '../../common/commonUtils';
import axios from 'axios';

const URL = process.env.REACT_APP_API_URL;

const RegisterBuyerUser = (props) => {

    const [primaryStates, setPrimaryStates] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [isValidMail, setIsValidMail] = useState();
    const [agreeTerms, setAgreeTerms] = useState();
    const [spinner, setSpinner] = useState(false);
    const [isValidEntity, setIsValidEntity] = useState();
    const [formState, setFormState] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        mobileNumber: "",
        confirmPassword: "",
        companyName: "",
        website: "",
        additionalInformation: "",
        stateLicensed: {
            id: "",
            name: "",
            code: "",
        },
        userTypes: props.userType
    });
    const [formValidity, setFormValidity] = useState({
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        mobileNumber: null,
        confirmPassword: null,
        companyName: null,
        website: null,
        additionalInformation: null,
        stateLicensed: {
            id: null,
            name: null,
            code: null,
        },
        type: {
            code: null
        }
    });

    const [formErrorMessage, setFormErrorMessage] = useState({
        email: "Email is mandatory",
        password: "",
        firstName: "",
        lastName: "",
        mobileNumber: "Phone Number is mandatory",
        confirmPassword: "Confirm Password is mandatory",
        companyName: "Entity name is mandatory",
        website: "",
        additionalInformation: "",
        stateLicensed: {
            id: "",
            name: "",
            code: "",
        },
        type: {
            code: ""
        }
    });

    let userType = props.userType;
    localStorage.setItem("registeringUserType", userType);

    useEffect(() => {
        getPrimaryStates().then(states => setPrimaryStates(states))

        const buyerError = localStorage.getItem("BuyerError")
        const sellerError = localStorage.getItem("SellerError")
        if (buyerError) {
            notifyRegister({
                errorName: "BuyerError",
                errorMessage: "Something went wrong, please register again"
            })
            localStorage.removeItem("BuyerError");
        } else if (sellerError) {
            notifyRegister({
                errorName: "SellerError",
                errorMessage: "Something went wrong, please register again"
            })
            localStorage.removeItem("SellerError");
        }
        if (userType != "Buyer") { setIsValidEntity(true) }


    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name == "email") {
            
        } 
        else if (name == "mobileNumber") {
            var unformattedMaskNumber = value.replace(/[\s()-]/g, '');

            setFormState((prevState) => ({
                ...prevState,
                mobileNumber: unformattedMaskNumber,
            }));
        } else if (name === "stateLicensed") {

            setFormState((prevState) => ({
                ...prevState,
                stateLicensed: primaryStates[value]
            }));

        } else if (name == "agreeterms") {
            console.log(e.target.checked)
            setAgreeTerms(e.target.checked)
        }
        else {
            setFormState((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSpinner(true);

        if (validateRegisterUser(e) && isValidMail ) {

            if (formState.confirmPassword == formState.password) {
                let payload = formState;
                if (isValidMail && formState.firstName && formState.lastName && formState.mobileNumber && formState.email && formState.password) {
                    let res = await registerUser(payload)
                    let user = res?.data
                    if (res.status == 200) {

                        if (userType == "Buyer") localStorage.setItem("registered", "buyerRegistered");


                        let username = payload?.email;
                        let password = payload?.password;
                        const data = new URLSearchParams({
                            username,
                            password,
                        }).toString();
                        var config = {
                            method: "post",
                            url: loginuser,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                                Authorization: "Basic " + btoa(AUTH),
                            },
                            data: data,
                        };

                        await axios(config).then(res => {

                            let token = res?.data?.access_token
                            localStorage.setItem(
                                "login_user_token",
                                JSON.stringify(token)
                            );
                            let redirectURL = "";

                            if (userType.includes('Broker')) {
                                redirectURL = "/brokerDashboard";
                            }
                            if (userType.includes('Agent')) {
                                redirectURL = "/agentDashboard";
                            }
                            if (userType.includes('Buyer')) {
                                redirectURL = "/addBuyer";
                            }
                            if (userType.includes('Seller')) {
                                redirectURL = "/sellerDashboard";
                            }

                            setUserSession(user.firstName, user.lastName, user.userTypes, user.id, user.email, user.mobileNumber, user.userId);
                            sessionStorage.setItem("user_buyers", 0);
                            window.location = redirectURL;

                        }).catch(function (error) {
                            toast.error(error?.message,
                                {
                                    position: toast.POSITION.BOTTOM_RIGHT,
                                });
                            setSpinner(false)
                        });

                    } else {
                        toast.error(res?.data?.message,
                            {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                    }
                    setSpinner(false)

                }
                $("#confirmPassword").removeClass("is-invalid");
            } else {
                $("#confirmPassword").addClass("is-invalid");
            }
        } else {
            if (!isValidEntity) {
                $("#companyName").addClass("is-invalid");
                $("#companyName").removeClass("is-valid");
            }
        }
        setSpinner(false)
    }

    function validateRegisterUser(event) {
        event.preventDefault();
        let name = event.target.name;
        let value = event.target.value;
        let state = formState
        let errorCount = 0;


        if (state.firstName == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                firstName: false,
            }));
            $("#firstName").addClass("is-invalid");
            errorCount += 1
        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                firstName: true,
            }));
            $("#firstName").removeClass("is-invalid");
        }

        if (state.lastName == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                lastName: false,
            }));
            $("#lastName").addClass("is-invalid");
            errorCount += 1
        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                lastName: true,
            }));
            $("#lastName").removeClass("is-invalid");
        }

        if (state.companyName == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                companyName: false,
            }));
            $("#companyName").addClass("is-invalid");
            errorCount += 1
        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                companyName: true,
            }));
            $("#companyName").removeClass("is-invalid");
        }

        if (state.email == "" || !state.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setFormValidity((prevState) => ({
                ...prevState,
                email: false,
            }));
            $("#user-email").addClass("is-invalid");
            errorCount += 1
        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                email: true,
            }));
            $("#user-email").removeClass("is-invalid");
        }

        if (state.mobileNumber == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                mobileNumber: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                mobileNumber: "Phone Number is mandatory",
            }));
            $("#mobileNumber").addClass("is-invalid");
            errorCount += 1

        } else if (!state.mobileNumber.match(/^[0-9]{10}$/)) {
            setFormValidity((prevState) => ({
                ...prevState,
                mobileNumber: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                mobileNumber: "Please provide a valid Phone Number",
            }));
            $("#mobileNumber").addClass("is-invalid");
            errorCount += 1

        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                mobileNumber: true,
            }));
            $("#mobileNumber").removeClass("is-invalid");
        }

        if (state.password == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                password: false,
            }));
            $("#password").addClass("is-invalid");
            errorCount += 1
        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                password: true,
            }));
            $("#password").removeClass("is-invalid");
        }

        if (state.confirmPassword == "") {
            setFormValidity((prevState) => ({
                ...prevState,
                confirmPassword: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                confirmPassword: "Confirm Password is mandatory",
            }));
            $("#confirmPassword").addClass("is-invalid");
            errorCount += 1
        } else if (state.password != state.confirmPassword) {

            setFormValidity((prevState) => ({
                ...prevState,
                confirmPassword: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                confirmPassword: "Password mismatch",
            }));
            $("#confirmPassword").addClass("is-invalid");
            errorCount += 1

        } else {
            setFormValidity((prevState) => ({
                ...prevState,
                confirmPassword: true,
            }));
            $("#confirmPassword").removeClass("is-invalid");
        }

        if (state.stateLicensed.id == "") {
            $("#stateLicensed").addClass("is-invalid");
            errorCount += 1
        } else {
            $("#stateLicensed").removeClass("is-invalid");
        }

        return errorCount == 0;
    }

    const checkEntity = async (e) => {
        let entity = e?.target?.value;
        let res = "";
        if(entity != ""){
            res  = await validateEntity(entity);
        }
        

        if (!res?.data) {
            $("#companyName").addClass("is-valid");
            $("#companyName").removeClass("is-invalid");
            setIsValidEntity(!res?.data)
            if (entity.includes("Hotel Brokers of America", "HBA", "hba")) {
                setFormState((prevState) => ({
                    ...prevState,
                    userType: "BUYER"
                }));
            }
        } else {
            $("#companyName").addClass("is-invalid");
            $("#companyName").removeClass("is-valid");
            setFormValidity((prevState) => ({
                ...prevState,
                companyName: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                companyName: `This entity has already been registered. Please contact our support (${CustomerSupportNumber}) for help.`,
            }));
            setIsValidEntity(!res?.data)
        }

    }

    const checkEmailAvailability = async (e) => {

        let email = e.target.value;
        let name = e.target.name;

        if (email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {

            let res = await validateEmail(email);

            setIsValidMail(!res?.data?.isPresent);
            if (!res?.data?.isPresent) {
                setFormState((prevState) => ({
                    ...prevState,
                    [name]: email,
                }));

                $("#user-email").addClass("is-valid");
                $("#user-email").removeClass("is-invalid");

            } else {
                setFormValidity((prevState) => ({
                    ...prevState,
                    email: false,
                }));
                setFormErrorMessage((prevState) => ({
                    ...prevState,
                    email: "Email is already in use",
                }));

                $("#user-email").removeClass("is-valid");
                $("#user-email").addClass("is-invalid");
            }

        } else {

            setFormValidity((prevState) => ({
                ...prevState,
                email: false,
            }));
            setFormErrorMessage((prevState) => ({
                ...prevState,
                email: "Please provide valid email",
            }));

            $("#user-email").addClass("is-invalid");
            $("#user-email").removeClass("is-valid");

        }
    };

    



    return (
        <>
            <h3 className="d-flex justify-content-start font-size-18 font-weight-400 py-3">Your name will remain confidential. We only share important and necessary information with your consent.</h3>
            <div className="form-body">
                <div className={"row"}>
                    <div className="form-group col-12 col-lg-6">
                        <label>FIRST NAME<sup>*</sup></label>

                        <input id="firstName" type="text" className={"form-input"}  name="firstName" required="" maxLength={128} onChange={handleChange} />
                        <div className="invalid-feedback">
                            First Name is mandatory
                        </div>
                    </div>
                    <div className="form-group col-12 col-lg-6">
                        <label>LAST NAME<sup>*</sup></label>
                        <input id="lastName" type="text" className={"form-input"}  name="lastName" required="" maxLength={128} onChange={handleChange} />
                        <div className="invalid-feedback">
                            Last Name is mandatory
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-12 col-lg-6">
                        <label>ENTITY NAME (NAME OF THE 1031 EXCHANGE ENTITY)<sup>*</sup></label>
                        <input id="companyName" type="text" className="form-input form-control" autoComplete="none" name="companyName" required="" maxLength={128} onChange={handleChange} />
                        <div className="invalid-feedback">
                            {formErrorMessage?.companyName}
                        </div>
                    </div>

                    <div className="form-group col-12 col-lg-6" >
                        <label>WHAT IS YOUR HOME STATE (HOME OFFICE)</label>
                        <select
                            className="form-input form-select select-state"
                            id="stateLicensed"
                            name="stateLicensed"
                            placeholder="Select State"
                            disabled={disableButton}
                            defaultValue={primaryStates[0]}
                            onChange={(event) =>
                                handleChange(event)
                            }
                        >
                            <option value="">Please Select</option>
                            {primaryStates.map((state, key) => {
                                return (
                                    <option
                                        id={key}
                                        value={key}
                                    >
                                        {state.name} - {state.code}
                                    </option>
                                );
                            })}
                        </select>
                        <div className="invalid-feedback">
                            Please select your home state
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className="form-group col-12 col-lg-6">
                        <label>PHONE NUMBER (CELL PHONE)<sup>*</sup></label>
                        <InputMask
                            mask="(999) 999-9999"
                            maskChar=" "
                            className="phone form-input"
                            id="mobileNumber"
                            name="mobileNumber"
                            autoComplete="none"
                            required
                            onChange={handleChange}
                        />
                        <div className="invalid-feedback">
                            {formErrorMessage.mobileNumber}
                        </div>
                    </div>
                    <div className="form-group col-12 col-lg-6">
                        <label>WEBSITE</label>
                        <input type="text" className="form-input" name="website" required="" maxLength={128} onChange={handleChange} />
                        <div className="invalid-feedback">
                            Invalid website.
                        </div>
                    </div>
                </div>

                <div className={"row"}>
                    <div className="form-group col-12 col-lg-6">
                        <label>EMAIL (USERNAME)<sup>*</sup></label>
                        <input type="text"
                            id="user-email"
                            className={"form-input form-control"}
                            autoComplete="off"
                            name="email" required="" maxLength={128} onBlur={checkEmailAvailability} />
                        <div id="email-feedback" className="invalid-feedback">
                            {formErrorMessage?.email}

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="form-group col-12 col-lg-6">
                        <label>PASSWORD<sup>*</sup></label>
                        <input type="password" id="user-password" name="password" autoComplete="none" className="form-input" maxLength={128} onChange={handleChange} />
                        <div className="invalid-feedback">
                            Password is mandatory
                        </div>
                    </div>
                    <div className="form-group col-12 col-lg-6">
                        <label>CONFIRM PASSWORD<sup>*</sup></label>
                        <input type="password" id="confirmPassword" name="confirmPassword" autoComplete="none" className="form-input" maxLength={128} onChange={handleChange} />
                        <div className="invalid-feedback">
                            {formErrorMessage.confirmPassword}
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <label>TELL US ABOUT YOU AS A BUYER</label>
                    <textarea className="form-input" name="additionalInformation"  required="" maxLength={256} onChange={handleChange} />
                    <div className="invalid-feedback">
                        Oh no! You entered an inappropriate word.
                    </div>
                </div>

                <div className="form-group" style={{ float: "right" }}>
                    <div className='row'>
                        <div className='col'>
                            <button className='btn-outline-cancel' ariaDisabled="true">
                                <a href={"/"} className="fill-label-red">CANCEL</a>
                            </button>
                        </div>
                        <div className='col'>
                            <button
                                className="btn btn-lg btn-solid"
                                onClick={handleSubmit}
                            >
                                NEXT
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default RegisterBuyerUser;
