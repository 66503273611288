import React, { useEffect } from "react";
import { getPropertyBrands, getPropertyCategories, getPropertyLoanTypes, getPropertyMarketTypes } from "../../../api/api";
import { getAppUrl } from "../../common/commonUtils";
import vectorImage from '../../../components/assets/Vector 1.png';
import RegisterSellerUser from "./RegisterSellerUser";

const Registration = () => {
  const user = sessionStorage.getItem("user");
  useEffect(() => {
    if (!user)
      document.body.classList.add("no-navbars");
    getAppUrl();
    getPropertyLoanTypes();
    getPropertyCategories();
    getPropertyMarketTypes();
    getPropertyBrands();
  }, []);

  return (
    <>

      <div className="container-common">
        <div className="left ">

          <div className='box justify-content-center w-100'>
            <div className="status-nav d-flex justify-content-center align-start py-2 w-100">
              <label className="font-weight-b  fill-white px-2 highligter">Seller Registration</label>
              <label className="font-weight-b fill-white px-2">Upload Images</label>
              <label className="font-weight-b fill-white px-2">Upload Documents</label>
              <label className="font-weight-b fill-white px-2">Accept Terms and Conditions</label>
              <label className="font-weight-b fill-white px-2">Listing Agreement</label>
            </div>
            <img className='common-vector margin-top-40' src={vectorImage} alt="No Vector"></img>
          </div>
        </div>
        <div className="right">
          <h2>REGISTER AS A CONFIDENTIAL OFF MARKET SELLER</h2>
          <form
            id="register_wizard_with_validation"
            className="form-control h-100"
            method="POST"
          >
            {!user && <RegisterSellerUser userType="Seller" />}
          </form>
        </div>
      </div>
    </>
  );
};

export default Registration;
