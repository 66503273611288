import React, { useCallback, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from 'react-bootstrap-table2-editor';
import { BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
import CurrencyFormat from "react-currency-format";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { acceptInterestFromBuyer, acceptInterestFromHotel, interestUpdateOfBuyer, interestUpdateOfHotel, getUserInfo, updateBuyerById, updateInterestForBuyer, updateInterestForProperty, updateRankByBuyer, updateRankBySeller, updateSta, interestUpdateOfHoteltu, interestUpdateOfBuyersById, updateStatusById } from "../../api/api";
import { useState } from "react";
import Swal from "sweetalert2";
import { S3_IMAGES } from "../../constants/constants";
import { AgGridReact } from "ag-grid-react";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Carousel, Dropdown } from "react-bootstrap";
import { AdminOptions } from "../common/utils";
import { dealStatusMask, tableValueParser } from "../common/commonUtils";



const Table = ({ large, rows, column, user, interest, blueFilters, applyFilter, rejectedDeals, activeFilter, dealbox, enableSort, loading, refreshData, handleButtonClick, noDataMessage }) => {

  const navigate = useNavigate();
  // const [navigate, setNavigate] = useState(false);
  const [childState, setChildState] = useState(false);
  const [selectedRow, setSelectedRowId] = useState("");
  const [approvedBuyer, setApprovedBuyer] = useState(false);
  const [approvedSeller, setApprovedSeller] = useState(false);
  const [newRankedRows, setNewRankedRows] = useState([]);
  const [data, setData] = useState([]);
  const [hiddenRowIds, setHiddenRowIds] = useState([]);
  const [grayedRowsId, setGrayedRowsId] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [commnts, setComments] = useState("");
  const [selectedStstusOption, setSelectedStatusOption] = useState();
  const [newRankedData, setNewRankedData] = useState([]);

  let user_type = sessionStorage.getItem("user_type");

  useEffect(() => {

    checkApprovalStatus();
    setGrayedRows(rows)
    setData(rows);
  }, [])

  const setGrayedRows = (rows) => {
    let grayedId = []
    rows.map(row => { row?.dealStatus.includes("Closed") || row?.dealStatus.includes("In-contract") ? grayedId.push(row?.id) : <></> }
    )
    setGrayedRowsId(grayedId)
  }

  const checkApprovalStatus = async () => {
    const user = await getUserInfo();
    if (user.approvedBuyer == true) {
      setApprovedBuyer(true);
    } else {
      setApprovedBuyer(false);
    }

    if (user.approvedSeller == true) {
      setApprovedSeller(true);
    } else {
      setApprovedSeller(false);
    }
  }


  const handleBuyerInterestUpdate = async (e, row, value) => {
    e.stopPropagation()
    let payload = {}
    user.interestReceived.map((interest) => {
      if (interest.property.id == row.id) {
        console.log(interest.id)
        payload = interest
        payload.buyerAccepted = value
      }
    })

    if (value == false) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        text: "You won't be able to revert this",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await acceptInterestFromBuyer(payload)
          refreshData()
          setTimeout(() => {
            blueFilters("Deals Received By You")
          }, 1000)
        }
      })
    } else {
      let res = await acceptInterestFromBuyer(payload)
      { res?.status?.code == 200 ? showBuyerAlert(row.id) : <></> }
      refreshData()
      setTimeout(() => {
        blueFilters("Deals Received By You")
      }, 1000)
    }

  }
  const showSellerAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-cancel',
        container: 'my-swal',
        popup: 'my-swal-popup',
        header: 'my-swal-header',
        title: 'my-swal-title',
        closeButton: 'my-swal-close-button',
        content: 'my-swal-content'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Congratulations',
      text: "You have been matched with the buyer. This buyer has been added to your deal box.",
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Go to deal box',
    }).then((result) => {

      if (result.isDismissed) {
        navigate('/BuyerPost/' + id + '/dealbox')
      }

      if (result.isConfirmed) {
        return true
      }
    })
  }

  const showBuyerAlert = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-cancel',
        container: 'my-swal',
        popup: 'my-swal-popup',
        header: 'my-swal-header',
        title: 'my-swal-title',
        closeButton: 'my-swal-close-button',
        content: 'my-swal-content'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Congratulations',
      text: "You have been matched with the seller. This hotel has been added to your deal box.",
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
      cancelButtonText: 'Go to deal box',
    }).then((result) => {
      console.log(result)
      if (result.isDismissed) {
        navigate('/PropertyPost/' + id + '/dealbox')
      }

      if (result.isConfirmed) {
        return true
      }
    })
  }


  const handleSellerInterestUpdate = async (e, row, value) => {
    e.stopPropagation()

    let payload = {}
    user.interestReceived.map((interest) => {
      if (interest.buyer.id == row.id) {
        console.log(interest.id)
        payload = interest
        payload.sellerAccepted = value
      }
    })

    if (value == false) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        text: "You won't be able to revert this",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await acceptInterestFromHotel(payload)
          refreshData()
          setTimeout(() => {
            blueFilters("Deals Received By You")
          }, 1000)
        }
      })
    } else {
      let res = await acceptInterestFromHotel(payload)
      { res?.status?.code == 200 ? showSellerAlert(row.id) : <></> }
      refreshData()
      setTimeout(() => {
        blueFilters("Deals Received By You")
      }, 1000)
    }
  }

  const HandleRemoveFromDealBox = async (e, params, interest) => {
    // e.stopPropagation();
    let payload = {}
    try {

      Swal.fire({
        title: 'Are you sure you want to delete it?',
        text: "You won't be able to revert this",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it'
      }).then(async (result) => {
        let res = ""
        if (result.isConfirmed) {
          if (column == "buyercolumns") {
            payload = interest
            payload.removeFromBuyerDealBox = true

            if (interest?.source == "InterestReceived") {
              res = await interestUpdateOfHotel(payload)
            } else {
              res = await interestUpdateOfBuyer(payload)
            }
          } else {

            payload = interest
            payload.removeFromSellerDealBox = true

            if (interest?.source == "InterestReceived") {
              res = await interestUpdateOfBuyer(payload)
            } else {
              res = await interestUpdateOfHotel(payload)
            }
          }
          console.log(res)

          if (res?.status == 200) {
            params.api.applyTransaction({
              remove: [params.node.data],
            });
          }

        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const HandleAddToDealBox = async (e, row, interest) => {
    e.stopPropagation();
    let payload = {}
    try {
      if (column == "buyercolumns") {
        payload = interest
        payload.removeFromBuyerDealBox = false

        if (interest?.source == "InterestReceived") {
          let res = await interestUpdateOfHotel(payload)
        } else {
          let res = await interestUpdateOfBuyer(payload)
        }
      } else {

        payload = interest
        payload.removeFromSellerDealBox = false

        if (interest?.source == "InterestReceived") {
          let res = await interestUpdateOfBuyer(payload)
        } else {
          let res = await interestUpdateOfHotel(payload)
        }
      }

      refreshData()
      setTimeout(() => {
        blueFilters("Deals Rejected By You")
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  }


  const iconHandler = (order) => {
    if (!order)
      return (
        <div className="iconWrapper">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp style={{ color: "#d7d7d7" }} />
          </span>
          <span>
            <CgArrowLongDown style={{ color: "#d7d7d7" }} />
          </span>
        </div>
      );
    else if (order === "asc")
      return (
        <div className="iconWrapper">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp />
          </span>
          <span>
            <CgArrowLongDown style={{ color: "#d7d7d7" }} />
          </span>
        </div>
      );
    else if (order === "desc")
      return (
        <div className="iconWrapper">
          <span style={{ marginRight: "-6px" }}>
            <CgArrowLongUp style={{ color: "#d7d7d7" }} />
          </span>
          <span>
            <CgArrowLongDown />
          </span>
        </div>
      );
    return null;
  };

  const showDeadline = (date, color) => {
    let divClass = "";

    divClass = `right-div-${color}-button`

    return (
      <div className="date-wrapper">
        <div className={divClass}>{date ? date : "-"}</div>
      </div>
    );
  };

  const showClosingDate = (date, color) => {
    let divClass = "";
    if (color === "green") {
      divClass = "closingdate-div-green";
    } else if (color === "red") {
      divClass = "closingdate-div-red";
    } else if (color === "yellow") {
      divClass = "closingdate-div-yellow";
    }

    return (
      <div className="date-wrapper">
        <div className={divClass}>{date ? date : "-"}</div>
      </div>
    );
  };

  const notifyGetApproved = () => {
    // toast.error("Your profile is being reviewed. Please wait until your profile is approved to access the page!", {
    //   position: toast.POSITION.BOTTOM_CENTER,
    // });
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        container: 'my-swal',
        popup: 'my-swal-popup-notify',

        content: 'my-swal-content'
      }

    })


    swalWithBootstrapButtons.fire({
      title: 'Your profile is being reviewed',
      html : `<div>
              <h4>Please wait until your profile is approved to access the hotels</h4>
              </br>    
              <h4> You can contact us on </h4>    
              <h4><b>info@1031hotels.com</b></h4>
              <h4><b>661-808-4998</b></h4>
            </div>
        `
    })
  };

  const getDealSource = (deal, post) => {
    if (deal == "InterestSent") {
      return "Requested By You"
    } else if (deal == "InterestReceived" && post == "buyer") {
      return "Sent By Seller"
    } else if (deal == "InterestReceived" && post == "seller") {
      return "Sent By Buyer"
    } else if (deal == "AdminRecommended" || deal == "Broker") {
      return "Broker"
    } else {
      return "Mutually Interested"
    }
  }

  const conficolumns = [
    {
      dataField: "buyer",
      text: "1031 Exchange Buyer ID",
      classes: large === true ? "data-stylebuyer-id-lg hand-cursor" : "data-style-id  hand-cursor",
      sort: true,
      formatter: (value, row) => <label className="buyer hand-cursor" >{value?.generatedBuyerId ? value.generatedBuyerId : "1031 Exchange Buyer ID"}</label>,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation()
          navigate("/buyerProfile/" + row.buyer.id)
        }
      },
    },
    {
      dataField: "buyer",
      text: "Buyer Name",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, row) => (
        <label>{value?.buyerName ? value?.buyerName : "-"}</label>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Buyer Name</Tooltip>}
        >
          <label>Buyer Name</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "buyer",
      text: "Broker Name",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, row) => (
        <label>{value?.user?.userTypes.includes("Broker") ? value?.user?.firstName + " " + value?.user?.lastName : "-"}</label>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Broker Name</Tooltip>}
        >
          <label>Broker Name</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "property",
      text: "Hotel ID",
      classes: large ? "data-styleproperty-id-lg hand-cursor" : "data-style-id hand-cursor",
      sort: true,
      formatter: (value, row) => <label className="hotel hand-cursor" >{value?.generatedPropertyId ? value.generatedPropertyId : "1031 Exchange Buyer ID"}</label>,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation()
          navigate("/propertyProfile/" + row.property.id)
        }
      },
    },
    {
      dataField: "property",
      text: "Hotel Brand",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, row) => (
        <>
          {row?.displayBrandOn1031 ?
            <label>{value?.propertyType?.brandName ? value?.propertyType?.brandName : "-"}</label>
            :
            <label>{value?.propertyType?.brandType ? value?.propertyType?.brandType : "-"}</label>
          }
        </>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Hotel Brand</Tooltip>}
        >
          <label>Hotel Brand</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "property",
      text: "Hotel Franchise",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, row) => (
        <label>{value?.propertyType?.franchiseName ? value?.propertyType?.franchiseName : "-"}</label>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Hotel Franchise</Tooltip>}
        >
          <label>Hotel Franchise</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "property",
      text: "Nearest MSA",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, row) => (
        <label>{value?.nearestMSA ? value?.nearestMSA : "-"}</label>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Nearest MSA</Tooltip>}
        >
          <label>Nearest MSA</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "property",
      text: "State",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, row) => (
        <label>{value?.address?.state?.name ? value?.address?.state?.name : "-"}</label>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>State</Tooltip>}
        >
          <label>State</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "property",
      text: "Seller",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, row) => (
        <label>{value?.sellerName ? value?.sellerName : "-"}</label>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Seller</Tooltip>}
        >
          <label>Seller</label>
        </OverlayTrigger>
      )
    },

  ]

  //----------------------------------------- P R O P E R T Y - C O L ---------------------------------------------//

  const propcolumns = [
    {
      dataField: "generatedBuyerId",
      text: "1031 Exchange Buyer ID",
      classes: large === true ? "data-stylebuyer-id-lg hand-cursor" : "data-style-id  hand-cursor",
      sort: true,
      formatter: (value, row) => <label className="buyer hand-cursor" >{value ? value : "1031 Exchange Buyer ID"}</label>,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) =>
          navigate("/buyerProfile/" + row.id)
      },
    },
    {
      dataField: "funds1031",
      text: "Amount w/1031 Exchange (MAX. Down Payment)",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value) => (
        <label><CurrencyFormat value={value !== null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></label>
      ),
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>1031 Exchange Funds</Tooltip>}
        >
          <label>1031 Exchange Funds</label>
        </OverlayTrigger>
      )
    },


    {
      dataField: "buyingPower",
      text: "Max Buying Power",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value) => (
        <label><CurrencyFormat value={value !== null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></label>
      ),
      sort: true,
      
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Max Buying Power</Tooltip>}
        >
          <label>Max Buying Power</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "deadLineDate45DaysString",
      text: "Days to 45-Day Deadline",
      formatter: (date, val) => showClosingDate(date, val.deadLineDate45DaysColor),
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Days to 45-Day Deadline</Tooltip>}
        >
          <label>Days to 45-Day Deadline</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "closingDate1031String",
      text: "Days to 1031 Closing",
      formatter: (date, val) => showClosingDate(date, val.closingDate1031Color),
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Days to 1031 Closing</Tooltip>}
        >
          <label>Days to 1031 Closing</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "interestedBrands",
      text: "Interested Hotel Brand",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value) => <label>{value !== null ? `${tableValueParser(value)}` : "-"}</label>,
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Interested Hotel Brand</Tooltip>}
        >
          <label>Interested Hotel Brand</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "interestedCategory",
      text: "Interested Market Segment",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value) => <label>{value !== null ? `${tableValueParser(value)}` : "-"}</label>,
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Interested Market Segment</Tooltip>}
        >
          <label>Interested Market Segment</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "interestedRoomCount",
      text: "No.of Rooms",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, cell, row) => (
        <label>
          {value && cell.interestedRoomCount !== null
            ? `${value}`
            : "-"}
        </label>
      ),
      sort: true,
      
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>No.of Rooms</Tooltip>}
        >
          <label>No.of Rooms</label>
        </OverlayTrigger>
      )
    },


    {
      dataField: "source",
      text: "Deal Source",
      classes: large === true ? "data-style-lg" : "data-style",
      sort: true,
      formatter: (value, row) => {
        return (
          <>
            {user?.dealBox.map(deal =>

              deal?.buyer?.id == row?.id ?
                <label>{deal ? getDealSource(deal?.source, "seller") : "-"}</label>

                : <></>
            )}
          </>

        )
      },
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Deal Source</Tooltip>}
        >
          <label>Deal Source</label>
        </OverlayTrigger>
      ),
      hidden: !dealbox,
    },

    {
      dataField: "dealStatus",
      text: "Deal Status",
      sort: true,
      formatter: (value, row) => (

        <label>{value != null ? dealStatusMask(value) : "-"} </label>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Deal Status</Tooltip>}
        >
          <label>Deal Status</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "comments",
      text: "Comments",
      headerStyle: { width: '300px', backgroundColor: 'lightgray' },
      style: { width: '300%', border: '1px solid #ccc' },
      sort: true,
      formatter: (value, row) => (
        <>
        <textarea
          className="d-flex"
          style={{
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: '0.3rem'
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={handleCommentChange}
          onBlur={handleSaveRow}
        />
        <button className="btn btn-primary-narrow">Save</button>
        </>
      ),
      hidden: !dealbox,
      classes: large ? "data-style-lg w-auto" : "data-style w-auto",
      editable: true,
      editable: (cell, row, rowIndex, colIndex) => {
        console.log(cell)
      },
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Comments</Tooltip>}
        >
          <label>Comments</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "dealBox",
      text: "",
      sort: true,
      formatter: (value, row) => {
        return (
          <div id='buyer-button' className="btn-wrapper">

            {user.interestReceived.map(interest => interest.buyer.id == row.id && interest.sellerAccepted == true ?
              <button id='buyer-accept-btn' className="accept-btn" disabled>
                Accepted
              </button> : <></>)}


            {user.interestReceived.map(interest => interest.buyer.id == row.id && interest.sellerAccepted == false ?
              <button id='buyer-reject-btn' className="reject-btn" disabled>
                Rejected
              </button>
              : <></>)}

            {user.interestReceived.map(interest => interest.buyer.id == row.id && interest.sellerAccepted == null ?
              <button id='buyer-accept-btn' className="accept-btn" onClick={(e) => handleSellerInterestUpdate(e, row, true)}>
                <BsCheck style={{ fontSize: "15px" }} />Accept
              </button>
              : <></>)}

            {user.interestReceived.map(interest => interest.buyer.id == row.id && interest.sellerAccepted == null ?
              <button id='buyer-reject-btn' className="reject-btn" onClick={(e) => handleSellerInterestUpdate(e, row, false)}>
                <BsCheck style={{ fontSize: "15px" }} />Reject
              </button>
              : <></>)}

          </div>
        )

      },
      hidden: !interest,
      classes: "btn-class",
    },
    {
      dataField: "user",
      text: "Remove From Deal Box",
      sort: true,
      formatter: (e, value, row) => {
        return (
          <div id='buyer-button' className="btn-wrapper">
            {user.dealBox.map((interest, key) => interest?.buyer.id == row?.id ?
              <button id='buyer-reject-btn' className="reject-btn" onClick={(e) => {
                HandleRemoveFromDealBox(e, row, interest)
              }
              }>
                <BsCheck style={{ fontSize: "15px" }} />Remove
              </button>
              : <></>)}

          </div>
        )
      },
      hidden: !dealbox,
      classes: "btn-class",
    },

    {
      dataField: "user",
      text: "Add to Deal Box",
      sort: true,
      formatter: (value, row) => {
        {
          selectedInterest = user?.interestReceived?.filter(interest => interest.buyer.id == row.id)
          if (selectedInterest.length == 0) {
            selectedInterest = user?.interestSent?.filter(interest => interest.buyer.id == row.id)
          }
        }

        return (
          <div id='buyer-button' className="btn-wrapper">
            {selectedInterest?.map((interest, key) => interest?.buyer.id == row?.id ?
              <button id='buyer-reject-btn' className="accept-btn" onClick={(e) => {
                HandleAddToDealBox(e, row, interest)
              }
              }>
                <BsCheck style={{ fontSize: "15px" }} />Add
              </button>
              : <></>)}
          </div>
        )
      },
      hidden: !rejectedDeals,
      classes: "btn-class",
    }

  ];

  const postedByMask = (userType, row) => {
    
    let type = userType.includes("Agent")  ? "BROKER"  : userType;
    if(row?.buyerCompanyName?.includes("Hotel Brokers of America", "HBA", "1031Agents") || row?.sellerCompanyName?.includes("Hotel Brokers of America", "HBA", "1031Agents" )){
      type?.toUpperCase()
    }
    return type;
  }

  //-------------------------------------- B U Y E R S - C O L -----------------------------------------------//

  const getImagesArray = (urls) => {
    let urlsArray = urls?.split(',')
    let imageUrls = []

    urlsArray.slice(0, 5)?.map(url => {
      if (url.includes("files")) {
        const index = url.indexOf("files");
        const substringBeforeKeyword = url.substring(0, index);

        if (substringBeforeKeyword.trim() === "") {
          imageUrls.push(S3_IMAGES + url)
        } else {
          imageUrls.push(url)
        }
      }
    })
    return imageUrls
  }

  const handleCommentChange = (e) => {
    console.log(e.target.value)
    setComments(e.target.value)

  }

  const handleSaveRow = (e) => {
    console.log(e)

  }

  const buyercolumns = [
    {
      dataField: "generatedPropertyId",
      text: "Hotel ID",
      classes: true ? "data-style property-id-lg hand-cursor" : "data-style-id hand-cursor",
      sort: true,
      formatter: (value, row) => <>
        <OverlayTrigger
          placement="auto-end"
          overlay={

            <div className="row carousel-overlay">
              {getImagesArray(row?.imagesPath).map((url, index) => (
                <img
                  key={index}
                  className="col-4"
                  src={url}
                  style={{ width: '270px', height: '216px' }}
                  alt={`Image ${index + 1}`}
                />
              ))}
            </div>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <div
              variant="light"
              {...triggerHandler}
              className="d-inline-flex align-items-center"
            >
              <label ref={ref} className="ms-1 hotel hand-cursor"> <label>{value ? value : 'Hotel ID'}</label></label>
            </div>
          )}
        </OverlayTrigger>
      </>,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          if (approvedBuyer === true) {
            navigate("/propertyProfile/" + row.id);
          } else {
            notifyGetApproved();
          }
        },
      },
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Hotel ID</Tooltip>}
        >
          <label>Hotel ID</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "propertyType",
      text: "Brand",
      sort: true,
      formatter: (value, row) => (
        <>
          {row?.displayBrandOn1031 ?
            <label>{value !== null ? value.brandName : "-"}</label>
            :
            <label>{value !== null ? value.brandType : "-"}</label>
          }
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Brand</Tooltip>}
        >
          <label>Brand</label>
        </OverlayTrigger>
      )
    },

    {
      dataField: "primaryState.name",
      text: "State",
      sort: true,
      formatter: (value, row) =>
        <>
          <label>{value?  value : "-"}</label>
        </>,
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>State</Tooltip>}
        >
          <label>State</label>
        </OverlayTrigger>
      )
    },

    {
      dataField: "askingPrice",
      text: "Asking Price",
      sort: true,
      formatter: (value, row) => (
        <>
          <label><CurrencyFormat value={value !== null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: enableSort,
      
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Asking Price</Tooltip>}
        >
          <label>Asking Price</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "pipEstimate",
      text: "Estimated PIP",
      sort: true,
      formatter: (value, row) =>
        <label><CurrencyFormat value={value !== null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></label>,
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Estimated PIP</Tooltip>}
        >
          <label>Estimated PIP</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "noOfRooms",
      text: "Room Count",
      sort: true,
      formatter: (value, row) =>
        <label>{value !== null ? `${value}` : "-"}</label>
      ,
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Room Count</Tooltip>}
        >
          <label>Room Count</label>
        </OverlayTrigger>
      )
    },

    {
      dataField: "totalRevenue",
      text: "Revenue",
      sort: true,
      formatter: (value, row) => (
        <>
          <label><CurrencyFormat value={value !== null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></label>
        </>
      ),
      classes: large ? "data-style-lg" : "data-style",
      sort: enableSort,
      
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Revenue</Tooltip>}
        >
          <label>Revenue</label>
        </OverlayTrigger>
      )
    },

    {
      dataField: "capRate",
      text: "Cap Rate %",
      sort: true,
      formatter: (value, row) =>
        <label>{value !== null ? `${value.toFixed(2)}` : "-"}</label>
      ,
      classes: large ? "data-style-lg" : "data-style",
      sort: enableSort,
      
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Cap Rate %</Tooltip>}
        >
          <label>Cap Rate %</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "roomRevenueMultiplier",
      text: "Revenue Multiplier",
      sort: true,
      formatter: (value, row) => (
        <label>{value != null ? value.toFixed(2) : 0} </label>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Revenue Multiplier</Tooltip>}
        >
          <label>Revenue Multiplier</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "pricePerKey",
      text: "Price Per Room",
      sort: true,
      formatter: (value, row) => (
        <label><CurrencyFormat value={value !== null ? value : 0} decimalScale={0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></label>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Price Per Room</Tooltip>}
        >
          <label>Price Per Room</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "source",
      text: "Deal Source",
      sort: true,
      formatter: (value, row) => {
        return (
          <>
            {user?.dealBox.map(deal =>

              deal?.property?.id == row?.id ?
                <label>{deal ? getDealSource(deal?.source, "buyer") : "-"}</label>

                : <></>
            )}
          </>

        )

      },
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Deal Source</Tooltip>}
        >
          <label>Deal Source</label>
        </OverlayTrigger>
      ),
      hidden: !dealbox,
    },
    {
      dataField: "dealStatus",
      text: "Deal Status",
      sort: true,
      formatter: (value, row) => (
        <label>{value != null ? dealStatusMask(value) : "-"} </label>
      ),
      classes: large ? "data-style-lg" : "data-style",
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Deal Status</Tooltip>}
        >
          <label>Deal Status</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "comments",
      text: "Comments",
      sort: true,
      headerStyle: { width: '300px', backgroundColor: 'lightgray' }, // Style for the column header
      style: { width: '300%', border: '1px solid #ccc' }, // Style for the column cells
      formatter: (value, row) => (
        <textarea
            className="d-flex"
            style={{
              width: '100%',
              border: '1px solid #ccc',
              borderRadius: '0.3rem'
            }}
            onClick={(e) => e.stopPropagation()}
            onChange={handleCommentChange}
            onBlur={handleSaveRow}
          />
      ),
      hidden: !dealbox,
      classes: large ? "data-style-lg w-auto" : "data-style w-auto",
      editable: true,
      editable: (cell, row, rowIndex, colIndex) => {
        console.log(cell)
      },
      headerFormatter: column => (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>Comments</Tooltip>}
        >
          <label>Comments</label>
        </OverlayTrigger>
      )
    },
    {
      dataField: "",
      text: "",
      formatter: (value, row) => {
        return (
          <div id='buyer-button' className="btn-wrapper">

            {user?.interestReceived?.map(interest => interest.property.id == row.id && interest.buyerAccepted == true ?
              <button id='buyer-accept-btn' className="accept-btn" disabled>
                Accepted
              </button> : <></>)}


            {user?.interestReceived?.map(interest => interest.property.id == row.id && interest.buyerAccepted == false ?
              <button id='buyer-reject-btn' className="reject-btn" disabled>
                Rejected
              </button>
              : <></>)}

            {user?.interestReceived?.map(interest => interest.property.id == row.id && interest.buyerAccepted == null ?
              <button id='buyer-accept-btn' className="accept-btn" onClick={(e) => handleBuyerInterestUpdate(e, row, true)}>
                <BsCheck style={{ fontSize: "15px" }} />Accept
              </button>
              : <></>)}

            {user?.interestReceived?.map(interest => interest.property.id == row.id && interest.buyerAccepted == null ?
              <button id='buyer-reject-btn' className="reject-btn" onClick={(e) => handleBuyerInterestUpdate(e, row, false)}>
                <BsCheck style={{ fontSize: "15px" }} />Reject
              </button>
              : <></>)}

          </div>)
      },
      hidden: !interest,
      classes: "btn-class",
    },
    {
      dataField: "user",
      text: "Remove From Deal Box",
      formatter: (e, value, row) => {
        return (
          <div id='buyer-button' className="btn-wrapper">
            {user.dealBox.map(interest => interest.property.id == row.id ?
              <button id='buyer-reject-btn' className="reject-btn" onClick={(e) => {
                HandleRemoveFromDealBox(e, row, interest)
              }
              }>
                <BsCheck style={{ fontSize: "15px" }} />Remove
              </button>
              : <></>)}
          </div>
        )
      },
      hidden: !dealbox,
      classes: "btn-class",
    },
    {
      dataField: "user",
      text: "Add to Deal Box",
      formatter: (value, row) => {
        // { console.log(user.dealBox) }
        {
          selectedInterest = user?.interestReceived?.filter(interest => interest.property.id == row.id)
          if (selectedInterest.length == 0) {
            selectedInterest = user?.interestSent?.filter(interest => interest.property.id == row.id)
          }
        }

        return (
          <div id='buyer-button' className="btn-wrapper">

            {selectedInterest?.map(interest => interest.property.id == row.id ?
              <button id='buyer-accept-btn' className="accept-btn" onClick={(e) => {
                HandleAddToDealBox(e, row, interest)
              }
              }>
                <BsCheck style={{ fontSize: "15px" }} />Add
              </button>
              : <></>)}
          </div>
        )
      },
      hidden: !rejectedDeals,
      classes: "btn-class",
    }


  ];

  let selectedInterest = ""
  //------------------------------------------------------------------------------------------------------------------//

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = "";
      activeStyle.padding = "0 10px";
      activeStyle.fontSize = "10px";
      activeStyle.color = "black";
      activeStyle.marginRight = "6px";
      activeStyle.backgroundColor = "#c3c1c185";
      activeStyle.cursor = "pointer";
      activeStyle.borderRadius = "2px";
      activeStyle.boxShadow =
        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
    } else {
      activeStyle.backgroundColor = "";
      activeStyle.padding = "0 10px";
      activeStyle.fontSize = "10px";
      activeStyle.marginRight = "6px";
      activeStyle.color = "black";
      activeStyle.backgroundColor = "";
      activeStyle.cursor = "pointer";
      activeStyle.borderRadius = "2px";
      activeStyle.boxShadow =
        "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px";
    }
    if (typeof page === "string") {
      activeStyle.backgroundColor = "white";
      activeStyle.color = "black";
    }
    return (
      <li className="page-item">
        <div onClick={handleClick} style={activeStyle}>
          {" "}
          {page}
        </div>
      </li>
    );
  };

  const options = {
    pageButtonRenderer, sizePerPage: 1000, sizePerPageList: [],
  };

  const checkRemovedRows = (rows) => {

    // console.log("here again")
    if (dealbox) {
      let dealBoxrows = [];
      if (column == 'buyercolumns') {
        user?.dealBox?.forEach(deal => dealBoxrows.push(deal?.property));
        setData(dealBoxrows)
        return dealBoxrows
      } else {
        user?.dealBox?.forEach(deal => dealBoxrows.push(deal?.buyer));
        setData(dealBoxrows)
        return dealBoxrows
      }
    } else {
      setData(data)
      return rows
    }

  }


  const handleCellClick = (cell, row, rowIndex) => {
    console.log(rowIndex)
    setHiddenRowIds(prevIds => [...prevIds, row.id]);
  };


  const rowStyle = (row, rowIndex) => {
    if (hiddenRowIds.includes(row.id)) {
      return {
        display: 'none',
        cursor: "move"
      };
    }

    if (grayedRowsId.includes(row.id) && dealbox) {
      // console.log(grayedRowsId)
      return {
        backgroundColor: "#6c757da1",
        cursor: "move"
      }
    }
    return {}
  };

  const clickEvent = (id) => {
    if (column == "buyercolumns") {
      if (approvedBuyer === true) {
        navigate("/propertyProfile/" + id);
      } else {
        notifyGetApproved();
      }
    } else {
      navigate("/buyerProfile/" + id)
    }
  }
  const rowEvents = {
    onClick: (e, row) => clickEvent(row.id)
  };

  const moveRow = {
    onBlur: async (e) => {
      let payload = {}
      let res;
      let selectedInterest = []


      try {
        if (column == "buyercolumns") {
          selectedInterest = user.dealBox.filter((interest) => interest?.property?.id == e?.data?.id)
          payload = selectedInterest[0]
          if (selectedInterest[0]?.source == "InterestReceived") {
            payload.buyerRank = e?.rowIndex
            res = await interestUpdateOfHotel(payload)
          } else if (selectedInterest[0]?.source == "InterestSent") {
            payload.buyerRank = e?.rowIndex
            res = await interestUpdateOfBuyer(payload)
          } else {
            payload.buyerRank = e?.rowIndex
            res = await interestUpdateOfBuyer(payload)
          }
        } else {
          selectedInterest = user.dealBox.filter((interest) => interest?.buyer?.id == e?.data?.id)
          payload = selectedInterest[0]
          console.log(selectedInterest)
          console.log(selectedInterest[0]?.source == "InterestSent")
          if (selectedInterest[0]?.source == "InterestReceived") {
            payload.sellerRank = e?.rowIndex
            res = await interestUpdateOfBuyer(payload)
          } else if (selectedInterest[0]?.source == "InterestSent") {
            payload.sellerRank = e?.rowIndex
            res = await interestUpdateOfHotel(payload)
          } else {
            payload.sellerRank = e?.rowIndex
            res = await interestUpdateOfHotel(payload)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  };

  function onGridReady(params) {
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
    if (column == "buyercolumns") {
      document.getElementById('customButton')?.addEventListener('click', () => {
        applyFilter('Shop Hotels For Sale');
      });
    }else{
      document.getElementById('customButton')?.addEventListener('click', () => {
        applyFilter('Shop 1031 Buyers');
      });
    }

    
  }

  function currencyFormatter(params) {
    return '$' + formatNumber(params.value);
  }

  function formatNumber(number) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const CustomTooltip = (params) => {
    console.log(params.data.imagesPath)
    return (
      <Carousel>
        {params?.data?.imagesPath?.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Image ${index}`} />
          </div>
        ))}
      </Carousel>
    );
  };

  const valueFormatterAg = (params) => {
    if (params.value == null || params?.value == "") return "-"
    return params.value
  }

  const dealStatusValueFormatter = (params) => {
    return dealStatusMask(params.value)
  }


  const getRowStyle = (params) => {

    if (grayedRowsId.includes(params.data.id)) {
      console.log('grayedRowsId')
      return {
        background: "#6c757da1",
        cursor: "move"
      }
    }

    return {};

  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const getButtonColor = (value) => {
    if (value == "Approved") {
      return "btn bg-green-color-filler "
    } else if (value == "Unapproved") {
      return "btn bg-yellow-color-filler  "
    } else if (value == "In-contract") {
      return "btn bg-orange-color-filler "
    } else if (value == "Closed") {
      return "btn bg-grey-color-filler "
    } else if (value == "Off-market") {
      return "bg-paleBlue-color-filler"
    }
  }

  const getBadgeColor = (value) => {
    if (value == "Approved") {
      return "bg-green-color-filler btn-round-badge rounded-pill"
    } else if (value == "Unapproved") {
      return "bg-yellow-color-filler  btn-round-badge rounded-pill "
    } else if (value == "In-contract") {
      return "bg-orange-color-filler  btn-round-badge rounded-pill "
    } else if (value == "Closed") {
      return "bg-grey-color-filler  btn-round-badge rounded-pill "
    } else if (value == "Off-market") {
      return "bg-paleBlue-color-filler  btn-round-badge rounded-pill "
    }
  }

  const approveHandler = async (status, id) => {
    let updatedstatus = {}
    if (column == "buyercolumns") {
      updatedstatus = {
        buyerId: id,
        dealStatus: status,
      }

    } else {
      updatedstatus = {
        propertyId: id,
        dealStatus: status,
      }

    }
    try {
      let res = await updateStatusById(updatedstatus);
    } catch (error) {
      console.log(error);
    }
  };

  const rankValueFormatter = (params) => {
    if (newRankedRows != "") {
      if (newRankedRows?.id == params?.data?.id) return parseInt(newRankedRows?.rank) + 1;
      newRankedRows?.map(rowRank => {
        if (rowRank?.id == params?.data?.id) return parseInt(rowRank?.rank) + 1;
      })
    } else {
      if (params.value == null || params?.value == "") return "-"
      return parseInt(params.value) + 1
    }

  }
  /////// Buyer Columns AG Grid /////

  const buyercolumns_ag = [
    {
      headerName: "Rank Your Deal",
      field: "rank",
      valueFormatter: rankValueFormatter,
      autoSize: true,
      sortable: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
      minWidth: 100
    },
    {
      headerName: "Hotel ID",
      field: "generatedPropertyId",
      onCellClicked: params => {
        if (approvedBuyer === true) {
          navigate("/propertyProfile/" + params.data.id);
        } else {
          notifyGetApproved();
        }
      },
      valueFormatter: valueFormatterAg,
      autoSize: true,
      sortable: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell fill-blue font-bold',
      wrapText: true,
      autoHeight: true,
      minWidth: 100
    },
    {
      headerName: "Brand",
      field: "propertyType.brandType",
      autoSize: true,
      sortable: true,
      valueFormatter: valueFormatterAg,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,

    },
    {
      headerName: "State",
      field: "primaryState.name",
      autoSize: true,
      sortable: true,
      valueFormatter: valueFormatterAg,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Asking Price",
      field: "askingPrice",
      sortable: true,
      valueFormatter: currencyFormatter,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,

    },
    {
      headerName: "Estimated PIP",
      field: "pipEstimate",
      valueFormatter: currencyFormatter,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
      sortable: true,
    },
    {
      headerName: "Room Count",
      field: "noOfRooms",
      valueFormatter: valueFormatterAg,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
      sortable: true,
      // width: 80
    },
    {
      headerName: "Revenue",
      field: "totalRevenue",
      valueFormatter: currencyFormatter,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
      sortable: true,

    },
    {
      headerName: "Cap Rate %",
      field: "capRate",
      valueFormatter: valueFormatterAg,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
      sortable: true,
    },
    {
      headerName: "Revenue Multiplier",
      field: "roomRevenueMultiplier",
      autoSize: true,
      valueFormatter: valueFormatterAg,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
      sortable: true,
      // width:200
    },
    {
      headerName: "Price Per Room",
      field: "pricePerKey",
      valueFormatter: currencyFormatter,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
      sortable: true,
    },
    {
      headerName: "Deal Source",
      field: "source",
      sortable: true,
      cellRenderer: function (params) {
        const row = params.data;
        const dealBox = user?.dealBox;

        if (dealBox) {
          const deal = dealBox.find((deal) => deal?.property?.id === row.id);
          if (deal) {
            const dealSource = getDealSource(deal?.source, "buyer");
            return <label>{dealSource}</label>;
          }
        }
        return "";
      },

      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      hide: !dealbox,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Deal Status",
      field: "dealStatus",
      valueFormatter: dealStatusValueFormatter,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: params => {
        switch (params.value) {
          case 'Approved':
            return 'custom-cell fill-green font-bold';
          default:
            return 'custom-cell';
        }
      },
      wrapText: true,
      autoHeight: true,
      sortable: true,

    },

    // {
    //   headerName: "Deal Status",
    //   field: "dealStatus",
    //   editable: false,
    //   sortable: true,
    //   cellRenderer: function (params) {
    //     return (
    //       <select
    //         className={getButtonColor(params.data?.dealStatus)}
    //         id="dropdown-basic"
    //         onChange={(event) => {
    //           const selectedValue = event.target.value;
    //           approveHandler(selectedValue, params.data.id);
    //         }}
    //       >
    //         <option value="" disabled selected>
    //           {params.data?.dealStatus}
    //         </option>
    //         {AdminOptions.map((option) => (
    //           <option value={option.value} key={option.value}>
    //             {option.label}
    //           </option>
    //         ))}
    //       </select>

    //     )
    //   },
    //   headerClass: 'custom-header',
    //   cellClass: 'custom-cell',
    //   wrapText: true,
    //   hide: !user_type?.includes("Agent")
    // },
    {
      headerName: "Comments",
      field: "comments",
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      minWidth: 200,
      headerClass: 'custom-header',
      cellClass: '',
      sortable: true,
    },
    {
      headerName: "Actions",
      cellRenderer: params => (
        <div id='buyer-button' className="btn-wrapper">

          {user.interestReceived.map(interest => interest.property.id == params.data.id && interest.buyerAccepted == true ?
            <button id='buyer-accept-btn' className="accept-btn" disabled>
              Accepted
            </button> : <></>)}


          {user.interestReceived.map(interest => interest.property.id == params.data.id && interest.buyerAccepted == false ?
            <button id='buyer-reject-btn' className="reject-btn" disabled>
              Rejected
            </button>
            : <></>)}

          {user.interestReceived.map(interest => interest.property.id == params.data.id && interest.buyerAccepted == null ?
            <button id='buyer-accept-btn' className="accept-btn" onClick={(e) => handleBuyerInterestUpdate(e, params.data, true)}>
              <BsCheck style={{ fontSize: "15px" }} />Accept
            </button>
            : <></>)}

          {user.interestReceived.map(interest => interest.property.id == params.data.id && interest.buyerAccepted == null ?
            <button id='buyer-reject-btn' className="reject-btn" onClick={(e) => handleBuyerInterestUpdate(e, params.data, false)}>
              <BsCheck style={{ fontSize: "15px" }} />Reject
            </button>
            : <></>)}

        </div>
      ),
      hide: !interest,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,

    },
    {
      headerName: "Remove From Deal Box",
      field: "user.dealBox",
      cellRenderer: params => (
        <div id='buyer-button' className="btn-wrapper">
          {user.dealBox.map(interest => interest.property.id == params.data.id ?
            <button id='buyer-reject-btn' className="reject-btn" onClick={(e) => {
              HandleRemoveFromDealBox(e, params, interest)
            }
            }>
              <BsCheck />Remove
            </button>
            : <></>)}
        </div>
      ),
      hide: !dealbox,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell custom-border',
      wrapText: true,
      autoHeight: true,
    },

  ];


  //// Hotel columns AG ////

  const propcolumns_ag = [
    {
      headerName: "Rank Your Deal",
      field: "rank",
      sortable: true,
      valueFormatter: (params) => {
        if (params.value == null || params?.value == "") return "-"
        return parseInt(params.value) + 1
      },
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
      minWidth: 100
    },
    {
      field: "generatedBuyerId",
      headerName: "1031 Exchange Buyer ID",
      sortable: true,
      cellRenderer: (params) => (
        <label className="buyer hand-cursor">
          {params.value ? params.value : "1031 Exchange Buyer ID"}
        </label>
      ),
      onCellClicked: (params) => navigate("/buyerProfile/" + params.data.id),
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell fill-red font-bold',
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "funds1031",
      headerName: "Amount w/1031 Exchange (MAX. Down Payment)",
      sortable: true,
      cellRenderer: (params) => (
        <label>
          <CurrencyFormat
            value={params.value !== null ? params.value : 0}
            decimalScale={0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </label>
      ),
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "buyingPower",
      headerName: "Max Buying Power",
      cellRenderer: (params) => (
        <label>
          <CurrencyFormat
            value={params.value !== null ? params.value : 0}
            decimalScale={0}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </label>
      ),
      sortable: true,
      // sort: "asc",
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "deadLineDate45DaysString",
      headerName: "Days to 45-Day Deadline",
      cellRenderer: (params) => showClosingDate(params.value, params.data.deadLineDate45DaysColor),
      sortable: true,
      headerTooltip: "Days to 45-Day Deadline",
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell btn-class',
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "closingDate1031String",
      headerName: "Days to 1031 Closing",
      cellRenderer: (params) => showClosingDate(params.value, params.data.closingDate1031Color),

      sortable: true,
      headerTooltip: "Days to 1031 Closing",
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "interestedBrands",
      headerName: "Interested Hotel Brand",
      sortable: true,
      cellRenderer: (params) => (
        <label>{params.value !== null ? `${tableValueParser(params.value)}` : "-"}</label>
      ),
      headerTooltip: "Hotel Brand",
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "interestedCategory",
      headerName: "Interested Market Segment",
      sortable: true,
      cellRenderer: (params) => (
        <label>{params.value !== null ? `${tableValueParser(params.value)}` : "-"}</label>
      ),
      headerTooltip: "Hotel Category",
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "interestedRoomCount",
      headerName: "No.of Rooms",
      cellRenderer: (params) => (
        <label>
          {params.value && params.data.interestedRoomCount !== null ? `${params.value}` : "-"}
        </label>
      ),
      sortable: true,
      // sort: "asc",
      headerTooltip: "No.of Rooms",
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "source",
      headerName: "Deal Source",
      cellRenderer: (params) => {
        return (
          <>
            {user?.dealBox.map((deal) =>
              deal?.buyer?.id == params.data?.id ? (
                <label>{deal ? getDealSource(deal?.source, "seller") : "-"}</label>
              ) : (
                <></>
              )
            )}
          </>
        );
      },
      headerTooltip: "Deal Source",
      sortable: true,
      hide: !dealbox,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Comments",
      field: "comments",
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      minWidth: 200,
      sortable: true,
      headerClass: 'custom-header',
      cellClass: '',
    },
    {
      headerName: "Action",
      cellRenderer: (params) => (
        <div id="buyer-button" className="btn-wrapper">
          {user.interestReceived.map((interest) =>
            interest.buyer.id == params.data.id && interest.sellerAccepted == true ? (
              <button id="buyer-accept-btn" className="accept-btn" disabled>
                Accepted
              </button>
            ) : (
              <></>
            )
          )}

          {user.interestReceived.map((interest) =>
            interest.buyer.id == params.data.id && interest.sellerAccepted == false ? (
              <button id="buyer-reject-btn" className="reject-btn" disabled>
                Rejected
              </button>
            ) : (
              <></>
            )
          )}

          {user.interestReceived.map((interest) =>
            interest.buyer.id == params.data.id && interest.sellerAccepted == null ? (
              <button
                id="buyer-accept-btn"
                className="accept-btn"
                onClick={(e) => handleSellerInterestUpdate(e, params.data, true)}
              >
                <BsCheck style={{ fontSize: "15px" }} />
                Accept
              </button>
            ) : (
              <></>
            )
          )}

          {user.interestReceived.map((interest) =>
            interest.buyer.id == params.data.id && interest.sellerAccepted == null ? (
              <button
                id="buyer-reject-btn"
                className="reject-btn"
                onClick={(e) => handleSellerInterestUpdate(e, params.data, false)}
              >
                <BsCheck style={{ fontSize: "15px" }} />
                Reject
              </button>
            ) : (
              <></>
            )
          )}
        </div>
      ),
      hide: !interest,
      cellClass: "btn-class",
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Remove From Deal Box",
      field: "user.dealBox",
      cellRenderer: (params) => (
        <div id="buyer-button" className="btn-wrapper">
          {user.dealBox.map((interest, key) =>
            interest?.buyer.id == params.data?.id ? (
              <button
                id="buyer-reject-btn"
                className="reject-btn"
                onClick={(e) => {

                  HandleRemoveFromDealBox(e, params, interest)
                }}
              >
                Remove
              </button>
            ) : (
              <></>
            )
          )}
        </div>
      ),
      hide: !dealbox,
      autoSize: true,
      headerClass: 'custom-header',
      cellClass: 'custom-cell',
      wrapText: true,
      autoHeight: true,
    }
  ];

  const onCellEditingStopped = async (params) => {
    const { data, colDef, newValue, oldValue } = params;
    let payload = {}
    try {
      if (column == "buyercolumns") {
        user?.dealBox?.map(async (interest) => {
          if (interest.property.id == data.id) {
            payload = interest

            if ((interest.sellerDeal || interest?.removeFromSellerDealBox) && !interest?.buyerDeal) {
              payload.sellerComments = newValue
              let res = await interestUpdateOfHotel(payload)
            }
            if (!interest.sellerDeal && (interest?.buyerDeal || interest?.removeFromBuyerDealBox)) {
              payload.buyerComments = newValue
              let res = await interestUpdateOfBuyer(payload)
            }
          }
        })
      } else {
        user?.dealBox?.map(async (interest) => {
          if (interest.buyer.id == data.id) {
            payload = interest


            if ((interest.sellerDeal || interest?.removeFromSellerDealBox) && !interest?.buyerDeal) {
              payload.sellerComments = newValue
              let res = await interestUpdateOfHotel(payload)
            }
            if (!interest.sellerDeal && (interest?.buyerDeal || interest?.removeFromBuyerDealBox)) {
              payload.buyerComments = newValue
              let res = await interestUpdateOfBuyer(payload)
            }
          }
        })
      }

    } catch (error) {
      console.log(error)
    }
  };

  const onRowDragEnd = (event) => {
    const { api } = event;
    const rowNode = event.node;

    const updatedData = api.getModel().rowsToDisplay.map((row) => ({
      ...row.data,
      rank: api.getRowNode(row.id).rowIndex.toString(),
    }));

    setNewRankedData(updatedData)

  };

  const getOverlayNoRowsTemplate = (column) => {
    let message = '';
    let buttonText = '';
    let buttonAction = '';

   
    if (column === 'buyercolumns') {
        
                message = "You have no deals";
                buttonText = "Shop Hotels For Sale";
                buttonAction = () => applyFilter('Shop Hotels For Sale');
              
        }
     else if (column === 'propscolumn') {
        
                message = "You have no deals";
                buttonText = "Shop 1031 Buyers";
                buttonAction = () => applyFilter('Shop Hotels For Sale');
             
        }
    

        return `
        <div style="text-align: center; padding: 20px;">
            <br><br>
            <span>${message}</span>
            <br><br>
            <button class="btn btn-primary" id="customButton">${buttonText}</button>
        </div>
    `;
};

  

  return (
    <>
      {
        loading === true ?
          <div className="loading-wrapper">
            <span className="loading-text">Loading </span>
            <span className="loading-icon"></span>
          </div> :

          !dealbox ?
            <BootstrapTable
              pagination={large ? paginationFactory(options) : ""}
              keyField="id"
              data={rows && rows.length ? rows : []}
              columns={column === "buyercolumns" ? buyercolumns : column == "conficolumns" ? conficolumns : propcolumns}
              headerClasses={large === true ? "header-class-lg" : "header-class"}
              noDataIndication={noDataMessage}
              rowStyle={rowStyle}
              rowEvents={rowEvents}
              cellEdit={cellEditFactory({ mode: 'click', blurToSave: true })}
            >
            </BootstrapTable>
            :
            <div >
              <div className='ag-theme-alpine buyer-post-table'>
                <div className={`react-bootstrap-table `}>
                  <AgGridReact
                    columnDefs={column == "buyercolumns" ? buyercolumns_ag : column == "propscolumn" ? propcolumns_ag : column == "conficolumns" ? conficolumns : []}
                    rowData={rows && rows.length && newRankedData.length == 0 ? rows : newRankedData}
                    animateRows={true}
                    getRowStyle={rowStyle}
                    rowClassRules={moveRow}
                    onRowDragEnd={onRowDragEnd}
                    enableCellTextSelection={true}
                    suppressHorizontalScroll={true}
                    onGridReady={onGridReady}
                    rowDragManaged={true}
                    rowDragEntireRow={true}
                    rowDragMultiRow={true}
                    rowSelection={'multiple'}
                    rowDrag={true}
                    suppressClickEdit={true}
                    defaultColDef={{
                      minWidth: 50,
                      resizable: true,
                      autoHeaderHeight: true,
                      wrapHeaderText: true,
                      wrapText: true,
                      autoHeight: true,
                      // cellEditorPopup: true
                    }}
                    onCellEditingStopped={onCellEditingStopped}
                    overlayNoRowsTemplate= {getOverlayNoRowsTemplate(column, activeFilter)}
                    
                  />
                </div>
              </div>
            </div>

      }
    </>
  );
};

export default Table;
