import React from "react";
import { MdLocationPin } from "react-icons/md";
import { ImPhone } from "react-icons/im";
import { FaGlobeAsia, FaTelegramPlane } from "react-icons/fa";
import { useFormik } from "formik";
import { Breadcrumbs, Chip, emphasize, Link, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import styled from "styled-components";
import { toast } from "react-toastify";
import { contactUsApi } from "../../../api/api";
import { contactUsSubjects } from "../../common/utils";

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      subject: "",
      message: "",
    },
    onSubmit: async (values, { resetForm }) => {
      let payload = {
        fullName: values.full_name,
        email: values.email,
        message: values.message,
        subject: values.subject
      }

      const res = await contactUsApi(payload).then((res => {
        toast.success(res, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }))

     
        
      resetForm({ values: "" });
    },
  });

  return (
    <>
      <div className="contact-main-wrapper">
        <div className="parent">
          <div className="child">
            <div className="one">
              <div className="one-wrapper">
                <h1 className="component-header">Contact Us</h1>
                <div className="chat-span">
                  Contact us to make your next deal
                </div>
                <table class="table-contact">
                  <tbody>
                    <tr>
                      <td className="table-field-icon">
                        <div className="contact-icon">
                          <MdLocationPin />
                        </div>
                      </td>
                      <td className="table-field">
                        <span className="field-head">Address : </span>
                        12655 North central expressway # 550 Dallas 75243
                      </td>
                    </tr>
                    <tr>
                      <td className="table-field-icon">
                        <div className="contact-icon">
                          <ImPhone />
                        </div>
                      </td>
                      <td className="table-field">
                        <span className="field-head">Phone : </span>
                        661-808-4998
                      </td>
                    </tr>
                    <tr>
                      <td className="table-field-icon">
                        <div className="contact-icon">
                          <FaTelegramPlane />
                        </div>
                      </td>
                      <td className="table-field">
                        <span className="field-head">Email : </span>
                        info@1031hotels.com
                      </td>
                    </tr>
                    <tr>
                      <td className="table-field-icon">
                        <div className="contact-icon">
                          <FaGlobeAsia />
                        </div>
                      </td>
                      <td className="table-field">
                        <span className="field-head">Website : </span>
                        <a
                          href="https://hotelbrokersinc.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          hotelbrokersinc.com
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="two">
              <div className="two-wrapper">
                <h1 className="component-header">Get in touch</h1>
                <form onSubmit={formik.handleSubmit} className="form-div">
                  <div className="name-email">
                    <div className="name">
                      <label>Full Name</label>
                      <br />
                      <input
                        placeholder="Name"
                        value={formik.values.full_name}
                        name="full_name"
                        onChange={formik.handleChange}
                        required
                        type="text"
                      />
                    </div>
                    <div className="email">
                      <label>Email Address</label>
                      <br />
                      <input
                        placeholder="Email"
                        value={formik.values.email}
                        name="email"
                        required
                        onChange={formik.handleChange}
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="dropdown-container subject ">
                    <label>Subject</label>
                    <br />
                    {/* <label htmlFor="dropdown">What would you like to do?</label> */}
                    <select className="" id="dropdown" name="subject" required value={formik.values.subject} onChange={formik.handleChange}>
                      <option value="">-- Select a subject --</option>
                      {contactUsSubjects.map((option) => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </div>
                  <div className="message">
                    <label>Message</label>
                    <br />
                    <textarea
                      rows="4"
                      placeholder="Message"
                      value={formik.values.message}
                      name="message"
                      onChange={formik.handleChange}
                      type="text"
                    />
                  </div>
                  <div className="submit-wrapper " style={{float:"right"}}>
                    <button type="submit" className="btn btn-primary">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
