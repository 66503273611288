import React, {useEffect, useState, useMemo, useCallback} from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import TableComponent from "../../Tables/TableComponent";
import "bootstrap/dist/css/bootstrap.css";
import {useNavigate} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import CurrencyFormat from 'react-currency-format';


const DashBoardBuyersTable = ({buyersRows}) => {
    const [buyers, setBuyers] = useState([]);
    

    useEffect(() => {
        setBuyers(buyersRows)
        console.log(buyersRows)
    }, []);

   

  const navigate = useNavigate();

  const buyerInterestColumns = [
    {
      dataField: "buyerId",
      text: "1031 Exchange Buyer ID",
      classes: "broker-buyer-interest-th",
      formatter: (value) => <span><b>{value ? value : "1031 Exchange Buyer ID"}</b></span>,
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          
          let id = row.id;
          navigate("/buyerPost/" + id + "/interestsReceived");
        },
      },
    },
    {
      dataField: "buyerName",
      text: "Buyer Name",
      classes: "broker-buyer-interest-th",
      sort: true,
      formatter: (value) => (
        <span>{value !== null ? `${value}` : "-"}</span>
      ),
    },
    {
      dataField: "location",
      text: "Location",
      classes: "broker-buyer-interest-th",
      sort: true,
      formatter: (value) => <span>{value !== null ? `${value}` : "-"}</span>,
    },
    {
      dataField: "totalInterested",
      text: "Total Interests",
      classes: "broker-buyer-interest-th",
      sort: true,
      formatter: (value) => <span>{value !== null ? `${value}` : "-"}</span>,
    },
    {
      dataField: "maxBuyingPower",
      text: "Max Buying Power",
      classes: "broker-buyer-interest-th",
      sort: true,
      formatter: (value) => (
        <CurrencyFormat value={value != null ? value : '-'} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      ),
    }
  ];


    return (
        <div className="col-12">
            <div className="card">
                <TableComponent
                    columns={buyerInterestColumns}
                    products={buyersRows}
                    filterTabs={''}
                    filterBtnTabs={''}
                    searchData={false}
                    noDataMessage={'No New Interests For Buyers'}
                />
            </div>
        </div>
    );
};

export default DashBoardBuyersTable;
