import Cookies from "js-cookie";
import { URL } from "../../constants/constants";
import authapi from "../../axiosInstances/authInstances";
import { USAstates } from "./utils";
import { CgArrowLongDown, CgArrowLongUp } from "react-icons/cg";
// import Cookies from 'universal-cookie';

// const cookies = new Cookies();

export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getToken = (token_label) => {
  return Cookies.get(token_label) || null;
};

export const removeUserSession = () => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("user_type");
  sessionStorage.removeItem("user_id");
  sessionStorage.removeItem("user_email");
  sessionStorage.removeItem("mobileNumber");
  sessionStorage.removeItem("generatedUserId");
  sessionStorage.removeItem("properties");
  sessionStorage.removeItem("user_buyers");
  sessionStorage.removeItem("user_buyer_id");
  sessionStorage.removeItem("user_sellers");
  sessionStorage.removeItem("user_seller_id");
  sessionStorage.removeItem("Appbuyer");
  sessionStorage.removeItem("Appseller");
  sessionStorage.removeItem("currentPostid");
  sessionStorage.removeItem("agent");
  sessionStorage.removeItem("redirectAfterAuth")
  localStorage.removeItem("stateId");
  localStorage.removeItem("login_user_token");
  localStorage.removeItem("uploadFileUrls");
  localStorage.removeItem("registeringUserType");
  localStorage.removeItem("uploaded_file");
  localStorage.removeItem("stateCode");
  localStorage.removeItem("stateName");
  localStorage.removeItem("Approved");
  localStorage.removeItem("email");
  localStorage.removeItem("LoggedIn");
  
  console.log("LOGGED OUT")
  Cookies.remove("JWT_1031");
};

export const setUserSession = (firstName, lastName, user_type, id, email, mobileNumber, userId) => {
  if (firstName !== null)
    sessionStorage.setItem("user", JSON.stringify(`${firstName} ${lastName}`));
  sessionStorage.setItem("user_type", JSON.stringify(user_type));
  sessionStorage.setItem("user_id", JSON.stringify(id));
  sessionStorage.setItem("user_email", JSON.stringify(email));
  sessionStorage.setItem('mobileNumber', JSON.stringify(mobileNumber));
  sessionStorage.setItem('generatedUserId', JSON.stringify(userId));
};

export const getUserType = () => {
  return getUser() != null ? JSON.parse(sessionStorage.getItem("user_type")) : null;
};

export const setUserProperties = (properties) => {
  sessionStorage.setItem("properties", JSON.stringify(properties));
};

export const getUserProperties = () => {
  const properties = sessionStorage.getItem("properties");
  if (properties) return JSON.parse(properties);
  else return null;
};

export const isUserLoggedIn = () => {
  return localStorage.getItem("login_user_token") != undefined && localStorage.getItem("login_user_token") != null ? true : false;
};

export const setDefaultPropName = (defProp) => {
  sessionStorage.setItem("defProp", JSON.stringify(defProp));
};

export const getDefaultPropName = () => {
  const defProp = sessionStorage.getItem("defProp");
  if (defProp) return JSON.parse(defProp);
  else return null;
};

export const addZeroDate = (n) => {
  return n < 10 ? "0" + n : "" + n;
};

export const GetClientTokenCookie = () => {
  if (Cookies.get("AUTH_CODE") != null) {
    return Cookies.get("AUTH_CODE")
  }
  // return localStorage.getItem("login_user_token") 
};


export const GetUserTokenCookie = () => {
  if (Cookies.get("JWT_1031") != null) {
    return Cookies.get("JWT_1031")
  }
  // return localStorage.getItem("login_user_token") 
};

export const getAppUrl = (url) => {
  sessionStorage.setItem("app_url", URL);
}

export const checkUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
}


export const setToken = async () => {
  console.log("Token Called");
  let refreshTokenInterval;
  try {
    const res = await authapi.post(
      "oauth/token?grant_type=client_credentials"
    ).then(res => {
      console.log(res)
      const jsonR = res;
      Cookies.set("AUTH_CODE", jsonR.data.access_token, {
        path: "/",
        maxAge: 1800,
      });

    });

  } catch (err) {
    console.log(err);
  }
  clearInterval(refreshTokenInterval);
  refreshTokenInterval = setInterval(() => setToken(), 25 * 60 * 1000)
};


export const Logout = () => {

  removeUserSession();
  window.location = "/login";
}

export const getDateFormatted = (dateStr) => {
  const date = new Date(dateStr + 'T00:00:00-04:00'); // Set the timezone offset to -4:00 (ET)

  const formattedDate = date.toLocaleDateString('en-US', {
    timeZone: 'America/New_York',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  return formattedDate
}

export const agentHandler = (agent, post) => {
  sessionStorage.setItem("agent", JSON.stringify(agent))
}

export const getAgent = () => {
  let agent = sessionStorage.getItem("agent");
  return JSON.parse(agent);
}

export const getDaysOnMarket = (date) => {


  // Calculate the number of milliseconds since the given date
  const msPassed = Date.now() - new Date(date).getTime();

  // Convert milliseconds to days
  const daysPassed = Math.floor(msPassed / (1000 * 60 * 60 * 24));

  return daysPassed
}

export const tableValueParser = (value) => {
  if (value?.includes("Any")) {
    return "Any"
  }

  return value?.replace(/,/g, ', ');
}

export const stateFormatter = (value) => {
  if (value?.length < 4) {
    let states = value.map((s, index) => {
      if (index < value.length - 1) {
        return `${s.code}, `;
      } else {
        return `${s.code}`;
      }
    });
    return states;
  } else {
    return "Any"
  }
};

export const iconHandler = (order) => {
  if (!order)
    return (
      <div className="iconWrapper-small">
        <span style={{ marginRight: "-6px" }}>
          <CgArrowLongUp style={{ color: "#d7d7d7" }} />
        </span>
        <span>
          <CgArrowLongDown style={{ color: "#d7d7d7" }} />
        </span>
      </div>
    );
  else if (order === "asc")
    return (
      <div className="iconWrapper-small">
        <span style={{ marginRight: "-6px" }}>
          <CgArrowLongUp />
        </span>
        <span>
          <CgArrowLongDown style={{ color: "#d7d7d7" }} />
        </span>
      </div>
    );
  else if (order === "desc")
    return (
      <div className="iconWrapper-small">
        <span style={{ marginRight: "-6px" }}>
          <CgArrowLongUp style={{ color: "#d7d7d7" }} />
        </span>
        <span>
          <CgArrowLongDown />
        </span>
      </div>
    );
  return null;
};


export const stateFormaterByIds = (value) => {
  let stateIds = value?.split(',')
  if (stateIds?.length < 4) {
    
    let states = stateIds?.map((id, index) => {
      if (index < parseInt(stateIds?.length - 1)) {
        return `${USAstates[id].code}, `;
      } else {
        return `${USAstates[id].code}`;
      }
    });
    return states;
  } else {
    return "Any"
  }
}

export const dealStatusMask = (value) => {
  if (value == "Approved") {
    return "For Sale"
  } else if (value == "In-contract"){
    return "In Contract"
  } else {
    return value
  }
}

